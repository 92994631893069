<template>
  <div>
    <v-card id="form-users" :loading="fetchLoading">
      <v-card-title dark class="panel-title">
        Ajouter un(e) utilisateur(trice)
      </v-card-title>
      <v-spacer />
      <v-card-text v-if="!fetchLoading" class="pb-0">
        <v-row>
          <v-col cols="12" lg="6">
            <v-select
              v-model="newUser.role"
              :items="rolesList"
              label="Rôle"
              required
            />
          </v-col>
          <v-col cols="12" lg="5">
            <v-autocomplete
              v-model="newUser.id_organisation"
              :items="organisationsSelectList"
              label="Organisation"
              :disabled="orgaForced"
              required
            />
          </v-col>
          <v-col cols="12" lg="1" class="justify-center align-self-center">
            <v-btn
              v-tippy
              color="success"
              fab
              dark
              small
              content="Ajouter une organisation"
              @click="closePanelOpenOrga"
            >
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <hr v-if="newUser.id_organisation" class="w-90 ma-auto" />

      <!-- Last field of form -->
      <v-card-text v-if="newUser.id_organisation" class="pt-0">
        <v-row>
          <v-col cols="12" lg="6" class="pb-0">
            <v-text-field
              v-model="newUser.firstname"
              :rules="userRules.firstname"
              label="Prénom"
              required
            />
          </v-col>
          <v-col cols="12" lg="6" class="pb-0">
            <v-text-field
              v-model="newUser.lastname"
              :rules="userRules.lastname"
              label="Nom"
              required
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="6" class="py-0">
            <v-text-field
              v-model="newUser.email"
              :rules="userRules.email"
              label="Email de l'utilisateur"
              required
            />
          </v-col>
          <v-col cols="12" lg="6" class="py-0">
            <v-autocomplete
              v-model="newUser.id_consultant"
              :items="consultantsList"
              label="Consultant"
              :disabled="isNewAdmin"
              required
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="12" class="py-0">
            <v-text-field
              v-model="newUser.link_drive"
              label="Lien Google Drive"
              placeholder="https://"
              :disabled="isNewAdmin"
              required
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="newUser.id_organisation" class="justify-end">
        <v-btn
          color="success"
          class="mr-4"
          :loading="sendLoading"
          @click="sendForm"
        >
          Enregistrer
        </v-btn>
        <v-btn
          color="secondary"
          class="mr-4"
          :loading="sendLoading"
          @click="closePanel"
        >
          Annuler
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CreateUsers',
  data() {
    return {
      fetchLoading: true,
      sendLoading: false,
      orgaForced: false,
      newUser: {
        firstname: '',
        lastname: '',
        email: '',
        role: 'USER',
        link_drive: '',
        status: 'PENDING',
        id_consultant: null,
        id_organisation: null,
      },
      userRules: {
        firstname: [(v) => !!v || 'Prénom requis'],
        lastname: [(v) => !!v || 'Nom requis'],
        email: [
          (v) => !!v || 'Email requis',
          (v) =>
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
              v
            ) || 'Email non valide',
        ],
      },
    }
  },
  computed: {
    ...mapGetters(['getOrganisationsSelectList', 'getConsultantsList']),
    rolesList() {
      return this.$enums.roleTypes
    },
    organisationsSelectList() {
      return this.getOrganisationsSelectList
    },
    consultantsList() {
      return this.getConsultantsList
    },
    isNewAdmin() {
      return this.newUser.role === 'SUPERADMIN' || this.newUser.role === 'ADMIN'
    },
  },
  watch: {
    // On change
    isNewAdmin() {
      if (this.isNewAdmin) {
        // orga = explorys
        if (this.organisationsSelectList.length) {
          const explorysOrga = this.organisationsSelectList.find(
            (i) => this.$utils.isExplorys(i.text)
          )
          this.newUser.id_organisation = explorysOrga.value
          this.orgaForced = true
        }
      } else {
        this.newUser.id_organisation = null
        this.orgaForced = false
      }
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions([
      'fetchOrganisationsSelectList',
      'fetchConsultantsList',
      'createUser',
      'togglePanel',
      'hidePanel',
      'setReloadingNeed',
      'setPanelForm',
      'clearPanelItemId',
    ]),
    init() {
      this.fetchOrganisationsSelectList().then((reponses) => {
        this.fetchLoading = false
      })

      this.fetchConsultantsList()
    },
    sendForm() {
      // Check empty fields
      if (
        !this.newUser.firstname ||
        !this.newUser.lastname ||
        !this.newUser.email
      ) {
        this.$awn.warning('Vous devez remplir tous les champs du formulaire')
        return
      } else if (
        this.newUser.role === 'USER' &&
        (!this.newUser.id_consultant || !this.newUser.link_drive)
      ) {
        this.$awn.warning('Vous devez remplir tous les champs du formulaire')
        return
      }

      // Trigger loading
      this.sendLoading = true

      this.createUser(this.newUser)
        .then((response) => {
          this.$awn.success(
            "L'utilisateur(rice) <b>" +
              this.newUser.firstname +
              ' ' +
              this.newUser.lastname +
              '</b> a été créé(e) avec succès'
          )
          this.closePanel()
        })
        .catch((e) => {
          this.$awn.alert("Erreur lors de la création de l'utilisateur(rice)")
          // Disable loading
          this.sendLoading = false
        })
    },
    closePanelOpenOrga() {
      this.hidePanel()
      this.clearPanelItemId()
      this.setPanelForm('createorganisations')
      this.togglePanel()
      // Disable loading
      this.sendLoading = false
    },
    closePanel() {
      this.hidePanel()
      this.setReloadingNeed(true)
      // Disable loading
      this.sendLoading = false
    },
  },
}
</script>
