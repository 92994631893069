<template>
  <v-container id="b-o-organisations" fluid class="p-0">
    <v-row>
      <v-col cols="12" lg="4">
        <h3>Gestion des organisations</h3>
      </v-col>
      <v-col cols="12" lg="4">
        <v-text-field
          v-model="searchString"
          label="Recherche"
          prepend-icon="mdi-magnify"
          clear-icon="mdi-close-circle-outline"
          clearable
          dense
          counter="3"
          @click:clear="clearSearch"
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="4" class="text-right">
        <v-btn
          v-if="loggedUser.role === 'SUPERADMIN'"
          dark
          color="primary"
          @click="openPanelCreateOrganisation"
        >
          <v-icon class="mr-2">fa fa-plus</v-icon>
          Ajouter une organisation
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="gotOrganisationsList"
      class="elevation-1"
      light
      dense
      :page.sync="page"
      :items-per-page="itemsPerPage"
      :loading="isLoading"
      loading-text="Chargement... Veuillez patienter."
      hide-default-footer
    >
      <template v-slot:[`item.show_in_trombi`]="{ item }">
        <div class="w-fit-content mx-auto">
          <v-switch v-model="item.show_in_trombi" readonly />
        </div>
      </template>

      <template v-slot:[`item.logo`]="{ item }">
        <v-avatar size="36">
          <v-img v-if="item.id_logo" :src="item.logo_data[0]" />
          <v-img
            v-if="!item.id_logo"
            :src="require('@images/company_defaut.jpg')"
          />
        </v-avatar>
      </template>

      <template v-slot:[`item.date_entry`]="{ item }">
        {{ $utils.formatDate(item.date_entry) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          v-tippy
          :disabled="$utils.isExplorys(item.name)"
          color="success"
          class="fs-16-btn mr-3"
          icon
          small
          :dark="!$utils.isExplorys(item.name)"
          content="Voir sa vitrine"
          @click="redirectOrganisation(item.id)"
        >
          <v-icon>fas fa-eye</v-icon>
        </v-btn>
        <v-btn
          v-if="isSuperAdmin"
          v-tippy
          :disabled="$utils.isExplorys(item.name)"
          color="primary"
          class="fs-16-btn mr-2"
          icon
          small
          :dark="!$utils.isExplorys(item.name)"
          content="Modifier l'organisation"
          @click="openPanelEditOrganisation(item.id)"
        >
          <v-icon>fas fa-edit</v-icon>
        </v-btn>
        <v-btn
          v-if="isSuperAdmin"
          v-tippy
          :disabled="$utils.isExplorys(item.name)"
          color="red"
          class="fs-16-btn"
          icon
          small
          :dark="!$utils.isExplorys(item.name)"
          content="Supprimer l'organisation"
          @click.native="confirmDeleteOrganisation(item.id, item.name)"
        >
          <v-icon>fas fa-trash-alt</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-pagination
      v-model="page"
      :length="pageCount"
      class="mt-2"
    ></v-pagination>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'OrganisationsManagement',
  metaInfo() {
    return null
  },
  components: {},
  data() {
    return {
      headers: [
        {
          text: 'ID',
          value: 'id',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Affichage Trombi',
          value: 'show_in_trombi',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Logo',
          value: 'logo',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Nom',
          value: 'name',
          sortable: false,
          align: 'center',
        },
        {
          text: "Date d'entrée chez explorys",
          value: 'date_entry',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Description',
          value: 'description',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Emplacement',
          value: 'place_name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Programme suivi',
          value: 'program_name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'E-mail',
          value: 'contact_email',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Téléphone',
          value: 'contact_phone',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'center',
          width: '140px',
        },
      ],
      isLoading: true,
      searchString: '',
      itemsPerPage: 10,
      page: 1,
      rules: {
        info: (value) => value.length < 3 || 'min 3 caractères - supprimer pour réinitialiser',
      },
    }
  },
  computed: {
    ...mapGetters([
      'getLoggedUser',
      'getOrganisationsList',
      'getOrganisationsCount',
      'isPanelDisplayed',
      'isTableNeedsReloading',
    ]),
    loggedUser() {
      return this.getLoggedUser
    },
    isSuperAdmin() {
      return this.loggedUser.role === 'SUPERADMIN'
    },
    gotOrganisationsList() {
      return this.getOrganisationsList
    },
    pageCount() {
      return Math.ceil(this.getOrganisationsCount / this.itemsPerPage)
    },
    requestArgs() {
      const args = {
        search: '',
        limit: 0,
        offset: 0,
      }
      args.search = this.searchString
      args.limit = this.itemsPerPage
      args.offset = (this.page - 1) * this.itemsPerPage
      return args
    },
    panelDisplayed() {
      return this.isPanelDisplayed
    },
    tableNeedsReloading() {
      return this.isTableNeedsReloading
    },
  },
  watch: {
    searchString: function(value) {
      if ( value ) {
        if (value.length >= 3 || value.length === 0) {
          this.isLoading = true
          this.page = 1
          this.fetchOrganisationsList(this.requestArgs).then((response) => {
            this.isLoading = false
          })
        }
      }
    },
    page: function() {
      this.isLoading = true
      this.fetchOrganisationsList(this.requestArgs).then((response) => {
        this.isLoading = false
      })
    },
    panelDisplayed: function() {
      if (!this.panelDisplayed && this.isTableNeedsReloading) {
        this.isLoading = true
        this.fetchOrganisationsList(this.requestArgs).then((response) => {
          this.isLoading = false
          this.setReloadingNeed(false)
        })
      }
    },
  },
  mounted() {
    this.fetchOrganisationsList(this.requestArgs).then((response) => {
      this.isLoading = false

      // Disable Loading
      this.setLoading(false)
    })
  },
  methods: {
    ...mapActions([
      'togglePanel',
      'fetchOrganisationsList',
      'deleteOrganisationById',
      'setReloadingNeed',
      'setPanelForm',
      'setPanelItemId',
      'clearPanelItemId',
      'setLoading',
    ]),
    clearSearch() {
      this.searchString = ''
      this.isLoading = true
      this.fetchOrganisationsList(this.requestArgs).then((response) => {
        this.isLoading = false
      })
    },
    openPanelCreateOrganisation() {
      this.clearPanelItemId()
      this.setPanelForm('createorganisations')
      this.togglePanel()
    },
    openPanelEditOrganisation(organisationId) {
      this.setPanelItemId(organisationId)
      this.setPanelForm('editorganisations')
      this.togglePanel()
    },
    redirectOrganisation(id) {
      this.$router.push('/extranet/explorateur/' + id)
    },
    confirmDeleteOrganisation(organisationId, organisationName) {
      const onOk = () => {
        this.deleteOrganisation(organisationId, organisationName)
      }
      const onCancel = () => {}
      const confirmMessage =
        "Souhaitez-vous supprimer définitivement l'organisation <b>" +
        organisationName +
        '</b> ?' +
        '<br /><br />' +
        '<b style="color: red;">Attention :</b> les utilisateurs associés seront également supprimés.'

      this.$awn.confirm(confirmMessage, onOk, onCancel, {
        labels: {
          confirm: "Suppression d'organisation",
        },
      })
    },
    deleteOrganisation(id, name) {
      this.isLoading = true
      this.deleteOrganisationById(id)
        .then((response) => {
          this.$awn.success("L'organisation a bien été supprimée")
        })
        .catch((e) => {
          this.$awn.alert(
            'Une erreur est survenue, veuillez contacter le service support'
          )
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
  },
}
</script>
