<template>
  <div class="pa-10">
    <h2 class="text-center mb-2">MENTIONS LEGALES DU SITE</h2>

    <h3 class="my-3">Explorys</h3>

    <p>
      Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin 2004 pour la Confiance dans l'économie numérique, dite L.C.E.N., il est porté à la connaissance des utilisateurs et visiteurs du site Explorys les présentes mentions légales.
      <br><br>
      Le site Explorys est accessible à l'adresse suivante : <b>www.explorys.fr</b> (ci-après "le Site"). L'accès et l'utilisation du Site sont soumis aux présentes " Mentions légales" détaillées ci-après ainsi qu'aux lois et/ou règlements applicables.
      <br>
      La connexion, l'utilisation et l'accès à ce Site impliquent l'acceptation intégrale et sans réserve de l'internaute de toutes les dispositions des présentes Mentions Légales.
    </p>

    <h3 class="text-center my-3">ARTICLE 1 - INFORMATIONS LÉGALES</h3>

    <p>
      En vertu de l'Article 6 de la Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, il est précisé dans cet article l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi.
    </p>

    <h3 class="my-3">A. Editeur du site</h3>
    <p>
      Le site Explorys est édité par : <b>Explorys</b>
      <br>
      Ayant son siège social à l'adresse suivante : 6 quai jean moulin, 69001 Lyon et immatriculée au numéro suivant : 82967644400013.
      <br>
      Capital social : 50 000 euros
      <br>
      Téléphone : 04.72.07.31.25
      <br>
      Adresse e-mail : contact@explorys.fr
      <br>
      <i>ci-après " l'Éditeur "</i>
    </p>

    <h3 class="my-3">B. Développement du site</h3>
    <p>
      Le site Explorys est développé par : <b>Exo-Dev</b>
      <br>
      dont le siège est situé à l'adresse suivante : 6 quai jean moulin, 69001 Lyon
      <br>
      Adresse du site web : <a href="https://exo-dev.fr" target="_blank">https://exo-dev.fr</a>
      <br>
      <i>ci-après " Développeur du site "</i>
    </p>

    <h3 class="my-3">C. Hébergeur</h3>
    <p>
      Le site Explorys est hébergé par : OVH
      <br>
      dont le siège est situé à l'adresse suivante : 2 rue Kellermann - 59100 Roubaix - France
      <br>
      <i>ci-après " l'Hébergeur "</i>
    </p>

    <h3 class="my-3">D. Utilisateurs</h3>
    <p>
      Sont considérés comme utilisateurs tous les internautes qui naviguent, lisent, visionnent et utilisent le site Explorys.
      <br>
      <i>ci-après les " Utilisateurs "</i>
    </p>

    <h3 class="text-center my-3">ARTICLE 2 - ACCESSIBILITÉ</h3>
    <p>
      Le site Explorys est par principe accessible aux Utilisateurs 24/24h et 7/7j, sauf interruption, programmée ou non, pour des besoins de maintenance ou en cas de force majeure.
      <br>
      En cas d'impossibilité d'accès au Site, celui-ci s'engage à faire son maximum afin d'en rétablir l'accès. Le Site ne saurait être tenu pour responsable de tout dommage, quelle qu'en soit la nature, résultant de son indisponibilité.
    </p>

    <h3 class="text-center my-3">ARTICLE 3 - LOI APPLICABLE ET JURIDICTION</h3>
    <p>
      Les présentes Mentions Légales sont régies par la loi française. En cas de différend et à défaut d'accord amiable, le litige sera porté devant les tribunaux français conformément aux règles de compétence en vigueur.
    </p>

    <h3 class="text-center my-3">ARTICLE 4 - CONTACT</h3>
    <p>
      Pour tout signalement de contenus ou d'activités illicites, l'Utilisateur peut contacter l'Éditeur à l'adresse suivante : <b>contact@explorys.fr</b>, ou par courrier recommandé avec accusé de réception adressé à l'Éditeur aux coordonnées précisées dans les présentes mentions légales.
    </p>

    <div class="text-center mb-10">
      <p>
        <i>Le site Explorys vous souhaite une excellente navigation !</i>
      </p>
    </div>

  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'MentionsLegales',
  metaInfo() {
    return {
      title: 'Mentions légales',
    }
  },
  mounted() {
    // Disable Loading
    this.setLoading(false)
  },
  methods: {
    ...mapActions(['setLoading'])
  }
}
</script>
