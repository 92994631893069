<template>
  <v-container id="contact" fluid class="pa-0">
    <v-row justify="center">
      <v-col cols="12" lg="auto">
        <h2>Une question ? Un projet ? Contactez-nous !</h2>
      </v-col>
    </v-row>
    <v-row justify-lg="center">
      <v-col cols="12" lg="6" class="text-center pa-0">
        <div id="map" class="map" v-bind="attrs" v-on="on"> </div>
      </v-col>
      <v-col id="info" cols="12" lg="5">
        <v-row>
          <v-col cols="4" lg="12">
            <v-img :src="require('@images/compass.png')" />
          </v-col>
          <v-col v-if="!selectedFeature.title" cols="8" lg="12">
            <p>
              Pour en savoir plus,<br />
              choisissez votre destination sur la carte !
            </p>
          </v-col>
          <v-col
            v-if="selectedFeature.title && selectedFeature.show_in_vitrine"
            cols="8"
            lg="12"
          >
            <p>
              Vous êtes actuellement à {{ selectedFeature.title }}, <br />
              et nous aussi !
            </p>
            <p>
              Pour nous contacter<br />
              {{ selectedFeature.email }}
            </p>
          </v-col>
          <v-col
            v-if="selectedFeature.title && !selectedFeature.show_in_vitrine"
            cols="8"
            lg="12"
          >
            <p> Vous êtes actuellement à {{ selectedFeature.title }}. </p>
            <p>
              Retrouvez-nous chez notre partenaire !
            </p>
            <p> Pour nous contacter {{ selectedFeature.email }} </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col id="bottom-text" cols="12" lg="auto">
        <p>
          04.72.07.31.25 ou contact@explorys.fr
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Feature from 'ol/Feature'
import Style from 'ol/style/Style'
import Point from 'ol/geom/Point'
import Icon from 'ol/style/Icon'
import Map from 'ol/Map'
import OSM from 'ol/source/OSM'
import Select from 'ol/interaction/Select'
import VectorSource from 'ol/source/Vector'
import View from 'ol/View'
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer'
import { defaults as defaultInteractions } from 'ol/interaction.js'

export default {
  name: 'Contact',
  metaInfo() {
    return null
  },
  components: {},
  data() {
    return {
      on: null,
      attrs: null,
      centerFranceCoords: [254382.430133, 5872809.757207],
      zoomForFrance: 0,
      selectedFeature: {},
      dragPanIsActive: true,
    }
  },
  computed: {
    ...mapGetters(['getPlacesList']),
    breakpointName() {
      return this.$vuetify.breakpoint.name
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    vitrinePlacesList() {
      return this.getPlacesList
    },
  },
  mounted() {
    this.adaptZoom()

    this.setActualPage('contact')
    var raster = new TileLayer({
      source: new OSM(),
    })

    // Projection for coordinates EPSG: 3857
    this.fetchPlacesList().then((response) => {
      var featuresArray = []

      this.vitrinePlacesList.forEach((place) => {
        var geomPos = new Point([place.latitude, place.longitude])

        var pinFeature = new Feature()

        if (place.show_in_vitrine) {
          pinFeature.setStyle(
            new Style({
              image: new Icon({
                src: require('../assets/images/map_pin.png'),
                // scale: 0.5,
                anchor: [0.5, 1],
              }),
            })
          )
        } else {
          pinFeature.setStyle(
            new Style({
              image: new Icon({
                src: require('../assets/images/map_pin2.png'),
                // scale: 0.5,
                anchor: [0.5, 1],
              }),
            })
          )
        }

        pinFeature.setGeometry(geomPos)
        pinFeature.set('title', place.title)
        pinFeature.set('email', place.email)
        pinFeature.set('show_in_vitrine', place.show_in_vitrine)
        featuresArray.push(pinFeature)
      })

      var vector = new VectorLayer({
        title: 'geoloc_layer',
        ref: 'geoloc',
        zIndex: 500,
        map: this.map,
        source: new VectorSource({
          features: featuresArray,
        }),
      })

      if (this.isMobile) {
        this.dragPanIsActive = false
      }

      var map = new Map({
        layers: [raster, vector],
        interactions: defaultInteractions({
          doubleClickZoom: false,
          dragAndDrop: false,
          dragPan: this.dragPanIsActive,
          keyboardPan: false,
          keyboardZoom: false,
          mouseWheelZoom: true,
          pointer: false,
        }),
        // controls: [], // hide control buttons
        target: 'map', // map div id's reference
        // initial display
        view: new View({
          center: this.centerFranceCoords,
          zoom: this.zoomForFrance,
        }),
      })

      var select = new Select() // ref to currently selected interaction

      map.addInteraction(select)
      select.on(
        'select',
        function(e) {
          const selectedStyle = new Style({
            image: new Icon({
              src: require('../assets/images/map_pin_selected.png'),
              // scale: 0.5,
              anchor: [0.5, 1],
            }),
          })

          this.selectedFeature = e.selected[0].values_
          e.target
            .getFeatures()
            .getArray()[0]
            .setStyle(selectedStyle)
        }.bind(this)
      )
    })

    // Disable Loading
    this.setLoading(false)
  },
  methods: {
    ...mapActions(['setActualPage', 'fetchPlacesList', 'setLoading']),
    adaptZoom() {
      // Change zoom with breakpoints
      switch (this.breakpointName) {
        case 'xs':
          this.zoomForFrance = 5
          break
        case 'sm':
          this.zoomForFrance = 5
          break
        case 'md':
          this.zoomForFrance = 5
          break
        case 'lg':
          this.zoomForFrance = 5.5
          break
        case 'xl':
          this.zoomForFrance = 5.5
          break
      }
    },
  },
}
</script>
