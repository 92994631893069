import axios from 'axios'

// all of theses $item are globally declare
/* eslint-disable */
export default {
  install(Vue, options) {
    ;[
      (Vue.prototype.$utils = {
        // Utils Methods
        debugLog(message) {
          if (process.env.NODE_ENV !== 'production') {
            console.log(message)
          }
        },

        // generate UUID
        uuid() {
          var uuidValue = '',
            k,
            randomValue
          for (k = 0; k < 32; k++) {
            randomValue = (Math.random() * 16) | 0

            if (k == 8 || k == 12 || k == 16 || k == 20) {
              uuidValue += '-'
            }
            uuidValue += (k == 12
              ? 4
              : k == 16
              ? (randomValue & 3) | 8
              : randomValue
            ).toString(16)
          }
          return uuidValue
        },

        // Date
        // TODO: set locale with user locale
        formatDate(date) {
          const dateObj = new Date(date)
          return dateObj.toLocaleDateString()
        },
        formatGenericDate(date) {
          const dateObj = new Date(date)
          const month =
            dateObj.getMonth() < 10
              ? '0' + (dateObj.getMonth() + 1)
              : dateObj.getMonth() + 1
          const day =
            dateObj.getDate() < 10 ? '0' + dateObj.getDate() : dateObj.getDate()
          return dateObj.getFullYear() + '-' + month + '-' + day
        },
        formatDateWithHours(date) {
          // console.log(date)
          const dateObj = new Date(date)
          return (
            dateObj.toLocaleDateString() +
            ' ' +
            dateObj.toLocaleTimeString().slice(0, -3)
          )
        },
        formatTime(date) {
          const dateObj = new Date(date)
          return dateObj.toLocaleTimeString().slice(0, -3)
        },
        calcTimeFromNow(date) {
          const oneDay = 1000 * 60 * 60 * 24
          const nowDate = new Date()
          const dateObj = new Date(date)

          var nbDay = (
            Math.round(nowDate.getTime() - dateObj.getTime()) / oneDay
          ).toFixed(0)
          // return nbDay

          if (nbDay == 0) {
            return "Aujourd'hui"
          } else if (nbDay > 0 && nbDay < 7) {
            return 'Il y a ' + nbDay + ' j'
          } else if (nbDay >= 7 && nbDay < 365) {
            var nbSem = (nbDay / 7).toFixed(0)
            return 'Il y a ' + nbSem + ' sem'
          } else if (nbDay >= 365) {
            return 'Le ' + this.formatDate(dateObj)
          }
        },

        // CSS Methods
        flagSelected(locale, currentFlag) {
          if (locale === currentFlag) {
            return 'flag-selected'
          } else {
            return ''
          }
        },
        isAR(locale) {
          if (locale === 'ar') {
            return 'text-right'
          } else {
            return ''
          }
        },
        isExplorys(name) {
          if(name.toLowerCase()==='explorys') {
            return true
          } else {
            return false
          }
        },
        getLangFlag(lang) {
          return lang === 'fr'
            ? 'flag-icon-fr'
            : lang === 'en'
            ? 'flag-icon-gb'
            : lang === 'ar'
            ? 'flag-icon-tn'
            : 'flag-icon-fr'
        },
        escapeSpecial(string) {
          let result = string
          result = result.replace(new RegExp(/\s/g), '')
          result = result.replace(new RegExp(/[àáâãäå]/g), 'a')
          result = result.replace(new RegExp(/æ/g), 'ae')
          result = result.replace(new RegExp(/ç/g), 'c')
          result = result.replace(new RegExp(/[èéêë]/g), 'e')
          result = result.replace(new RegExp(/[ìíîï]/g), 'i')
          result = result.replace(new RegExp(/ñ/g), 'n')
          result = result.replace(new RegExp(/[òóôõö]/g), 'o')
          result = result.replace(new RegExp(/œ/g), 'oe')
          result = result.replace(new RegExp(/[ùúûü]/g), 'u')
          result = result.replace(new RegExp(/[ýÿ]/g), 'y')
          result = result.replace(new RegExp(/\W/g), '')

          return result
        },
        toPascalCase(string) {
          return string.charAt(0).toUpperCase() + string.substr(1).toLowerCase()
        },
        toBase64(file) {
          // console.log('reader')
          // console.log(reader)
          return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
              return resolve(reader.result)
            }
            reader.onerror = (error) => {
              return reject(error)
            }
          })
        },
        toURLRequest(args) {
          let requestURL = ''

          if (
            args.show_in_trombi ||
            args.search || args.order ||
            args.place || args.activity ||
            args.id_organisation ||
            (args.limit && args.limit !== 0) ||
            (args.offset && args.offset !== 0) ||
            args.private !== undefined
          ) {
            requestURL += '?'
            if (args.show_in_trombi) {
              requestURL += 'show_in_trombi=' + args.show_in_trombi + '&'
            }
            if (args.search) {
              requestURL += 's=' + args.search + '&'
            }
            if (args.id_organisation) {
              requestURL += 'id_organisation=' + args.id_organisation + '&'
            }
            if (args.limit && args.limit !== 0) {
              requestURL += 'limit=' + args.limit + '&'
            }
            if (args.offset && args.offset !== 0) {
              requestURL += 'offset=' + args.offset + '&'
            }
            if (args.private !== undefined) {
              requestURL += 'private=' + args.private + '&'
            }
            if (args.order !== undefined) {
              const order_value = args.order.split('-')
              if (order_value.length === 2) {
                requestURL += 'order_by=' + order_value[0] + '&'
                requestURL += 'order_dir=' + order_value[1] + '&'
              } else {
                requestURL += 'order_by=' + args.order + '&'
                requestURL += 'order_dir=asc&'
              }
            }
            // specific for explorers list
            if( args.activity && args.activity !== 0 ) {
              requestURL += 'activity=' + args.activity + '&'
            }
            if( args.place && args.place !== 0 ) {
              requestURL += 'place=' + args.place + '&'
            }
            requestURL = requestURL.slice(0, -1)
          }
          return requestURL
        },
        formatPhoneInput(e) {
          const key = e.key
          // cancel input if it's not a digit
          if (
            /\d{1}/.test(key.charAt(key.length - 1)) ||
            key === 'Backspace' ||
            key === 'Delete' ||
            key === 'ArrowLeft' ||
            key === 'ArrowRight' ||
            key === 'ArrowUp' ||
            key === 'ArrowDown' ||
            key === 'Tab'
          ) {
            // good
          } else {
            e.preventDefault()
          }
        },
      }),
      (Vue.prototype.$API = {
        getToken() {
          var token = null
          if (Vue.prototype.$cookies.get('authKeyTemp')) {
            token = Vue.prototype.$cookies.get('authKeyTemp')
          } else {
            token = Vue.prototype.$cookies.get('authKey')
          }

          if (token) {
            return axios.create({
              baseURL: process.env.VUE_APP_API_ROOT,
              headers: {
                Authorization: 'Bearer ' + token,
              },
            })
          } else {
            return axios.create({
              baseURL: process.env.VUE_APP_API_ROOT,
            })
          }
        },
        withoutToken() {
          return axios.create({
            baseURL: process.env.VUE_APP_API_ROOT,
          })
        },
        displayErrors(e) {
          // If 401 => return
          if (e.response && e.response.status === 401) return
          // Retour server
          if (e.response && e.response.data.content && e.response.data.msg) {
            const serverMessage = e.response.data.content
            Vue.prototype.$awn.alert(
              serverMessage,
              'Erreur ' + e.response.status + ' !'
            )
          } else if (e.response) {
            Vue.prototype.$awn.alert(
              e.response.statusText,
              'Erreur ' + e.response.status + ' !'
            )
          } else {
            Vue.prototype.$awn.alert(e, 'Une erreur est survenue')
          }
          return Promise.reject(e)
        },
      }),
    ]
  },
}
