<template>
  <v-container id="header" fluid class="py-4">
    <v-app-bar
      v-if="section === 'vitrine'"
      absolute
      flat
      class="header__vitrine"
      height="90"
      :class="isSliderPos ? 'sliderHeader' : ''"
    >
      <div class="logo">
        <v-img
          v-if="!isSliderPos"
          v-tippy="{ placement: 'top' }"
          :src="require('@images/explorys_logo_yellow.png')"
          alt="Logo Explorys"
          content="Retour a l'accueil"
          @click="goHome"
        />
        <v-img
          v-if="isSliderPos"
          v-tippy="{ placement: 'top' }"
          :src="require('@images/explorys_logo_blue.png')"
          alt="Logo Explorys"
          content="Retour a l'accueil"
          @click="goHome"
        />
      </div>

      <v-spacer></v-spacer>
      <v-btn-toggle v-if="section === 'vitrine'" right group>
        <v-btn
          v-for="route in routesVitrineList"
          :key="route.path"
          :to="route.path"
          x-large
          text
          dark
          height="64"
          active-class="btn-active-vitrine"
          class="link"
          exact
        >
          {{ route.title }}
        </v-btn>
        <v-btn
          v-if="isLogged"
          to="/extranet"
          x-large
          text
          tile
          dark
          height="64"
          exact
        >
          Mon Espace
        </v-btn>
      </v-btn-toggle>

      <Login />

      <v-menu v-if="isLogged" offset-y class="z-1000">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            icon
            v-bind="attrs"
            class="btn-user"
            outlined
            large
            v-on="on"
          >
            <v-avatar
              v-tippy="{ placement: 'bottom-end' }"
              size="48"
              :content="
                '<b>Menu Utilisateur</b><br /><br />' +
                  loggedUser.firstname +
                  ' ' +
                  loggedUser.lastname +
                  '<br />' +
                  loggedUser.position +
                  ' chez <b>' +
                  loggedUser.organisation_name +
                  '</b>'
              "
            >
              <v-img
                v-if="loggedUser.id_photo === 0 || !loggedUser.id_photo"
                id="photo"
                :src="require('@images/avatar_defaut.png')"
                contain
                width="48"
                height="48"
              />
              <v-img
                v-else
                contain
                :src="loggedUser.photo_data"
                alt="Avatar de l'utilisateur"
                width="48"
                height="48"
              />
            </v-avatar>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in loggedVitrineDropdown"
            :key="index"
            :to="item.path ? item.path : null"
            exact
            color="primary"
            :class="item.class ? item.class : null"
            v-on="{ click: item.function ? item.function : null }"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-app-bar v-if="section === 'extranet'" absolute class="header__extranet">
      <div class="logo">
        <v-img
          v-tippy="{ placement: 'top' }"
          :src="require('@images/explorys_version_bleu_mini.png')"
          alt="Logo Explorys"
          content="Retour au site vitrine"
          @click="goHome"
        />
      </div>

      <v-spacer></v-spacer>
      <!-- SUPERADMIN EXTRANET BUTTONS ========================================================== -->
      <v-btn-toggle
        v-if="
          section === 'extranet' &&
            loggedUser &&
            loggedUser.role === 'SUPERADMIN'
        "
        right
        group
      >
        <v-menu offset-y class="z-1000">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              x-large
              text
              tile
              height="64"
              class="px-1"
              v-on="on"
            >
              Administration
              <i class="fas fa-angle-down px-2"></i>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in backOfficesList"
              :key="index"
              :to="item.path ? item.path : null"
              exact
              color="primary"
              :class="item.class ? item.class : null"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu offset-y class="z-1000">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              x-large
              text
              tile
              height="64"
              class="px-1"
              v-on="on"
            >
              Gestion du site vitrine
              <i class="fas fa-angle-down px-2"></i>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in vitrineList"
              :key="index"
              :to="item.path ? item.path : null"
              exact
              color="primary"
              :class="item.class ? item.class : null"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          to="/extranet/agenda"
          exact
          x-large
          tile
          text
          height="64"
          class="px-1"
        >
          Agenda
        </v-btn>
        <v-btn
          to="/extranet/nos-explorateurs"
          exact
          x-large
          tile
          text
          height="64"
          class="px-1"
        >
          Trombinoscope
        </v-btn>
        <v-btn to="/extranet/" exact x-large tile text height="64" class="px-1">
          Mon Espace
        </v-btn>
      </v-btn-toggle>

      <!-- ADMIN EXTRANET BUTTONS ========================================================== -->
      <v-btn-toggle
        v-if="
          section === 'extranet' && loggedUser && loggedUser.role === 'ADMIN'
        "
        right
        group
      >
        <v-menu offset-y class="z-1000">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              x-large
              text
              tile
              height="64"
              class="px-1"
              v-on="on"
            >
              Administration
              <i class="fas fa-angle-down px-2"></i>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in backOfficesAdminList"
              :key="index"
              :to="item.path ? item.path : null"
              exact
              color="primary"
              :class="item.class ? item.class : null"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          v-for="route in routesExtranetAdminList"
          :key="route.path"
          :to="route.path"
          exact
          x-large
          tile
          text
          class="px-1"
          height="64"
        >
          {{ route.title }}
        </v-btn>
      </v-btn-toggle>

      <!-- USER EXTRANET BUTTONS ========================================================== -->
      <v-btn-toggle
        v-if="
          section === 'extranet' && loggedUser && loggedUser.role === 'USER'
        "
        right
        group
      >
        <v-btn
          v-for="route in routesExtranetUserList"
          :key="route.path"
          :to="route.path"
          exact
          x-large
          tile
          text
          height="64"
        >
          {{ route.title }}
        </v-btn>
      </v-btn-toggle>

      <Login />

      <v-menu v-if="isLogged" offset-y class="z-1000">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            icon
            v-bind="attrs"
            class="btn-user"
            outlined
            v-on="on"
          >
            <v-avatar
              v-tippy="{ placement: 'bottom-end' }"
              size="48"
              :content="
                '<b>Menu Utilisateur</b><br /><br />' +
                  loggedUser.firstname +
                  ' ' +
                  loggedUser.lastname +
                  '<br />' +
                  loggedUser.position +
                  ' chez <b>' +
                  loggedUser.organisation_name +
                  '</b>'
              "
            >
              <v-img
                v-if="loggedUser.id_photo === 0 || !loggedUser.id_photo"
                id="photo"
                :src="require('@images/avatar_defaut.png')"
                contain
                width="48"
                height="48"
              />
              <v-img
                v-else
                contain
                :src="loggedUser.photo_data"
                alt="Avatar de l'utilisateur"
                width="48"
              />
            </v-avatar>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in loggedDropdown"
            :key="index"
            :to="item.path ? item.path : null"
            exact
            color="primary"
            :class="item.class ? item.class : null"
            v-on="{ click: item.function ? item.function : null }"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </v-container>
</template>

<script>
import Login from '@components/Login.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Header',
  components: {
    Login,
  },
  props: {
    section: {
      type: String,
      default: 'vitrine',
    },
  },
  data() {
    return {
      loggedDropdown: [
        {
          title: 'Mon profil utilisateur',
          function: this.openEditUserProfilePanel,
        },
        { title: 'CGU', function: this.redirectCGU },
        { title: 'Déconnexion', class: 'red', function: this.logout },
      ],
      loggedVitrineDropdown: [
        { title: 'CGU', function: this.redirectCGU },
        { title: 'Déconnexion', class: 'red', function: this.logout },
      ],
      routesVitrineList: [
        {
          title: 'Qui sommes-nous',
          path: '/qui-sommes-nous',
        },
        {
          title: 'Nos programmes',
          path: '/nos-programmes',
        },
        {
          title: 'Les explorateurs',
          path: '/nos-explorateurs',
        },
        {
          title: 'Contact',
          path: '/contact',
        },
      ],
      routesExtranetAdminList: [
        {
          title: 'Agenda',
          path: '/extranet/agenda',
        },
        {
          title: 'Trombinoscope',
          path: '/extranet/nos-explorateurs',
        },
        {
          title: 'Mon Espace',
          path: '/extranet/',
        },
      ],
      routesExtranetUserList: [
        {
          title: 'Ma Vitrine',
          path: '/extranet/votre-page',
        },
        {
          title: 'Documents Explorys',
          path: '/extranet/gestion-fichiers',
        },
        {
          title: 'Agenda',
          path: '/extranet/agenda',
        },
        {
          title: 'Trombinoscope',
          path: '/extranet/nos-explorateurs',
        },
        {
          title: 'Mon Espace',
          path: '/extranet/',
        },
      ],
      backOfficesList: [
        {
          title: 'Gestion des fichiers',
          path: '/extranet/gestion-fichiers',
        },
        {
          title: 'Gestion des utilisateurs',
          path: '/extranet/gestion-utilisateurs',
        },
        {
          title: 'Gestion des organisations',
          path: '/extranet/gestion-organisations',
        },
        {
          title: 'Modération des évènements',
          path: '/extranet/validation-evenements',
        },
      ],
      backOfficesAdminList: [
        {
          title: 'Documents Explorys',
          path: '/extranet/gestion-fichiers',
        },
        {
          title: 'Gestion des utilisateurs',
          path: '/extranet/gestion-utilisateurs',
        },
        {
          title: 'Gestion des organisations',
          path: '/extranet/gestion-organisations',
        },
      ],
      vitrineList: [
        {
          title: 'Gestion des partenaires',
          path: '/extranet/gestion-partenaires',
        },
        {
          title: "Gestion de l'équipe Explorys",
          path: '/extranet/gestion-equipe',
        },
        {
          title: 'Gestion des villes',
          path: '/extranet/gestion-villes',
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'getLogged',
      'getLoggedUser',
      'getScrollBox',
      'getScrollPage',
    ]),
    isLogged() {
      return this.getLogged
    },
    loggedUser() {
      return this.getLoggedUser
    },
    colorAppBar() {
      switch (this.section) {
        case 'extranet':
          return 'accent'
        case 'vitrine':
          return 'primary'
        default:
          return null
      }
    },
    isSliderPos() {
      const pos = this.getScrollBox

      if (pos === 0 && this.getScrollPage === 'home') {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    ...mapActions([
      'togglePanel',
      'setPanelForm',
      'setPanelItemId',
      'logOutUser',
    ]),
    goHome() {
      if (this.$router.currentRoute.name !== 'Home') {
        this.$router.push('/')
      }
    },
    openEditUserProfilePanel() {
      this.setPanelItemId(this.loggedUser.id)
      this.setPanelForm('users')
      this.togglePanel()
    },
    redirectCGU() {
      this.$router.push('/extranet/CGU')
    },
    redirectDashboard() {
      this.$router.push('/extranet/')
    },
    logout() {
      this.logOutUser()
      if (this.$router.currentRoute.name !== 'Home') {
        this.$router.push({ name: 'Home' })
      }
      this.$cookies.remove('authKey')
      this.$awn.info('Vous êtes déconnecté(e)')
    },
  },
}
</script>
