<template>
  <v-app-bar
    id="mobile-app-bar"
    ref="mobile-app-bar"
    fixed
    max-height="75"
    :color="isExtranet ? '#fbd700' : '#003e4c'"
    dark
  >
    <v-row>
      <v-col cols="2">
        <v-app-bar-nav-icon
          @click="drawer = true"
        />
      </v-col>
      <v-col
        cols="8"
      >
        <div class="logo-wrapper">
          <v-img
            :src="require('@images/explorys_logo.png')"
            height="32"
            alt="Explorys Short Logo"
          />
        </div>
      </v-col>
      <v-col cols="2" />
    </v-row>

    <v-navigation-drawer
      id="nav-drawer"
      v-model="drawer"
      absolute
      overlay-color="#0f152a"
      overlay-opacity="0.8"
      :elevation="24"
    >
      <perfect-scrollbar>
        <v-list
          id="menu-nav"
          nav
          dense
        >
          <span>Menu</span>
          <v-list-item-group
            v-if="isVitrine"
            active-class="nav-link-active"
          >
            <v-list-item
              to="/"
              exact
            >
              <v-list-item-icon>
                <v-icon>fas fa-home</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Accueil</v-list-item-title>
            </v-list-item>

            <v-list-item
              to="/qui-sommes-nous"
              exact
            >
              <v-list-item-icon>
                <v-icon>fas fa-building</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                Qui sommes-nous
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              to="/nos-programmes"
              exact
            >
              <v-list-item-icon>
                <v-icon>fa fa-columns</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                Nos Programmes
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              to="/nos-explorateurs"
              exact
            >
              <v-list-item-icon>
                <v-icon>fa fa-users</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Les Explorateurs</v-list-item-title>
            </v-list-item>

            <v-list-item
              to="/contact"
              exact
              class="mb-5"
            >
              <v-list-item-icon>
                <v-icon>mdi-phone</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Contact</v-list-item-title>
            </v-list-item>
          </v-list-item-group>

          <v-list-item-group
            v-if="isExtranet"
            active-class="nav-link-active"
          >
            <v-list-item
              to="/extranet"
              exact
            >
              <v-list-item-icon>
                <v-icon>fas fa-user-shield</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Espace personnel</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="loggedUser.role === 'USER'"
              to="/extranet/votre-page"
              exact
            >
              <v-list-item-icon>
                <v-icon>fas fa-desktop</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                Ma vitrine
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              to="/extranet/nos-explorateurs"
              exact
            >
              <v-list-item-icon>
                <v-icon>fas fa-users</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                Trombinoscope
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              to="/extranet/agenda"
              exact
            >
              <v-list-item-icon>
                <v-icon>fas fa-calendar-alt</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                Agenda
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              to="/extranet/gestion-fichiers"
              exact
              class="mb-5"
            >
              <v-list-item-icon>
                <v-icon>fa fa-folder</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                Documents Explorys
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>

          <!-- Login -->
            <span>Mon profil utilisateur</span>
            <v-list-item-group class="mb-5">
              <Login />
            </v-list-item-group>

          <!-- RS links -->
            <span>Réseaux sociaux</span>
            <v-list-item-group>
              <v-list-item
                href="https://www.linkedin.com/company/explorys-incubateur/"
                target="_blank"
                @click="toggleDrawer"
              >
                <v-list-item-icon>
                  <v-icon>fab fa-linkedin</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Linkedin Explorys</v-list-item-title>
              </v-list-item>

              <v-list-item
                href="https://www.instagram.com/explorysevents/?hl=fr"
                target="_blank"
                @click="toggleDrawer"
              >
                <v-list-item-icon>
                  <v-icon>fab fa-instagram</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Instagram Explorys
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        <!-- </div> -->
      </perfect-scrollbar>
    </v-navigation-drawer>
  </v-app-bar>
</template>

<script>
import Login from '@components/Login'
import { mapGetters } from 'vuex'

export default {
  name: 'HeaderMobile',
  components: {
    Login,
  },
  props: {
    section: {
      type: String,
      default: 'vitrine',
    },
  },
  data() {
    return {
      drawer: false,
      group: null,
    }
  },
  computed: {
    ...mapGetters(['getLoggedUser']),
    loggedUser() {
      return this.getLoggedUser
    },
    isVitrine() {
      return this.section === "vitrine"
    },
    isExtranet() {
      return this.section === "extranet"
    },
    isLandscape() {
      return this.$vuetify.breakpoint.mobile && this.$vuetify.breakpoint.height < this.$vuetify.breakpoint.thresholds.xs
    },
  },
  mounted() {
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer
    },
  },
}
</script>
