<template>
  <v-container id="b-o-users" fluid class="p-0">
    <v-row>
      <v-col cols="12" lg="4">
        <h3>Gestion des utilisateurs</h3>
      </v-col>
      <v-col cols="12" lg="4">
        <v-text-field
          v-model="searchString"
          label="Recherche"
          prepend-icon="mdi-magnify"
          clear-icon="mdi-close-circle-outline"
          clearable
          dense
          counter="3"
          @click:clear="clearSearch"
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="4" class="text-right">
        <v-btn
          v-if="loggedUser && loggedUser.role === 'SUPERADMIN'"
          dark
          color="primary"
          @click="openPanelCreateUser"
        >
          <v-icon class="mr-2">fa fa-plus</v-icon>
          Ajouter un(e) utilisateur(trice)
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="gotUsersList"
      class="elevation-1"
      light
      :page.sync="page"
      :items-per-page="itemsPerPage"
      :loading="isLoading"
      loading-text="Chargement... Veuillez patienter."
      hide-default-footer
    >
      <template v-slot:[`item.photo`]="{ item }">
        <v-avatar size="36">
          <v-img v-if="item.id_photo" :src="item.photo_data" />
          <v-img
            v-if="!item.id_photo"
            :src="require('@images/avatar_defaut.png')"
          />
        </v-avatar>
      </template>

      <template v-slot:[`item.role`]="{ item }">
        <v-chip
          :color="$enums.getRoleChipsColor(item.role)"
          text-color="white"
          small
        >
          {{ $enums.getRoleChipsText(item.role) }}
        </v-chip>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          :color="$enums.getStatusChipsColor(item.status)"
          text-color="white"
          small
        >
          {{ $enums.getStatusChipsText(item.status) }}
        </v-chip>
      </template>

      <template v-slot:[`item.creation_date`]="{ item }">
        {{ $utils.formatDate(item.creation_date) }}
      </template>

      <template v-slot:[`item.modification_date`]="{ item }">
        {{ $utils.formatDate(item.modification_date) }}
      </template>

      <template v-slot:[`item.fullname`]="{ item }">
        {{ item.firstname + ' ' + item.lastname }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          v-if="isSuperAdmin && item.status === 'PENDING'"
          v-tippy
          color="success"
          class="fs-16-btn mr-2"
          icon
          small
          dark
          :loading="sendLoading"
          content="Renvoyer le mail de bienvenue"
          @click="launchWelcome(item.id)"
        >
          <v-icon>
            fas fa-paper-plane
          </v-icon>
        </v-btn>

        <v-btn
          v-if="!$utils.isExplorys(item.organisation_name)"
          v-tippy
          color="primary"
          class="fs-16-btn mr-2"
          icon
          small
          dark
          content="Voir sa vitrine"
          @click="redirectOrganisation(item.id_organisation)"
        >
          <v-icon>fas fa-building</v-icon>
        </v-btn>

        <v-btn
          v-if="isSuperAdmin"
          v-tippy
          color="primary"
          class="fs-16-btn mr-2"
          icon
          small
          dark
          content="Modifier l'utilisateur"
          @click="openPanelEditUser(item.id)"
        >
          <v-icon>
            fas fa-edit
          </v-icon>
        </v-btn>

        <v-btn
          v-if="isSuperAdmin"
          v-tippy
          color="red"
          icon
          small
          class="fs-16-btn"
          light
          content="Supprimer l'utilisateur"
          :dark="loggedUser && !loggedUser.id === item.id"
          :disabled="loggedUser && loggedUser.id === item.id"
          @click.native="
            confirmDeleteUser(item.id, item.firstname + ' ' + item.lastname)
          "
        >
          <v-icon>
            fas fa-trash-alt
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-pagination
      v-model="page"
      :length="pageCount"
      class="mt-2"
    ></v-pagination>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'UsersManagement',
  metaInfo() {
    return null
  },

  components: {},
  data() {
    return {
      sendLoading: false,
      headers: [
        {
          text: 'ID',
          value: 'id',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Photo',
          value: 'photo',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Nom',
          value: 'fullname',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Poste',
          value: 'position',
          sortable: false,
          align: 'center',
        },
        {
          text: 'E-mail',
          value: 'email',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Rôle',
          value: 'role',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Statut',
          value: 'status',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Consultant',
          value: 'consultant_name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Organisation',
          value: 'organisation_name',
          sortable: false,
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'right',
          width: '180px',
        },
      ],
      isLoading: true,
      searchString: '',
      itemsPerPage: 10,
      page: 1,
      rules: {
        info: (value) => value.length === 0 || 'min 3 caractères - supprimer pour réinitialiser',
      },
    }
  },
  computed: {
    ...mapGetters([
      'getLoggedUser',
      'getUsersList',
      'getUsersCount',
      'isPanelDisplayed',
      'isTableNeedsReloading',
    ]),
    loggedUser() {
      return this.getLoggedUser
    },
    isSuperAdmin() {
      return this.loggedUser.role === "SUPERADMIN"
    },
    gotUsersList() {
      return this.getUsersList
    },
    pageCount() {
      return Math.ceil(this.getUsersCount / this.itemsPerPage)
    },
    requestArgs() {
      const args = {
        search: '',
        limit: 0,
        offset: 0,
      }
      args.search = this.searchString
      args.limit = this.itemsPerPage
      args.offset = (this.page - 1) * this.itemsPerPage
      return args
    },
    panelDisplayed() {
      return this.isPanelDisplayed
    },
    tableNeedsReloading() {
      return this.isTableNeedsReloading
    },
  },
  watch: {
    searchString: function(value, oldValue) {
      if ( value ) {
        if (value.length >= 3 || value.length === 0) {
          this.isLoading = true
          this.page = 1
          this.fetchUsersList(this.requestArgs).then(() => {
            this.isLoading = false
          })
        }
      } else {
        this.isLoading = true
        this.page = 1
        this.fetchUsersList(this.requestArgs).then(() => {
          this.isLoading = false
        })
      }
    },
    page: function() {
      this.isLoading = true
      this.fetchUsersList(this.requestArgs).then(() => {
        this.isLoading = false
      })
    },
    panelDisplayed: function() {
      if (!this.panelDisplayed && this.isTableNeedsReloading) {
        this.isLoading = true
        this.fetchUsersList(this.requestArgs).then((response) => {
          this.isLoading = false
          this.setReloadingNeed(false)
        })
      }
    },
  },
  mounted() {
    this.fetchUsersList(this.requestArgs).then((response) => {
      this.isLoading = false

      // Disable Loading
      this.setLoading(false)
    })
  },
  methods: {
    ...mapActions([
      'togglePanel',
      'fetchUsersList',
      'deleteUserById',
      'setReloadingNeed',
      'setPanelForm',
      'setPanelItemId',
      'clearPanelItemId',
      'resendWelcomeMail',
      'setLoading'
    ]),
    clearSearch() {
      this.searchString = ''
      this.isLoading = true
      this.fetchUsersList(this.requestArgs).then((response) => {
        this.isLoading = false
      })
    },
    openPanelCreateUser() {
      this.clearPanelItemId()
      this.setPanelForm('crea-users')
      this.togglePanel()
    },
    openPanelEditUser(userId) {
      this.setPanelItemId(userId)
      this.setPanelForm('users')
      this.togglePanel()
    },
    confirmDeleteUser(userId, userName) {
      // Set trigering functions
      const onOk = () => {
        this.deleteUser(userId)
      }
      const onCancel = () => {
      }

      this.$awn.confirm(
        "Souhaitez-vous supprimer définitivement l'utilisateur(rice) <b>" + userName +'</b> ?',
        onOk,
        onCancel,
        {
          labels: {
            confirm: "Suppression de l'utilisateur(rice)",
          },
        }
      )
    },
    deleteUser(id, name) {
      this.isLoading = true
      this.deleteUserById(id)
        .then((response) => {
          this.$awn.success("L'utilisateur(rice) a bien été supprimé(e)")
          this.fetchUsersList(this.requestArgs)
        })
        .catch((e) => {
          this.$awn.alert(
            'Une erreur est survenue, veuillez contacter le service support'
          )
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    launchWelcome(idUser) {
      // Set trigering functions
      const onOk = () => {
        this.sendLoading = true
        this.resendWelcomeMail(idUser)
          .then((response) => {
            this.$awn.success("L'utilisateur(rice) va de nouveau recevoir un mail de bienvenue")
            this.sendLoading = false
          })
          .catch((e) => {
            this.$awn.alert('Une erreur est survenue, veuillez contacter le service support')
            this.sendLoading = false
          })
      }
      const onCancel = () => {
      }

      this.$awn.confirm(
        "Souhaitez-vous renvoyer le mail de bienvenue ?",
        onOk,
        onCancel,
        {
          labels: {
            confirm: "Renvoyer le mail de bienvenue (Première connexion)",
          },
        }
      )
    },
    redirectOrganisation(id) {
      this.$router.push('/extranet/explorateur/' + id)
    },
  },
}
</script>
