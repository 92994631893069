import Vue from 'vue'

// State
const state = {
  expert: {
    id: String,
  },
  expertsList: [],
  expertsCount: 0,
}

// Getters
const getters = {
  getExpert(state) {
    return state.expert
  },
  getExpertsList(state) {
    return state.expertsList
  },
  getExpertsCount(state) {
    return state.expertsCount
  },
}

// Mutations
const mutations = {
  SET_EXPERT(state, expert) {
    state.expert = expert
  },
  SET_EXPERTS(state, expertsList) {
    state.expertsList = expertsList
  },
  SET_EXPERTS_COUNT(state, count) {
    state.expertsCount = count
  },
  DELETE_EXPERT_BY_ID(state, idExpert) {
    state.expert = state.expertsList.filter((n) => n.id !== idExpert)
  },
}

// Actions
const actions = {
  fetchExpertById(store, expertId) {
    return Vue.prototype.$API
      .getToken()
      .get('/experts/' + expertId)
      .then((response) => {
        // Set Expert
        const expert = {
          id: response.data[0].id,
          firstname: response.data[0].firstname,
          lastname: response.data[0].lastname,
          position: response.data[0].position,
          email: response.data[0].email,
          phone: response.data[0].phone,
          link_linkedin: response.data[0].link_linkedin,
          description: response.data[0].description,
          id_photo: response.data[0].id_photo,
          photo_data: response.data[0].photo_data,
          photo_type: response.data[0].photo_type,
          order_card: response.data[0].order_card,
        }
        // Set Expert
        this.commit('SET_EXPERT', expert)
        return Promise.resolve(expert)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  fetchExpertsList(store, args) {
    let requestURL = '/experts'
    // Optionnal arguments for search and pagination
    requestURL += Vue.prototype.$utils.toURLRequest(args)

    return Vue.prototype.$API
      .withoutToken()
      .get(requestURL)
      .then((response) => {
        // Set Experts
        var expertsList = []
        response.data.data.forEach((expert) => {
          expertsList.push(expert)
        })
        // Set Experts
        this.commit('SET_EXPERTS_COUNT', response.data.count)
        this.commit('SET_EXPERTS', expertsList)
        return Promise.resolve(expertsList)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  fetchExpertsListOrder(store) {
    return Vue.prototype.$API
      .getToken()
      .get('/experts/light')
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  createExpert(store, newExpert) {
    return Vue.prototype.$API
      .getToken()
      .post('/experts', newExpert)
      .then((response) => {
        return Promise.resolve(response.data.content)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  updateExpert(store, editExpert) {
    return Vue.prototype.$API
      .getToken()
      .put('/experts/' + editExpert.id, editExpert)
      .then((response) => {
        this.commit('SET_EXPERT', response.data.content)
        return Promise.resolve(response.data.content)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  updateExpertOrder(store, listExperts) {
    return Vue.prototype.$API
      .getToken()
      .patch('/experts/orders', listExperts)
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  deleteExpertById(store, idExpert) {
    return Vue.prototype.$API
      .getToken()
      .delete('/experts/' + idExpert)
      .then((response) => {
        this.commit('DELETE_EXPERT_BY_ID', idExpert)
        return Promise.resolve(response.data.content)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
}

// Export
export default {
  state,
  getters,
  mutations,
  actions,
}
