<template>
  <div>
    <Header v-if="!isMobile" class="extranet" section="extranet" />
    <HeaderMobile v-if="isMobile" section="extranet" />
    <div id="container-extranet">
      <router-view />
    </div>
    <Panel :form="panelForm" />
  </div>
</template>

<script>
import Header from '@components/Header.vue'
import HeaderMobile from '@components/HeaderMobile.vue'
import Panel from '@components/Panel.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    Header,
    HeaderMobile,
    Panel,
  },
  computed: {
    ...mapGetters(['getPanelForm']),
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    panelForm() {
      return this.getPanelForm
    },
  },
}
</script>
