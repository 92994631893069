<template>
  <div>
    <!-- Down -->
    <div v-if="!isLast" id="scroll">
      <v-btn
        id="scroll-btn"
        ref="scrollBtn"
        class="mx-2"
        fab
        small
        outlined
        raised
        @click="scrollDown"
      >
        <v-icon dark>
          mdi-chevron-double-down
        </v-icon>
      </v-btn>
    </div>

    <!-- Up -->
    <div v-if="isLast" id="scroll">
      <v-btn
        id="scroll-btn"
        ref="scrollBtn"
        class="mx-2"
        fab
        small
        outlined
        raised
        @click="scrollUp"
      >
        <v-icon dark>
          mdi-chevron-double-up
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScrollBtn',
  props: {
    fp: {
      type: Object,
      default: null,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentFp: this.fp,
    }
  },
  methods: {
    scrollDown() {
      this.currentFp.api.moveSectionDown()
      this.unFocus()
    },
    scrollUp() {
      this.currentFp.api.moveTo(1)
      this.unFocus()
    },
    unFocus() {
      this.$refs.scrollBtn.$el.blur()
    },
  },
}
</script>
