<template>
  <div>
    <v-card id="form-users">
      <v-card-title v-if="!userid" dark class="panel-title">
        Ajouter un(e) utilisateur(trice)
      </v-card-title>
      <v-card-title v-if="userid && !isSelfEdition" dark class="panel-title">
        Modifier l'utilisateur(rice)
        {{ currentUser.firstname + ' ' + currentUser.lastname }}
      </v-card-title>
      <v-card-title v-if="userid && isSelfEdition" dark class="panel-title">
        Modifier votre profil utilisateur
      </v-card-title>

      <v-spacer></v-spacer>

      <v-skeleton-loader
        v-if="loading"
        id="loader-form"
        :loading="loading"
        type="image"
      ></v-skeleton-loader>
      <v-card-text v-show="!loading">
        <v-form ref="formFile" v-model="valid">
          <v-row justify="space-around">
            <v-col cols="12" lg="6" :class="isMobile ? 'py-0' : ''">
              <v-text-field
                v-model="currentUser.firstname"
                :rules="userRules.firstname"
                label="Prénom"
                required
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" lg="6" :class="isMobile ? 'py-0' : ''">
              <v-text-field
                v-model="currentUser.lastname"
                :rules="userRules.lastname"
                label="Nom"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row justify="space-around">
            <v-col cols="12" lg="6" :class="isMobile ? 'py-0' : ''">
              <v-text-field
                v-model="currentUser.position"
                label="Poste occupé"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" :class="isMobile ? 'py-0' : ''">
              <v-select
                v-model="currentUser.role"
                :items="rolesList"
                label="Rôle"
                :disabled="!isSuperAdmin"
                required
              ></v-select>
            </v-col>
          </v-row>

          <v-row justify="space-around">
            <v-col cols="12" lg="6" :class="isMobile ? 'py-0' : ''">
              <v-text-field
                v-model="currentUser.email"
                label="E-mail"
                :disabled="!isSuperAdmin"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" :class="isMobile ? 'py-0' : ''"> </v-col>
          </v-row>

          <v-row v-if="userid && isSelfEdition" justify="space-around">
            <v-col cols="11">
              <v-checkbox
                v-model="currentUser.accept_notifications"
                label="J'accepte de recevoir les notifications de la plateforme par email"
              />
            </v-col>
          </v-row>

          <v-row v-if="userid && isSelfEdition" justify="space-around">
            <v-col cols="11">
              <v-checkbox
                v-model="isPasswordEdited"
                label="Souhaitez-vous modifier votre mot de passe ?"
              />
            </v-col>
          </v-row>

          <v-expand-transition>
            <v-row v-if="isPasswordEdited" justify="space-around">
              <v-col cols="12" lg="6" :class="isMobile ? 'py-0' : ''">
                <v-text-field
                  v-model="currentUser.password"
                  :rules="isPasswordEdited ? userRules.password : []"
                  label="Mot de passe"
                  :append-icon="showEditPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showEditPassword ? 'text' : 'password'"
                  required
                  @click:append="showEditPassword = !showEditPassword"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="6" :class="isMobile ? 'py-0' : ''">
                <v-text-field
                  v-model="repassword"
                  :rules="isPasswordEdited ? userRules.repassword : []"
                  label="Répétez le mot de passe"
                  :append-icon="showEditPasswordBis ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showEditPasswordBis ? 'text' : 'password'"
                  required
                  @click:append="showEditPasswordBis = !showEditPasswordBis"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-expand-transition>

          <v-row justify="space-around">
            <v-col cols="12" lg="6" :class="isMobile ? 'py-0' : ''">
              <v-text-field
                v-model="currentUser.link_linkedin"
                label="Liens réseaux sociaux"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" :class="isMobile ? 'py-0' : ''">
              <v-text-field
                v-model="currentUser.link_drive"
                label="Lien Google Drive"
                placeholder="https://"
                :disabled="!isAdmin"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="isSuperAdmin" justify="space-around">
            <v-col cols="12" lg="6" :class="isMobile ? 'py-0' : ''">
              <v-autocomplete
                v-model="currentUser.id_consultant"
                :items="consultantsList"
                label="Consultant"
                required
              />
            </v-col>
            <v-col cols="12" lg="6" :class="isMobile ? 'py-0' : ''">
              <v-autocomplete
                v-model="currentUser.id_organisation"
                :items="organisationsSelectList"
                label="Organisation"
                required
              />
            </v-col>
          </v-row>

          <v-row justify="space-around">
            <v-col cols="5">
              <v-img
                v-if="currentUser.id_photo === 0 || !currentUser.id_photo"
                id="photo"
                :src="require('@images/avatar_defaut.png')"
                contain
                max-width="500"
                max-height="150"
              />
              <v-img
                v-else
                id="photo"
                :src="currentUser.photo_data"
                contain
                max-width="500"
                max-height="150"
              />
            </v-col>
            <v-col cols="5">
              <VueDropzone
                id="dropzoneFile"
                ref="dropzoneFile"
                :options="dropzoneOptions"
                @vdropzone-file-added="checkAddedFile"
              />
              <v-btn
                color="red"
                dark
                small
                class="dropzone-trash-btn"
                @click="removeFiles"
              >
                <v-icon small>fas fa-trash-alt</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-spacer></v-spacer>

      <v-card-actions class="justify-end">
        <v-btn
          :disabled="!valid && !loading"
          color="success"
          class="mr-4"
          @click="validate"
        >
          Enregistrer
        </v-btn>
        <!-- <v-btn
          :disabled="!valid && !loading"
          color="success"
          class="mr-4"
          @click="validate"
        >
          Enreg. et + utilisateurs
        </v-btn> -->

        <v-btn color="secondary" class="mr-4" @click="closePanel">
          Annuler
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
/* global _ */
import VueDropzone from 'vue2-dropzone'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'EditUsers',
  components: {
    VueDropzone,
  },
  props: {
    // if userid is set, that's an update else it's a creation
    userid: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      sendLoading: false,
      showEditPassword: false,
      showEditPasswordBis: false,
      currentUser: {
        firstname: '',
        lastname: '',
        position: '',
        email: '',
        password: '',
        role: 'USER',
        link_drive: '',
        status: 'PENDING',
        link_linkedin: '',
        id_consultant: 0,
        id_photo: 0,
        id_organisation: 0,
      },
      userRules: {
        firstname: [(v) => !!v || 'Prénom requis'],
        lastname: [(v) => !!v || 'Nom requis'],
        password: [
          (v) => !!v || 'Mot de passe requis',
          (v) =>
            /^(?=[\x21-\x7E]*[0-9])(?=[\x21-\x7E]*[A-Z])(?=[\x21-\x7E]*[a-z])(?=[\x21-\x7E]*[\x21-\x2F|\x3A-\x40|\x5B-\x60|\x7B-\x7E])[\x21-\x7E]{8,}?/.test(
              v
            ) ||
            'Au minimum : 8 caractères, une majuscule, une minuscule, un chiffre, un caractère spécial',
        ],
        repassword: [
          (v) => v === this.currentUser.password || 'Mots de passe différents',
        ],
      },
      dropzoneOptions: {
        maxFiles: 1,
        autoProcessQueue: false,
        url: function() {},
        thumbnailWidth: 150,
        parallelUploads: 1,
        dictDefaultMessage:
          "<i class='fa fa-cloud-upload'></i>Cliquez ou déposez un fichier ici",
      },
      valid: true,
      loading: true,
      repassword: '',
      isPasswordEdited: false,
      fileIdToDelete: 0,
    }
  },
  computed: {
    ...mapGetters([
      'getFile',
      'getUser',
      'getLoggedUser',
      'getConsultantsList',
      'getOrganisationsSelectList',
    ]),
    gotPhoto() {
      return this.getFile
    },
    gotUser() {
      return this.getUser
    },
    loggedUser() {
      return this.getLoggedUser
    },
    isSelfEdition() {
      if (this.loggedUser.id === this.userid) {
        return true
      } else {
        return false
      }
    },
    isAdmin() {
      return (
        this.loggedUser.role === 'SUPERADMIN' ||
        this.loggedUser.role === 'ADMIN'
      )
    },
    isSuperAdmin() {
      return this.loggedUser.role === 'SUPERADMIN'
    },
    consultantsList() {
      return this.getConsultantsList
    },
    organisationsSelectList() {
      return this.getOrganisationsSelectList
    },
    rolesList() {
      return this.$enums.roleTypes
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
  mounted() {
    if (this.isSuperAdmin) {
      this.fetchConsultantsList()
      this.fetchOrganisationsSelectList()
    }
    // Change default value of fields if it's an update
    if (this.userid) {
      this.fetchUserById(this.userid).then((user) => {
        // user.password = ''
        this.currentUser = _.clone(user, true)
        this.currentUser.password = ''
        this.loading = false
      })
    } else {
      this.loading = false
    }
  },
  methods: {
    ...mapActions([
      'togglePanel',
      'createUser',
      'fetchUserById',
      'patchUser',
      'fetchFile',
      'setReloadingNeed',
      'saveFile',
      'deleteFile',
      'fetchConsultantsList',
      'fetchOrganisationsSelectList',
      'updateLoggedUser',
      'setLoggedUser',
    ]),
    removeFiles() {
      this.$refs.dropzoneFile.removeAllFiles()
    },
    validate() {
      const acceptedFiles = this.$refs.dropzoneFile.getAcceptedFiles()
      if (acceptedFiles.length >= 1) {
        // Send File before User request
        this.sendFile(acceptedFiles)
      }
      // User request without new file
      else {
        this.sendUserRequest()
      }
    },
    sendFile(acceptedFiles) {
      this.$utils
        .toBase64(acceptedFiles[0])
        .then((response) => {
          // set file type from file name
          var fileType = acceptedFiles[0].name.split('.')
          fileType = fileType[fileType.length - 1]
          var firstname = this.currentUser.firstname
          var lastname = this.currentUser.lastname

          const fileToSave = {
            name: acceptedFiles[0].name,
            title: 'Photo User ' + firstname + ' ' + lastname,
            description:
              'Photo User ' +
              firstname +
              ' ' +
              lastname +
              ' sets automatically from user form',
            folder: 'INTERNAL',
            type: fileType,
            data: response,
          }
          this.saveFile(fileToSave)
            .then((response) => {
              this.$awn.success('Fichier enregistré avec succès')

              // Set old file to delete if needed
              if (
                this.currentUser.id_photo &&
                this.currentUser.id_photo !== 0
              ) {
                this.fileIdToDelete = this.currentUser.id_photo
              }

              this.currentUser.id_photo = response.data.id
              this.sendUserRequest()
            })
            .catch((e) => {
              this.$awn.alert("ERREUR lors de l'enregistrement du fichier")
            })
        })
        .catch((e) => {
          this.$awn.alert('ERREUR lors de la lecture du fichier')
        })
    },
    sendUserRequest() {
      // Trigger Loading
      this.sendLoading = true

      // EDIT case
      if (this.userid) {
        // UPDATE loggedUser
        if (this.isSelfEdition) {
          // Check password modification needs
          if (!this.isPasswordEdited) {
            delete this.currentUser.password
          }

          this.updateLoggedUser(this.currentUser)
            .then((response) => {
              this.fetchUserById(this.userid)
                .then((user) => {
                  user.token = this.$cookies.get('authKey')
                  this.setLoggedUser(user)
                  this.$awn.success('Votre profil a été modifié')
                  // Delete old File if needed
                  if (this.fileIdToDelete !== 0) {
                    this.deleteFile(this.fileIdToDelete)
                  }
                  this.closePanel()
                })
                .catch((e) => {
                  this.$awn.alert(
                    'ERREUR lors du rafraichissement du profil utilisateur'
                  )
                  // Disable Loading
                  this.sendLoading = false
                })
            })
            .catch((e) => {
              this.$awn.alert(
                'ERREUR lors de la demande de modification de profil'
              )
              // Disable Loading
              this.sendLoading = false
            })
            .finally((end) => {
              // reset password if it was deleted before call
              if (!this.isPasswordEdited) {
                this.currentUser.password = ''
              }
            })
        }

        // UPDATE another User
        else {
          // Clear password propertie before sending request
          delete this.currentUser.password
          this.patchUser(this.currentUser)
            .then((response) => {
              this.$awn.success(
                "L'utilisateur(rice) <b>" +
                  this.currentUser.firstname +
                  ' ' +
                  this.currentUser.lastname +
                  '</b> a été modifié(e)'
              )
              // Delete old File if needed
              if (this.fileIdToDelete !== 0) {
                this.deleteFile(this.fileIdToDelete)
              }
              this.closePanel()
            })
            .catch((e) => {
              this.$awn.alert(
                "ERREUR lors de la demande de modification d'utilisateur(rice) "
              )
              // Disable Loading
              this.sendLoading = false
            })
            .finally((end) => {
              // reset password if it was deleted before call
              this.currentUser.password = ''
            })
        }
      }
      // POST case
      else {
        this.createUser(this.currentUser)
          .then((response) => {
            this.$awn.success(
              "L'utilisateur(rice) <b>" +
                this.currentUser.firstname +
                ' ' +
                this.currentUser.lastname +
                '</b> a été créé(e) avec succès'
            )
            this.closePanel()
          })
          .catch((e) => {
            this.$awn.alert("Erreur lors de la création de l'utilisateur(rice)")
            // Disable Loading
            this.sendLoading = false
          })
      }
    },
    closePanel() {
      this.fileIdToDelete = 0
      this.togglePanel()
      this.setReloadingNeed(true)
      // Disable Loading
      this.sendLoading = false
    },
    checkAddedFile(File) {
      // event addedFile watcher on dropzone to check file added
      if (File.size >= 5000000) {
        this.$refs.dropzoneFile.removeFile(File)
        this.$awn.warning('Le fichier doit faire moins de 5Mo')
      }
      if (
        File.type !== 'image/png' &&
        File.type !== 'image/jpg' &&
        File.type !== 'image/jpeg'
      ) {
        this.$refs.dropzoneFile.removeFile(File)
        this.$awn.warning('Le fichier doit être une image (format PNG ou JPEG)')
      }
      if (this.$refs.dropzoneFile.getAcceptedFiles().length === 1) {
        this.$refs.dropzoneFile.removeFile(File)
        this.$awn.warning('Une seule image autorisée')
      }
    },
  },
}
</script>
