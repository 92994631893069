<template>
  <v-container id="explorers-list-container" fluid class="mx-auto row">
    <v-container
      id="explorers-list"
      class="section"
      data-tooltip="Liste des explorateurs"
    >
      <v-row class="explorers-list-header" no-gutters>
        <v-col cols="12">
          <h2>
            les explorateurs,<br />
            <small>Entrepreneurs chez explorys</small>
          </h2>
          <p class="pt-4">
            Ils nous ont choisi pour les accompagner dans leur aventure
            entrepreneuriale.
          </p>
        </v-col>
      </v-row>

      <v-row v-if="isMobile">
        <v-col class="explorers-list-show-filters text-center">
          <v-btn @click="showFiltersOnMobile = !showFiltersOnMobile">
            Afficher/Masquer les filtres
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        v-if="!isMobile || showFiltersOnMobile"
        no-gutters
        class="explorers-list-filters justify-center align-stretch"
      >
        <v-col cols="12" lg="2" md="3">
          <v-text-field
            v-model="filterValue"
            label="Rechercher :"
            @keyup.enter.native="launchFilters"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="2" md="3">
          <v-select
            v-model="placeFiltered"
            :items="gotPlacesListSelect"
            label="Agences Explorys"
          ></v-select>
        </v-col>
        <v-col cols="12" lg="2" md="3">
          <v-select
            v-model="activityFiltered"
            :items="gotActivitiesList"
            label="Secteur d'activité"
          ></v-select>
        </v-col>
        <v-col cols="12" lg="auto" md="1" class="py-4">
          <v-btn
            title="Filtrer"
            color="primary"
            class="bold mr-1"
            small
            outlined
            @click="launchFilters"
          >
            OK
          </v-btn>
          <v-btn
            v-if="isFiltered"
            title="Filtrer"
            class="bold"
            color="red"
            outlined
            small
            @click="eraseFilters"
          >
            EFFACER
          </v-btn>
        </v-col>
      </v-row>

      <v-row
        justify="center"
        align="stretch"
        class="explorers-list-items"
        no-gutters
      >
        <div v-if="!apiStatus" class="error-api-is-broken">
          <p>Un problème est survenu, veuillez re-essayer plus tard</p>
        </div>
        <v-col
          v-if="apiStatus && gotOrganisationCount === 0"
          cols="12"
          class="explorers-list-items-no-data py-10"
        >
          <!-- <p>Aucun explorateur n'a été trouvé.</p> -->
        </v-col>
        <v-col
          v-for="orga in gotOrganisationsList"
          v-else
          :key="orga.id"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          class="item-block-orga"
        >
          <v-card class="orga-card" outlined tile>
            <div class="d-flex">
              <img
                v-if="orga.logo_data[0]"
                class="orga-logo"
                :src="orga.logo_data[0]"
                :alt="'Logo ' + orga.name"
              />
              <img
                v-else
                class="orga-logo"
                :src="require('@images/company_defaut.jpg')"
                :alt="'Logo ' + orga.name"
              />
            </div>
            <v-card-text class="px-1 text-center">
              <h3 class="orga-name px-1">{{ orga.name }}</h3>
              <p class="orga-baseline">{{ orga.baseline }}</p>
              <p class="orga-description">{{ orga.description }}</p>

              <ul class="orga-members-list">
                <li v-for="member in orga.members" :key="member">
                  {{ member }}
                </li>
              </ul>

              <div class="orga-infos">
                <p class="contact-title">Contacts</p>
                <p class="orga-infos-mail px-1 mb-0 text-center">
                  <a href="#" @click="decryptEmail(listEmails[orga.id])"
                    >Email</a
                  >
                  <span v-if="orga.link_website">
                    |
                    <a :href="orga.link_website" target="_blank">Site web</a>
                  </span>
                </p>
              </div>

              <div class="orga-actions">
                <v-btn small outlined @click="redirectToOrga(orga.id)">
                  >> Explorer.
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
          <div slot="no-more"> </div>
          <div slot="no-results"> Aucun explorateur n'a été trouvé </div>
        </infinite-loading>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ExplorersList',
  metaInfo() {
    return {
      title: 'Explorateurs',
      meta: [
        {
          name: 'description',
          content: 'Liste des explorateurs et de leur organisation',
        },
      ],
    }
  },

  components: {},
  data() {
    return {
      listEmails: [],
      showFiltersOnMobile: false,
      isFiltered: false,
      activityFiltered: null,
      listActivities: [],
      placeFiltered: null,
      listPlaces: [],
      apiStatus: true,
      filterValue: '',
      sortSelected: 'o.date_entry-desc',
      fp: null,
      isLast: null,
      fpOpt: {
        licenseKey: '0AF69EB3-BBC24272-B09D04A0-C7C9A161',
        // Navigation fullpage
        navigation: false,
        controlArrows: false,
        // Design fullpage
        responsiveWidth: this.$vuetify.breakpoint.thresholds.sm,
        // Scrolling fullpage
        css3: true,
        easingcss3: 'cubic-bezier(0.3,1.18,0.3,1)',
        scrollingSpeed: 1100,
        loopHorizontal: true,
        // Accessibility fullpage
        keyboardScrolling: true,
        recordHistory: false,
        // block with normal scroll
        normalScrollElements: '#explorers-list',
        scrollOverflow: true,
        // Events fullpage
        onLeave: function(origin, destination, direction) {
          this.isLast = destination.isLast
          this.setNewScrollPos(destination.index)
        }.bind(this),
      },
      itemsPerPage: 8,
      page: 1,
      infiniteId: 0, // to track reset List needs
    }
  },
  computed: {
    ...mapGetters([
      'getOrganisationsList',
      'getOrganisationsCount',
      'getPlacesListSelect',
      'getActivitiesList',
    ]),
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    gotOrganisationCount() {
      return this.getOrganisationsCount
    },
    gotOrganisationsList() {
      return this.getOrganisationsList
    },
    gotPlacesListSelect() {
      return this.getPlacesListSelect
    },
    gotActivitiesList() {
      return this.getActivitiesList
    },
    requestArgs() {
      const args = {
        activity: this.activityFiltered,
        place: this.placeFiltered,
        show_in_trombi: true,
        search: this.filterValue,
        limit: this.itemsPerPage,
        offset: 0,
        order: this.sortSelected,
      }
      return args
    },
  },
  watch: {
    gotOrganisationsList: function() {
      if (this.gotOrganisationsList.length) {
        this.gotOrganisationsList.forEach((element) => {
          this.listEmails[element.id] = this.encodedEmail(element.contact_email)
          // element.email_encode = this.encodedEmail(element.contact_email)

          // Override link_website with https://
          if(element.link_website) {
            element.link_website = this.getRSLink(element.link_website)
          }
        })
      }
    },
  },
  mounted() {
    this.setLoading(false)
    // this.fetchPlacesListSelect()
    this.fetchAgencesPlacesListSelect()
    this.fetchActivitiesList()
    this.verifApiStatus()
    this.setActualPage('explorers-list')
  },
  beforeDestroy() {
    this.setListOrgaNull()
  },
  methods: {
    ...mapActions([
      'setActualPage',
      'checkApiStatus',
      'setLoading',
      'fetchActivitiesList',
      'fetchPlacesListSelect',
      'fetchAgencesPlacesListSelect',
      'fetchAddOrganisationsList',
      'setListOrgaNull',
    ]),
    infiniteHandler($state) {
      this.fetchAddOrganisationsList(this.requestArgs).then((response) => {
        if (response.length) {
          this.requestArgs.offset += this.itemsPerPage
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    },
    resetOrganisationsList() {
      this.page = 1
      this.setListOrgaNull()
      this.infiniteId += 1
    },
    launchFilters() {
      if (
        this.activityFiltered !== null ||
        this.placeFiltered !== null ||
        this.filterValue !== ''
      ) {
        this.isFiltered = true
        this.resetOrganisationsList()
      }
    },
    eraseFilters() {
      this.isFiltered = false
      this.activityFiltered = null
      this.placeFiltered = null
      this.filterValue = ''
      this.resetOrganisationsList()
    },
    redirectToOrga(idOrga) {
      this.$router.push({ path: 'explorateur/' + idOrga })
    },
    verifApiStatus() {
      this.checkApiStatus()
        .then((response) => {
          this.apiStatus = true
        })
        .catch((e) => {
          this.apiStatus = false
        })
    },
    encodedEmail(email) {
      return btoa(email)
    },
    decryptEmail(encoded) {
      var address = atob(encoded)
      window.location.href = 'mailto:' + address
    },
    getRSLink(link) {
      if(link[0] === 'h' && link[1] === 't') {
        return link
      } else {
        const newLink = 'https://' + link
        return newLink
      }
    }
  },
}
</script>
