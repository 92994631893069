<template>
  <div>
    <v-card id="form-order-experts">
      <v-card-title dark class="panel-title">
        Gestion de l'ordre des experts
      </v-card-title>

      <v-spacer></v-spacer>

      <v-skeleton-loader
        v-if="loading"
        id="loader-form"
        :loading="loading"
        type="image"
      ></v-skeleton-loader>
      <v-card-text v-show="!loading">
        <v-form ref="formFile" v-model="valid">
          <v-row class="order-experts-row">
            <v-col cols="12" lg="2" class="order-experts-numbers">
              <h4>Ordre</h4>
              <div v-for="number in numExperts" :key="number">
                {{ number }}
              </div>
            </v-col>
            <v-col cols="12" lg="6">
              <h4 class="text-left">Experts</h4>
              <Draggable
                v-model="listExperts"
                class="order-experts-list"
                @start="drag = true"
                @end="drag = false"
              >
                <div
                  v-for="expert in listExperts"
                  :key="expert[0]"
                  class="expert text-left"
                >
                  {{ expert[1] }} {{ expert[2] }}
                </div>
              </Draggable>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-spacer></v-spacer>

      <v-card-actions class="justify-end">
        <v-btn
          :disabled="!valid && !loading"
          color="success"
          class="mr-4"
          :loading="sendLoading"
          @click="validate"
        >
          Enregistrer
        </v-btn>

        <v-btn
          color="secondary"
          class="mr-4"
          :loading="sendLoading"
          @click="closePanel"
        >
          Annuler
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'OrderExperts',
  components: { Draggable },
  data() {
    return {
      listExperts: [],
      numExperts: 0,
      sendLoading: false,
      currentExpert: {
        id: null,
        firstname: '',
        lastname: '',
        order_card: '',
      },
      valid: true,
      loading: true,
    }
  },
  computed: {
    ...mapGetters([]),
  },
  mounted() {
    this.fetchExpertsListOrder()
      .then((response) => {
        this.listExperts = response
        this.numExperts = response.length
        this.loading = false
      })
      .catch((e) => {
        this.loading = false
      })
  },
  methods: {
    ...mapActions([
      'togglePanel',
      'fetchExpertsListOrder',
      'updateExpertOrder',
    ]),
    validate() {
      let order = 1
      this.sendLoading = true
      this.listExperts.forEach((element) => {
        element[3] = order
        order++
      })
      this.updateExpertOrder(this.listExperts)
        .then((response) => {
          this.$awn.success("L'ordre des experts a bien été mis à jour")
          this.sendLoading = false
          this.togglePanel()
        })
        .catch((e) => {
          this.$awn.alert(
            "Une erreur est survenue lors de la mise à jour de l'ordre des experts"
          )
          this.sendLoading = false
        })
    },
    closePanel() {
      this.togglePanel()
      this.setReloadingNeed(false)
      // Disable loading
      this.sendLoading = false
    },
  },
}
</script>
