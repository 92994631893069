import Vue from 'vue'

// State
const state = {
  partner: {
    id: String,
  },
  partnersList: [],
  partnersCount: 0,
}

// Getters
const getters = {
  getPartner(state) {
    return state.partner
  },
  getPartnersList(state) {
    return state.partnersList
  },
  getPartnersCount(state) {
    return state.partnersCount
  },
}

// Mutations
const mutations = {
  SET_PARTNER(state, partner) {
    state.partner = partner
  },
  SET_PARTNERS(state, partnersList) {
    state.partnersList = partnersList
  },
  SET_PARTNERS_COUNT(state, count) {
    state.partnersCount = count
  },
  DELETE_PARTNER_BY_ID(state, idPartner) {
    state.partner = state.partnersList.filter((n) => n.id !== idPartner)
  },
}

// Actions
const actions = {
  fetchPartnerById(store, partnerId) {
    return Vue.prototype.$API
      .getToken()
      .get('/partners/' + partnerId)
      .then((response) => {
        // Set Partner
        const partner = {
          id: response.data[0].id,
          creation_date: response.data[0].creation_date,
          modification_date: response.data[0].modification_date,
          name: response.data[0].name,
          link_website: response.data[0].link_website,
          id_logo: response.data[0].id_logo,
          logo_data: response.data[0].logo_data,
          logo_type: response.data[0].logo_type,
        }
        // Set Partner
        this.commit('SET_PARTNER', partner)
        return Promise.resolve(partner)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  fetchPartnersList(store, args) {
    let requestURL = '/partners'
    // Optionnal arguments for search and pagination
    requestURL += Vue.prototype.$utils.toURLRequest(args)

    return Vue.prototype.$API
      .withoutToken()
      .get(requestURL)
      .then((response) => {
        // Set Partners
        var partnersList = []
        if(response.data.data && response.data.data.length) {
          response.data.data.forEach((partner) => {
            partnersList.push(partner)
          })
        }
        // Set Partners
        this.commit('SET_PARTNERS_COUNT', response.data.count)
        this.commit('SET_PARTNERS', partnersList)
        return Promise.resolve(partnersList)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  createPartner(store, newPartner) {
    return Vue.prototype.$API
      .getToken()
      .post('/partners', newPartner)
      .then((response) => {
        return Promise.resolve(response.data.content)
      })
      .catch((e) => {
        return Vue.prototype.$API.displayErrors(e)
      })
  },
  updatePartner(store, editPartner) {
    return Vue.prototype.$API
      .getToken()
      .put('/partners/' + editPartner.id, editPartner)
      .then((response) => {
        this.commit('SET_PARTNER', response.data.content)
        return Promise.resolve(response.data.content)
      })
      .catch((e) => {
        return Vue.prototype.$API.displayErrors(e)
      })
  },
  deletePartnerById(store, idPartner) {
    return Vue.prototype.$API
      .getToken()
      .delete('/partners/' + idPartner)
      .then((response) => {
        this.commit('DELETE_PARTNER_BY_ID', idPartner)
        return Promise.resolve(response.data.content)
      })
      .catch((e) => {
        return Vue.prototype.$API.displayErrors(e)
      })
  },
}

// Export
export default {
  state,
  getters,
  mutations,
  actions,
}
