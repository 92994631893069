<template>
  <div>
    <v-card id="form-events">
      <v-card-title v-if="!eventid && isUser" dark class="panel-title">
        Proposer un évènement
      </v-card-title>
      <v-card-title v-if="!eventid && !isUser" dark class="panel-title">
        Créer un évènement
      </v-card-title>
      <v-card-title v-if="eventid" dark class="panel-title">
        Modifier l'évènement
      </v-card-title>

      <v-spacer></v-spacer>

      <v-skeleton-loader
        v-if="loading"
        id="loader-form"
        :loading="loading"
        type="image"
      ></v-skeleton-loader>
      <v-card-text v-show="!loading" class="px-6">
        <v-form ref="formFile" v-model="valid">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="title"
                :rules="titleRules"
                label="Titre"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="dateStart"
                :rules="dateStartRules"
                label="Date de début"
                type="date"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="1"></v-col>
            <v-col cols="5">
              <v-text-field
                v-model="timeStart"
                :rules="timeStartRules"
                label="Heure de début"
                type="time"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="dateEnd"
                :rules="dateEndRules"
                label="Date de fin"
                type="date"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="1"></v-col>
            <v-col cols="5">
              <v-text-field
                v-model="timeEnd"
                :rules="timeEndRules"
                label="Heure de fin"
                type="time"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="description"
                :rules="descriptionRules"
                label="Description"
                rows="4"
                auto-grow
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row v-if="isSuperAdmin">
            <v-col cols="12">
              <v-text-field v-model="place" label="Emplacement"></v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="isSuperAdmin">
            <v-col cols="12">
              <v-select
                v-model="status"
                :items="statusList"
                label="Status de l'évènement"
                required
              ></v-select>
              <v-alert
                v-if="status === 'ACTIVE' && !alreadyActive"
                border="right"
                colored-border
                type="warning"
                elevation="2"
                class="text-left"
              >
                Attention ! Une fois activé, tous les explorateurs recevront un mail pour leur présenter l'évènement.
              </v-alert>
            </v-col>
          </v-row>

          <v-row v-if="isSuperAdmin && action === 'modify'">
            <v-col cols="12">
              <v-checkbox v-model="notifyParticipants" label="Notifier les participants de la modification par mail" />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-spacer></v-spacer>

      <v-card-actions class="justify-end">
        <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4"
          :loading="sendLoading"
          @click="validate"
        >
          Enregistrer
        </v-btn>

        <v-btn
          color="secondary"
          class="mr-4"
          :loading="sendLoading"
          @click="closePanel"
        >
          Annuler
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'EvenementsForm',
  components: {},
  props: {
    eventid: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      action: null,
      loading: true,
      sendLoading: false,
      alreadyActive: false,
      valid: true,
      title: '',
      titleRules: [
        (v) => !!v || 'Titre requis',
        (v) => v.length <= 100 || 'Titre de 100 caractères maximum',
      ],
      dateStart: '',
      dateStartRules: [(v) => !!v || 'Date de départ requise'],
      timeStart: '',
      timeStartRules: [(v) => !!v || 'Heure de départ requise'],
      dateEnd: '',
      dateEndRules: [(v) => !!v || 'Date de fin requise'],
      timeEnd: '',
      timeEndRules: [(v) => !!v || 'Heure de fin requise'],
      description: '',
      descriptionRules: [
        (v) => !!v || 'Description requise',
        (v) => v.length <= 255 || 'Description de 256 caractères maximum',
      ],
      status: this.$enums.statusList[1].value,
      statusList: [this.$enums.statusList[0], this.$enums.statusList[1]],
      place: '',
      planner: null,
      notifyParticipants: true,
    }
  },
  computed: {
    ...mapGetters(['getEvent', 'getLoggedUser']),
    loggedUser() {
      return this.getLoggedUser
    },
    isUser() {
      return this.loggedUser.role === 'USER'
    },
    isSuperAdmin() {
      return this.loggedUser.role === 'SUPERADMIN'
    },
  },
  mounted() {
    if (this.eventid) {
      this.getEditedEvent()
    } else {
      this.action = 'create'
      this.loading = false
      this.planner = this.loggedUser.id
      // Set default status
      if(this.isSuperAdmin) {
        this.status = this.$enums.statusList[0].value
      } else {
        this.status = this.$enums.statusList[1].value
      }
    }
  },
  methods: {
    ...mapActions([
      'saveEvent',
      'loadListEvents',
      'togglePanel',
      'clearPanelItemId',
      'getEventById',
      'updateEvent',
      'cleanEventActif',
      'setReloadingNeed',
      'setShowDialogEvent',
    ]),
    getEditedEvent() {
      this.getEventById(this.eventid)
        .then((response) => {
          this.action = 'modify'
          this.title = response.data[0].title
          this.dateStart = this.$utils.formatGenericDate(
            response.data[0].time_start
          )
          this.timeStart = this.$utils.formatTime(response.data[0].time_start)
          this.dateEnd = this.$utils.formatGenericDate(
            response.data[0].time_end
          )
          this.timeEnd = this.$utils.formatTime(response.data[0].time_end)
          this.description = response.data[0].description
          this.place = response.data[0].place
          this.loading = false
          this.planner = response.data[0].id_planner
          if (response.data[0].status === 'PENDING') {
            this.alreadyActive = false
            this.status = 'ACTIVE'
            this.action = 'validate'
          } else {
            this.alreadyActive = true
            this.status = response.data[0].status
          }
        })
        .catch((e) => {
          this.$awn.alert("L'évènement n'a pas été trouvé")
          this.togglePanel()
        })
    },
    validate() {
      // Trigger loading
      this.sendLoading = true

      const dateStart = this.dateStart + ' ' + this.timeStart + ':00'
      const dateEnd = this.dateEnd + ' ' + this.timeEnd + ':00'
      const eventToSave = {
        id: this.eventid,
        title: this.title,
        time_start: dateStart,
        time_end: dateEnd,
        description: this.description,
        place: this.place,
        status: this.status,
        id_planner: this.planner,
        action: this.action,
        notify: this.notifyParticipants,
      }

      if (this.eventid) {
        this.updateEvent(eventToSave)
          .then((response) => {
            this.$awn.success(
              "L'évènement <b>" + this.title + '</b> a été modifié avec succès'
            )
            this.loadListEvents('PENDING')
            this.sendLoading = false
            this.closePanel()
          })
          .catch((e) => {
            this.$awn.alert("ERREUR lors de la modification de l'évènement")
            this.sendLoading = false
          })
      } else {
        this.saveEvent(eventToSave)
          .then((response) => {
            if (this.isSuperAdmin) {
              this.$awn.success("L'évènement a été créé avec succès")
              this.loadListEvents('PENDING')
            } else {
              // Show DialogEvent (in Agenda)
              this.setShowDialogEvent(true)
              this.loadListEvents()
            }
            this.sendLoading = false
            this.closePanel()
          })
          .catch((e) => {
            this.sendLoading = false
            this.$awn.alert("ERREUR lors de la demande de création d'évènement")
          })
      }
    },
    closePanel() {
      this.togglePanel()
      this.cleanEventActif()
      this.clearPanelItemId()
      this.setReloadingNeed(true)
      // Disable loading
      this.sendLoading = false
    },
  },
}
</script>
