import Vue from 'vue'

// State
const state = {
  place: {
    id: String,
  },
  placesList: [],
  placesListSelect: [],
}

// Getters
const getters = {
  getPlace(state) {
    return state.place
  },
  getPlacesList(state) {
    return state.placesList
  },
  getPlacesListSelect(state) {
    return state.placesListSelect
  },
}

// Mutations
const mutations = {
  SET_PLACE(state, place) {
    state.place = place
  },
  SET_PLACES(state, placesList) {
    state.placesList = placesList
  },
  SET_PLACES_SELECT(state, placesList) {
    state.placesListSelect = placesList
  },
  DELETE_PLACE_BY_ID(state, idPlace) {
    state.place = state.placesList.filter((n) => n.id !== idPlace)
  },
}

// Actions
const actions = {
  fetchPlaceById(store, placeId) {
    return Vue.prototype.$API
      .withoutToken()
      .get('/places/' + placeId)
      .then((response) => {
        // Set Place
        this.commit('SET_PLACE', response)
        return Promise.resolve(response)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  fetchPlacesList(store) {
    const requestURL = '/places'
    // Optionnal arguments for search and pagination
    return Vue.prototype.$API
      .withoutToken()
      .get(requestURL)
      .then((response) => {
        // Set Places
        var placesList = []
        response.data.forEach((place) => {
          placesList.push(place)
        })
        // Set Places
        this.commit('SET_PLACES', placesList)
        return Promise.resolve(placesList)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  fetchPlacesListSelect(store) {
    const requestURL = '/places?order_by=title&order_dir=asc'
    return Vue.prototype.$API
      .withoutToken()
      .get(requestURL)
      .then((response) => {
        // Set Places
        var placesList = []
        response.data.forEach((place) => {
          const newPlace = {
            value: place.id,
            text: place.title,
          }
          placesList.push(newPlace)
        })
        // Set Places
        this.commit('SET_PLACES_SELECT', placesList)
        return Promise.resolve(placesList)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  fetchAgencesPlacesListSelect(store) {
    const requestURL = '/places?order_by=title&order_dir=asc&show_in_vitrine=true'
    return Vue.prototype.$API
      .withoutToken()
      .get(requestURL)
      .then((response) => {
        // Set Places
        var placesList = []
        response.data.forEach((place) => {
          const newPlace = {
            value: place.id,
            text: place.title,
          }
          placesList.push(newPlace)
        })
        // Set Places
        this.commit('SET_PLACES_SELECT', placesList)
        return Promise.resolve(placesList)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  updatePlaceDisplay(store, displayState) {
    return Vue.prototype.$API
      .getToken()
      .patch('/places/show_in_vitrine/' + displayState.id, {
        show_in_vitrine: displayState.show_in_vitrine,
      })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
}

// Export
export default {
  state,
  getters,
  mutations,
  actions,
}
