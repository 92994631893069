<template>
  <v-container id="social-network" fluid class="pt-0">
    <!-- UsersMember on mobile -->
    <v-dialog id="dialogMembers" v-model="membersDialog">
      <v-card class="pt-2">
        <v-card-text class="pa-0">
          <v-row
            v-for="member in gotOrganisation.members"
            :key="member.id"
            justify-lg="center"
          >
            <v-col cols="12" lg="12" class="pa-0">
              <v-card elevation="0">
                <v-row>
                  <v-col cols="2" lg="1" class="text-center">
                    <v-avatar size="45">
                      <v-img
                        v-if="member.photo_data === 0 || !member.photo_data"
                        :src="require('@images/avatar_defaut.png')"
                      />
                      <v-img v-else :src="member.photo_data" />
                    </v-avatar>
                  </v-col>
                  <v-col cols="8" lg="9" class="content">
                    <span class="fullname">{{ member.fullname }}</span> <br />
                    <span class="position">{{ member.position }}</span>
                  </v-col>
                  <v-col cols="2" lg="2" class="text-right">
                    <a
                      v-if="member.link_linkedin"
                      :href="member.link_linkedin"
                      target="_blank"
                    >
                      <v-btn
                        v-tippy
                        text
                        small
                        :content="'Réseaux de ' + member.fullname"
                      >
                        <v-icon>
                          fas fa-globe
                        </v-icon>
                      </v-btn>
                    </a>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="primary" @click="membersDialog = false">
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12" lg="4" md="5" sm="12" xs="12" class="orga-card-col">
        <v-skeleton-loader
          v-if="loadingOrganisation"
          id="loader-organisation"
          :loading="loadingOrganisation"
          type="image"
        ></v-skeleton-loader>
        <v-card
          v-if="!loadingOrganisation"
          id="organisation-card"
          color="primary"
          tile
          dark
        >
          <v-btn
            v-if="
              loggedUser &&
                loggedUser.id_organisation === gotOrganisation.id &&
                !isMobile
            "
            id="edit-btn"
            v-tippy="{ placement: 'right' }"
            content="Modifier votre organisation"
            color="accent"
            fab
            large
            text
            elevation="0"
            light
            @click="openPanelEditOrganisationsUsers(gotOrganisation.id)"
          >
            <v-icon>far fa-edit</v-icon>
          </v-btn>
          <v-row>
            <v-col cols="12" class="d-flex img-organisation">
              <img
                v-if="gotOrganisation.id_logo"
                :src="gotOrganisation.logo_data"
              />
              <img
                v-if="!gotOrganisation.id_logo"
                :src="require('@images/company_defaut.jpg')"
              />
            </v-col>
          </v-row>

          <!-- Mobile on -->
          <div v-if="isMobile" class="mobile-container">
            <v-row>
              <v-col id="organisation-name" sm="6" xs="6">
                {{ gotOrganisation.name }}
              </v-col>
              <v-col id="organisation-icones" sm="6" xs="6" align-self="center">
                <v-btn
                  icon
                  outlined
                  :large="isTablet"
                  color="accent"
                  class="mr-2"
                  @click="decryptEmail(encodedEmail)"
                >
                  <v-icon>
                    mdi-email-edit-outline
                  </v-icon>
                </v-btn>
                <a target="_BLANK" :href="gotOrganisation.link_website">
                  <v-btn
                    icon
                    outlined
                    :large="isTablet"
                    color="accent"
                    class="mr-2"
                  >
                    <v-icon>
                      mdi-link-variant
                    </v-icon>
                  </v-btn>
                </a>
                <v-btn
                  icon
                  outlined
                  :large="isTablet"
                  color="accent"
                  @click="membersDialog = true"
                >
                  <v-icon>
                    mdi-account-group-outline
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <div id="organisation-baseline">
              {{ gotOrganisation.baseline }}
            </div>
            <div id="organisation-description">
              <v-icon class="mr-1">
                mdi-domain
              </v-icon>
              {{ gotOrganisation.description }}
            </div>
          </div>

          <!-- Mobile off -->
          <div v-if="!isMobile">
            <v-row>
              <v-col id="organisation-name" cols="12">
                {{ gotOrganisation.name }}
              </v-col>
            </v-row>
            <v-row>
              <v-col id="organisation-baseline" cols="12">
                {{ gotOrganisation.baseline }}
              </v-col>
            </v-row>
            <v-row>
              <v-col id="organisation-description" cols="12">
                {{ gotOrganisation.description }}
              </v-col>
            </v-row>
            <v-row class="pb-5">
              <v-col id="organisation-place" cols="12" lg="3">
                {{ gotOrganisation.place_name }}
              </v-col>
            </v-row>
            <v-row
              v-for="member in gotOrganisation.members"
              :key="member.id"
              justify-lg="center"
            >
              <v-col cols="12" lg="12" class="pa-0">
                <v-card dark elevation="0" color="primary" class="member-card">
                  <v-row>
                    <v-col cols="12" lg="1" class="image">
                      <v-avatar size="45">
                        <v-img
                          v-if="member.photo_data === 0 || !member.photo_data"
                          :src="require('@images/avatar_defaut.png')"
                        />
                        <v-img v-else :src="member.photo_data" />
                      </v-avatar>
                    </v-col>
                    <v-col cols="12" lg="9" class="content">
                      <span class="fullname">{{ member.fullname }}</span> <br />
                      <span class="position">{{ member.position }}</span>
                    </v-col>
                    <v-col cols="12" lg="2" class="text-right">
                      <v-btn
                        v-if="member.link_linkedin"
                        v-tippy
                        :href="member.link_linkedin"
                        target="_blank"
                        text
                        small
                        :content="'Réseaux de ' + member.fullname"
                      >
                        <v-icon>
                          fas fa-globe
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col id="organisation-contact-title" cols="12" lg="8">
                Contacts et réseaux sociaux
              </v-col>
            </v-row>
            <v-row>
              <v-col id="organisation-contact" cols="12">
                <span v-if="gotOrganisation.link_website === null">
                  <a href="#" @click="decryptEmail(encodedEmail)">Email</a>
                </span>
                <span v-else>
                  <a href="#" @click="decryptEmail(encodedEmail)">Email</a> |
                  <a target="_BLANK" :href="gotOrganisation.link_website">
                    {{ gotOrganisation.link_website }}
                  </a>
                </span>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" lg="7" offset-lg="5" offset-md="8">
        <v-card tile class="posts-card">
          <v-row id="posts-display-container">
            <v-col v-if="displayPostCreation" cols="12">
              <PostCreation />
            </v-col>
            <v-col cols="12" class="post-wrapper">
              <div v-for="post in gotPostsList" :key="post.id">
                <PostDisplay
                  class="post-display"
                  :post="post"
                />
              </div>
              <infinite-loading @infinite="infiniteHandler">
                <div slot="spinner">
                  <v-skeleton-loader
                    v-for="n in postsSkeletonNumber"
                    id="loader-posts"
                    :key="n"
                    type="list-item-avatar, divider, list-item-three-line, actions"
                  ></v-skeleton-loader>
                </div>
                <div slot="no-results">
                  <div v-if="gotPostsList.length === 0">
                    <img
                      :src="require('@images/megaphone.png')"
                      alt="Megaphone"
                      width="100"
                      height="100"
                      class="mx-auto mb-5 placeholder-posts-img"
                    />
                    <p class="fs-15 text-primary">
                      Cet explorateur n'est pas très bavard !
                    </p>
                    <v-btn
                      class="explorys-btn mt-10 mb-5"
                      tile
                      to="/extranet/nos-explorateurs"
                      >Consulter les explorateurs</v-btn
                    >
                  </div>
                  <div v-else>
                    Fin des messages
                  </div>
                </div>
                <div slot="no-more">
                  Fin des messages
                </div>
              </infinite-loading>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import PostCreation from '@components/PostCreation.vue'
import PostDisplay from '@components/PostDisplay.vue'

export default {
  name: 'SocialNetwork',
  metaInfo() {
    return {
      title: this.metaTitle,
      meta: [
        // metadatas
        {
          name: 'title',
          content: this.metaTitle,
        },
        {
          name: 'image',
          content: this.metaUrl,
        },
        {
          name: 'description',
          content: this.metaDescription,
        },
        // OG metadatas
        {
          property: 'og:url',
          content: this.metaUrl,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:title',
          content: this.metaTitle,
        },
        {
          property: 'og:description',
          content: this.metaDescription,
        },
        {
          property: 'og:image',
          content: this.metaLogoFile,
        }
      ],
    }
  },
  components: {
    PostCreation,
    PostDisplay,
  },
  data() {
    return {
      loadingOrganisation: true,
      membersDialog: false,
      postsSkeletonNumber: [1, 2, 3],
      metaUrl: this.$route.query.page,
      metaTitle: 'Explorateur',
      metaDescription: 'Description',
      metaLogoFile: '/favicon_explorys.png',
      metaLogoType: 'image/png',
    }
  },
  computed: {
    ...mapGetters([
      'getOrganisation',
      'getLoggedUser',
      'getPostsListSN',
      'isTableNeedsReloading',
      'getPostsArgs',
    ]),
    postsArgs() {
      return this.getPostsArgs
    },
    idRouteOrganisation() {
      return this.$route.params.id_organisation
    },
    // isMaPage() {
    //   return this.$route.path.includes('votre-page')
    // },
    gotOrganisation() {
      return this.getOrganisation
    },
    encodedEmail() {
      return btoa(this.gotOrganisation.contact_email)
    },
    loggedUser() {
      return this.getLoggedUser
    },
    gotPostsList() {
      return this.getPostsListSN
    },
    isReloadingNeeded() {
      return this.isTableNeedsReloading
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    isTablet() {
      return this.$vuetify.breakpoint.smOnly
    },
    displayPostCreation() {
      return (
        this.getLoggedUser &&
        ((this.getLoggedUser.role === 'USER' &&
          !this.$route.params.id_organisation) ||
          parseInt(this.$route.params.id_organisation) ===
            this.getLoggedUser.id_organisation)
      )
    },
  },
  watch: {
    loggedUser() {
      // fetch organisation for USER editing
      if (this.loggedUser && this.loadingOrganisation) {
        this.fetchOrganisationById(this.loggedUser.id_organisation).then(
          (organisation) => {
            this.loadingOrganisation = false
          }
        )
      }
    },
    isReloadingNeeded() {
      if (this.isReloadingNeeded) {
        this.loadingOrganisation = true
        this.getDatas()
      }
    },
  },
  beforeMount() {
    this.clearPostsListSN()
    this.setPostsArgs({
      limit: 10,
      offset: 0,
      private: false,
    })
    this.getDatas()
  },
  methods: {
    ...mapActions([
      'fetchOrganisationById',
      'fetchPostsListSN',
      'setPanelItemId',
      'setPanelForm',
      'togglePanel',
      'setReloadingNeed',
      'clearPostsListSN',
      'setLoading',
      'setPostsArgs',
    ]),
    openPanelEditOrganisationsUsers(organisationId) {
      this.setPanelItemId(organisationId)
      this.setPanelForm('organisationsusers')
      this.togglePanel()
    },
    getDatas() {
      this.loadingOrganisation = true

      if (this.idRouteOrganisation) {
        this.postsArgs.id_organisation = this.idRouteOrganisation
        this.fetchOrganisationById(this.idRouteOrganisation).then(
          (organisation) => {
            this.metaTitle = organisation.name
            this.metaDescription = organisation.description
            this.metaUrl = window.location.href
            if (organisation.id_logo) {
              this.metaLogoFile = window.location.origin + '/logo/' + organisation.id_logo
              this.metaLogoType = 'image/' + organisation.logo_type
            } else {
              this.metaLogoFile = '/favicon_explorys.png'
              this.metaLogoType = 'image/png'
            }

            this.loadingOrganisation = false

            // For Each user --> Add https:// to link
            this.overrideLink()

            // Disable Loading
            this.setLoading(false)
          }
        )
      } else {
        this.postsArgs.id_organisation = this.loggedUser.id_organisation
        this.fetchOrganisationById(this.postsArgs.id_organisation).then(
          (organisation) => {
            this.loadingOrganisation = false

            // For Each user --> Add https:// to link
            this.overrideLink()

            // Disable Loading
            this.setLoading(false)
          }
        )
      }
    },
    overrideLink() {
      // Override link_website with https://
      if(this.gotOrganisation.link_website) {
        this.gotOrganisation.link_website = this.getRSLink(this.gotOrganisation.link_website)
      }

      // For Each user --> Add https:// to link
      this.gotOrganisation.members.forEach(member => {
        if(member.link_linkedin) {
          const memberIndex = this.gotOrganisation.members.findIndex((m) => m.id === member.id)
          this.gotOrganisation.members[memberIndex].link_linkedin = this.getRSLink(member.link_linkedin)
        }
      });
    },
    infiniteHandler($state) {
      this.fetchPostsListSN(this.postsArgs)
        .then((response) => {
          if (response.length) {
            const args = _.clone(this.postsArgs)
            args.offset += 10
            this.setPostsArgs(args)
            $state.loaded()
          } else {
            $state.complete()
          }
      })
    },
    decryptEmail(encoded) {
      var address = atob(encoded)
      window.location.href = 'mailto:' + address
    },
    getRSLink(link) {
      if(link[0] === 'h' && link[1] === 't') {
        return link
      } else {
        const newLink = 'https://' + link
        return newLink
      }
    }
  },
}
</script>
