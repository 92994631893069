<template>
  <v-app>
    <!-- Global site tag (gtag.js) - Google Analytics -->
    <script v-if="isAnalyticsOK" type="application/javascript" async src="https://www.googletagmanager.com/gtag/js?id=G-1D065C33Q1"></script>
    <script v-if="isAnalyticsOK" type="application/javascript">
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'G-1D065C33Q1');
    </script>

    <div id="app">
      <Keywords :page="'ref'" />
      <LoadingPage />

      <!-- Beta Dialog -->
      <v-dialog
        v-model="betaDialog"
        persistent
        max-width="600"
      >
        <v-card>
          <v-card-title>Explorys fait peau neuve !</v-card-title>
          <v-card-text class="text-justify">
            <p>
              Vous vous trouvez sur la version <i><b>beta</b></i> du nouveau site internet d'Explorys.
              Celui-ci est encore en cours de développement et devrait être ouvert courant <i><b>Février 2021</b></i>.
            </p>
            <p class="mb-1">
              En poursuivant la navigation sur ce site, il se peut que vous rencontriez quelques éléments non finalisés.
            </p>
          </v-card-text>
          <v-card-actions class="d-flex justify-end">
            <v-btn
              color="primary"
              @click="betaDialog = false"
            >
              D'accord !
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <router-view v-if="loggedUserReady" :key="$route.fullPath" />
    </div>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import LoadingPage from '@components/LoadingPage.vue'
import Keywords from '@components/KeywordsCloud.vue'
import GridBreakpoints from '@design'

export default {
  name: 'App',
  metaInfo() {
    return {
      title: 'Explorys',
      titleTemplate: '%s | Explorys',
      htmlAttrs: {
        lang: 'fr',
      },
      meta: [
        { name: 'author', content: 'Exo-Dev' },
        { name: 'copyright', content: 'Exo-Dev' },
      ],
    }
  },
  components: {
    Keywords,
    LoadingPage,
  },
  data() {
    return {
      loggedUserReady: false,
      betaDialog: false,
    }
  },
  computed: {
    ...mapGetters(['getLogged', 'getLoggedUser', 'getCookiesPref']),
    loggedUser() {
      return this.getLoggedUser
    },
    isAnalyticsOK() {
      return this.getCookiesPref.tracking
    },
  },
  watch: {
    // On Route Change
    $route(to, from) {
      // Enable Loading
      this.setLoading(true)

      if (to.matched.some((record) => record.meta.roles)) {
        this.hidePanel()
        this.checkValidToken().then((response) => {
          this.checkRouteAuthentication()
        })
      } else {
        this.loggedUserReady = true
      }
    },
  },
  mounted() {
    // check API status
    this.checkApiStatus()
      .then((response) => {})
      .catch((e) => {})

    // in the case there is a cookie but user not logged in
    this.checkValidToken()
      // Check if user have right to this route (by typing the url or refreshing)
    this.checkMeta()

    // Add Analytics Cookies

    // DEBUG FOR RESPONSIVE
    this.onResize()
  },
  methods: {
    ...mapActions([
      'logOutUser',
      'checkLoggedToken',
      'hidePanel',
      'reFetchLoggedUser',
      'checkApiStatus',
      'setLoading',
    ]),
    checkMeta() {
      if (this.$route.meta && this.$route.meta.roles) {
        this.checkRouteAuthentication()
      }
    },
    checkValidToken() {
      return new Promise((resolve, reject) => {
        const token = this.$cookies.get('authKey')

        if (token) {
          this.checkLoggedToken()
            .then((response) => {
              if (!this.loggedUser) {
                this.reFetchLoggedUser()
                  .then((response) => {
                    resolve(response)
                    this.loggedUserReady = true
                  })
                  .catch((e) => {
                    this.resetUserToken()
                    reject(e)
                  })
              }
            })
            .catch((e) => {
              this.resetUserToken()
              reject(e)
            })
        } else {
          this.loggedUserReady = true
        }
      })
    },
    checkRouteAuthentication() {
      const routeRolesAuthorized = this.$route.meta.roles
      const token = this.$cookies.get('authKey')

      if (token) {
        this.checkLoggedToken()
          .then((response) => {
            const currentUser = response.data.user
            // If route have roles and user role is not include
            if (
              routeRolesAuthorized &&
              !routeRolesAuthorized.includes(currentUser.role)
            ) {
              this.redirectForbiddenAccess()
            }
          })
          .catch((e) => {
            this.resetUserToken()
            this.redirectForbiddenAccess()
          })
      } else {
        this.redirectForbiddenAccess()
      }
    },
    resetUserToken() {
      this.$cookies.remove('authKey')
      this.logOutUser()
      this.redirectForbiddenAccess()
      this.loggedUserReady = true
    },
    redirectForbiddenAccess() {
      // Redirect if needed
      if (this.$route.path !== '/') {
        this.$router.push('/')
      }
      // Notif
      this.$awn.warning('Accès interdit !')
    },
    onResize () {
      // DEBUG RESPONSIVE
      this.$utils.debugLog(
        'Current screen size : ' + window.innerWidth + ' x ' + window.innerHeight,
      )
      this.$utils.debugLog(
        'Current Breakpoint : ' + this.$vuetify.breakpoint.name,
      )
      this.$utils.debugLog('Current Mobile ? ' + this.$vuetify.breakpoint.mobile)
      this.$utils.debugLog('Breakpoint object :')
      this.$utils.debugLog(this.$vuetify.breakpoint)
      this.$utils.debugLog('Breakpoint thresholds :')
      this.$utils.debugLog(this.$vuetify.breakpoint.thresholds)
      this.$utils.debugLog('GridBreakpoints :')
      this.$utils.debugLog(GridBreakpoints)
    },
  },
}
</script>

<style lang="scss">
// Design variables and utilities from src/design.
@import '@design';
</style>
