<template>
  <div v-if="cookies" id="cookie-banner-mobile">
    <!-- Cookies button -->
    <v-img
      v-if="isBannerShow"
      :src="require('@images/cookie.png')"
      alt="Cookies Image"
      class="cookie-btn-mobile animate__animated animate__rubberBand animate__delay-2s"
      @click="toggleShowCard"
    />

    <!-- Overlay -->
    <v-overlay
      absolute
      :value="showCard"
      color="#003e4c"
      opacity="0.8"
    />

    <!-- Cookie Card -->
    <v-card
      v-if="showCard"
      v-click-outside="toggleShowCard"
      class="cookie-card-mobile"
    >
      <div class="snack-content">
        <span>On utilise des cookies pour la navigation sur notre site !</span>
      </div>

      <!-- Buttons -->
      <div class="text-center mx-2">
        <v-btn
          block
          color="success"
          @click="allCookies"
        >
          Accepter
        </v-btn>
        <v-btn
          block
          color="error"
          @click="onlyNecessaryCookies"
        >
          Uniquement le nécessaire
        </v-btn>
        <v-btn
          block
          outlined
          color="white"
          @click="triggerMore"
        >
          Plus d'infos
        </v-btn>
      </div>

      <!-- Show More -->
      <v-expansion-panels
        class="snack-expand"
        :class="rgpdDisplayMore ? 'visible' : ''"
        :focusable="true"
        :accordion="true"
        :tile="true"
        dark
      >
        <!-- What is cookies -->
        <v-expansion-panel>
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <v-row>
              <v-col
                cols="8"
                class="text-left"
              >
                Votre confidentialité est notre priorité
              </v-col>
              <v-col cols="4">
                <template v-slot:actions>
                  <v-icon color="primary">
                    $expand
                  </v-icon>
                </template>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-3 fs-10">
            Les cookies sont de très petits fichiers texte qui sont stockés sur
            votre ordinateur lorsque vous visitez un site Web. Nous utilisons
            des cookies à diverses fins et pour améliorer votre expérience en
            ligne sur notre site Web (par exemple, pour mémoriser les
            informations de connexion de votre compte).
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- Necessary -->
        <v-expansion-panel>
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <v-row>
              <v-col
                cols="8"
                class="text-left"
              >
                Cookies nécessaires
              </v-col>
              <v-col cols="4">
                <template v-slot:actions>
                  <v-icon color="primary">
                    $expand
                  </v-icon>
                </template>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-3 fs-10">
            Ces cookies sont essentiels pour vous fournir les services
            disponibles sur notre site Web et vous permettre d’utiliser
            certaines fonctionnalités de notre site Web.

            <!-- Switch -->
            <v-switch
              v-model="isNecessary"
              :success="isNecessary"
              inset
              color="green"
              class="text-center"
            >
              <template v-slot:label>
                Activer les cookies nécessaires au site
              </template>
            </v-switch>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- Tracking -->
        <v-expansion-panel>
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <v-row>
              <v-col
                cols="8"
                class="text-left"
              >
                Cookies de suivi et de performance
              </v-col>
              <v-col cols="4">
                <template v-slot:actions>
                  <v-icon color="primary">
                    $expand
                  </v-icon>
                </template>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-3 fs-10">
            Ces cookies sont utilisés pour collecter des informations permettant
            d'analyser le trafic sur notre site et la manière dont les visiteurs
            utilisent notre site.

            <!-- Switch -->
            <v-switch
              v-model="isTracking"
              :success="isTracking"
              inset
              color="green"
              class="text-center"
            >
              <template v-slot:label>
                Activer les cookies de suivi et de performance
              </template>
            </v-switch>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- Save -->
        <div class="save-wrapper mx-2">
          <v-btn
            block
            color="success"
            @click="saveCookiesPref"
          >
            <v-icon>mdi-check</v-icon> &nbsp; Sauvegarder
          </v-btn>
        </div>
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
/* global _ */
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CookiesBannerMobile',
  props: {},
  data() {
    return {
      showCard: false,
      rgpdDisplayMore: false,
      cookies: null,
      isNecessary: true,
      isTracking: false,
    }
  },
  computed: {
    ...mapGetters(['getCookiesPref', 'getBannerState']),
    cookiesPref() {
      return this.getCookiesPref
    },
    isBannerShow() {
      return this.getBannerState
    },
  },
  mounted() {
    this.loadCookiesPref()
  },
  methods: {
    ...mapActions(['setCookiesPref']),
    toggleShowCard() {
      this.showCard = !this.showCard
    },
    triggerMore() {
      this.rgpdDisplayMore = !this.rgpdDisplayMore
    },
    allCookies() {
      this.isNecessary = true
      this.isTracking = true
      this.saveCookiesPref()
    },
    onlyNecessaryCookies() {
      this.isNecessary = true
      this.isTracking = false
      this.saveCookiesPref()
    },
    saveCookiesPref() {
      // Close Card
      this.showCard = false
      // Build new object
      this.cookies = {
        showBanner: false,
        necessary: this.isNecessary,
        tracking: this.isTracking,
      }
      // Set into Store
      this.setCookiesPref(this.cookies)
      // Only if necessary cookies is checked
      if (this.cookies.necessary) {
        // btoa = convert to b64 - JSON.stringify = get json string from object
        this.$cookies.set('cookiesPrefs', btoa(JSON.stringify(this.cookies)))
      } else {
        this.$cookies.remove('cookiesPrefs')
      }

      if (!this.cookies.tracking) {
        // Manual remove of Analytics Cookies
        this.$cookies.remove('_ga_1D065C33Q1')
        this.$cookies.remove('_ga')
      }
    },
    loadCookiesPref() {
      // Init
      this.cookies = _.clone(this.cookiesPref)
      this.isNecessary = this.cookiesPref.necessary
      this.isTracking = this.cookiesPref.tracking

      // Override with user preferences
      if (this.$cookies.get('cookiesPrefs')) {
        // Get from $cookies
        this.cookies = JSON.parse(atob(this.$cookies.get('cookiesPrefs')))
        this.isNecessary = this.cookies.necessary
        this.isTracking = this.cookies.tracking

        // Set into Store
        this.setCookiesPref(_.clone(this.cookies))

        // Manual remove of Analytics Cookies
        if (!this.cookies.tracking) {
          this.$cookies.remove('_ga_1D065C33Q1')
          this.$cookies.remove('_ga')
        }
      }

    },
  },
}
</script>
