<template>
  <div>
    <v-dialog
      v-model="dialogLogin"
      overlay-color="white"
      overlay-opacity="0.5"
      content-class="loginModale"
    >
      <template v-slot:activator="{ on, attrs }">
        <div v-if="isMobile">
          <!-- No logged -->
          <v-list-item
            v-if="!isLogged"
            v-bind="attrs"
            v-on="on"
          >
            <v-list-item-icon>
              <v-icon>fas fa-lock</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Connexion</v-list-item-title>
          </v-list-item>

          <!-- Logged -->
          <v-list-item
            v-if="isLogged && !$route.path.includes('extranet')"
            to="/extranet"
          >
            <v-list-item-icon>
              <v-icon>fas fa-user-shield</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Espace personnel</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="isLogged && $route.path.includes('extranet')"
            to="/"
          >
            <v-list-item-icon>
              <v-icon>fas fa-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Retour au site vitrine</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="isLogged && $route.path.includes('extranet')"
            @click="showProfilUser"
          >
            <v-list-item-icon>
              <v-icon>fas fa-user</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Modifier mes informations</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="isLogged"
            to="/extranet/CGU"
          >
            <v-list-item-icon>
              <v-icon>fas fa-lock</v-icon>
            </v-list-item-icon>
            <v-list-item-title>CGU</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="isLogged"
            @click="logout"
          >
            <v-list-item-icon>
              <v-icon>mdi-logout-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Déconnexion</v-list-item-title>
          </v-list-item>
        </div>

        <div v-if="!isMobile">
          <a
            v-if="!isLogged"
            v-tippy
            color="primary"
            icon
            class="btn-user"
            outlined
            v-bind="attrs"
            small
            content="Connexion"
            v-on="on"
            @click="verifApiStatus"
          >
            <v-avatar size="48">
              <v-img
                :src="require('@images/explorys_logo_login.png')"
                alt="Logo Explorys icone"
                width="48"
                heigth="48"
              />
            </v-avatar>
          </a>
        </div>
      </template>

      <v-window v-model="step">
        <v-window-item :value="1">
          <v-card>
            <v-form ref="formLogin">
              <v-card-title class="headline">
                Connexion
              </v-card-title>

              <v-divider></v-divider>

              <v-card-text>
                <v-text-field
                  v-model="userLogin.login"
                  :rules="userRules.email"
                  label="Email"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="userLogin.password"
                  label="Mot de passe"
                  :append-icon="showLoginPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showLoginPassword ? 'text' : 'password'"
                  required
                  @click:append="showLoginPassword = !showLoginPassword"
                ></v-text-field>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-btn
                  dark
                  color="primary"
                  type="submit"
                  :loading="sendLoading"
                  @click="validateLogin"
                  >Se connecter</v-btn
                >

                <v-spacer></v-spacer>

                <v-btn text @click="step++">
                  Mot de passe oublié ?
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-window-item>

        <v-window-item :value="2">
          <v-card>
            <v-form ref="formReset">
              <v-card-title class="headline">
                Mot de passe oublié ?
              </v-card-title>

              <v-divider></v-divider>

              <v-card-text>
                <v-text-field
                  v-model="userLogin.login"
                  :rules="userRules.email"
                  label="Email"
                  required
                ></v-text-field>
                <p class="text-brown">Un mail avec un lien vous sera envoyé.</p>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-btn
                  color="primary"
                  type="submit"
                  depressed
                  :loading="sendLoading"
                  @click="validateReset"
                >
                  Envoyer le code
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn :disabled="step === 1" text @click="step--">
                  Retour
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-window-item>

        <v-window-item :value="3">
          <v-card>
            <v-card-title class="headline">
              Erreur
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text>
              <p>Un problème est survenu, veuillez re-essayer plus tard</p>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn @click="dialogLogin = false">
                Fermer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-window-item>
      </v-window>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Login',
  components: {},
  data() {
    return {
      apiStatus: true,
      sendLoading: false,
      step: 1,
      dialogLogin: false,
      showLoginPassword: false,
      userLogin: {
        login: '',
        password: '',
      },
      userRules: {
        email: [
          (v) => !!v || 'Email requis',
          (v) =>
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
              v
            ) || 'Email non valide',
        ],
      },
    }
  },
  computed: {
    ...mapGetters([
      'getLogged',
      'getLoggedUser',
      'getLoggedUserToken',
      'getCookieLoginTime',
    ]),
    isLogged() {
      return this.getLogged
    },
    loggedUser() {
      return this.getLoggedUser
    },
    loggedUserToken() {
      return this.getLoggedUserToken
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
  mounted() {
    this.verifApiStatus()
  },
  methods: {
    ...mapActions([
      'loginUser',
      'logOutUser',
      'requestResetPwd',
      'checkApiStatus',
      'setPanelItemId',
      'setPanelForm',
      'togglePanel',
    ]),
    validateLogin(e) {
      e.preventDefault()
      let status = false
      this.sendLoading = true

      if (!this.$refs.formLogin.validate()) {
        return status
      }

      if (this.userLogin.login === '' || this.userLogin.password === '') {
        this.$awn.warning('Veuillez saisir vos informations de connexion')
        return status
      }

      // Delete authKeyTemp if exist
      if (this.$cookies.get('authKeyTemp')) {
        this.$cookies.remove('authKeyTemp')
      }

      this.loginUser(this.userLogin)
        .then((response) => {
          this.$awn.success('Vous êtes connecté')
          status = true
          this.sendLoading = false
        })
        .catch((e) => {
          this.sendLoading = false
          if (e.response.data.status === 'pending') {
            this.$awn.info(
              "Votre compte n'est pas encore activé, un lien vous à été envoyé par email afin d'effectuer vos premiers pas"
            )
          } else {
            this.$awn.alert('Le login ou le mot de passe sont incorrect.')
          }
        })
        .finally((response) => {
          if (status) {
            this.dialogLogin = false
            this.$cookies.set(
              'authKey',
              this.loggedUserToken,
              this.getCookieLoginTime
            )
            this.$router.push({ name: 'Dashboard' })
          } else {
            this.$cookies.remove('authKey')
          }
        })
    },
    logout() {
      this.logOutUser()
      this.$router.push({ name: 'Home' })
      this.$cookies.remove('authKey')
      this.$awn.info('Vous êtes déconnecté(e)')
    },
    validateReset(e) {
      e.preventDefault()
      this.sendLoading = true

      if (!this.$refs.formReset.validate()) {
        return status
      }

      if (this.login === '') {
        this.$awn.warning('Veuillez saisir vos informations de connexion')
        return false
      }

      this.requestResetPwd(this.userLogin.login)
        .then((response) => {
          this.$awn.success(
            'Un e-mail vous a été envoyé ! Pensez à vérifier votre dossier "indésirables".'
          )
          this.sendLoading = false
          this.dialogLogin = false
          this.userLogin.login = ''
          this.userLogin.password =''
          this.step = 1
        })
        .catch((e) => {
          this.sendLoading = false
          if (e.response.data.status === 'pending') {
            this.$awn.info(
              "Votre compte n'est pas encore activé, un lien vous à été envoyé par email afin d'effectuer vos premiers pas"
            )
          } else {
            this.$awn.alert(
              'Une erreur est survenue, veuillez contacter le service support.'
            )
          }
        })
    },
    verifApiStatus() {
      this.checkApiStatus()
        .then((response) => {
          this.apiStatus = true
          this.step = 1
        })
        .catch((e) => {
          this.apiStatus = false
          this.step = 3
        })
    },
    showProfilUser() {
      this.setPanelItemId(this.loggedUser.id)
      this.setPanelForm('users')
      this.togglePanel()
    },
  },
}
</script>
