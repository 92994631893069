<template>
  <!-- Footer -->
  <div
    id="section-footer"
    class="section fp-auto-height"
    data-tooltip="Explorys"
  >
    <div id="footer-wrapper">
      <v-row>
        <v-col cols="12" lg="7" md="6" sm="5" xs="12">
          <div class="text-center logo-wrapper">
            <router-link to="/mentions-legales">
              <v-img
                v-tippy="{ placement: 'top' }"
                :src="require('@images/explorys_logo_yellow.png')"
                alt="Logo Explorys"
                height="32px"
                width="156px"
                content="Mentions légales"
              />
            </router-link>
          </div>
        </v-col>
        <v-col cols="12" lg="3" md="4" sm="4" xs="12"
          class="text-center pt-1"
        >
          <router-link to="/mentions-legales">
            <div
              class="white-link fs-10"
              :class="isMobile ? 'ma-auto' : ''"
            >
              Mentions légales
            </div>
          </router-link>
          <div
            class="white-link fs-10 pt-1"
            :class="isMobile ? 'ma-auto' : ''"
            @click="openCookiesModal"
          >
            Préférences des Cookies
          </div>
        </v-col>
        <v-col cols="12" lg="2" md="2" sm="3" xs="12">
          <div class="copyright-logo">
            <a href="https://exo-dev.fr" target="_blank">
              <img
                :src="require('@images/by-exo-dev.png')"
                alt="Logo format petit de Exo-Dev"
                height="47px"
                width="128px"
              />
            </a>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Footer',
  data() {
    return {}
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
  methods: {
    ...mapActions(['setBannerState']),
    openCookiesModal() {
      this.setBannerState(true)
    }
  }
}
</script>
