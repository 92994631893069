var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"p-0",attrs:{"id":"b-o-partners","fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('h3',[_vm._v("Gestion des partenaires")])]),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-text-field',{attrs:{"label":"Recherche","prepend-icon":"mdi-magnify","clear-icon":"mdi-close-circle-outline","clearable":"","dense":"","counter":"3"},on:{"click:clear":_vm.clearSearch},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","lg":"4"}},[(_vm.loggedUser.role === 'SUPERADMIN')?_c('v-btn',{attrs:{"dark":"","color":"primary"},on:{"click":_vm.openPanelCreatePartner}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fa fa-plus")]),_vm._v(" Ajouter un partenaire ")],1):_vm._e()],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.gotPartnersList,"light":"","page":_vm.page,"items-per-page":_vm.itemsPerPage,"loading":_vm.isLoading,"loading-text":"Chargement... Veuillez patienter.","hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.logo",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"36"}},[(item.id_logo)?_c('v-img',{attrs:{"src":item.logo_data}}):_vm._e(),(!item.id_logo)?_c('v-img',{attrs:{"src":require('@images/company_defaut.jpg')}}):_vm._e()],1)]}},{key:"item.creation_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.formatDate(item.creation_date))+" ")]}},{key:"item.modification_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.formatDate(item.modification_date))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"fs-16-btn mr-2",attrs:{"color":"primary","icon":"","small":"","dark":"","content":"Modifier le partenaire"},on:{"click":function($event){return _vm.openPanelEditPartner(item.id)}}},[_c('v-icon',[_vm._v("fas fa-edit")])],1),_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"fs-16-btn",attrs:{"color":"red","icon":"","small":"","dark":"","content":"Supprimer le partenaire"},nativeOn:{"click":function($event){return _vm.confirmDeletePartner(item.id, item.name)}}},[_c('v-icon',[_vm._v("fas fa-trash-alt")])],1)]}}],null,true)}),_c('v-pagination',{staticClass:"mt-2",attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }