import Vue from 'vue'

// State
const state = {
  activity: {
    id: String,
  },
  activitiesList: [],
}

// Getters
const getters = {
  getActivity(state) {
    return state.activity
  },
  getActivitiesList(state) {
    return state.activitiesList
  },
}

// Mutations
const mutations = {
  SET_PROGRAM(state, activity) {
    state.activity = activity
  },
  SET_ACTIVITIES(state, activitiesList) {
    state.activitiesList = activitiesList
  },
}

// Actions
const actions = {
  fetchActivityById(store, activityId) {
    return Vue.prototype.$API
      .withoutToken()
      .get('/activities/' + activityId)
      .then((response) => {
        // Set Program
        this.commit('SET_ACTIVITY', response)
        return Promise.resolve(response)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  fetchActivitiesList(store) {
    return Vue.prototype.$API
      .withoutToken()
      .get('/activities?order_by=title&order_dir=asc')
      .then((response) => {
        // Set Programs
        var activitiesList = []
        response.data.forEach((activity) => {
          const newActivity = {
            value: activity.id,
            text: activity.title,
          }
          activitiesList.push(newActivity)
        })
        // Set Programs
        this.commit('SET_ACTIVITIES', activitiesList)
        return Promise.resolve(activitiesList)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
}

// Export
export default {
  state,
  getters,
  mutations,
  actions,
}
