<template>
  <v-container id="dashboard" fluid>
    <v-row>
      <v-col
        v-if="loggedUser && loggedUser.role === 'SUPERADMIN'"
        id="tiles-container"
        cols="12"
        lg="6"
      >
        <div class="subtitle">
          ACTIONS RAPIDES
        </div>
        <v-card class="px-5 py-2">
          <v-row>
            <v-col cols="12" lg="4" md="4" sm="4" xs="12" class="p-relative">
              <v-btn
                v-if="loggedUser && loggedUser.role === 'SUPERADMIN'"
                v-tippy
                icon
                content="Ajouter un document"
                class="btn-add"
                @click="showAddFile"
              >
                <v-icon>fas fa-plus</v-icon>
              </v-btn>
              <router-link to="/extranet/gestion-fichiers">
                <div class="tile-dashboard">
                  <v-icon>fas fa-folder-open</v-icon>
                  <div><span>Documents Explorys</span></div>
                </div>
              </router-link>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="4" xs="12" class="p-relative">
              <v-btn
                v-if="loggedUser && loggedUser.role === 'SUPERADMIN'"
                v-tippy
                icon
                content="Ajouter un.e utilisateur.trice"
                class="btn-add"
                @click="showAddUser"
              >
                <v-icon>fas fa-plus</v-icon>
              </v-btn>
              <router-link to="/extranet/gestion-utilisateurs">
                <div class="tile-dashboard">
                  <v-icon>fas fa-users-cog</v-icon>
                  <div><span>Gestion des utilisateurs</span></div>
                </div>
              </router-link>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="4" xs="12" class="p-relative">
              <v-btn
                v-if="loggedUser && loggedUser.role === 'SUPERADMIN'"
                v-tippy
                icon
                content="Ajouter une organisation"
                class="btn-add"
                @click="showAddOrga"
              >
                <v-icon>fas fa-plus</v-icon>
              </v-btn>
              <router-link to="/extranet/gestion-organisations">
                <div class="tile-dashboard">
                  <v-icon>fas fa-briefcase</v-icon>
                  <div><span>Gestion des organisations</span></div>
                </div>
              </router-link>
            </v-col>

            <v-col cols="12" lg="4" md="4" sm="4" xs="12" class="p-relative">
              <v-btn
                v-if="loggedUser && loggedUser.role === 'SUPERADMIN'"
                v-tippy
                icon
                class="btn-add"
                content="Ajouter un évènement"
                @click="showAddEvent"
              >
                <v-icon>fas fa-plus</v-icon>
              </v-btn>
              <router-link to="/extranet/agenda">
                <div class="tile-dashboard">
                  <v-icon>fas fa-calendar-alt</v-icon>
                  <div><span>Gestion de l'agenda</span></div>
                </div>
              </router-link>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="4" xs="12" class="p-relative">
              <v-chip v-if="allEvents && allEvents.length" class="badge-events">
                {{ allEvents.length }} en attente
              </v-chip>
              <router-link to="/extranet/validation-evenements">
                <div class="tile-dashboard">
                  <v-icon>fas fa-calendar-check</v-icon>
                  <div><span>Modération des évènements</span></div>
                </div>
              </router-link>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="4" xs="12">
              <div class="pointer" @click="showProfilUser">
                <div class="tile-dashboard">
                  <v-icon>fas fa-user-edit</v-icon>
                  <div><span>Mon profil utilisateur</span></div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>

        <div class="subtitle pt-2">
          GESTION DU SITE VITRINE
        </div>
        <v-card class="px-5 py-2">
          <v-row>
            <v-col cols="12" lg="4" md="4" sm="4" xs="12" class="p-relative">
              <v-btn
                v-if="loggedUser && loggedUser.role === 'SUPERADMIN'"
                v-tippy
                icon
                content="Ajouter un membre"
                class="btn-add"
                @click="showAddExpert"
              >
                <v-icon>fas fa-plus</v-icon>
              </v-btn>
              <router-link to="/extranet/gestion-equipe">
                <div class="tile-dashboard">
                  <v-icon>fas fa-users</v-icon>
                  <div><span>Gestion de l'équipe Explorys</span></div>
                </div>
              </router-link>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="4" xs="12" class="p-relative">
              <v-btn
                v-if="loggedUser && loggedUser.role === 'SUPERADMIN'"
                v-tippy
                icon
                content="Ajouter un partenaire"
                class="btn-add"
                @click="showAddPartner"
              >
                <v-icon>fas fa-plus</v-icon>
              </v-btn>
              <router-link to="/extranet/gestion-partenaires">
                <div class="tile-dashboard">
                  <v-icon>fas fa-handshake</v-icon>
                  <div><span>Gestion des partenaires</span></div>
                </div>
              </router-link>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="4" xs="12">
              <router-link to="/extranet/gestion-villes">
                <div class="tile-dashboard">
                  <v-icon>fas fa-map-marker-alt</v-icon>
                  <div><span>Gestion des villes</span></div>
                </div>
              </router-link>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col
        v-if="loggedUser && loggedUser.role === 'ADMIN'"
        id="tiles-container"
        cols="12"
        lg="6"
      >
        <div class="subtitle pt-2">
          ACTIONS RAPIDES
        </div>
        <v-card class="px-5 py-2">
          <v-row>
            <v-col cols="12" lg="4" md="4" sm="4" xs="12">
              <router-link to="/extranet/gestion-fichiers">
                <div class="tile-dashboard">
                  <v-icon>fas fa-folder-open</v-icon>
                  <div><span>Documents Explorys</span></div>
                </div>
              </router-link>
            </v-col>

            <v-col cols="12" lg="4" md="4" sm="4" xs="12">
              <router-link to="/extranet/gestion-utilisateurs">
                <div class="tile-dashboard">
                  <v-icon>fas fa-users-cog</v-icon>
                  <div><span>Gestion des utilisateurs</span></div>
                </div>
              </router-link>
            </v-col>

            <v-col cols="12" lg="4" md="4" sm="4" xs="12">
              <router-link to="/extranet/gestion-organisations">
                <div class="tile-dashboard">
                  <v-icon>fas fa-briefcase</v-icon>
                  <div><span>Gestion des organisations</span></div>
                </div>
              </router-link>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="4" md="4" sm="4" xs="12">
              <router-link to="/extranet/agenda">
                <div class="tile-dashboard">
                  <v-icon>fas fa-calendar-alt</v-icon>
                  <div><span>Agenda</span></div>
                </div>
              </router-link>
            </v-col>

            <v-col cols="12" lg="4" md="4" sm="4" xs="12">
              <router-link to="/nos-explorateurs">
                <div class="tile-dashboard">
                  <v-icon>fas fa-users</v-icon>
                  <div><span>Trombinoscope</span></div>
                </div>
              </router-link>
            </v-col>

            <v-col cols="12" lg="4" md="4" sm="4" xs="12">
              <div class="pointer" @click="showProfilUser">
                <div class="tile-dashboard">
                  <v-icon>fas fa-user-edit</v-icon>
                  <div><span>Mon profil utilisateur</span></div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col
        v-if="loggedUser && loggedUser.role === 'USER'"
        id="tiles-container"
        cols="12"
        lg="6"
      >
        <div class="subtitle pt-2">
          ACTIONS RAPIDES
        </div>
        <v-card class="px-5 py-2">
          <v-row>
            <v-col cols="12" lg="4" md="4" sm="4" xs="12">
              <a :href="loggedUser && loggedUser.link_drive" target="_blank">
                <div
                  class="tile-dashboard"
                  :class="
                    loggedUser && !loggedUser.link_drive ? 'disabled' : ''
                  "
                >
                  <v-icon>fab fa-google-drive</v-icon>
                  <div><span>Mes documents Google Drive</span></div>
                </div>
              </a>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="4" xs="12">
              <router-link to="/extranet/votre-page">
                <div class="tile-dashboard">
                  <v-icon>fas fa-briefcase</v-icon>
                  <div><span>Ma vitrine</span></div>
                </div>
              </router-link>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="4" xs="12">
              <div class="pointer" @click="showProfilUser">
                <div class="tile-dashboard">
                  <v-icon>fas fa-user-edit</v-icon>
                  <div><span>Mon profil utilisateur</span></div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="4" md="4" sm="4" xs="12">
              <router-link to="/extranet/gestion-fichiers">
                <div class="tile-dashboard">
                  <v-icon>fas fa-folder-open</v-icon>
                  <div><span>Documents Explorys</span></div>
                </div>
              </router-link>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="4" xs="12">
              <router-link to="/extranet/agenda">
                <div class="tile-dashboard">
                  <v-icon>fas fa-calendar-alt</v-icon>
                  <div><span>Agenda</span></div>
                </div>
              </router-link>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="4" xs="12">
              <router-link to="/nos-explorateurs">
                <div class="tile-dashboard">
                  <v-icon>fas fa-users</v-icon>
                  <div><span>Trombinoscope</span></div>
                </div>
              </router-link>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" lg="6" class="annonces">
        <div class="subtitle pt-2">
          PETITES ANNONCES
        </div>
        <v-card class="posts-card" tile>
          <v-row id="posts-display-container">
            <v-col cols="12" class="px-1">
              <PostCreation />
            </v-col>
            <v-col cols="12" class="px-3">
              <div v-for="post in gotPostsList" :key="post.id">
                <PostDisplay
                  class="post-display"
                  :post="post"
                />
              </div>
              <infinite-loading @infinite="infiniteHandler">
                <div slot="spinner">
                  <v-skeleton-loader
                    v-for="n in postsSkeletonNumber"
                    id="loader-posts"
                    :key="n"
                    :loading="loadingPosts"
                    type="list-item-avatar, divider, list-item-three-line, actions"
                  ></v-skeleton-loader>
                </div>
                <div slot="no-results">
                  <img
                    :src="require('@images/megaphone.png')"
                    alt="Megaphone"
                    width="100"
                    height="100"
                    class="mx-auto mb-5 placeholder-posts-img"
                  />
                  <p class="fs-15 text-primary">
                    Il n'y a pas de petites annonces pour le moment, revenez
                    plus tard !
                  </p>
                  <v-btn
                    class="explorys-btn mt-10 mb-5"
                    tile
                    to="/extranet/nos-explorateurs"
                    >Consulter les explorateurs</v-btn
                  >
                </div>
                <div slot="no-more">
                  Fin des petites annonces
                </div>
              </infinite-loading>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import PostCreation from '@components/PostCreation.vue'
import PostDisplay from '@components/PostDisplay.vue'

export default {
  name: 'Dashboard',
  components: {
    PostCreation,
    PostDisplay,
  },
  data() {
    return {
      loadingPosts: false,
      postsSkeletonNumber: [1, 2, 3],
    }
  },
  computed: {
    ...mapGetters(['getPostsListDB', 'getLoggedUser', 'getEvents','getPostsArgs']),
    gotPostsList() {
      return this.getPostsListDB
    },
    loggedUser() {
      return this.getLoggedUser
    },
    isUser() {
      return this.loggedUser.role === 'USER'
    },
    allEvents() {
      return this.getEvents
    },
    postsArgs() {
      return this.getPostsArgs
    },
  },
  mounted() {
    // Init
    this.init()
    // Fetch event to moderate
    this.loadListEvents('PENDING')
    // Disable Loading
    this.setLoading(false)
  },
  methods: {
    ...mapActions([
      'fetchPostsListDB',
      'togglePanel',
      'setPanelForm',
      'clearPanelItemId',
      'setPanelItemId',
      'loadListEvents',
      'clearPostsListDB',
      'setLoading',
      'setPostsArgs',
    ]),
    init() {
      this.setPostsArgs({
        limit: 10,
        offset: 0,
        private: true,
      })
      this.clearPostsListDB()
    },
    infiniteHandler($state) {
      this.fetchPostsListDB(this.postsArgs).then((response) => {
        if (response.length) {
          const args = _.clone(this.postsArgs)
          args.offset += 10
          this.setPostsArgs(args)
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    },
    showAddFile() {
      this.clearPanelItemId()
      this.setPanelForm('files')
      this.togglePanel()
    },
    showAddUser() {
      this.clearPanelItemId()
      this.setPanelForm('crea-users')
      this.togglePanel()
    },
    showProfilUser() {
      this.setPanelItemId(this.loggedUser.id)
      this.setPanelForm('users')
      this.togglePanel()
    },
    showAddOrga() {
      this.clearPanelItemId()
      this.setPanelForm('createorganisations')
      this.togglePanel()
    },
    showAddEvent() {
      this.clearPanelItemId()
      this.setPanelForm('events')
      this.togglePanel()
    },
    showAddExpert() {
      this.clearPanelItemId()
      this.setPanelForm('experts')
      this.togglePanel()
    },
    showAddPartner() {
      this.clearPanelItemId()
      this.setPanelForm('partners')
      this.togglePanel()
    },
  },
}
</script>
