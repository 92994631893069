<template>
  <div class="keywords-cloud">
    <strong v-for="keyword in this.$enums.keywords" :key="keyword">
      {{ keyword }}
      <br>
    </strong>
  </div>
</template>

<script>
export default {
  name: 'KeywordsCloud',
  // eslint-disable-next-line
  props: ['page'],
  data() {
    return {
      keywords: 'keywords.' + this.page,
    }
  },
}
</script>
