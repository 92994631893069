<template>
  <v-container id="bloc-testimonials" fluid>
    <v-row justify="center">
      <v-col id="text" cols="12" lg="12">
        <h2 class="w-fit-content ma-auto">Ils nous font confiance</h2>
        <p>Découvrez les témoignages des entrepreneurs.</p>
      </v-col>
      <v-col cols="12" lg="11" justify-lg="center" class="pt-0">
        <v-row id="testimonials-container" justify="space-around">
          <v-col
            v-for="testimonial in testimonialsList"
            :key="testimonial.id"
            cols="12"
            sm="6"
            md="6"
            lg="auto"
            justify="center"
          >
            <v-card class="card" flat>
              <img :src="getImgUrl(testimonial.imgSrc)" />
              <p class="title-testimonial">
                {{ testimonial.title }}
              </p>
              <p class="content"> " {{ testimonial.content }} " </p>
              <p class="content-btn">
                <v-btn
                  :href="getLink(testimonial.id)"
                  target="_blank"
                  class="link-linktree"
                  light
                  text
                  link
                >
                  Découvrir
                </v-btn>
              </p>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Temoignages',
  data() {
    return {
      testimonialsList: [
        {
          id: 0,
          imgSrc: 'portrait_thomas_faure.jpg',
          title: 'Thomas Faure, CEO, life-01',
          content:
            'Explorys a su nous guider et nous aider dans notre processus de levée de fonds en amorçage, notamment dans le processus de road-show ! Une équipe compétente, dynamique et de superbes lieux d’incubation.',
          linkLinkTree: 'https://www.linkedin.com/company/life-01/',
        },
        {
          id: 1,
          imgSrc: 'portrait_johanna_bruet.jpg',
          title: 'Johanna Bruet, enseignante yoga B2C et B2B, Joy Yoga',
          content:
            'Merci mille fois Explorys. Je suis restée 3 ans (le maximum en contrat CAPE) et je viens de reprendre un pack mentorat pour rester encore un peu avec eux. J’ai adoré l’accompagnement ainsi que la relation avec les autres explorateurs. ',
          linkLinkTree: 'https://www.linkedin.com/company/joy-yoga-lyon/',
        },
        {
          id: 2,
          imgSrc: 'portrait_thierry_buffet.jpg',
          title: 'Thierry Buffet, Fondateur et manager inclusif, B-impact',
          content:
            'Quand j’ai rencontré Explorys j’avais un projet entrepreunerial et un potentiel premier client. J’ai pu trouver la solution idéale pour facturer mes prestations. L’incubateur m’a permis de bénéficier d’un accompagement, d’un réseau et d’un coworking pour y travailler, recevoir des clients et tenir des réunions dans un cadre agréable. ',
          linkLinkTree: 'https://www.linkedin.com/company/b-impact-fr/',
        },
        {
          id: 3,
          imgSrc: 'portrait_luc_stievenard.jpg',
          title: 'Luc Stievenard, Gérant, Lookayti',
          content:
            'Lorsque j’ai intégré Explorys, je savais que je voulais me lancer dans l’infogérence. Grâce à eux, j’avais toutes les bases pour bien démarrer : formations, comptabilité, accompagnement individuel et un réseau d’experts. Tout cela m’a permis de construire ma société sereinement. Merci. ',
          linkLinkTree: 'https://www.linkedin.com/company/lookayti/',
        },
      ],
    }
  },
  methods: {
    getImgUrl(imgPath) {
      return require('@images/' + imgPath)
    },
    getLink(idTesimonial) {
      if (this.testimonialsList[idTesimonial]) {
        return this.testimonialsList[idTesimonial].linkLinkTree
      }
    },
  },
}
</script>
