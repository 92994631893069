<template>
  <v-container id="who-we-are" fluid>
    <ScrollBtn v-if="fp && !isMobile" :is-last="isLast" :fp="fp" />
    <!-- fullpage -->
    <full-page id="fullpage" ref="fullpage" :options="fpOpt">
      <div
        id="container-why"
        class="section"
        data-tooltip="Pourquoi Explorys ?"
      >
        <WhyExplorys />
      </div>
      <div
        id="container-experts"
        class="section"
        data-tooltip="Nos experts à votre écoute"
      >
        <OurExperts :fp-obj="fp" :is-pos-one="isPosOne" />
      </div>
      <div
        id="container-partners"
        class="section"
        data-tooltip="Ils nous soutiennent"
      >
        <OurPartners :fp-obj="fp" />
      </div>
      <!-- Footer fullpage -->
      <Footer />
    </full-page>
  </v-container>
</template>

<script>
import WhyExplorys from '@components/WhoWeAreWhyExplorys.vue'
import OurExperts from '@components/WhoWeAreExperts.vue'
import OurPartners from '@components/WhoWeArePartners.vue'
import Footer from '@components/Footer.vue'
import ScrollBtn from '@components/ScrollBtn.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'WhoWeAre',
  metaInfo() {
    return {
      title: 'Qui sommes-nous',
      // meta: [
      //   {
      //     name: 'description',
      //     content: 'description page Qui Sommes nous'
      //   }
      // ],
    }
  },
  components: {
    ScrollBtn,
    WhyExplorys,
    OurExperts,
    OurPartners,
    Footer,
  },
  data() {
    return {
      fp: null,
      isLast: null,
      fpOpt: {
        licenseKey: '0AF69EB3-BBC24272-B09D04A0-C7C9A161',
        // Navigation fullpage
        navigation: true,
        controlArrows: false,
        // Design fullpage
        responsiveWidth: this.$vuetify.breakpoint.thresholds.sm,
        // Scrolling fullpage
        css3: true,
        easingcss3: 'cubic-bezier(0.3,1.18,0.3,1)',
        scrollingSpeed: 1100,
        loopHorizontal: true,
        loopTop: false,
        loopBottom: false,
        scrollOverflow: !this.$vuetify.breakpoint.mobile,
        // Accessibility fullpage
        keyboardScrolling: true,
        recordHistory: false,
        // Events fullpage
        onLeave: function(origin, destination, direction) {
          this.isLast = destination.isLast
          this.setNewScrollPos(destination.index)
        }.bind(this),
      },
    }
  },
  computed: {
    ...mapGetters(['getScrollBox', 'getScrollPage']),
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    currentScrollPos() {
      return this.getScrollBox
    },
    isPosOne() {
      return this.currentScrollPos === 1
    }
  },
  mounted() {
    // Store fullpage into data return
    if (this.$refs && this.$refs.fullpage) {
      // Get FP
      this.fp = this.$refs.fullpage
      this.setActualPage('who-we-are')
      this.setNewScrollPos(0)
    }
    // Disable Loading --> Experts bloc
  },
  methods: {
    ...mapActions(['setNewScrollPos', 'setActualPage',]),
  },
}
</script>
