<template>
  <v-container id="file-management" fluid class="p-0">
    <v-row>
      <v-col v-if="loggedUser.role !== 'SUPERADMIN'" cols="12" lg="4">
        <h3>Documents Explorys</h3>
      </v-col>
      <v-col v-else cols="12" lg="4">
        <h3>Gestion des fichiers Explorys</h3>
      </v-col>
      <v-col cols="12" lg="4">
        <v-text-field
          v-model="search"
          label="Recherche"
          prepend-icon="mdi-magnify"
          clear-icon="mdi-close-circle-outline"
          clearable
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="4" class="files-btn">
        <v-btn v-if="loggedUser.role === 'SUPERADMIN'" dark color="primary" @click="openPanelCreateFile">
          <v-icon class="mr-2">fa fa-plus</v-icon>
          Ajouter un fichier
        </v-btn>
      </v-col>
    </v-row>

    <v-card id="files-content">
      <v-progress-linear :indeterminate="loading"></v-progress-linear>

      <v-treeview
        v-model="tree"
        :search="search"
        :filter="filter"
        :items="allItems"
        :selectable="isSuperAdmin"
        item-key="name"
        open-on-click
      >
        <template v-slot:prepend="{ item, open }">
          <v-icon v-if="!item.file">
            {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
          </v-icon>
          <v-icon v-else>
            {{ files[item.file] }}
          </v-icon>
        </template>
        <template v-slot:label="{ item }">
          <span v-if="item.description !== undefined">
            <b>{{ item.title }}</b> ({{ item.name }})<br />
            {{ item.description }}
          </span>
          <span v-else>
            {{ item.name }}
          </span>
        </template>
        <template v-slot:append="{ item }">
          <span v-if="item.file">
            <v-btn
              v-tippy
              dark
              class="fs-16-btn mr-2"
              color="green"
              icon
              small
              content="Télécharger le fichier"
              :loading="sendLoading"
              @click="downloadFile(item.id)"
            >
              <v-icon>
                fa-download
              </v-icon>
            </v-btn>
            &nbsp;
            <v-btn
              v-if="getLoggedUser.role === 'SUPERADMIN'"
              v-tippy
              class="fs-16-btn mr-2"
              color="primary"
              dark
              icon
              small
              content="Editer les informations"
              :loading="sendLoading"
              @click="openPanelEditFile(item.id)"
            >
              <v-icon>
                fa-edit
              </v-icon>
            </v-btn>
            &nbsp;
            <v-btn
              v-if="loggedUser.role === 'SUPERADMIN'"
              v-tippy
              class="fs-16-btn mr-2"
              color="red"
              dark
              icon
              small
              content="Supprimer le fichier"
              :loading="sendLoading"
              @click="confirmDeleteFile(item.id, item.name)"
            >
              <v-icon>
                far fa-trash-alt
              </v-icon>
            </v-btn>
          </span>
        </template>
      </v-treeview>

      <!-- Multiple Delete -->
      <v-card-actions v-if="loggedUser.role === 'SUPERADMIN' && tree.length">
        <v-btn
          color="red"
          dark
          small
          :loading="sendLoading"
          @click="confirmDeleteAllFiles"
        >
          <v-icon class="fs-11 mr-2">
            far fa-trash-alt
          </v-icon>
          Supprimer la séléction
        </v-btn>
        &nbsp;
        <v-btn
          color="primary"
          dark
          small
          :loading="sendLoading"
          @click="resetSelectedTree"
        >
          <v-icon class="fs-11 mr-2">
            fas fa-times
          </v-icon>
          Effacer la séléction
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-container>
</template>

<script>
import download from 'downloadjs'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'FileManagement',
  components: {},
  data() {
    return {
      loading: true,
      sendLoading: false,
      files: {
        html: 'mdi-language-html5',
        pdf: 'mdi-file-pdf',
        png: 'mdi-file-image',
        jpg: 'mdi-file-image',
        jpeg: 'mdi-file-image',
        txt: 'mdi-file-document-outline',
        doc: 'mdi-file-word',
        docx: 'mdi-file-word',
        odt: 'mdi-file-word',
        xls: 'mdi-file-excel',
        xlsx: 'mdi-file-excel',
        ods: 'mdi-file-excel',
        ppt: 'mdi-file-powerpoint',
        pptx: 'mdi-file-powerpoint',
      },
      tree: [],
      items: [],
      search: null,
      caseSensitive: false,
    }
  },
  computed: {
    ...mapGetters([
      'getFiles',
      'getLoggedUser',
    ]),
    loggedUser() {
      return this.getLoggedUser
    },
    isSuperAdmin() {
      return this.loggedUser.role === "SUPERADMIN"
    },
    allFiles() {
      return this.getFiles
    },
    allItems() {
      var records = []
      const files = this.allFiles
      const folderTypes = this.$enums.folderTypes
      // format datas for TreeView with folders
      folderTypes.forEach(function(key, value) {
        records.push({
          id: key.value,
          name: key.text,
          children: [],
        })
      })

      files.forEach(function(key, value) {
        let folderId
        switch (key.folder) {
          case 'COMPTABILITE':
            folderId = 0
            break
          case 'FORMATION':
            folderId = 1
            break
          case 'JURIDIQUE':
            folderId = 2
            break
          default:
            folderId = null
            break
        }
        // push files into folders
        records[folderId].children.push({
          id: key.id,
          name: key.name,
          title: key.title,
          file: key.type,
          description: key.description,
        })
      })
      return records
    },
    filter() {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined
    },
  },
  mounted() {
    this.getFilesLoading()

    // Disable Loading
    this.setLoading(false)
  },
  methods: {
    ...mapActions([
      'getFilesForTreeView',
      'getDataForDownload',
      'togglePanel',
      'setPanelForm',
      'setPanelItemId',
      'clearPanelItemId',
      'deleteFile',
      'updateFile',
      'setLoading',
    ]),
    getFilesLoading() {
      this.getFilesForTreeView()
        .then((response) => {
          this.loading = false

          // Disable loading
          this.sendLoading = false
        })
        .catch((e) => {
          this.loading = false
          this.$awn.alert('ERREUR lors du chargement des fichiers')

          // Disable loading
          this.sendLoading = false
        })
    },
    downloadFile(id) {
      // Trigger loading
      this.sendLoading = true

      this.getDataForDownload(id)
        .then((response) => {
          var file = response.data[0]
          download(file.data, file.name)
          this.$awn.success('Téléchargement réussi de ' + file.title)

          // Disable loading
          this.sendLoading = false
        })
        .catch((e) => {
          this.$awn.alert('ERREUR lors de la récupération du fichier')

          // Disable loading
          this.sendLoading = false
        })
    },
    confirmDeleteFile(idFile, nameFile) {
      const onOk = () => {
        // Trigger loading
        this.sendLoading = true

        this.deleteFile(idFile)
          .then((response) => {
            this.loading = true
            this.getFilesLoading()
            this.$awn.success('Le fichier a bien été suppprimé')
          })
          .catch((e) => {
            this.$awn.alert('ERREUR lors de la suppression du fichier')

            // Disable loading
            this.sendLoading = false
          })
      }
      const onCancel = () => {
      }
      this.$awn.confirm(
        'Souhaitez-vous vraiment supprimer le fichier <b>' + nameFile + '</b> ?',
        onOk,
        onCancel,
        {
          labels: {
            confirm: 'Suppression d\'un fichier',
          },
        }
      )
    },
    confirmDeleteAllFiles() {
      this.$awn.confirm(
        'Souhaitez-vous vraiment supprimer les fichiers selectionnés',
        this.deleteSelectedFiles,
        null,
        {
          labels: {
            confirm: 'Suppression des fichiers',
          },
        }
      )
    },
    deleteSelectedFiles() {
      // Trigger loading
      this.sendLoading = true

      // filter array -> keep element by name
      var selectedFiles = this.allFiles.filter(f => this.tree.includes(f.name))

      // call array promises
      this.deleteFilesPromises(selectedFiles)
      .then((response) => {
        this.$awn.success('Tous les fichiers ont été supprimés avec succès')
        // Disable loading
        this.sendLoading = false
        // Re-fetch
        this.loading = true
        this.getFilesLoading()
        // Reset tree
        this.resetSelectedTree()
      })
      .catch((e) => {
        this.$awn.alert('ERREUR lors de la suppression des fichiers')
        // Disable loading
        this.sendLoading = false
        // Re-fetch
        this.loading = true
        this.getFilesLoading()
        // Reset tree
        this.resetSelectedTree()
      })
    },
    deleteFilesPromises(selectedFiles) {
      var promisesList = []

      selectedFiles.forEach((file) => {
        promisesList.push(this.deleteFile(file.id))
      })
      return Promise.all(promisesList)
    },
    resetSelectedTree() {
      this.tree = []
    },
    openPanelEditFile(idFile) {
      this.setPanelItemId(idFile)
      this.setPanelForm('files')
      this.togglePanel()
    },
    openPanelCreateFile() {
      this.clearPanelItemId()
      this.setPanelForm('files')
      this.togglePanel()
    },
  },
}
</script>
