<template>
  <transition name="fade">
    <v-container v-if="gotLoading" id="loading-page" fluid transition="fade-transition">
      <v-row class="full-row justify-center align-center">
        <v-col cols="10">
          <div class="icon-loading">
            <v-img :src="require('@images/loading_collaboration.png')"></v-img>
            <v-img :src="require('@images/loading_stats.png')"></v-img>
            <v-img :src="require('@images/loading_lighting.png')"></v-img>
            <v-img :src="require('@images/loading_win.png')"></v-img>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'LoadingPage',
  components: {},
  data() {
    return {}
  },
  computed: {
    ...mapGetters([
      'getLoading'
    ]),
    gotLoading() {
      return this.getLoading
    },
  },
  mounted() {

  },
  methods: {
    ...mapActions([]),

  },
}
</script>