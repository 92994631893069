<template>
  <v-container>
    <v-row justify="center" no-gutters>
      <v-card class="col-md-10">
        <v-card-title class="headline">
          Première connexion
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text v-show="loading">
          <v-row class="my-2" justify="space-around">
            <v-col cols="3" style="text-align: center;">
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-show="!loading">
          <div class="py-2">
            <p>
              <em>
                Bienvenue pour votre première connexion au site Explorys, nous allons vous accompagner durant la création de votre compte.
                <br/>
                Les champs avec un * sont obligatoires.
              </em>
            </p>
          </div>
          <v-divider></v-divider>
          <div v-if="tokenValid" style="max-height: 600px; overflow-y: scroll;">

            <v-stepper
              v-model="step"
              vertical
            >
              <v-stepper-step
                :complete="validStep1"
                step="1"
              >
                Choix du mot de passe
              </v-stepper-step>
              <v-stepper-content step="1">
                <!-- content for creating password -->
                <v-text-field
                  v-model="password"
                  name="password"
                  :rules="rules.passwordRules"
                  label="Mot de passe *"
                  :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showNewPassword ? 'text' : 'password'"
                  required
                  @click:append="showNewPassword = !showNewPassword"
                />

                <v-text-field
                  v-model="passwordConfirm"
                  name="password_confirm"
                  :rules="rules.passwordRules"
                  label="Confirmer le mot de passe *"
                  :append-icon="showNewPasswordBis ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showNewPasswordBis ? 'text' : 'password'"
                  required
                  @click:append="showNewPasswordBis = !showNewPasswordBis"
                />

                <p>
                  <b>Votre mot de passe doit comporter :</b>
                  <ul>
                    <li>Au moins 8 caractères</li>
                    <li>Au moins une MAJUSCULE</li>
                    <li>Au moins un chiffre</li>
                    <li>Au moins un caractère spécial (@, !, ?, #, €, %, $, ...)</li>
                  </ul>
                </p>

                <p>
                  <v-checkbox
                    v-model="cguAccepted"
                  >
                  <template v-slot:label>
                    <div>
                      Je reconnais avoir lu et accepté les
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <a
                            target="_blank"
                            href="/extranet/cgu"
                            @click.stop
                            v-on="on"
                          >
                            Conditions Générales d'Utilisation
                          </a>
                        </template>
                        Ouvrir les CGU
                      </v-tooltip>
                    </div>
                  </template>
                  </v-checkbox>
                </p>

              </v-stepper-content>

              <v-stepper-step
                :complete="validStep2"
                step="2"
              >
                Informations utilisateur
              </v-stepper-step>
              <v-stepper-content step="2">
                <!-- content for typing users informations -->
                <v-row class="my-2" justify="space-around">
                  <v-col cols="5">
                    <v-text-field
                      v-model="infosUser.firstname"
                      :rules="rules.firstname"
                      label="Prénom *"
                      required
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                      v-model="infosUser.lastname"
                      :rules="rules.lastname"
                      label="Nom *"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="my-2" justify="space-around">
                  <v-col cols="5">
                    <v-text-field
                      v-model="infosUser.position"
                      :rules="rules.position"
                      label="Poste occupé *"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                      v-model="infosUser.email"
                      label="E-mail"
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="my-2" justify="space-around">
                  <v-col cols="5">
                    <v-text-field
                      v-model="infosUser.link_linkedin"
                      :rules="rules.link_reseaux"
                      label="Lien réseaux sociaux"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="5">
                    <v-text-field
                      v-model="consultantName"
                      label="Consultant"
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="py-4" justify="space-around" style="text-align:center;">
                  <v-col cols="5">
                    Image de l'avatar :<br/>
                    <VueDropzone
                      id="dropzoneFileUser"
                      ref="dropzoneFileUser"
                      :options="dropzoneOptions"
                      @vdropzone-file-added="checkAddedFileUser"
                    />
                    <v-btn class="mx-auto dropzone-trash-btn" color="red" dark small @click="removeFilesUser">
                      <v-icon small>fas fa-trash-alt</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

                <p>
                </p>
              </v-stepper-content>

              <v-stepper-step
                :complete="validStep3"
                step="3"
              >
                <span v-if="infosUser.role === 'USER'">Informations de l'Organisation</span>
                <span v-else>Finalisation</span>
              </v-stepper-step>
              <v-stepper-content step="3">
                <!-- content for typing organisation informations for USER -->
                <div v-if="infosUser.role === 'USER'">
                  <v-row justify="space-around">
                    <v-col cols="5">
                      <v-text-field
                        v-model="infosOrga.name"
                        :rules="rules.orgaName"
                        label="Nom *"
                        required
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        v-model="infosOrga.baseline"
                        :rules="rules.baseline"
                        hint="Exemple : L'informatique pour tous !"
                        persistent-hint
                        label="Slogan *"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row justify="space-around" class="my-1">
                    <v-col cols="11">
                      <v-text-field
                        v-model="infosOrga.description"
                        :rules="rules.description"
                        hint="Exemple : Développement logiciel sur mesure"
                        persistent-hint
                        counter
                        maxlength="255"
                        label="Détailler votre secteur d'activité *"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row justify="space-around" class="my-4">
                    <v-col cols="5">
                      <v-text-field
                        v-model="infosOrga.contact_email"
                        :rules="rules.contact_email"
                        label="E-mail de contact *"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        v-model="infosOrga.contact_phone"
                        :rules="rules.contact_phone"
                        counter="10"
                        label="Téléphone de contact"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="py-4" justify="space-around">
                    <v-col cols="5">
                      <v-avatar size="150">
                        <v-img id="logo" :src="infosOrga.logo_data" />
                      </v-avatar>
                    </v-col>
                    <v-col cols="5" style="text-align:center;">
                      Logo de l'organisation :<br/>
                      <VueDropzone
                        id="dropzoneFileOrga"
                        ref="dropzoneFileOrga"
                        :options="dropzoneOptions"
                        @vdropzone-file-added="checkAddedFileOrga"
                      />
                      <v-btn class="mx-auto" color="red" dark fab x-small @click="removeFilesOrga">
                        <v-icon>fas fa-trash-alt</v-icon>
                      </v-btn>
                      <p class="mt-2">Dimensions optimales : 275px / 150px</p>
                    </v-col>
                  </v-row>
                </div>
                <div v-else>
                  <!-- content validation for ADMIN -->
                  <p>Vous avez terminé ! Découvrez dès à présent la plateforme d'Explorys</p>
                </div>
              </v-stepper-content>

            </v-stepper>
          </div>

          <div v-else>
            <p class="text-center pt-8">
              <b>Votre code temporaire a expiré...</b><br>
              Veuillez contacter Explorys pour demander un nouveau mail d'inscription en cliquant sur le lien suivant :
              <a @click="contactExplorys"><b>Help Explorys</b></a>
            </p>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions v-if="tokenValid">
          <v-spacer></v-spacer>
          <v-btn
            v-if="step < 3"
            color="primary"
            @click="nextStep"
          >
            Etape suivante
          </v-btn>
          <v-btn
            v-if="step === 3"
            color="primary"
            :loading="sendLoading"
            @click="validateForm"
          >
            Valider mes informations et découvrir la plateforme
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueDropzone from 'vue2-dropzone'
const globalRegex = RegExp(/^(?=[\x21-\x7E]*[0-9])(?=[\x21-\x7E]*[A-Z])(?=[\x21-\x7E]*[a-z])(?=[\x21-\x7E]*[\x21-\x2F|\x3A-\x40|\x5B-\x60|\x7B-\x7E])[\x21-\x7E]{8,}$/, 'g')

export default {
  name: 'FirstConnexion',
  components: {
    VueDropzone,
  },
  data() {
    return {
      sendLoading: false,
      loading: true,
      tokenValid: true,
      showNewPassword: false,
      showNewPasswordBis: false,

      // datas
      infosUser: [],
      infosOrga: [],
      password: '',
      passwordConfirm: '',
      cguAccepted: false,
      consultantName: '',

      // Stepper
      step: 1,
      logoUser: null,
      logoOrga: null,
      validStep1: null,
      validStep2: null,
      validStep3: null,

      rules: {
        // rules to validate user inputs
        passwordRules: [
          v => !!v || 'Champ requis !',
          v => /^(?=[\x21-\x7E]*[0-9])(?=[\x21-\x7E]*[A-Z])(?=[\x21-\x7E]*[a-z])(?=[\x21-\x7E]*[\x21-\x2F|\x3A-\x40|\x5B-\x60|\x7B-\x7E])[\x21-\x7E]{8,}?/.test(v) || 'Mot de passe non sécurisé'
        ],
        firstname: [
          (v) => !!v || 'Prénom requis'
        ],
        lastname: [
          (v) => !!v || 'Nom requis'
        ],
        position: [
          (v) => !!v || 'Poste requis'
        ],
        link_reseaux: [
          // eslint-disable-next-line
          v => !v || /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(v) || 'URL non conforme'
        ],
        // rules to validate orga input
        orgaName: [
          (v) => !!v || 'Le nom est obligatoire'
        ],
        baseline: [
          (v) => !!v || 'Le slogan est obligatoire'
        ],
        description: [
          (v) => !!v || 'La description est obligatoire',
          v => v.length <= 255 || 'Max. 255 caractères',
        ],
        contact_email: [
          (v) => !!v || 'Le mail est obligatoire'
        ],
      },
      // DropzoneConf
      dropzoneOptions: {
        maxFiles: 1,
        autoProcessQueue: false,
        url: function() {},
        thumbnailWidth: 150,
        parallelUploads: 1,
        dictDefaultMessage:
          "<i class='fa fa-cloud-upload'></i>Cliquez ou déposez une image ici",
      },
    }
  },
  computed: {
    ...mapGetters([
      'getCookieLoginTime',
      'getIdUser',
      'getConsultantsList',
      'getCookieLoginTime',
      'getLoggedUserToken',
    ]),
    loadIdUser() {
      return this.getIdUser
    },
    consultantsList() {
      return this.getConsultantsList
    },
  },
  mounted() {
    this.setLoading(false)
    if(this.$route.query.token) {
      // this.fetchConsultantsList()
      // this.fetchOrganisationsSelectList()
      const accessToken = this.$route.query.token
      this.$cookies.set('authKeyTemp', accessToken, this.getCookieLoginTime)
      // 1. check token
      this.checkLoggedTempToken(this.$route.query.mail)
        .then((response) => {
          // 1.1 get datas
          this.fetchDatasFromDB(response.data.id)
            .then((response) => {
              this.infosUser = response
              this.infosOrga = response.organisation
              this.consultantName = response.consultant_name
              this.loading = false
            })
            .catch((e) => {
              this.loading = false
              this.$awn.alert('Une erreur est survenue lors de la récupération de vos données')
            })
        })
        .catch((e) => {
          this.loading = false
          this.$awn.warning('Votre clé de connexion a expirée')
          this.tokenValid = false
        })
    } else {
      this.$awn.alert('Accès interdit')
      this.$router.push('/')
    }
  },
  methods: {
    ...mapActions([
      'checkLoggedTempToken',
      'fetchDatasFromDB',
      'fetchConsultantsList',
      'fetchOrganisationsSelectList',
      'saveFile',
      'fetchFile',
      'updateOrganisation',
      'patchUser',
      'loginUser',
      'setLoading',
    ]),
    sendNewToken() {
      // ! @TODO: create process OR send email ?
    },
    nextStep() {
      if (this.step < 3) {
        if (this.step === 1) {
          if (this.password !== '' && this.passwordConfirm === this.password
              && globalRegex.test(this.password) && this.cguAccepted ) {
            this.validStep1 = true
            this.infosUser.password = this.password
            this.step++
          } else if (this.passwordConfirm !== this.password) {
            this.validStep1 = false
            this.$awn.warning("Vos deux mots de passe ne sont pas identiques")
          } else if (!this.cguAccepted) {
            this.validStep1 = false
            this.$awn.warning("Veuillez accepter les CGU")
          } else {
            this.validStep1 = false
            this.$awn.warning("Veuillez remplir/valider tous les champs obligatoires")
          }
        } else if (this.step === 2) {
          if ( this.infosUser.firstname !== '' && this.infosUser.lastname !== ''
                && this.infosUser.position !== '') {
            this.validStep2 = true
            this.step++
          } else {
            this.validStep2 = false
            this.$awn.warning("Veuillez remplir tous les champs obligatoires")
          }
        }
      }
    },
    removeFilesUser() {
      this.$refs.dropzoneFileUser.removeAllFiles()
      this.logoUser = false
    },
    checkAddedFileUser(File) {
      // event addedFile watcher on dropzone to check file added
      if (File.size >= 5000000) {
        this.$refs.dropzoneFile.removeFile(File)
        this.$awn.warning('Le fichier doit faire moins de 5Mo')
      }
      if (File.type !== "image/png" && File.type !== "image/jpg" && File.type !== "image/jpeg") {
        this.$refs.dropzoneFile.removeFile(File)
        this.$awn.warning('Le fichier doit être une image (format PNG ou JPEG)')
      }
      if (this.$refs.dropzoneFileUser.getAcceptedFiles().length===1) {
        this.$refs.dropzoneFileUser.removeFile(File)
        this.$awn.warning("Une seule image autorisée")
      } else {
        this.logoUser = true
      }
    },
    removeFilesOrga() {
      this.$refs.dropzoneFileOrga.removeAllFiles()
      this.logoOrga = false
    },
    checkAddedFileOrga(File) {
      // event addedFile watcher on dropzone to check file added
      if (File.size >= 5000000) {
        this.$refs.dropzoneFile.removeFile(File)
        this.$awn.warning('Le fichier doit faire moins de 5Mo')
      }
      if (File.type !== "image/png" && File.type !== "image/jpg" && File.type !== "image/jpeg") {
        this.$refs.dropzoneFile.removeFile(File)
        this.$awn.warning('Le fichier doit être une image (format PNG ou JPEG)')
      }
      if (this.$refs.dropzoneFileOrga.getAcceptedFiles().length===1) {
        this.$refs.dropzoneFileOrga.removeFile(File)
        this.$awn.warning("Une seule image autorisée")
      } else {
        this.logoOrga = true
      }
    },
    validateForm() {
      if (this.infosUser.role === 'USER') {
        // Case of USER simple
        if (this.infosOrga.baseline !== '' && this.infosOrga.description !== ''
                  && this.infosOrga.contact_email !== '' && this.infosUser.role === 'USER') {
          this.validStep3 = true

          if (this.logoOrga) {
            const fileToUpload = this.$refs.dropzoneFileOrga.getAcceptedFiles()[0]
            this.$utils
              .toBase64(fileToUpload)
              .then((response) => {
                // set file type from file name
                var fileType = fileToUpload.name.split('.')
                var fileName = fileType[0] + '_' + this.$utils.uuid() + '.' + fileType[fileType.length - 1]
                fileType = fileType[fileType.length - 1]

                const fileToSave = {
                  name: fileName,
                  title: 'Logo Organisation ' + this.infosOrga.name,
                  description:
                    'Logo Organisation ' +
                    this.infosOrga.name +
                    ' sets automatically from organisation form',
                  folder: 'INTERNAL',
                  type: fileType,
                  data: response,
                }
                this.saveFile(fileToSave)
                  .then((response) => {
                    this.$awn.success('Logo d\'organisation enregistré avec succès')
                    var orgaToUpdate = this.infosOrga
                    orgaToUpdate.id_logo = response.data.id
                    orgaToUpdate.show_in_trombi = true
                    // SAVE ORGA with id_photo
                    this.updateOrganisation(orgaToUpdate)
                      .then((response) => {
                        this.$awn.success("Votre organisation a été mise à jour !")
                      })
                      .catch((e) => {
                        this.$awn.alert("Une erreur est survenue lors de la mise à jour de votre organisation")
                      })
                  })
                  .catch((e) => {
                    this.$awn.alert("ERREUR lors de l'enregistrement du fichier Logo Organisation")
                  })
              })
              .catch((e) => {
                this.$awn.alert('ERREUR lors de la lecture du fichier Logo Organisation')
              })
          } else {
            // SAVE ORGA without new logo
            var orgaToUpdate = this.infosOrga
            orgaToUpdate.show_in_trombi = true
            this.updateOrganisation(orgaToUpdate)
              .then((response) => {
                this.$awn.success("Votre organisation a été mise à jour !")
              })
              .catch((e) => {
                this.$awn.alert("Une erreur est survenue lors de la mise à jour de votre organisation")
              })
          }

        } else {
          this.validStep3 = false
          this.$awn.warning("Veuillez remplir tous les champs obligatoires")
        }
      } else {
        // Case of ADMIN user
        this.validStep3 = true
      }

      // tout est good !
      if (this.validStep3) {
        // Trigger Loading
        this.sendLoading = true

        if (this.logoUser) {
          const avatarToUpload = this.$refs.dropzoneFileUser.getAcceptedFiles()[0]
          this.$utils
          .toBase64(avatarToUpload)
          .then((response) => { // set file type from file name
            var fileType = avatarToUpload.name.split('.')
            var fileName = fileType[0] + '_' + this.$utils.uuid() + '.' + fileType[fileType.length - 1]
            fileType = fileType[fileType.length - 1]

            const avatarToSave = {
              name: fileName,
              title: 'Photo User ' + this.infosUser.firstname + ' ' + this.infosUser.lastname,
              description:
                'Photo User ' +
                this.infosUser.firstname +
                ' ' +
                this.infosUser.lastname +
                ' sets automatically from user form',
              folder: 'INTERNAL',
              type: fileType,
              data: response,
            }
            this.saveFile(avatarToSave)
              .then((response) => {
                this.$awn.success('Fichier enregistré avec succès')
                this.infosUser.id_photo = response.data.id
                this.infosUser.status = 'ACTIVE'
                // SAVE USER
                this.patchUser(this.infosUser)
                  .then((response) => {
                    this.$awn.success("Vos informations utilisateur ont été mise à jour ! Vous pouvez vous connecter")
                    this.logUser()
                  })
                  .catch((e) => {
                    this.$awn.alert("Une erreur est survenue lors de la mise à jour de vos informations utilisateur")
                    this.sendLoading = false
                  })
              })
              .catch((e) => {
                this.$awn.alert("ERREUR lors de l'enregistrement du fichier Avatar")
                this.sendLoading = false
              })
          })
          .catch((e) => {
            this.$awn.alert('ERREUR lors de la lecture du fichier Avatar')
            this.sendLoading = false
          })
        } else {
          this.infosUser.status = 'ACTIVE'
          // SAVE USER
          this.patchUser(this.infosUser)
            .then((response) => {
              this.$awn.success("Vos informations utilisateur ont été mise à jour ! Vous pouvez vous connecter")
              this.logUser()
            })
            .catch((e) => {
              this.$awn.alert("Une erreur est survenue lors de la mise à jour de vos informations utilisateur")
              this.sendLoading = false
            })
        }
      }
    },
    logUser() {
      this.$cookies.remove('authKeyTemp')
      this.loginUser({
        login: this.infosUser.email,
        password: this.infosUser.password,
      }).then((response) => {
          this.$awn.success('Vous êtes connecté')
          this.$cookies.set('authKey', response.token, this.getCookieLoginTime)
          this.$router.push({name: 'Dashboard'})
          this.sendLoading = false
      }).catch((e) => {
          this.$awn.alert('Une erreur est survenue lors de la connexion, merci de vous re-connecter')
          this.$cookies.remove('authKey')
          this.$router.push({name: "Home"})
          this.sendLoading = false
      })
    },
    contactExplorys() {
      window.location.href = 'mailto:help@explorys.fr'
    },
  },
}
</script>
