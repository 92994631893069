/* eslint-disable */
export default {
  install(Vue, options) {
    ;[
      (Vue.prototype.$enums = {
        default: {
          value: 'UNKNOWN',
          text: 'inconnu',
        },
        keywords: [
          'explorys',
          'explorys lyon',
          'explorys grenoble',
          'explorys annecy',
          'explorys saint etienne',
          'explorys clermont ferrand',
          'explorys valence',
          'explorys incubateur',
          'exploris',
          'incubateur',
          'incubateur entreprise',
          'incubateur lyon',
          'incubateur grenoble',
          'incubateur annecy',
          'incubateur saint etienne',
          'incubateur clermont ferrand',
          'cape',
          'cape lyon',
          'cape pole emploi',
          'contrat cape',
          'portage salarial',
          'portage salarial consultant',
          'programme explorys',
          'programmes explorys',
          'accompagnement',
          'accompagnement performant',
          'crash test',
          'crash-test',
          'impulse',
          'academy',
          'explorys academy',
          'gagnant gagnant',
          'gagnant/gagnant',
          'win win',
          'win/win',
          'consultant expert',
          'cpf',
          'cpf explorys',
          'créer une entreprise',
          'créer son entreprise',
          'créer entreprise',
          'création entreprise',
          'création d\'entreprise',
          'création d\'entreprise lyon',
          'croissance entreprise',
          'tpe',
          'créer son entreprise lyon',
          'créer son entreprise grenoble',
          'créer son entreprise annecy',
          'consultant création entreprise lyon',
          'consultant création entreprise grenoble',
          'consultant création entreprise annecy',
          'accompagnement entrepreneur lyon',
          'accompagnement entrepreneur grenoble',
          'accompagnement entrepreneur annecy',
          'transition professionnelle',
          'plan social',
          'reprise entreprise',
          'développement commercial',
          'formation creation entreprise lyon',
          'formation creation entreprise grenoble',
          'formation creation entreprise annecy',
          'formation chef entreprise lyon',
          'formation chef entreprise grenoble',
          'formation chef entreprise annecy',
          'comment créer mon entreprise',
          'comment démarrer son entreprise',
          'tester son projet création entreprise',
          'aides création entreprise',
          'qu\'est ce que le contrat cape',
        ],
        folderTypes: [
          {
            value: 'COMPTABILITE',
            text: 'Comptabilité',
          },
          {
            value: 'FORMATION',
            text: 'Formation',
          },
          {
            value: 'JURIDIQUE',
            text: 'Juridique',
          },
        ],
        roleTypes: [
          {
            value: 'USER',
            text: 'Utilisateur',
            color: '#406e78',
          },
          {
            value: 'ADMIN',
            text: 'Administrateur',
            color: '#003e4c',
          },
          {
            value: 'SUPERADMIN',
            text: 'Super Administrateur',
            color: '#001a20',
          },
        ],
        statusList: [
          {
            value: 'ACTIVE',
            text: 'Actif',
            color: 'green',
          },
          {
            value: 'PENDING',
            text: 'En attente',
            color: 'orange',
          },
          {
            value: 'INACTIVE',
            text: 'Désactivé',
            color: 'grey',
          },
          {
            value: 'BANNED',
            text: 'Banni',
            color: 'red',
          },
        ],
        statusOrgaList: [
          {
            value: 'ACTIVE',
            text: 'Active',
            color: 'green',
          },
          {
            value: 'INACTIVE',
            text: 'Inactive',
            color: 'gray',
          },
        ],
        getRoleChipsColor(itemValue) {
          const currentRole = Vue.prototype.$enums.roleTypes.find(s => s.value === itemValue)
          return currentRole.color
        },
        getRoleChipsText(itemValue) {
          const currentRole = Vue.prototype.$enums.roleTypes.find(s => s.value === itemValue)
          return currentRole.text
        },
        getStatusChipsColor(itemValue) {
          const currentStatus = Vue.prototype.$enums.statusList.find(s => s.value === itemValue)
          return currentStatus.color
        },
        getStatusChipsText(itemValue) {
          const currentStatus = Vue.prototype.$enums.statusList.find(s => s.value === itemValue)
          return currentStatus.text
        },
        getStatusOrgaChipsColor(itemValue) {
          const currentStatusOrga = Vue.prototype.$enums.statusOrgaList.find(s => s.value === itemValue)
          return currentStatusOrga.color
        },
        getStatusOrgaChipsText(itemValue) {
          const currentStatusOrga = Vue.prototype.$enums.statusOrgaList.find(s => s.value === itemValue)
          return currentStatusOrga.text
        },
      }),
    ]
  },
}
