<template>
  <v-container>
    <v-row justify="center" no-gutters class="mt-10">
      <v-col cols="12" lg="4">
        <v-card>
          <v-card-title class="headline">
            Changer son mot de passe
          </v-card-title>

          <v-card-text>
            <v-form ref="formReset">
              <v-text-field
                v-model="password"
                name="password"
                :rules="userRules.password"
                label="Mot de passe"
                :append-icon="showResetPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showResetPassword ? 'text' : 'password'"
                required
                @click:append="showResetPassword = !showResetPassword"
              ></v-text-field>

              <v-text-field
                v-model="passwordConfirm"
                name="password_confirm"
                :rules="userRules.repassword"
                label="Confirmez le mot de passe"
                :append-icon="showResetPasswordBis ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showResetPasswordBis ? 'text' : 'password'"
                required
                @click:append="showResetPasswordBis = !showResetPasswordBis"
              ></v-text-field>
            </v-form>
          </v-card-text>

          <v-card-actions class="justify-end">
            <v-btn color="primary" depressed :loading="sendLoading" @click="resetPassword">
              Mettre à jour
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Reset',
  components: {},
  data() {
    return {
      sendLoading: false,
      password: '',
      passwordConfirm: '',
      showResetPassword: false,
      showResetPasswordBis: false,
      userRules: {
        password: [
          (v) => !!v || 'Mot de passe requis',
          (v) =>
            /^(?=[\x21-\x7E]*[0-9])(?=[\x21-\x7E]*[A-Z])(?=[\x21-\x7E]*[a-z])(?=[\x21-\x7E]*[\x21-\x2F|\x3A-\x40|\x5B-\x60|\x7B-\x7E])[\x21-\x7E]{8,}?/.test(v) ||
            'Au minimum : 8 caractères, une majuscule, une minuscule, un chiffre, un caractère spécial',
        ],
        repassword: [
          (v) => v === this.password || 'Mots de passe différents',
        ],
      },
    }
  },
  computed: {
    ...mapGetters(['getCookieResetTime']),
  },
  mounted() {
    this.setLoading(false)
    if (this.$route.query.token) {
      const accessToken = this.$route.query.token
      this.$cookies.set('authKey', accessToken, this.getCookieResetTime)
    } else {
      this.$awn.alert('Accès interdit')
      this.$router.push('/')
    }
  },
  methods: {
    ...mapActions([
      'launchResetPassword', 
      'setLoading',
    ]),
    resetPassword() {
      if (
        this.password !== '' && this.passwordConfirm !== ''
        && this.password === this.passwordConfirm
      ) {
        this.sendLoading = true
        this.launchResetPassword(this.password)
          .then((response) => {
            this.$awn.success(
              'Votre mot de passe a été changé avec succès, vous pouvez vous connecter'
            )
            this.$cookies.remove('authKey')
            this.sendLoading = false
            this.$router.push('/')
          })
          .catch((e) => {
            this.$awn.alert(
              'Une erreur est survenue, veuillez contacter le service support'
            )
            this.sendLoading = false
          })
      } else {
        this.$awn.warning('Erreur de saisie dans les mots de passe')
        this.sendLoading = false
        return false
      }
    },
  },
}
</script>
