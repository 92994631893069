<template>
  <div>
    <img v-if="logoObj" :src="logoObj.data" :alt="logoObj.name" style="max-height: 150px;">
    <img v-else src="/images/explorys_logo_icon.png" alt="Logo Explorys" style="max-height: 150px;">
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ExplorateurLogo',
  data() {
    return {
      logoObj: null,
    }
  },
  computed: {
    idLogo() {
      return this.$route.params.id_logo
    },
  },
  mounted() {
    if (this.idLogo) {
      this.setLoading(false)
      this.fetchFile(this.idLogo)
      .then((response) => {
        this.logoObj = response
      })
      .catch((e) => {
        console.log(e)
      })
    }
  },
  methods: {
    ...mapActions([
      'fetchFile',
      'setLoading',
    ]),
  }
}
</script>