<template>
  <div>
    <v-card id="form-organisations-users">
      <v-card-title dark class="panel-title">
        Modifier votre organisation
      </v-card-title>

      <v-spacer></v-spacer>

      <v-skeleton-loader
        v-if="loading"
        id="loader-form"
        :loading="loading"
        type="image"
      ></v-skeleton-loader>
      <v-card-text v-show="!loading">
        <v-form ref="form_organisation" v-model="valid">
          <v-row justify="space-around">
            <v-col cols="5">
              <v-text-field
                v-model="currentOrga.name"
                :rules="orgaRules.name"
                label="Nom"
                required
              >
              </v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="currentOrga.baseline"
                :rules="orgaRules.baseline"
                label="Slogan"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          
          <v-row justify="space-around">
            <v-col cols="5">
              <v-autocomplete
                v-model="currentOrga.id_activity"
                :items="activitiesList"
                label="Secteur d'activité"
              />
            </v-col>
            <v-col cols="5"></v-col>
          </v-row>

          <v-row justify="space-around">
            <v-col cols="11">
              <v-text-field
                v-model="currentOrga.description"
                :rules="orgaRules.description"
                label="Description"
                counter="100"
                hint="Limité à 100 caractères"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row justify="space-around">
            <v-col cols="5">
              <v-text-field
                v-model="currentOrga.contact_email"
                :rules="orgaRules.contact_email"
                label="E-mail de contact"
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="currentOrga.contact_phone"
                :rules="orgaRules.contact_phone"
                label="Téléphone de contact"
                counter="10"
                @keydown="$utils.formatPhoneInput($event)"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row justify="space-around">
            <v-col cols="5">
              <v-text-field
                v-model="currentOrga.link_website"
                label="Site Internet"
                required
              >
              </v-text-field>
            </v-col>
            <v-col cols="5"> </v-col>
          </v-row>

          <v-row justify="space-around">
            <v-col cols="5">
              <v-img
                v-if="currentOrga.id_logo === 0 || !currentOrga.id_logo"
                id="photo"
                :src="require('@images/company_defaut.jpg')"
                contain
                max-width="500"
                max-height="150"
              />
              <v-img
                v-else
                id="photo"
                :src="currentOrga.logo_data"
                contain
                max-width="500"
                max-height="150"
              />
            </v-col>
            <v-col cols="5">
              <VueDropzone
                id="dropzoneFile"
                ref="dropzoneFile"
                :options="dropzoneOptions"
                @vdropzone-file-added="checkAddedFile"
              />
              <v-btn
                color="red"
                dark
                small
                class="dropzone-trash-btn"
                @click="removeFiles"
              >
                <v-icon small>fas fa-trash-alt</v-icon>
              </v-btn>
              <p class="mt-2">Dimensions optimales : 275px / 150px</p>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-spacer></v-spacer>

      <v-card-actions class="justify-end">
        <v-btn
          :disabled="!valid && !loading"
          color="success"
          class="mr-4"
          :loading="sendLoading"
          @click="validate"
        >
          Enregistrer
        </v-btn>
        <v-btn
          color="secondary"
          class="mr-4"
          :loading="sendLoading"
          @click="closePanel"
        >
          Annuler
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import VueDropzone from 'vue2-dropzone'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'EditOrganisationsUsers',
  components: {
    VueDropzone,
  },
  props: {
    organisationid: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      sendLoading: false,
      currentOrga: {},
      orgaRules: {
        name: [(v) => !!v || 'Nom requis'],
        baseline: [(v) => !!v || 'Slogan requis'],
        description: [
          (v) => (!v ? true : v.length <= 100) || 'Description de 100 caractères maximum',
        ],
        contact_email: [
          (v) =>
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
              v
            ) || 'Email non valide',
        ],
        contact_phone: [(v) => /^[0-9]{10}$/.test(v) || 'Téléphone non valide'],
      },
      dropzoneOptions: {
        maxFiles: 1,
        autoProcessQueue: false,
        url: function() {},
        thumbnailWidth: 150,
        parallelUploads: 1,
        dictDefaultMessage:
          "<i class='fa fa-cloud-upload'></i>Cliquez ou déposez un fichier ici",
      },
      valid: false,
      loading: true,
      fileIdToDelete: 0,
    }
  },
  computed: {
    ...mapGetters([
      'getActivitiesList',
    ]),
    activitiesList() {
      return this.getActivitiesList
    },
  },
  mounted() {
    this.fetchOrganisationById(this.organisationid).then((orga) => {
      this.currentOrga = orga
      if (orga.id_logo) {
        this.fetchFile(orga.id_logo)
          .then((file) => {
            this.loading = false
          })
          .catch((e) => {
            console.log(e)
            this.loading = false
          })
      } else {
        this.loading = false
      }
    })
  },
  methods: {
    ...mapActions([
      'createOrganisation',
      'togglePanel',
      'fetchOrganisationById',
      'updateOrganisation',
      'fetchFile',
      'setReloadingNeed',
      'saveFile',
      'deleteFile',
    ]),
    removeFiles() {
      this.$refs.dropzoneFile.removeAllFiles()
    },
    validate() {
      const acceptedFiles = this.$refs.dropzoneFile.getAcceptedFiles()
      // Organisation request with new file
      if (acceptedFiles.length >= 1) {
        this.$utils
          .toBase64(acceptedFiles[0])
          .then((response) => {
            // set file type from file name
            var fileType = acceptedFiles[0].name.split('.')
            fileType = fileType[fileType.length - 1]

            const fileToSave = {
              name: acceptedFiles[0].name,
              title: 'Logo Organisation ' + this.currentOrga.name,
              description:
                'Logo Organisation ' +
                this.currentOrga.name +
                ' sets automatically from organisation form',
              folder: 'INTERNAL',
              type: fileType,
              data: response,
            }
            this.saveFile(fileToSave)
              .then((response) => {
                this.$awn.success('Fichier enregistré avec succès')

                // Set old file to delete if needed
                if (this.currentOrga.id_logo !== 0) {
                  this.fileIdToDelete = this.currentOrga.id_logo
                }

                this.currentOrga.id_logo = response.data.id
                this.sendOrganisationRequest()
              })
              .catch((e) => {
                this.$awn.alert("ERREUR lors de l'enregistrement du fichier")
              })
          })
          .catch((e) => {
            this.$awn.alert('ERREUR lors de la lecture du fichier')
          })
      }
      // Organisation request without new file
      else {
        this.sendOrganisationRequest()
      }
    },
    sendOrganisationRequest() {
      // Trigger Loading
      this.sendLoading = true

      this.$refs.form_organisation.validate()
      
      if (this.valid) {
        // UPDATE
        if (this.organisationid) {
          this.currentOrga.id = this.organisationid
          this.updateOrganisation(this.currentOrga)
            .then((response) => {
              this.$awn.success(
                "L'organisation <b>" +
                  this.currentOrga.name +
                  '</b> a été modifiée'
              )
              // Delete old File if needed
              if (this.fileIdToDelete !== 0) {
                this.deleteFile(this.fileIdToDelete)
              }
              this.closePanel()
            })
            .catch((e) => {
              this.$awn.alert(
                "ERREUR lors de la demande de modification de l'organisation <b>" +
                  this.currentOrga.name +
                  '</b>'
              )
              // Disable Loading
              this.sendLoading = false
            })
        } 
      } else {
        this.$awn.alert(
          "ERREUR Formulaire mal renseigné"
        )
        // Disable loading
        this.sendLoading = false
      }
    },
    closePanel() {
      this.fileIdToDelete = 0
      this.togglePanel()
      this.setReloadingNeed(true)
      // Disable Loading
      this.sendLoading = false
    },
    checkAddedFile(File) {
      // event addedFile watcher on dropzone to check file added
      if (File.size >= 5000000) {
        this.$refs.dropzoneFile.removeFile(File)
        this.$awn.warning('Le fichier doit faire moins de 5Mo')
      }
      if (
        File.type !== 'image/png' &&
        File.type !== 'image/jpg' &&
        File.type !== 'image/jpeg'
      ) {
        this.$refs.dropzoneFile.removeFile(File)
        this.$awn.warning('Le fichier doit être une image (format PNG ou JPEG)')
      }
      if (this.$refs.dropzoneFile.getAcceptedFiles().length === 1) {
        this.$refs.dropzoneFile.removeFile(File)
        this.$awn.warning('Une seule image autorisée')
      }
    },
  },
}
</script>
