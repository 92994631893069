var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"py-4",attrs:{"id":"header","fluid":""}},[(_vm.section === 'vitrine')?_c('v-app-bar',{staticClass:"header__vitrine",class:_vm.isSliderPos ? 'sliderHeader' : '',attrs:{"absolute":"","flat":"","height":"90"}},[_c('div',{staticClass:"logo"},[(!_vm.isSliderPos)?_c('v-img',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement: 'top' }),expression:"{ placement: 'top' }"}],attrs:{"src":require('@images/explorys_logo_yellow.png'),"alt":"Logo Explorys","content":"Retour a l'accueil"},on:{"click":_vm.goHome}}):_vm._e(),(_vm.isSliderPos)?_c('v-img',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement: 'top' }),expression:"{ placement: 'top' }"}],attrs:{"src":require('@images/explorys_logo_blue.png'),"alt":"Logo Explorys","content":"Retour a l'accueil"},on:{"click":_vm.goHome}}):_vm._e()],1),_c('v-spacer'),(_vm.section === 'vitrine')?_c('v-btn-toggle',{attrs:{"right":"","group":""}},[_vm._l((_vm.routesVitrineList),function(route){return _c('v-btn',{key:route.path,staticClass:"link",attrs:{"to":route.path,"x-large":"","text":"","dark":"","height":"64","active-class":"btn-active-vitrine","exact":""}},[_vm._v(" "+_vm._s(route.title)+" ")])}),(_vm.isLogged)?_c('v-btn',{attrs:{"to":"/extranet","x-large":"","text":"","tile":"","dark":"","height":"64","exact":""}},[_vm._v(" Mon Espace ")]):_vm._e()],2):_vm._e(),_c('Login'),(_vm.isLogged)?_c('v-menu',{staticClass:"z-1000",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-user",attrs:{"color":"primary","icon":"","outlined":"","large":""}},'v-btn',attrs,false),on),[_c('v-avatar',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement: 'bottom-end' }),expression:"{ placement: 'bottom-end' }"}],attrs:{"size":"48","content":'<b>Menu Utilisateur</b><br /><br />' +
                _vm.loggedUser.firstname +
                ' ' +
                _vm.loggedUser.lastname +
                '<br />' +
                _vm.loggedUser.position +
                ' chez <b>' +
                _vm.loggedUser.organisation_name +
                '</b>'}},[(_vm.loggedUser.id_photo === 0 || !_vm.loggedUser.id_photo)?_c('v-img',{attrs:{"id":"photo","src":require('@images/avatar_defaut.png'),"contain":"","width":"48","height":"48"}}):_c('v-img',{attrs:{"contain":"","src":_vm.loggedUser.photo_data,"alt":"Avatar de l'utilisateur","width":"48","height":"48"}})],1)],1)]}}],null,false,2044044379)},[_c('v-list',_vm._l((_vm.loggedVitrineDropdown),function(item,index){return _c('v-list-item',_vm._g({key:index,class:item.class ? item.class : null,attrs:{"to":item.path ? item.path : null,"exact":"","color":"primary"}},{ click: item.function ? item.function : null }),[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1):_vm._e()],1):_vm._e(),(_vm.section === 'extranet')?_c('v-app-bar',{staticClass:"header__extranet",attrs:{"absolute":""}},[_c('div',{staticClass:"logo"},[_c('v-img',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement: 'top' }),expression:"{ placement: 'top' }"}],attrs:{"src":require('@images/explorys_version_bleu_mini.png'),"alt":"Logo Explorys","content":"Retour au site vitrine"},on:{"click":_vm.goHome}})],1),_c('v-spacer'),(
        _vm.section === 'extranet' &&
          _vm.loggedUser &&
          _vm.loggedUser.role === 'SUPERADMIN'
      )?_c('v-btn-toggle',{attrs:{"right":"","group":""}},[_c('v-menu',{staticClass:"z-1000",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-1",attrs:{"x-large":"","text":"","tile":"","height":"64"}},'v-btn',attrs,false),on),[_vm._v(" Administration "),_c('i',{staticClass:"fas fa-angle-down px-2"})])]}}],null,false,3093565504)},[_c('v-list',_vm._l((_vm.backOfficesList),function(item,index){return _c('v-list-item',{key:index,class:item.class ? item.class : null,attrs:{"to":item.path ? item.path : null,"exact":"","color":"primary"}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1),_c('v-menu',{staticClass:"z-1000",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-1",attrs:{"x-large":"","text":"","tile":"","height":"64"}},'v-btn',attrs,false),on),[_vm._v(" Gestion du site vitrine "),_c('i',{staticClass:"fas fa-angle-down px-2"})])]}}],null,false,1266929858)},[_c('v-list',_vm._l((_vm.vitrineList),function(item,index){return _c('v-list-item',{key:index,class:item.class ? item.class : null,attrs:{"to":item.path ? item.path : null,"exact":"","color":"primary"}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1),_c('v-btn',{staticClass:"px-1",attrs:{"to":"/extranet/agenda","exact":"","x-large":"","tile":"","text":"","height":"64"}},[_vm._v(" Agenda ")]),_c('v-btn',{staticClass:"px-1",attrs:{"to":"/extranet/nos-explorateurs","exact":"","x-large":"","tile":"","text":"","height":"64"}},[_vm._v(" Trombinoscope ")]),_c('v-btn',{staticClass:"px-1",attrs:{"to":"/extranet/","exact":"","x-large":"","tile":"","text":"","height":"64"}},[_vm._v(" Mon Espace ")])],1):_vm._e(),(
        _vm.section === 'extranet' && _vm.loggedUser && _vm.loggedUser.role === 'ADMIN'
      )?_c('v-btn-toggle',{attrs:{"right":"","group":""}},[_c('v-menu',{staticClass:"z-1000",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-1",attrs:{"x-large":"","text":"","tile":"","height":"64"}},'v-btn',attrs,false),on),[_vm._v(" Administration "),_c('i',{staticClass:"fas fa-angle-down px-2"})])]}}],null,false,3093565504)},[_c('v-list',_vm._l((_vm.backOfficesAdminList),function(item,index){return _c('v-list-item',{key:index,class:item.class ? item.class : null,attrs:{"to":item.path ? item.path : null,"exact":"","color":"primary"}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1),_vm._l((_vm.routesExtranetAdminList),function(route){return _c('v-btn',{key:route.path,staticClass:"px-1",attrs:{"to":route.path,"exact":"","x-large":"","tile":"","text":"","height":"64"}},[_vm._v(" "+_vm._s(route.title)+" ")])})],2):_vm._e(),(
        _vm.section === 'extranet' && _vm.loggedUser && _vm.loggedUser.role === 'USER'
      )?_c('v-btn-toggle',{attrs:{"right":"","group":""}},_vm._l((_vm.routesExtranetUserList),function(route){return _c('v-btn',{key:route.path,attrs:{"to":route.path,"exact":"","x-large":"","tile":"","text":"","height":"64"}},[_vm._v(" "+_vm._s(route.title)+" ")])}),1):_vm._e(),_c('Login'),(_vm.isLogged)?_c('v-menu',{staticClass:"z-1000",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-user",attrs:{"color":"primary","icon":"","outlined":""}},'v-btn',attrs,false),on),[_c('v-avatar',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement: 'bottom-end' }),expression:"{ placement: 'bottom-end' }"}],attrs:{"size":"48","content":'<b>Menu Utilisateur</b><br /><br />' +
                _vm.loggedUser.firstname +
                ' ' +
                _vm.loggedUser.lastname +
                '<br />' +
                _vm.loggedUser.position +
                ' chez <b>' +
                _vm.loggedUser.organisation_name +
                '</b>'}},[(_vm.loggedUser.id_photo === 0 || !_vm.loggedUser.id_photo)?_c('v-img',{attrs:{"id":"photo","src":require('@images/avatar_defaut.png'),"contain":"","width":"48","height":"48"}}):_c('v-img',{attrs:{"contain":"","src":_vm.loggedUser.photo_data,"alt":"Avatar de l'utilisateur","width":"48"}})],1)],1)]}}],null,false,3436684085)},[_c('v-list',_vm._l((_vm.loggedDropdown),function(item,index){return _c('v-list-item',_vm._g({key:index,class:item.class ? item.class : null,attrs:{"to":item.path ? item.path : null,"exact":"","color":"primary"}},{ click: item.function ? item.function : null }),[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }