<template>
  <v-container id="expertise" fluid class="p-0">
    <ScrollBtn v-if="fp && !isMobile" :is-last="isLast" :fp="fp" />
    <!-- fullpage -->
    <full-page id="fullpage" ref="fullpage" :options="fpOpt">
      <div
        id="container-programs-home"
        class="section"
        data-tooltip="Nos programmes"
      >
        <v-container id="content-our-programs" class="p-0">
          <h2>Nos Programmes</h2>
          <v-row class="px-10 px-sm-4 justify-center">
            <v-col class="two-clics" cols="12" xs="10" sm="10" md="10" lg="10">
              <p>Votre programme Explorys <br v-if="isBpXs">en 2 clics</p>
              <v-btn
                v-if="!show2clics.status"
                x-large
                class="btn-two-clicks"
                @click="show2clics.status = true"
              >
                Cliquez-ici
              </v-btn>
              <v-row class="justify-center">
                <v-col
                  v-if="show2clics.status"
                  cols="12"
                  lg="6"
                  class="two-clicks-stepper"
                >
                  <v-stepper v-model="step">
                    <v-stepper-items>
                      <v-btn
                        text
                        fab
                        small
                        class="close-btn"
                        @click="show2clics.status = false"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-stepper-content step="1">
                        <p>Avez-vous déjà immatriculé votre entreprise ?</p>
                        <div class="row justify-center">
                          <v-btn
                            class="success mb-1"
                            @click="
                              show2clics.isRegistered = true
                              step++
                            "
                          >
                            OUI
                          </v-btn>
                          &nbsp;
                          <v-btn
                            class="error mb-1"
                            @click="
                              show2clics.isRegistered = false
                              step++
                            "
                          >
                            NON
                          </v-btn>
                        </div>
                      </v-stepper-content>

                      <v-stepper-content step="2">
                        <div v-if="show2clics.isRegistered">
                          <p>Avez-vous besoin d'une formation ?</p>
                          <div class="row justify-center">
                            <v-btn
                              class="success mb-1"
                              @click="
                                show2clics.needTraining = true
                                step++
                              "
                            >
                              OUI
                            </v-btn>
                            &nbsp;
                            <v-btn
                              class="error mb-1"
                              @click="
                                show2clics.needTraining = false
                                step++
                              "
                            >
                              NON
                            </v-btn>
                          </div>
                        </div>
                        <div v-else>
                          <p
                            >Souhaitez-vous tester la viabilité de notre projet
                            ?</p
                          >
                          <div class="row justify-center">
                            <v-btn
                              class="success mb-1"
                              @click="
                                show2clics.testViabilityProject = true
                                step++
                              "
                            >
                              OUI
                            </v-btn>
                            &nbsp;
                            <v-btn
                              class="error mb-1"
                              @click="
                                show2clics.testViabilityProject = false
                                step++
                              "
                            >
                              NON
                            </v-btn>
                          </div>
                        </div>
                      </v-stepper-content>

                      <v-stepper-content step="3">
                        <div v-if="isCrashTest">
                          <p
                            >Le <strong>Crash-Test</strong> est fait pour vous
                            !</p
                          >
                          <v-btn
                            class="success mb-1"
                            @click="goToStep(2)"
                          >
                            Aller voir
                          </v-btn>
                          <v-btn class="warning mb-1" @click="reloadShow2clics">
                            Recommencer
                          </v-btn>
                        </div>
                        <div v-if="isImpulse">
                          <p
                            >L'<strong>Impulse&reg;</strong> est faite pour vous
                            !</p
                          >
                          <v-btn
                            class="success  mb-1"
                            @click="goToStep(4)"
                          >
                            Aller voir
                          </v-btn>
                          <v-btn class="warning mb-1" @click="reloadShow2clics">
                            Recommencer
                          </v-btn>
                        </div>
                        <div v-if="isAcademy">
                          <p
                            >L'<strong>Academy</strong> est faite pour
                            vous !</p
                          >
                          <v-btn
                            class="success mb-1"
                            @click="goToStep(5)"
                          >
                            Aller voir
                          </v-btn>
                          <v-btn class="warning mb-1" @click="reloadShow2clics">
                            Recommencer
                          </v-btn>
                        </div>
                      </v-stepper-content>
                    </v-stepper-items>
                  </v-stepper>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row
            class="px-10 px-sm-4 justify-center align-center flex-column flex-sm-column flex-md-row flex-lg-row"
          >
            <v-col
              cols="12"
              xs="6"
              sm="6"
              md="3"
              lg="3"
              class="crash-test-container shadow-yellow mb-sm-12 mb-10 pa-0"
              @click="goToStep(2)"
            >
              <v-row class="our-programs-content align-center">
                <v-col>
                  <h3>le crash-test</h3>
                  <p>tester pour mieux créer</p>
                </v-col>
              </v-row>
            </v-col>

            <v-col
              cols="12"
              md="4"
              lg="4"
              class="immatriculation-container px-0 d-none d-sm-none d-md-block d-lg-block"
            >
              <div class="immatriculation-content">
                <v-icon>mdi-star</v-icon>
                <!-- <p>
                  <span class="bg-white">immatriculation</span>
                </p> -->
                <v-row>
                  <v-col lg="3" xl="4" class="pa-0 text-right">
                    <v-icon color="primary" class="arrow-start d-md-none d-lg-none d-xl-inline-flex">
                      mdi-minus
                    </v-icon>
                    <v-icon color="primary" class="arrow-start d-md-none d-lg-none d-xl-inline-flex">
                      mdi-minus
                    </v-icon>
                    <v-icon color="primary" class="arrow-start d-md-none d-lg-none d-xl-inline-flex">
                      mdi-minus
                    </v-icon>
                    <v-icon color="primary" class="arrow-start d-md-none d-lg-none d-xl-inline-flex">
                      mdi-minus
                    </v-icon>
                    <v-icon color="primary" class="arrow-start d-md-none d-lg-none d-xl-inline-flex">
                      mdi-minus
                    </v-icon>
                    <v-icon color="primary" class="arrow-start d-md-none d-lg-none d-xl-inline-flex">
                      mdi-minus
                    </v-icon>
                    <v-icon color="primary" class="arrow-start d-md-none d-lg-none d-xl-inline-flex">
                      mdi-minus
                    </v-icon>
                    <v-icon color="primary" class="arrow-start d-md-none d-lg-inline-flex d-xl-inline-flex">
                      mdi-minus
                    </v-icon>
                    <v-icon color="primary" class="arrow-start d-md-none d-lg-inline-flex d-xl-inline-flex">
                      mdi-minus
                    </v-icon>
                    <v-icon color="primary" class="arrow-start d-md-none d-lg-inline-flex d-xl-inline-flex">
                      mdi-minus
                    </v-icon>
                    <v-icon color="primary" class="arrow-start d-md-none">
                      mdi-minus
                    </v-icon>
                    <v-icon color="primary" class="arrow-start d-md-none">
                      mdi-minus
                    </v-icon>
                    <v-icon color="primary" class="arrow-start d-md-none">
                      mdi-minus
                    </v-icon>
                    <v-icon color="primary" class="arrow-start">
                      mdi-minus
                    </v-icon>
                    <v-icon color="primary" class="arrow-end">
                      mdi-minus
                    </v-icon>
                    <v-icon color="primary">
                      mdi-play
                    </v-icon>
                  </v-col>
                  <v-col lg="6" xl="4" class="text-center">
                    <span class="immat-text">immatriculation</span>
                  </v-col>
                  <v-col lg="3" xl="4" class="text-right">
                    <v-icon color="primary" class="arrow-start d-md-none d-lg-none d-xl-inline-flex">
                      mdi-minus
                    </v-icon>
                    <v-icon color="primary" class="arrow-start d-md-none d-lg-none d-xl-inline-flex">
                      mdi-minus
                    </v-icon>
                    <v-icon color="primary" class="arrow-start d-md-none d-lg-none d-xl-inline-flex">
                      mdi-minus
                    </v-icon>
                    <v-icon color="primary" class="arrow-start d-md-none d-lg-none d-xl-inline-flex">
                      mdi-minus
                    </v-icon>
                    <v-icon color="primary" class="arrow-start d-md-none d-lg-none d-xl-inline-flex">
                      mdi-minus
                    </v-icon>
                    <v-icon color="primary" class="arrow-start d-md-none d-lg-none d-xl-inline-flex">
                      mdi-minus
                    </v-icon>
                    <v-icon color="primary" class="arrow-start d-md-none d-lg-none d-xl-inline-flex">
                      mdi-minus
                    </v-icon>
                    <v-icon color="primary" class="arrow-start d-md-none d-lg-inline-flex d-xl-inline-flex">
                      mdi-minus
                    </v-icon>
                    <v-icon color="primary" class="arrow-start d-md-none d-lg-inline-flex d-xl-inline-flex">
                      mdi-minus
                    </v-icon>
                    <v-icon color="primary" class="arrow-start d-md-none d-lg-inline-flex d-xl-inline-flex">
                      mdi-minus
                    </v-icon>
                    <v-icon color="primary" class="arrow-start d-md-none">
                      mdi-minus
                    </v-icon>
                    <v-icon color="primary" class="arrow-start d-md-none">
                      mdi-minus
                    </v-icon>
                    <v-icon color="primary" class="arrow-start d-md-none">
                      mdi-minus
                    </v-icon>
                    <v-icon color="primary" class="arrow-start">
                      mdi-minus
                    </v-icon>
                    <v-icon color="primary" class="arrow-end">
                      mdi-minus
                    </v-icon>
                    <v-icon color="primary">
                      mdi-play
                    </v-icon>
                  </v-col>
                </v-row>
              </div>
            </v-col>

            <v-col
              cols="12"
              xs="6"
              sm="6"
              md="3"
              lg="3"
              class="impulse-container shadow-yellow mb-sm-12 mb-10 pa-0"
              @click="goToStep(4)"
            >
              <v-row class="our-programs-content align-center">
                <v-col>
                  <h3>impulse&reg;</h3>
                  <p>challenger pour avancer</p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="px-10 px-sm-4 my-0 my-md-4 justify-center">
            <v-col
              cols="12"
              xs="6"
              sm="6"
              md="10"
              lg="10"
              class="academy-container shadow-yellow mb-sm-12 mb-10 pa-0"
              @click="goToStep(5)"
            >
              <v-row class="our-programs-content align-center">
                <v-col>
                  <h3>academy</h3>
                  <p>apprendre<br />par l'expérience</p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div
        id="container-programs-crash-test"
        class="section"
        data-tooltip="Le Crash-Test"
      >
        <ProgramCrashTest />
      </div>
      <div
        id="fse-crash-test"
        class="section fp-auto-height text-center w-100"
        data-tooltip="Union Européenne"
      >
        <FseCrashTest />
      </div>
      <div
        id="container-programs-impulse"
        class="section"
        data-tooltip="Impulse&reg;"
      >
        <ProgramImpulse />
      </div>
      <div
        id="container-programs-explorys-academy"
        class="section"
        data-tooltip="Explorys Academy"
      >
        <ProgramExplorysAcademy />
      </div>
      <!-- Footer fullpage -->
      <Footer />
    </full-page>
  </v-container>
</template>

<script>
import ProgramCrashTest from '@components/ExpertiseCrashTest.vue'
import FseCrashTest from '@components/FseCrashTest.vue'
import ProgramImpulse from '@components/ExpertiseImpulse.vue'
import ProgramExplorysAcademy from '@components/ExpertiseExplorysAcademy.vue'
import Footer from '@components/Footer.vue'
import ScrollBtn from '@components/ScrollBtn.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Expertise',
  metaInfo() {
    return {
      title: 'Nos programmes',
      meta: [
        {
          name: 'description',
          content: 'description page Expertise',
        },
      ],
    }
  },
  components: {
    ProgramCrashTest,
    ProgramImpulse,
    ProgramExplorysAcademy,
    Footer,
    ScrollBtn,
    FseCrashTest,
  },
  data() {
    return {
      step: 1,
      show2clics: {
        status: false,
        isRegistered: null,
        needTraining: null,
        testViabilityProject: null,
      },
      fp: null,
      isLast: null,
      fpOpt: {
        licenseKey: '0AF69EB3-BBC24272-B09D04A0-C7C9A161',
        // Navigation fullpage
        navigation: true,
        controlArrows: false,
        // Design fullpage
        responsiveWidth: this.$vuetify.breakpoint.thresholds.sm,
        // Scrolling fullpage
        css3: true,
        easingcss3: 'cubic-bezier(0.3,1.18,0.3,1)',
        scrollingSpeed: 1100,
        loopHorizontal: true,
        // Accessibility fullpage
        keyboardScrolling: true,
        recordHistory: false,
        scrollOverflow: !this.$vuetify.breakpoint.mobile,
        // Events fullpage
        onLeave: function(origin, destination, direction) {
          this.isLast = destination.isLast
          this.setNewScrollPos(destination.index)
        }.bind(this),
      },
    }
  },
  computed: {
    ...mapGetters([]),
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    isBpXs() {
      return this.$vuetify.breakpoint.name === 'xs'
    },
    isCrashTest() {
      if (
        this.show2clics.isRegistered === false &&
        this.show2clics.testViabilityProject === true
      ) {
        return true
      } else {
        return false
      }
    },
    isImpulse() {
      if (
        this.show2clics.isRegistered === true &&
        this.show2clics.needTraining === false
      ) {
        return true
      } else {
        return false
      }
    },
    isAcademy() {
      if (
        (this.show2clics.isRegistered === true &&
          this.show2clics.needTraining === true) ||
        (this.show2clics.isRegistered === false &&
          this.show2clics.testViabilityProject === false)
      ) {
        return true
      } else {
        return false
      }
    },
  },
  mounted() {
    // Store fullpage into data return
    if (this.$refs && this.$refs.fullpage) {
      // Get FP
      this.fp = this.$refs.fullpage
      this.setActualPage('our-programs')
      this.setNewScrollPos(null)
    }

    // Disable Loading
    this.setLoading(false)
  },
  // updated() {
  //   this.$refs.fullpage.build()
  // },
  methods: {
    ...mapActions(['setNewScrollPos', 'setActualPage', 'setLoading']),
    reloadShow2clics() {
      this.step = 1
      this.show2clics.status = true
      this.show2clics.isRegistered = null
      this.show2clics.needTraining = null
      this.show2clics.testViabilityProject = null
    },
    goToStep(id) {
      this.show2clics.status = false
      this.$refs.fullpage.api.moveTo(id)
      this.step = 1
    },
  },
}
</script>
