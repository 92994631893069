<template>
  <div class="page-error">
    <v-img
      :src="require('@images/404.png')"
      alt="404"
      width="421"
      height="150"
      class="img-404"
      contain
    />
    <p class="message">
      Oops, il semblerait que vous soyez perdu.
    </p>
    <p>
      Cette page n'existe pas.
    </p>

    <!-- Home -->
    <router-link to="/" class="text-deco-none mt-15">
      <v-btn tile class="explorys-btn">
        Retrouver mon chemin
      </v-btn>
    </router-link>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Page404',
  metaInfo() {
    return {
      title: 'Page introuvable',
      meta: [{ name: 'robots', content: 'noindex' }],
    }
  },
  mounted() {
    // Disable Loading
    this.setLoading(false)
  },
  methods: {
    ...mapActions(['setLoading'])
  }
}
</script>
