<template>
  <v-container id="bloc-sliderHome" fluid>
    <v-row no-gutters>
      <v-col cols="12" lg="6"></v-col>
      <v-col cols="12" lg="8" class="sliderHome-content">
        <p>L'<span class="important">entrepreneur</span> de demain </p>
        <p>se construit <span class="important">aujourd'hui</span>.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'SliderHome',
  data() {
    return {}
  },
}
</script>
