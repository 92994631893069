import Vue from 'vue'

// State
const state = {
  organisation: {
    id: String,
  },
  organisationsList: [],
  organisationsCount: 0,
  organisationsSelectList: [],
}

// Getters
const getters = {
  getOrganisation(state) {
    return state.organisation
  },
  getOrganisationsList(state) {
    return state.organisationsList
  },
  getOrganisationsCount(state) {
    return state.organisationsCount
  },
  getOrganisationsSelectList(state) {
    return state.organisationsSelectList
  },
}

// Mutations
const mutations = {
  SET_ORGANISATION(state, organisation) {
    state.organisation = organisation
  },
  SET_ORGANISATIONS(state, organisationsList) {
    state.organisationsList = organisationsList
  },
  ADD_ORGANISATIONS(state, organisationsList) {
    state.organisationsList = state.organisationsList.concat(organisationsList)
  },
  SET_ORGANISATIONS_COUNT(state, count) {
    state.organisationsCount = count
  },
  ADD_ORGANISATIONS_COUNT(state, count) {
    state.organisationsCount += count
  },
  DELETE_ORGANISATION_BY_ID(state, idOrganisation) {
    state.organisation = state.organisationsList.filter(
      (n) => n.id !== idOrganisation
    )
  },
  SET_ORGANISATIONS_SELECT(state, organisationsSelectList) {
    state.organisationsSelectList = organisationsSelectList
  },
}

// Actions
const actions = {
  fetchOrganisationById(store, organisationId) {
    return Vue.prototype.$API
      .withoutToken()
      .get('/organisations/' + organisationId)
      .then((response) => {
        this.commit('SET_ORGANISATION', response.data[0])
        return Promise.resolve(response.data[0])
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  fetchOrganisationsList(store, args) {
    let requestURL = '/organisations'
    // Optionnal arguments for search and pagination
    requestURL += Vue.prototype.$utils.toURLRequest(args)

    return Vue.prototype.$API
      .withoutToken()
      .get(requestURL)
      .then((response) => {
        // Set Organisations
        var organisationsList = []
        response.data.data.forEach((organisation) => {
          organisationsList.push(organisation)
        })
        // Set Organisations
        this.commit('SET_ORGANISATIONS_COUNT', response.data.count)
        this.commit('SET_ORGANISATIONS', organisationsList)
        return Promise.resolve(organisationsList)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  fetchAddOrganisationsList(store, args) {
    let requestURL = '/organisations'
    // Optionnal arguments for search and pagination
    requestURL += Vue.prototype.$utils.toURLRequest(args)

    return Vue.prototype.$API
      .withoutToken()
      .get(requestURL)
      .then((response) => {
        // Set Organisations
        var organisationsList = []
        response.data.data.forEach((organisation) => {
          organisationsList.push(organisation)
        })
        // Set Organisations
        this.commit('ADD_ORGANISATIONS_COUNT', response.data.count)
        this.commit('ADD_ORGANISATIONS', organisationsList)
        return Promise.resolve(organisationsList)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  fetchOrganisationsSelectList(store) {
    return Vue.prototype.$API
      .getToken()
      .get('/organisations/form')
      .then((response) => {
        // Set Organisations
        var organisationsSelectList = []
        response.data.data.forEach((organisation) => {
          const newOrga = {
            value: organisation.id,
            text: organisation.name,
          }
          organisationsSelectList.push(newOrga)
        })
        // Set Organisations
        this.commit('SET_ORGANISATIONS_SELECT', organisationsSelectList)
        return Promise.resolve(organisationsSelectList)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  createOrganisation(store, newOrganisation) {
    return Vue.prototype.$API
      .getToken()
      .post('/organisations', newOrganisation)
      .then((response) => {
        return Promise.resolve(response.data.content)
      })
      .catch((e) => {
        return Vue.prototype.$API.displayErrors(e)
      })
  },
  updateOrganisation(store, editOrganisation) {
    return Vue.prototype.$API
      .getToken()
      .put('/organisations/' + editOrganisation.id, editOrganisation)
      .then((response) => {
        this.commit('SET_ORGANISATION', response.data.content)
        return Promise.resolve(response.data.content)
      })
      .catch((e) => {
        return Vue.prototype.$API.displayErrors(e)
      })
  },
  deleteOrganisationById(store, idOrganisation) {
    return Vue.prototype.$API
      .getToken()
      .delete('/organisations/' + idOrganisation)
      .then((response) => {
        this.commit('DELETE_ORGANISATION_BY_ID', idOrganisation)
        return Promise.resolve(response.data.content)
      })
      .catch((e) => {
        return Vue.prototype.$API.displayErrors(e)
      })
  },
  convertOrgaIntoPartner(store, idOrganisation) {
    return Vue.prototype.$API
      .getToken()
      .patch('/organisations/convert-to-partner/' + idOrganisation)
      .then((response) => {
        // this.commit('DELETE_ORGANISATION_BY_ID', idOrganisation)
        return Promise.resolve(response)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  updateOrganisationTrombiDisplay(store, idOrganisation) {
    return Vue.prototype.$API
      .getToken()
      .patch('/organisations/trombi-display/' + idOrganisation)
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  setListOrgaNull(store) {
    this.commit('SET_ORGANISATIONS', [])
    this.commit('SET_ORGANISATIONS_COUNT', 0)
  },
}

// Export
export default {
  state,
  getters,
  mutations,
  actions,
}
