<template>
  <div>
    <v-card id="form-organisations">
      <v-card-title v-if="organisationid" dark class="panel-title">
        Modifier l'organisation : {{ currentOrga ? currentOrga.name : '' }}
      </v-card-title>

      <v-spacer></v-spacer>

      <v-skeleton-loader
        v-if="loading"
        id="loader-form"
        :loading="loading"
        type="image"
      ></v-skeleton-loader>
      <v-card-text v-show="!loading">
        <v-form ref="form_organisation" v-model="valid">
          <v-row justify="space-around">
            <v-col cols="5">
              <v-switch
                v-model="currentOrga.show_in_trombi"
                label="Affiché sur le trombinoscope"
                @change="updateShowInTrombi"
              ></v-switch>
            </v-col>
            <v-col cols="5" class="d-flex align-center justify-end">
              <v-btn
                v-if="currentOrga.id"
                :disabled="!valid && !loading"
                color="primary"
                class="mr-4"
                outlined
                small
                :loading="sendLoading"
                @click="confirmToConvert"
              >
                <v-icon>mdi-exclamation-thick</v-icon>
                Convertir en Partenaire
              </v-btn>
            </v-col>
          </v-row>

          <v-row justify="space-around">
            <v-col cols="5">
              <v-text-field
                v-model="currentOrga.name"
                :rules="orgaRules.name"
                label="Nom"
                required
              >
              </v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="currentOrga.baseline"
                label="Slogan"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row justify="space-around">
            <v-col cols="5">
              <v-autocomplete
                v-model="currentOrga.id_activity"
                :items="activitiesList"
                label="Secteur d'activité"
              />
            </v-col>
            <v-col cols="5">
              <v-select
                v-model="currentOrga.status"
                :items="$enums.statusList"
                :rules="orgaRules.status"
                label="Statut"
                required
                disabled
              >
              </v-select>
            </v-col>
          </v-row>

          <v-row justify="space-around">
            <v-col cols="5">
              <v-text-field
                v-model="currentOrga.link_website"
                label="Adresse site internet"
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="currentOrga.date_entry"
                label="Date de création"
                type="date"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row justify="space-around">
            <v-col cols="11">
              <v-text-field
                v-model="currentOrga.description"
                :rules="orgaRules.description"
                label="Description"
                counter="100"
                hint="Limité à 100 caractères"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row justify="space-around">
            <v-col cols="5">
              <v-text-field
                v-model="currentOrga.contact_email"
                label="E-mail de contact"
                :rules="orgaRules.contact_email"
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="currentOrga.contact_phone"
                label="Téléphone de contact"
                counter="10"
                @keydown="$utils.formatPhoneInput($event)"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row justify="space-around">
            <v-col cols="5">
              <v-autocomplete
                v-model="currentOrga.id_place"
                :items="placesListSelect"
                label="Agence Explorys"
              />
            </v-col>
            <v-col cols="5">
              <v-select
                v-model="currentOrga.id_program"
                :items="programsList"
                label="Programme suivi"
              >
              </v-select>
            </v-col>
          </v-row>

          <v-row justify="space-around">
            <v-col cols="5">
              <v-img
                v-if="currentOrga.id_logo === 0 || !currentOrga.id_logo"
                id="photo"
                :src="require('@images/company_defaut.jpg')"
                contain
                max-width="500"
                max-height="150"
              />
              <v-img
                v-else
                id="photo"
                :src="currentOrga.logo_data"
                contain
                max-width="500"
                max-height="150"
              />
            </v-col>
            <v-col cols="5">
              <VueDropzone
                id="dropzoneFile"
                ref="dropzoneFile"
                :options="dropzoneOptions"
                @vdropzone-file-added="checkAddedFile"
              />
              <v-btn
                color="red"
                dark
                small
                class="dropzone-trash-btn"
                @click="removeFiles"
              >
                <v-icon small>fas fa-trash-alt</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-spacer></v-spacer>

      <v-card-actions v-if="!loading" class="justify-end">
        <v-btn
          :disabled="!valid && !loading"
          color="success"
          class="mr-4"
          :loading="sendLoading"
          @click="validate"
        >
          Enregistrer
        </v-btn>
        <!-- <v-btn
          :disabled="!valid && !loading"
          color="success"
          class="mr-4"
          @click="validate"
        >
          Enreg. et + utilisateurs
        </v-btn> -->
        <v-btn
          color="secondary"
          class="mr-4"
          :loading="sendLoading"
          @click="closePanel"
        >
          Annuler
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
/* global _ */
import VueDropzone from 'vue2-dropzone'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'EditOrganisations',
  components: {
    VueDropzone,
  },
  props: {
    // if organisationid is set, that's an update else it's a creation
    organisationid: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      sendLoading: false,
      currentOrga: {
        name: '',
        status: 'ACTIVE',
        baseline: '',
        description: '',
        link_website: '',
        date_entry: '',
        id_activity: '',
        contact_email: '',
        contact_phone: '',
        show_in_trombi: false,
        id_program: 1,
        id_place: 0,
        id_logo: 0,
      },
      orgaRules: {
        name: [(v) => !!v || 'Nom requis'],
        status: [(v) => !!v || 'Statut requis'],
        description: [
          (v) => (!v ? true : v.length <= 100) || 'Description de 100 caractères maximum',
        ],
        contact_email: [
          (v) => (!v ? true :
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
              v
            )) || 'Email non valide',
        ],
        contact_phone: [(v) => /^[0-9]{10}$/.test(v) || 'Téléphone non valide'],
      },
      dropzoneOptions: {
        maxFiles: 1,
        autoProcessQueue: false,
        url: function() {},
        thumbnailWidth: 150,
        parallelUploads: 1,
        dictDefaultMessage:
          "<i class='fa fa-cloud-upload'></i>Cliquez ou déposez un fichier ici",
      },
      valid: false,
      loading: true,
      fileIdToDelete: 0,
    }
  },
  computed: {
    ...mapGetters([
      'getProgramsList',
      'getActivitiesList',
      'getPlacesListSelect',
    ]),
    programsList() {
      return this.getProgramsList
    },
    activitiesList() {
      return this.getActivitiesList
    },
    placesListSelect() {
      return this.getPlacesListSelect
    },
  },
  mounted() {
    this.fetchProgramsList()
    this.fetchActivitiesList()
    // this.fetchPlacesListSelect()
    this.fetchAgencesPlacesListSelect()
    // Change default value of fields if it's an update
    if (this.organisationid) {
      this.fetchOrganisationById(this.organisationid).then((orga) => {
        this.currentOrga = _.clone(orga, true)
        this.loading = false
      })
    } else {
      this.loading = false
    }
  },
  methods: {
    ...mapActions([
      'fetchProgramsList',
      'fetchActivitiesList',
      'fetchPlacesListSelect',
      'fetchAgencesPlacesListSelect',
      'createOrganisation',
      'hidePanel',
      'fetchOrganisationById',
      'updateOrganisation',
      'setReloadingNeed',
      'saveFile',
      'deleteFile',
      'convertOrgaIntoPartner',
      'updateOrganisationTrombiDisplay',
    ]),
    removeFiles() {
      this.$refs.dropzoneFile.removeAllFiles()
    },
    validate() {
      const acceptedFiles = this.$refs.dropzoneFile.getAcceptedFiles()
      // Organisation request with new file
      if (acceptedFiles.length >= 1) {
        this.$utils
          .toBase64(acceptedFiles[0])
          .then((response) => {
            // set file type from file name
            var fileType = acceptedFiles[0].name.split('.')
            fileType = fileType[fileType.length - 1]

            const fileToSave = {
              name: acceptedFiles[0].name,
              title: 'Logo Organisation ' + this.currentOrga.name,
              description:
                'Logo Organisation ' +
                this.currentOrga.name +
                ' sets automatically from organisation form',
              folder: 'INTERNAL',
              type: fileType,
              data: response,
            }
            this.saveFile(fileToSave)
              .then((response) => {
                this.$awn.success('Fichier enregistré avec succès')

                // Set old file to delete if needed
                if (this.currentOrga.id_logo !== 0) {
                  this.fileIdToDelete = this.currentOrga.id_logo
                }

                this.currentOrga.id_logo = response.data.id
                this.sendOrganisationRequest()
              })
              .catch((e) => {
                this.$awn.alert("ERREUR lors de l'enregistrement du fichier")
              })
          })
          .catch((e) => {
            this.$awn.alert('ERREUR lors de la lecture du fichier')
          })
      }
      // Organisation request without new file
      else {
        this.sendOrganisationRequest()
      }
    },
    sendOrganisationRequest() {
      // Trigger loading
      this.sendLoading = true

      this.$refs.form_organisation.validate()

      if (this.valid) {
        // UPDATE case
        if (this.organisationid) {
          this.currentOrga.id = this.organisationid
          this.updateOrganisation(this.currentOrga)
            .then((response) => {
              this.$awn.success(
                "L'organisation <b>" +
                  this.currentOrga.name +
                  '</b> a été modifiée'
              )
              // Delete old File if needed
              if (this.fileIdToDelete !== 0) {
                this.deleteFile(this.fileIdToDelete)
              }
              this.closePanel()
            })
            .catch((e) => {
              this.$awn.alert(
                "ERREUR lors de la demande de modification de l'organisation <b>" +
                  this.currentOrga.name +
                  '</b>'
              )
              // Disable loading
              this.sendLoading = false
            })
        }
        // POST case
        else {
          this.createOrganisation(this.currentOrga)
            .then((response) => {
              this.$awn.success(
                "L'organisation " +
                  this.currentOrga.name +
                  ' a été créée avec succès'
              )
              this.closePanel()
            })
            .catch((e) => {
              this.$awn.alert(
                "ERREUR lors de la demande de création d'organisation"
              )
              // Disable loading
              this.sendLoading = false
            })
        }
      } else {
        this.$awn.alert(
          "ERREUR Formulaire mal renseigné"
        )
        // Disable loading
        this.sendLoading = false
      }
    },
    closePanel() {
      this.fileIdToDelete = 0
      this.hidePanel()
      this.setReloadingNeed(true)
      // Disable loading
      this.sendLoading = false
    },
    checkAddedFile(File) {
      // event addedFile watcher on dropzone to check file added
      if (File.size >= 5000000) {
        this.$refs.dropzoneFile.removeFile(File)
        this.$awn.warning('Le fichier doit faire moins de 5Mo')
      }
      if (
        File.type !== 'image/png' &&
        File.type !== 'image/jpg' &&
        File.type !== 'image/jpeg'
      ) {
        this.$refs.dropzoneFile.removeFile(File)
        this.$awn.warning('Le fichier doit être une image (format PNG ou JPEG)')
      }
      if (this.$refs.dropzoneFile.getAcceptedFiles().length === 1) {
        this.$refs.dropzoneFile.removeFile(File)
        this.$awn.warning('Une seule image autorisée')
      }
    },
    confirmToConvert() {
      const orgaName = this.currentOrga.name

      // Set trigering functions
      const onOk = () => {
        this.sendLoading = true
        this.convertOrgaIntoPartner(this.currentOrga.id)
          .then((response) => {
            this.$awn.success(
              "L'organisation <b>" +
                orgaName +
                '</b> a été convertie avec succès en partenaire'
            )
            this.sendLoading = false
            this.closePanel()
          })
          .catch((e) => {
            this.$awn.error(
              "Une erreur est survenue lors de la conversion de l'organisation en partenaire"
            )
            this.sendLoading = false
          })
      }
      const onCancel = () => {}

      this.$awn.confirm(
        "<br/>Souhaitez-vous convertir l'organisation <b>" +
          this.currentOrga.name +
          '</b> en tant que Partenaire ?<br/>' +
          "<p style='color: darkred;'><br/><b>Attention : </b> Cela entraînera la suppression irréversible de tous les utilisateurs, avatars, commentaires, publications liés à l'organisation...</p>",
        onOk,
        onCancel,
        {
          labels: {
            confirm: "Conversion d'une Organisation en Partenaire",
          },
        }
      )
    },
    updateShowInTrombi() {
      this.updateOrganisationTrombiDisplay(this.currentOrga.id)
        .then((response) => {
          var confirmMessage =
            "L'organisation " + this.currentOrga.name + ' est maintenant '
          confirmMessage += this.currentOrga.show_in_trombi
            ? 'affichée sur le '
            : 'masquée du '
          confirmMessage += 'trombinoscope.'
          this.$awn.success(confirmMessage)
        })
        .catch((e) => {
          this.$awn.alert(
            "ERREUR lors de la demande de modification de l'organisation"
          )
        })
    },
  },
}
</script>
