<template>
  <div>
    <v-card id="form-organisations">
      <v-card-title dark class="panel-title">
        Ajouter une organisation
      </v-card-title>

      <v-spacer></v-spacer>

      <v-skeleton-loader
        v-if="loading"
        id="loader-form"
        :loading="loading"
        type="image"
      ></v-skeleton-loader>
      <v-card-text v-show="!loading">
        <v-form ref="formFile" v-model="valid">
          <v-row justify="space-around">
            <v-col cols="5">
              <v-switch
                v-model="currentOrga.show_in_trombi"
                label="Affiché sur le trombinoscope"
              ></v-switch>
            </v-col>
            <v-col cols="5"> </v-col>
          </v-row>

          <v-row justify="space-around">
            <v-col cols="5">
              <v-text-field
                v-model="currentOrga.name"
                :rules="orgaRules.name"
                label="Nom"
                required
              >
              </v-text-field>
            </v-col>
            <v-col cols="5">
              <v-autocomplete
                v-model="currentOrga.id_activity"
                :items="activitiesList"
                label="Secteur d'activité"
                required
              />
            </v-col>
          </v-row>

          <v-row justify="space-around">
            <v-col cols="5">
              <v-autocomplete
                v-model="currentOrga.id_place"
                :items="placesListSelect"
                label="Agence Explorys"
                required
              />
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="currentOrga.date_entry"
                label="Date de création"
                type="date"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row justify="space-around">
            <v-col cols="5">
              <v-select
                v-model="currentOrga.id_program"
                :items="programsList"
                label="Programme suivi"
                required
              >
              </v-select>
            </v-col>
            <v-col cols="5"> </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-spacer></v-spacer>

      <v-card-actions v-if="!loading" class="justify-end">
        <v-btn
          :disabled="!valid && !loading"
          color="success"
          class="mr-4"
          :loading="sendLoading"
          @click="sendOrganisationRequest"
        >
          Enregistrer
        </v-btn>
        <!-- <v-btn
          :disabled="!valid && !loading"
          color="success"
          class="mr-4"
          @click="validate"
        >
          Enreg. et + utilisateurs
        </v-btn> -->

        <v-btn
          color="secondary"
          class="mr-4"
          :loading="sendLoading"
          @click="closePanel"
        >
          Annuler
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CreateOrganisations',
  components: {},
  data() {
    return {
      sendLoading: false,
      currentOrga: {
        name: '',
        status: 'ACTIVE',
        date_entry: '',
        id_activity: '',
        contact_email: '',
        contact_phone: '',
        show_in_trombi: false,
        id_program: 1,
        id_place: 0,
      },
      orgaRules: {
        name: [(v) => !!v || 'Nom requis'],
        status: [(v) => !!v || 'Statut requis'],
        contact_email: [
          (v) =>
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
              v
            ) || 'Email non valide',
        ],
        contact_phone: [(v) => /^[0-9]{10}$/.test(v) || 'Téléphone non valide'],
      },
      valid: true,
      loading: false,
    }
  },
  computed: {
    ...mapGetters([
      'getProgramsList',
      'getActivitiesList',
      'getPlacesListSelect',
    ]),
    programsList() {
      return this.getProgramsList
    },
    activitiesList() {
      return this.getActivitiesList
    },
    placesListSelect() {
      return this.getPlacesListSelect
    },
  },
  mounted() {
    this.fetchProgramsList()
    this.fetchActivitiesList()
    this.fetchPlacesListSelect()
  },
  methods: {
    ...mapActions([
      'createOrganisation',
      'hidePanel',
      'fetchProgramsList',
      'fetchPlacesListSelect',
      'fetchActivitiesList',
      'setReloadingNeed',
    ]),
    sendOrganisationRequest() {
      // Trigger loading
      this.sendLoading = true
      this.createOrganisation(this.currentOrga)
        .then((response) => {
          this.$awn.success(
            "L'organisation " +
              this.currentOrga.name +
              ' a été créée avec succès'
          )
          this.closePanel()
        })
        .catch((e) => {
          this.$awn.alert(
            "ERREUR lors de la demande de création d'organisation"
          )
          // Disable loading
          this.sendLoading = false
        })
    },
    closePanel() {
      this.hidePanel()
      this.setReloadingNeed(true)
      // Disable loading
      this.sendLoading = false
    },
  },
}
</script>
