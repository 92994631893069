// State
const state = {
  cookiesPref: {
    showBanner: true,
    necessary: true,
    tracking: false,
  },
}

// Getters
const getters = {
  getCookiesPref(state) {
    return state.cookiesPref
  },
  getBannerState(state) {
    return state.cookiesPref.showBanner
  }
}

// Mutations
const mutations = {
  SET_COOKIES_PREF(state, newPref) {
    state.cookiesPref = newPref
  },
  SET_BANNER_STATE(state, newState) {
    state.cookiesPref.showBanner = newState
  }
}

// Actions
const actions = {
  setCookiesPref(store, newPref) {
    this.commit('SET_COOKIES_PREF', newPref)
  },
  setBannerState(store, newState) {
    this.commit('SET_BANNER_STATE', newState)
  }
}

// Export
export default {
  state,
  getters,
  mutations,
  actions,
}
