<template>
  <v-col cols="12" sm="6" md="6" lg="3">
    <v-card
      class="e-box"
      :class="isMobile ? 'clicked' : ''"
      tile
    >
      <transition name="box-fade" mode="out-in">
        <v-container>
          <!-- Normal -->
          <p class="id">{{ '0' + box.id + '.' }}</p>
          <p class="box-title">{{ box.title }}</p>
          <!-- Hover -->
          <p class="box-title-hover">{{ box.title }}</p>
          <p class="baseline">{{ box.baseline }}</p>
          <p v-for="line in box.list" :key="line" class="list-item">{{
            line
          }}</p>
        </v-container>
      </transition>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: 'WhoWeAreEBox',
  props: {
    box: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      clicked: false,
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
  methods: {
    mobileClick() {
      if (this.isMobile) {
        // toggle clicked class
        this.clicked = !this.clicked
      }
    },
  },
}
</script>
