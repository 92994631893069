<template>
  <div
    id="fse-home"
    class="section fp-auto-height text-center w-100"
    data-tooltip="Union Européenne"
  >
    <div class="fse-logo">
      <a href="https://www.auvergnerhonealpes.fr/" target="_blank">
        <img
          :src="require('@images/ue-fse.png')"
          alt="Logo FSE"
        />
      </a>
    </div>
    <p class="fs-15">L’opération « Tester pour mieux créer » est cofinancée par l’Union européenne <br>dans le cadre du Fonds Social Européen (FSE).</p>
  </div>
</template>

<script>
export default {
  name: "FseHome",
}
</script>
