<template>
  <v-container>
    <v-fade-transition>
      <div v-if="stateVisible" id="overlay-panel" @click="hidePanel" />
    </v-fade-transition>
    <v-slide-x-reverse-transition origin="top 100%">
      <div v-if="stateVisible" id="panel">
        <v-btn icon class="btn-close-panel" @click="hidePanel">
          <v-icon>far fa-times-circle</v-icon>
        </v-btn>
        <EditOrganisations
          v-if="panelForm === 'editorganisations'"
          :organisationid="itemId"
        />
        <CreateEditPartners
          v-if="panelForm === 'partners'"
          :partnerid="itemId"
        />
        <CreateEditExperts v-if="panelForm === 'experts'" :expertid="itemId" />
        <CreateEditFiles v-if="panelForm === 'files'" :fileid="itemId" />
        <CreateEditEvents v-if="panelForm === 'events'" :eventid="itemId" />
        <CreateUser v-if="panelForm === 'crea-users'" />
        <EditUsers v-if="panelForm === 'users'" :userid="itemId" />
        <EditOrganisationsUsers
          v-if="panelForm === 'organisationsusers'"
          :organisationid="itemId"
        />
        <CreateOrganisations v-if="panelForm === 'createorganisations'" />
        <ManageOrderExperts v-if="panelForm === 'order-experts'" />
      </div>
    </v-slide-x-reverse-transition>
  </v-container>
</template>

<script>
import EditOrganisations from '@components/panels/EditOrganisations.vue'
import CreateEditPartners from '@components/panels/CreateEditPartners.vue'
import CreateEditExperts from '@components/panels/CreateEditExperts.vue'
import CreateUser from '@components/panels/CreateUser.vue'
import EditUsers from '@components/panels/EditUsers.vue'
import CreateEditFiles from '@components/panels/CreateEditFiles.vue'
import CreateEditEvents from '@components/panels/CreateEditEvents.vue'
import EditOrganisationsUsers from '@components/panels/EditOrganisationsUsers.vue'
import CreateOrganisations from '@components/panels/CreateOrganisations.vue'
import ManageOrderExperts from '@components/panels/ManageOrderExperts.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Panel',
  components: {
    EditOrganisations,
    CreateEditPartners,
    CreateEditExperts,
    CreateUser,
    EditUsers,
    CreateEditFiles,
    CreateEditEvents,
    EditOrganisationsUsers,
    CreateOrganisations,
    ManageOrderExperts,
  },
  props: {
    organisationid: {
      type: Number,
      default: 0,
    },
    partnerid: {
      type: Number,
      default: 0,
    },
    expertid: {
      type: Number,
      default: 0,
    },
    userid: {
      type: Number,
      default: 0,
    },
    eventid: {
      type: Number,
      default: 0,
    },
    fileid: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['isPanelDisplayed', 'getPanelForm', 'getPanelItemId']),
    stateVisible() {
      return this.isPanelDisplayed
    },
    panelForm() {
      return this.getPanelForm
    },
    itemId() {
      return this.getPanelItemId
    },
  },
  methods: {
    ...mapActions(['hidePanel']),
  },
}
</script>
