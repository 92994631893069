<template>
  <v-container id="b-o-experts" fluid class="p-0">
    <v-row>
      <v-col cols="12" lg="4">
        <h3>Gestion des membres de l'équipe Explorys</h3>
      </v-col>
      <v-col cols="12" lg="4">
        <v-text-field
          v-model="searchString"
          label="Recherche"
          prepend-icon="mdi-magnify"
          clear-icon="mdi-close-circle-outline"
          clearable
          dense
          counter="3"
          @click:clear="clearSearch"
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="4" class="text-right">
        <v-btn
          v-if="loggedUser.role === 'SUPERADMIN'"
          dark
          color="primary"
          class="mr-2"
          @click="openPanelOrderExpert"
        >
          <v-icon class="mr-2">fa fa-sort-numeric-up</v-icon>
          Gérer l'ordre
        </v-btn>
        <v-btn
          v-if="loggedUser.role === 'SUPERADMIN'"
          dark
          color="primary"
          @click="openPanelCreateExpert"
        >
          <v-icon class="mr-2">fa fa-plus</v-icon>
          Ajouter un membre
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="gotExpertsList"
      class="elevation-1"
      light
      :page.sync="page"
      :items-per-page="itemsPerPage"
      :loading="isLoading"
      loading-text="Chargement... Veuillez patienter."
      hide-default-footer
      :disable-sort="true"
    >
      <template v-slot:[`item.photo`]="{ item }">
        <v-avatar size="36">
          <v-img v-if="item.id_photo" :src="item.photo_data" />
          <v-img
            v-if="!item.id_photo"
            :src="require('@images/avatar_defaut.png')"
          />
        </v-avatar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          v-tippy
          class="fs-16-btn mr-2"
          color="primary"
          icon
          small
          dark
          content="Modifier l'expert"
          @click="openPanelEditExpert(item.id)"
        >
          <v-icon>fas fa-edit</v-icon>
        </v-btn>
        <v-btn
          v-tippy
          class="fs-16-btn"
          color="red"
          icon
          small
          dark
          content="Supprimer l'expert"
          @click.native="
            confirmDeleteExpert(item.id, item.firstname + ' ' + item.lastname)
          "
        >
          <v-icon>fas fa-trash-alt</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-pagination
      v-model="page"
      :length="pageCount"
      class="mt-2"
    ></v-pagination>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ExpertsManagement',
  metaInfo() {
    return null
  },
  components: {},
  data() {
    return {
      headers: [
        {
          text: 'ID',
          value: 'id',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Photo',
          value: 'photo',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Prénom',
          value: 'firstname',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Nom',
          value: 'lastname',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Poste',
          value: 'position',
          sortable: false,
          align: 'center',
        },
        {
          text: 'E-mail',
          value: 'email',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Téléphone',
          value: 'phone',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Lien LinkedIn',
          value: 'link_linkedin',
          sortable: false,
        },
        {
          text: 'Description',
          value: 'description',
          sortable: false,
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'center',
          width: '120px',
        },
      ],
      isLoading: true,
      searchString: '',
      itemsPerPage: 10,
      page: 1,
      rules: {
        info: (value) =>
          value.length === 0 || 'min 3 caractères - supprimer pour réinitialiser',
      },
    }
  },
  computed: {
    ...mapGetters([
      'getLoggedUser',
      'getExpertsList',
      'getExpertsCount',
      'isPanelDisplayed',
      'isTableNeedsReloading',
    ]),
    loggedUser() {
      return this.getLoggedUser
    },
    gotExpertsList() {
      return this.getExpertsList
    },
    pageCount() {
      return Math.ceil(this.getExpertsCount / this.itemsPerPage)
    },
    requestArgs() {
      const args = {
        search: '',
        limit: 0,
        offset: 0,
      }
      args.search = this.searchString
      args.limit = this.itemsPerPage
      args.offset = (this.page - 1) * this.itemsPerPage
      return args
    },
    panelDisplayed() {
      return this.isPanelDisplayed
    },
    tableNeedsReloading() {
      return this.isTableNeedsReloading
    },
  },
  watch: {
    searchString: function(value) {
      if (value && (value.length >= 3 || value.length === 0)) {
        this.isLoading = true
        this.page = 1
        this.fetchExpertsList(this.requestArgs).then((response) => {
          this.isLoading = false
        })
      }
    },
    page: function() {
      this.isLoading = true
      this.fetchExpertsList(this.requestArgs).then((response) => {
        this.isLoading = false
      })
    },
    panelDisplayed: function() {
      if (!this.panelDisplayed && this.isTableNeedsReloading) {
        this.isLoading = true
        this.fetchExpertsList(this.requestArgs).then((response) => {
          this.isLoading = false
          this.setReloadingNeed(false)
        })
      }
    },
  },
  mounted() {
    this.fetchExpertsList(this.requestArgs).then((response) => {
      this.isLoading = false
    })

    // Disable Loading
    this.setLoading(false)
  },
  methods: {
    ...mapActions([
      'togglePanel',
      'fetchExpertsList',
      'deleteExpertById',
      'setReloadingNeed',
      'setPanelForm',
      'setPanelItemId',
      'clearPanelItemId',
      'setLoading'
    ]),
    clearSearch() {
      this.searchString = ''
      this.isLoading = true
      this.fetchExpertsList(this.requestArgs).then((response) => {
        this.isLoading = false
      })
    },
    openPanelCreateExpert() {
      this.clearPanelItemId()
      this.setPanelForm('experts')
      this.togglePanel()
    },
    openPanelEditExpert(expertId) {
      this.setPanelItemId(expertId)
      this.setPanelForm('experts')
      this.togglePanel()
    },
    openPanelOrderExpert() {
      this.setPanelItemId()
      this.setPanelForm('order-experts')
      this.togglePanel()
    },
    confirmDeleteExpert(expertId, expertName) {
      // Set trigering functions
      const onOk = () => {
        this.deleteExpert(expertId, expertName)
      }
      const onCancel = () => {}

      this.$awn.confirm(
        "Souhaitez-vous supprimer définitivement l'expert <b>" +
          expertName +
          '</b> ?',
        onOk,
        onCancel,
        {
          labels: {
            confirm: "Suppression d'un expert",
          },
        }
      )
    },
    deleteExpert(id, name) {
      this.isLoading = true
      this.deleteExpertById(id)
        .then((response) => {
          this.$awn.success("L'expert a bien été supprimé")
          this.fetchExpertsList(this.requestArgs)
        })
        .catch((e) => {
          this.$awn.alert(
            'Une erreur est survenue, veuillez contacter le service support'
          )
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
  },
}
</script>
