<template>
  <div>
    <v-card id="form-partners">
      <v-card-title v-if="!partnerid" dark class="panel-title">
        Ajouter un partenaire
      </v-card-title>
      <v-card-title v-if="partnerid" dark class="panel-title">
        Modifier le partenaire : {{ currentPartner ? currentPartner.name : '' }}
      </v-card-title>

      <v-spacer></v-spacer>

      <v-skeleton-loader
        v-if="loading"
        id="loader-form"
        :loading="loading"
        type="image"
      ></v-skeleton-loader>
      <v-card-text v-show="!loading">
        <v-form ref="formFile" v-model="valid">
          <v-row justify="space-around">
            <v-col cols="5">
              <v-text-field
                v-model="currentPartner.name"
                :rules="partnerRules.name"
                label="Nom"
                required
              >
              </v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="currentPartner.link_website"
                :rules="partnerRules.link_website"
                label="Lien site internet"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row justify="space-around">
            <v-col cols="5">
              <v-img
                v-if="currentPartner.id_logo === 0 || !currentPartner.id_logo"
                id="photo"
                :src="require('@images/company_defaut.jpg')"
                contain
                max-width="500"
                max-height="150"
              />
              <v-img
                v-else
                id="photo"
                :src="currentPartner.logo_data"
                contain
                max-width="500"
                max-height="150"
              />
            </v-col>
            <v-col cols="5">
              <VueDropzone
                id="dropzoneFile"
                ref="dropzoneFile"
                :options="dropzoneOptions"
                @vdropzone-file-added="checkAddedFile"
              />
              <v-btn
                color="red"
                dark
                small
                class="dropzone-trash-btn"
                @click="removeFiles"
              >
                <v-icon small>fas fa-trash-alt</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-spacer></v-spacer>

      <v-card-actions class="justify-end">
        <v-btn
          :disabled="!valid && !loading"
          color="success"
          class="mr-4"
          :loading="sendLoading"
          @click="validate"
        >
          Enregistrer
        </v-btn>
        <!-- <v-btn
          :disabled="!valid && !loading"
          color="success"
          class="mr-4"
          @click="validate"
        >
          Enreg. et + utilisateurs
        </v-btn> -->

        <v-btn
          color="secondary"
          class="mr-4"
          :loading="sendLoading"
          @click="closePanel"
        >
          Annuler
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
/* global _ */
import VueDropzone from 'vue2-dropzone'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CreateEditPartners',
  components: {
    VueDropzone,
  },
  props: {
    // if partnerid is set, that's an update else it's a creation
    partnerid: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      sendLoading: false,
      currentPartner: {
        name: '', //
        link_website: '', //
        id_logo: 0,
      },
      partnerRules: {
        name: [(v) => !!v || 'Nom requis'],
        link_website: [(v) => !!v || 'Adresse site internet requise'],
      },
      dropzoneOptions: {
        maxFiles: 1,
        autoProcessQueue: false,
        url: function() {},
        thumbnailWidth: 150,
        parallelUploads: 1,
        dictDefaultMessage:
          "<i class='fa fa-cloud-upload'></i>Cliquez ou déposez un fichier ici",
      },
      valid: true,
      loading: true,
      fileIdToDelete: 0,
    }
  },
  computed: {
    ...mapGetters([]),
  },
  mounted() {
    // Change default value of fields if it's an update
    if (this.partnerid) {
      this.fetchPartnerById(this.partnerid).then((partner) => {
        this.currentPartner = _.clone(partner, true)
        this.loading = false
      })
    } else {
      this.loading = false
    }
  },
  methods: {
    ...mapActions([
      'createPartner',
      'togglePanel',
      'fetchPartnerById',
      'fetchPartnersList',
      'updatePartner',
      'setReloadingNeed',
      'saveFile',
      'deleteFile',
    ]),
    removeFiles() {
      this.$refs.dropzoneFile.removeAllFiles()
    },
    validate() {
      const acceptedFiles = this.$refs.dropzoneFile.getAcceptedFiles()
      // Partner request with new file
      if (acceptedFiles.length >= 1) {
        this.$utils
          .toBase64(acceptedFiles[0])
          .then((response) => {
            // set file type from file name
            var fileType = acceptedFiles[0].name.split('.')
            fileType = fileType[fileType.length - 1]

            const fileToSave = {
              name: acceptedFiles[0].name,
              title: 'Logo Partner ' + this.currentPartner.name,
              description:
                'Logo Partner ' +
                this.currentPartner.name +
                ' sets automatically from partner form',
              folder: 'INTERNAL',
              type: fileType,
              data: response,
            }
            this.saveFile(fileToSave)
              .then((response) => {
                this.$awn.success('Fichier enregistré avec succès')

                // Set old file to delete if needed
                if (this.currentPartner.id_logo !== 0) {
                  this.fileIdToDelete = this.currentPartner.id_logo
                }

                this.currentPartner.id_logo = response.data.id
                this.sendPartnerRequest()
              })
              .catch((e) => {
                this.$awn.alert("ERREUR lors de l'enregistrement du fichier")
              })
          })
          .catch((e) => {
            this.$awn.alert('ERREUR lors de la lecture du fichier')
          })
      }
      // Partner request without new file
      else {
        this.sendPartnerRequest()
      }
    },
    sendPartnerRequest() {
      // Disable loading
      this.sendLoading = true

      // UPADATE case
      if (this.partnerid) {
        this.currentPartner.id = this.partnerid
        this.updatePartner(this.currentPartner)
          .then((response) => {
            this.$awn.success(
              'Le partenaire <b>' +
                this.currentPartner.name +
                '</b> a été modifié'
            )
            // Delete old File if needed
            if (this.fileIdToDelete !== 0) {
              this.deleteFile(this.fileIdToDelete)
            }
            this.closePanel()
          })
          .catch((e) => {
            this.$awn.alert(
              'ERREUR lors de la demande de modification du partenaire ' +
                this.currentPartner.name
            )
            // Disable loading
            this.sendLoading = false
          })
      }
      // POST case
      else {
        this.createPartner(this.currentPartner)
          .then((response) => {
            this.$awn.success(
              'Le partenaire <b>' +
                this.currentPartner.name +
                '</b> a été créé avec succès'
            )
            this.closePanel()
          })
          .catch((e) => {
            this.$awn.alert(
              'ERREUR lors de la demande de création de partenaire'
            )
            // Disable loading
            this.sendLoading = false
          })
      }
    },
    closePanel() {
      this.fileIdToDelete = 0
      this.togglePanel()
      this.setReloadingNeed(true)
      // Disable loading
      this.sendLoading = false
    },
    checkAddedFile(File) {
      // event addedFile watcher on dropzone to check file added
      if (File.size >= 5000000) {
        this.$refs.dropzoneFile.removeFile(File)
        this.$awn.warning('Le fichier doit faire moins de 5Mo')
      }
      if (
        File.type !== 'image/png' &&
        File.type !== 'image/jpg' &&
        File.type !== 'image/jpeg'
      ) {
        this.$refs.dropzoneFile.removeFile(File)
        this.$awn.warning('Le fichier doit être une image (format PNG ou JPEG)')
      }
      if (this.$refs.dropzoneFile.getAcceptedFiles().length === 1) {
        this.$refs.dropzoneFile.removeFile(File)
        this.$awn.warning('Une seule image autorisée')
      }
    },
  },
}
</script>
