<template>
  <v-container id="agenda" fluid class="p-0">

    <!-- Confirm DialogEvent for user -->
    <v-dialog
      v-model="showDialogEvent"
      max-width="600"
    >
      <v-card>
        <v-card-title class="headline">
          Votre évènement à été proposé à Explorys !
        </v-card-title>

        <v-card-text>
          Il est actuellement en cours de validation. Un e-mail à été envoyé aux administrateurs de la plateforme.
          <br><br>
          Vous recevrez un email de confirmation lorsqu'il sera validé et l'évènement sera visible dans l'agenda afin d'y participer.
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn
            color="green"
            text
            @click="setShowDialogEvent(false)"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12" lg="4">
        <h3>Agenda Explorys</h3>
      </v-col>
      <v-col cols="12" lg="8" class="agenda-btns">
        <v-btn dark color="primary" class="mr-2" @click="openPanelEvent(0)">
          <v-icon class="mr-2">fa fa-plus</v-icon>
          <span v-if="getLoggedUser.role === 'SUPERADMIN'"
            >Ajouter un nouvel évènement</span
          >
          <span v-else>Proposer un évènement</span>
        </v-btn>
        <v-btn
          v-if="getLoggedUser.role === 'SUPERADMIN'"
          dark
          color="primary"
          @click="$router.push({ name: 'EventsConfirmation' })"
        >
          <v-icon class="mr-2">mdi-eye</v-icon>
          Voir les évènements en attente
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-if="!isMobile" cols="12" lg="6">
        <v-sheet tile height="54" class="d-flex">
          <v-btn icon class="ma-2" @click="prevMonth">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon class="ma-2" @click="nextMonth">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
            id="agenda-calendar"
            ref="calendar"
            v-model="value"
            :weekdays="weekday"
            :type="type"
            :events="events"
            :event-overlap-mode="mode"
            :event-overlap-threshold="30"
            :event-color="getEventColor"
            @change="events"
            @click:event="showEvent"
          ></v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card
              color="grey lighten-4"
              max-width="500px"
              width="100%"
              flat
              class="agenda-popup"
            >
              <v-toolbar :color="selectedEvent.color" dark>
                <!-- eslint-disable-next-line -->
                <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon>
                  <v-icon title="Fermer" @click="selectedOpen = false">
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <!-- eslint-disable-next-line -->
                <p>{{ selectedEvent.desc }}</p>
                <hr />
                <p class="place"><b>Lieu :</b> {{ selectedEvent.place }}</p>
                <p class="start">
                  <b>Date : </b><br/>
                  Du {{ isSafari ? selectedEvent.safaridate_start : $utils.formatDateWithHours(selectedEvent.start) }}<br/>
                  Au {{ isSafari ? selectedEvent.safaridate_end : $utils.formatDateWithHours(selectedEvent.end) }}
                </p>
                <p
                  v-if="
                    getLoggedUser.role === 'SUPERADMIN' &&
                      selectedEvent.participants
                  "
                  class="participants"
                >
                  <b>Nb de participants : </b>
                  <span v-if="selectedEvent.participants[0] === null">
                    0
                  </span>
                  <span v-else>
                    {{ selectedEvent.participants.length }}
                    <v-btn
                      icon
                      color="primary"
                      content="Afficher la liste des participants"
                      :loading="loaderParticipants"
                      @click="showListParticipants(selectedEvent.participants, selectedEvent.id)"
                    >
                      <v-icon>mdi mdi-account-details</v-icon>
                    </v-btn>
                  </span>
                </p>
              </v-card-text>
              <v-card-actions v-if="getLoggedUser.role !== 'SUPERADMIN' && selectedEvent.start > toDay">
                <!-- eslint-disable-next-line -->
                <v-btn
                  v-if="!isNotJoin(selectedEvent.id)"
                  dark
                  class="green"
                  :loading="sendLoading"
                  @click="joinEvent(selectedEvent.id)"
                >
                  ça m'intéresse !
                </v-btn>
                <!-- eslint-disable-next-line -->
                <v-btn
                  v-else
                  dark
                  class="red"
                  :loading="sendLoading"
                  @click="cancelEvent(selectedEvent.id)"
                >
                  ça ne m'intéresse plus...
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-col>

      <v-col cols="12" lg="6">
        <div
          v-if="getLoggedUser.role === 'SUPERADMIN'"
          class="superadmin-events"
        >
          <h4 class="mb-2">Evènements à venir</h4>
          <v-data-table
            dense
            :headers="tableHeadersSA"
            :items="eventsTbl"
            :items-per-page="10"
            class="elevation-1"
            no-data-text="Aucun évènement disponible"
            :loading="isLoading"
            loading-text="Chargement... Veuillez patienter."
          >
            <template v-slot:[`item.start`]="{ item }">
              {{ isSafari ? item.safaridate_start : $utils.formatDateWithHours(item.start) }}
            </template>
            <template v-slot:[`item.end`]="{ item }">
              {{ isSafari ? item.safaridate_end : $utils.formatDateWithHours(item.end) }}
            </template>
            <template v-slot:[`item.id`]="{ item }">
              <v-btn
                v-tippy
                depressed
                class="fs-16-btn mr-2"
                color="primary"
                dark
                icon
                small
                content="Editer l'évènement"
                :loading="sendLoading"
                @click="openPanelEvent(item.id)"
              >
                <v-icon>fas fa-edit</v-icon>
              </v-btn>
              &nbsp;
              <v-btn
                v-tippy
                depressed
                class="fs-16-btn"
                color="red"
                icon
                dark
                small
                content="Supprimer l'évènement"
                :loading="sendLoading"
                @click.native="confirmDeleteEvent(item.id, item.name)"
              >
                <v-icon>fas fa-trash-alt</v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.participants`]="{ item }">
              <div class="text-center">
                <span v-if="item.participants[0] === null">
                  0
                </span>
                <span v-else>
                  {{ item.participants.length }}
                  <v-btn
                    icon
                    color="primary"
                    content="Afficher la liste des participants"
                    :loading="loaderParticipants"
                    @click="showListParticipants(item.participants, item.id)"
                  >
                    <v-icon>mdi mdi-account-details</v-icon>
                  </v-btn>
                </span>
              </div>
            </template>
          </v-data-table>

          <div
            v-show="loaderParticipants"
            class="loader-participants"
            no-gutters
          >
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <div v-show="showingList && !loaderParticipants" class="list-participants">
            <h4 v-if="getEvent[0] !== undefined">
              Évènement : {{ getEvent[0].title }}
            </h4>
            <h5>
              Liste des participants :
            </h5>
            <ul>
              <li v-for="item in gotListParticipants" :key="item.id">
                {{ item.name }} - {{ item.email }} ({{ item.organisation}})
              </li>
            </ul>
          </div>
        </div>

        <div v-else class="user-events">
          <div class="my-events">
            <h4>Evènements auxquels je participe</h4>
            <v-data-table
              dense
              :headers="tableHeaders"
              :items="myEvents"
              :items-per-page="5"
              class="elevation-1"
              no-data-text="Aucun évènement disponible"
              :loading="isLoading"
              loading-text="Chargement... Veuillez patienter."
            >
              <template v-slot:[`item.start`]="{ item }">
                {{ isSafari ? item.safaridate_start : item.start }}
              </template>
              <template v-slot:[`item.end`]="{ item }">
                {{ isSafari ? item.safaridate_end : item.end }}
              </template>
              <template v-slot:[`item.id`]="{ item }">
                <v-btn
                  v-tippy
                  depressed
                  class="fs-16-btn"
                  color="red"
                  icon
                  dark
                  x-small
                  content="Ne plus participer"
                  :loading="sendLoading"
                  @click="cancelEvent(item.id)"
                >
                  <v-icon>
                    far fa-calendar-minus
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </div>

          <div class="other-events">
            <h4>Suggestion d'évènements</h4>
            <v-data-table
              dense
              :headers="tableHeaders"
              :items="otherEvents"
              :items-per-page="5"
              class="elevation-1"
              no-data-text="Aucun évènement disponible"
              :loading="isLoading"
              loading-text="Chargement... Veuillez patienter."
            >
              <template v-slot:[`item.start`]="{ item }">
                {{ isSafari ? item.safaridate_start : item.start }}
              </template>
              <template v-slot:[`item.end`]="{ item }">
                {{ isSafari ? item.safaridate_end : item.end }}
              </template>
              <template v-slot:[`item.id`]="{ item }">
                <v-btn
                  v-tippy
                  depressed
                  class="fs-16-btn"
                  color="green"
                  icon
                  dark
                  small
                  content="Participer"
                  :loading="sendLoading"
                  @click="joinEvent(item.id)"
                >
                  <v-icon>
                    far fa-calendar-plus
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Agenda',
  components: {},
  data: () => ({
    loaderParticipants: false,
    showingList: false,
    isLoading: true,
    sendLoading: false,
    tableHeaders: [
      {
        text: 'Titre',
        sortable: false,
        align: 'start',
        value: 'name',
      },
      {
        text: 'Date de début',
        sortable: false,
        value: 'start',
        align: 'center',
      },
      {
        text: 'Date de fin',
        sortable: false,
        value: 'end',
        align: 'center',
      },
      {
        text: 'Actions',
        sortable: false,
        value: 'id',
        width: 100,
      },
    ],
    tableHeadersSA: [
      {
        text: 'Titre',
        sortable: false,
        align: 'start',
        value: 'name',
      },
      {
        text: 'Date de début',
        sortable: false,
        value: 'start',
        align: 'center',
      },
      {
        text: 'Date de fin',
        sortable: false,
        value: 'end',
        align: 'center',
      },
      {
        text: 'Nb Participants',
        sortable: false,
        value: 'participants',
        align: 'center',
      },
      {
        text: 'Actions',
        sortable: false,
        value: 'id',
        width: 100,
      },
    ],
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    type: 'month',
    types: [],
    mode: 'stack',
    modes: [],
    weekday: [1, 2, 3, 4, 5, 6, 0],
    weekdays: [],
    value: '',
    events: [],
    eventsTbl: [],
    myEvents: [],
    otherEvents: [],
    colors: ['amber'],
    names: ['Meeting'],
    toDay: null,
  }),
  computed: {
    ...mapGetters([
      'getEvents',
      'getEvent',
      'getLoggedUser',
      'isPanelDisplayed',
      'isTableNeedsReloading',
      'getShowDialogEvent',
      'getListParticipants',
    ]),
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    panelDisplayed() {
      return this.isPanelDisplayed
    },
    tableNeedsReloading() {
      return this.isTableNeedsReloading
    },
    loggedUser() {
      return this.getLoggedUser
    },
    gotListParticipants() {
      return this.getListParticipants
    },
    showDialogEvent: {
      get() {
        return this.getShowDialogEvent
      },
      set(newState) {
        this.setShowDialogEvent(newState)
      }
    },
    isSafari() {
      /* eslint-disable */
      var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
      return isSafari
      /* eslint-enable */
    }
  },
  watch: {
    panelDisplayed: function() {
      if (!this.panelDisplayed && this.isTableNeedsReloading) {
        this.isLoading = true
        this.loadEvents()
      }
    },
  },
  mounted() {
    this.toDay = new Date()
    this.loadEvents()

    // Disable Loading
    this.setLoading(false)
  },
  methods: {
    ...mapActions([
      'loadListEvents',
      'togglePanel',
      'addParticipant',
      'removeParticipant',
      'setPanelForm',
      'setPanelItemId',
      'clearPanelItemId',
      'deleteEvent',
      'setReloadingNeed',
      'setShowDialogEvent',
      'loadListParticipants',
      'getEventById',
      'setLoading',
    ]),
    openPanelEvent(idEvent) {
      if (idEvent === 0) {
        this.clearPanelItemId()
      } else {
        this.setPanelItemId(idEvent)
      }
      this.setPanelForm('events')
      this.togglePanel()
      // Reset showingList
      this.showingList = false
    },
    getEventColor(event) {
      return event.color
    },
    loadEvents() {
      this.loadListEvents()
        .then((response) => {
          this.events = this.getEvents
          this.getAllEvents()
          this.getMyEvents()
          this.getOtherEvents()
          this.setReloadingNeed(false)

          // Disable Loading
          this.sendLoading = false
        })
        .catch((e) => {
          this.$awn.alert('ERREUR lors du chargement des évènements')
          this.isLoading = false

          // Disable Loading
          this.sendLoading = false
        })
    },
    getAllEvents() {
      // list all events (except olders) for the Table list (for SuperAdmins)
      const listEvents = []
      this.events.forEach((element) => {
        if(new Date(element.start) >= new Date()) {
          listEvents.push({
            id: element.id,
            name: element.name,
            start: element.start,
            safaridate_start: element.safaridate_start,
            end: element.end,
            safaridate_end: element.safaridate_end,
            participants: element.participants
          })
        }
      })
      this.eventsTbl = listEvents
      this.isLoading = false
    },
    getMyEvents() {
      const user = this.loggedUser
      const listEvents = []
      const allEvents = this.events
      allEvents.forEach((element) => {
        if (
          element.participants.includes(user.id)
        ) {
          listEvents.push({
            id: element.id,
            name: element.name,
            start: this.$utils.formatDateWithHours(element.start),
            safaridate_start: element.safaridate_start,
            end: this.$utils.formatDateWithHours(element.end),
            safaridate_end: element.safaridate_end
          })
        }
      })
      this.isLoading = false
      this.myEvents = listEvents
    },
    getOtherEvents() {
      const userId = this.getLoggedUser.id
      const listEvents = []
      const allEvents = this.events
      allEvents.forEach((element) => {
        if (
          !element.participants.includes(userId)
        ) {
          listEvents.push({
            id: element.id,
            name: element.name,
            start: this.$utils.formatDateWithHours(element.start),
            safaridate_start: element.safaridate_start,
            end: this.$utils.formatDateWithHours(element.end),
            safaridate_end: element.safaridate_end
          })
        }
      })
      this.isLoading = false
      this.otherEvents = listEvents
    },
    cancelEvent(id) {
      // Trigger Loading
      this.sendLoading = true

      this.removeParticipant({
        idUser: this.getLoggedUser.id,
        idEvent: id,
      })
        .then((response) => {
          this.$awn.success("Vous avez quitté l'évènement")
          this.isLoading = true
          this.selectedOpen = false
          this.loadEvents()

          // Disable Loading
          this.sendLoading = false
        })
        .catch((e) => {
          this.$awn.alert(
            "ERREUR lors de l'annulation de participation à l'évènement"
          )
          // Disable Loading
          this.sendLoading = false
        })
    },
    joinEvent(id) {
      // Trigger Loading
      this.sendLoading = true

      this.addParticipant({
        idUser: this.getLoggedUser.id,
        nameUser: this.getLoggedUser.firstname,
        emailUser: this.getLoggedUser.email,
        idEvent: id,
      })
        .then((response) => {
          this.$awn.success("Vous avez rejoint l'évènement")
          this.isLoading = true
          this.selectedOpen = false
          this.loadEvents()

          // Disable Loading
          this.sendLoading = false
        })
        .catch((e) => {
          this.$awn.alert("ERREUR lors de la participation à l'évènement")

          // Disable Loading
          this.sendLoading = false
        })
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => {
          this.selectedOpen = true
        }, 10)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    isNotJoin(idEvent) {
      let isPresent = false
      this.myEvents.forEach((element) => {
        if (element.id === idEvent) {
          isPresent = true
        }
      })
      return isPresent
    },
    confirmDeleteEvent(eventId, eventName) {
      const onOk = () => {
        // Trigger Loading
        this.sendLoading = true

        this.deleteEvent(eventId)
          .then((response) => {
            this.isLoading = true
            this.$awn.success(
              "L'évènement a été suppprimé avec succès"
            )
          })
          .catch((e) => {
            this.$awn.alert("ERREUR lors de la suppression de l'évènement")

            // Disable Loading
            this.sendLoading = false
          })
          .finally((response) => {
            this.loadEvents()
          })
      }
      const onCancel = () => {
      }
      this.$awn.confirm(
        "Souhaitez-vous vraiment supprimer l'évènement <b>" +
          eventName +
          '</b> ?',
        onOk,
        onCancel,
        {
          labels: {
            confirm: "Suppression d'évènement",
          },
        }
      )
    },
    showListParticipants(idParticipants, idEvent) {
      this.getEventById(idEvent)
      this.loaderParticipants = true
      this.loadListParticipants(idParticipants)
        .then((response) => {
          // this.listParticipants = this.gotListParticipants
          // this.listParticipants = response.data
          this.showingList = true
          this.loaderParticipants = false
        })
        .catch((e) => {
          this.showingList = false
          this.loaderParticipants = false
          this.$awn.alert("ERREUR lors de la récupération des participants")
        })
    },
    nextMonth() {
      // @FIXME: error when call this method
      this.$refs.calendar.next()
    },
    prevMonth() {
      this.$refs.calendar.prev()
    },
  },
}
</script>

<style>
.userEvents,
.superadminEvents {
  height: 100%;
  padding: 0 2em;
}

.userEvents > * {
  height: 50%;
}
</style>
