import Vue from 'vue'

// State
const state = {
  post: {
    id: String,
  },
  currentEditPost: null,
  postsListDB: [],
  postsCountDB: 0,
  postsListSN: [],
  postsCountSN: 0,
  postLikesList: [],
  postsArgs: null,
}

// Getters
const getters = {
  getCurrentEditPost(state){
    return state.currentEditPost
  },
  getPostsListDB(state) {
    return state.postsListDB
  },
  getPostsListSN(state) {
    return state.postsListSN
  },
  getPostLikesList(state) {
    return state.postLikesList
  },
  getPostsArgs(state) {
    return state.postsArgs
  }
}

// Mutations
const mutations = {
  SET_POSTS_ARGS(state, args) {
    state.postsArgs = args
  },
  SET_CURRENT_EDIT_POST(state, editPost) {
    state.currentEditPost = editPost
  },
  SET_POSTS_DB(state, postsList) {
    state.postsListDB = state.postsListDB.concat(postsList)
  },
  CLEAR_POSTS_DB(state) {
    state.postsListDB = []
  },
  SET_POSTS_SN(state, postsList) {
    state.postsListSN = state.postsListSN.concat(postsList)
  },
  CLEAR_POSTS_SN(state) {
    state.postsListSN = []
  },
  ADD_POST(state, post) {
    // remove last post if current page is full then add new one to correct list
    // needed for avoiding duplicate with infinite loader auto fetching
    if (post.private) {
      if (state.postsListDB.length % 10 === 0) {
        state.postsListDB.pop()
      }
      state.postsListDB.unshift(post)
    } else {
      if (state.postsListSN.length % 10 === 0) {
        state.postsListSN.pop()
      }
      state.postsListSN.unshift(post)
    }
  },
  UPDATE_POST_BY_ID(state, post) {
    const findPostIndexDB = state.postsListDB.findIndex((obj) => obj.id === post.id)
    if (state.postsListDB[findPostIndexDB]) {
      state.postsListDB[findPostIndexDB] = post
    }
    const findPostIndexSN = state.postsListSN.findIndex((obj) => obj.id === post.id)
    if (state.postsListSN[findPostIndexSN]) {
      state.postsListSN[findPostIndexSN] = post
    }
  },
  DELETE_POST_BY_ID(state, idPost) {
    state.postsListDB = state.postsListDB.filter((n) => n.id !== idPost)
    state.postsListSN = state.postsListSN.filter((n) => n.id !== idPost)
  },
}

// Actions
const actions = {
  setPostsArgs(store, args) {
    this.commit('SET_POSTS_ARGS', args)
  },
  cleanEditPost() {
    this.commit('SET_CURRENT_EDIT_POST', null)
  },
  fetchEditPost(store, postId) {
    return Vue.prototype.$API
      .getToken()
      .get('/posts/' + postId)
      .then((response) => {
        this.commit('SET_CURRENT_EDIT_POST', response.data.data[0])
        return Promise.resolve(response)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  fetchLikesForAPost(store, postId) {
    return Vue.prototype.$API
      .getToken()
      .get('/posts/' + postId + '/likes')
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  fetchThenAddPost(store, postId) {
    return Vue.prototype.$API
      .getToken()
      .get('/posts/' + postId)
      .then((response) => {
        const post = response.data.data[0]
        // Add Post at the start of one list
        this.commit('ADD_POST', post)
        return Promise.resolve(post)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  fetchPostsListDB(store, args) {
    let requestURL = '/posts'
    // Optionnal arguments for pagination
    requestURL += Vue.prototype.$utils.toURLRequest(args)

    return Vue.prototype.$API
      .withoutToken()
      .get(requestURL)
      .then((response) => {
        // Set Posts
        var postsList = []
        response.data.data.forEach((post) => {
          postsList.push(post)
        })
        // Set Posts
        this.commit('SET_POSTS_DB', postsList)
        return Promise.resolve(postsList)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  fetchPostsListSN(store, args) {
    let requestURL = '/posts'
    // Optionnal arguments for pagination
    requestURL += Vue.prototype.$utils.toURLRequest(args)

    return Vue.prototype.$API
      .withoutToken()
      .get(requestURL)
      .then((response) => {
        // Set Posts
        var postsList = []
        response.data.data.forEach((post) => {
          postsList.push(post)
        })
        // Set Posts
        this.commit('SET_POSTS_SN', postsList)
        return Promise.resolve(postsList)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  clearPostsListDB() {
    this.commit('CLEAR_POSTS_DB')
  },
  clearPostsListSN() {
    this.commit('CLEAR_POSTS_SN')
  },
  createPost(store, newPost) {
    return Vue.prototype.$API
      .getToken()
      .post('/posts', newPost)
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((e) => {
        return Vue.prototype.$API.displayErrors(e)
      })
  },
  addImagesForPost(store, postDatas) {
    return Vue.prototype.$API
      .getToken()
      .post('/posts/' + postDatas.idPost + '/images/' + postDatas.idFile)
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((e) => {
        return Vue.prototype.$API.displayErrors(e)
      })
  },
  updatePost(store, editPost) {
    return Vue.prototype.$API
      .getToken()
      .patch('/posts/' + editPost.id, editPost)
      .then((response) => {
        return Promise.resolve(response.data.content)
      })
      .catch((e) => {
        return Vue.prototype.$API.displayErrors(e)
      })
  },
  reloadUpdatedPost(store, postId) {
    return Vue.prototype.$API
      .getToken()
      .get('/posts/' + postId)
      .then((response) => {
        this.commit('UPDATE_POST_BY_ID', response.data.data[0])
        return Promise.resolve(response)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  deletePostById(store, idPost) {
    return Vue.prototype.$API
      .getToken()
      .delete('/posts/' + idPost)
      .then((response) => {
        this.commit('DELETE_POST_BY_ID', idPost)
        return Promise.resolve(response.data.content)
      })
      .catch((e) => {
        return Vue.prototype.$API.displayErrors(e)
      })
  },
  addLikeToPostById(store, like) {
    return Vue.prototype.$API
      .getToken()
      .post('/posts/' + like.id_post + '/likes/' + like.id_user)
      .then((response) => {
        return Promise.resolve(response.data.content)
      })
      .catch((e) => {
        return Vue.prototype.$API.displayErrors(e)
      })
  },
  removeLikeFromPostById(store, like) {
    return Vue.prototype.$API
      .getToken()
      .delete('/posts/' + like.id_post + '/likes/' + like.id_user)
      .then((response) => {
        return Promise.resolve(response.data.content)
      })
      .catch((e) => {
        return Vue.prototype.$API.displayErrors(e)
      })
  },
  fetchCommentsByPostId(store, id) {
    return Vue.prototype.$API
      .withoutToken()
      .get('/comments/post/' + id)
      .then((response) => {
        // Set Posts
        var commentsList = []
        response.data.data.forEach((comment) => {
          commentsList.push(comment)
        })
        // Set Posts
        return Promise.resolve(commentsList)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  addComment(store, newComment) {
    return Vue.prototype.$API
      .getToken()
      .post('/comments', newComment)
      .then((response) => {
        return Promise.resolve(response.data.content)
      })
      .catch((e) => {
        return Vue.prototype.$API.displayErrors(e)
      })
  },
  deleteCommentById(store, idComment) {
    return Vue.prototype.$API
      .getToken()
      .delete('/comments/' + idComment)
      .then((response) => {
        return Promise.resolve(response.data.content)
      })
      .catch((e) => {
        return Vue.prototype.$API.displayErrors(e)
      })
  },
}

// Export
export default {
  state,
  getters,
  mutations,
  actions,
}
