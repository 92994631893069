<template>
  <div>
    <v-card id="form-files">
      <v-card-title v-if="!fileid" dark class="panel-title">
        Ajouter un fichier
      </v-card-title>
      <v-card-title v-else dark class="panel-title">
        Modifier un fichier
      </v-card-title>

      <v-spacer></v-spacer>

      <v-skeleton-loader
        v-if="loading"
        id="loader-form"
        :loading="loading"
        type="image"
      ></v-skeleton-loader>
      <v-card-text v-show="!loading">
        <v-form ref="formFile" v-model="valid">
          <v-text-field
            v-model="title"
            :rules="titleRules"
            label="Titre"
            required
          ></v-text-field>

          <v-text-field
            v-model="description"
            :rules="descriptionRules"
            :counter="255"
            label="Description (255c. max)"
          ></v-text-field>

          <v-select
            v-model="folder"
            :items="folderList"
            :rules="folderRules"
            label="Dossier"
            required
          ></v-select>

          <VueDropzone
            v-if="!fileid"
            id="dropzoneFile"
            ref="dropzoneFile"
            :options="dropzoneOptions"
            @vdropzone-file-added="checkAddedFile"
          />
          <v-btn
            v-if="!fileid"
            color="red"
            dark
            small
            class="dropzone-trash-btn"
            @click="removeFiles"
          >
            <v-icon small>fas fa-trash-alt</v-icon>
          </v-btn>
        </v-form>
      </v-card-text>

      <v-spacer></v-spacer>

      <v-card-actions class="justify-end">
        <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4"
          :loading="sendLoading"
          @click="validate"
        >
          Enregistrer
        </v-btn>

        <v-btn
          color="secondary"
          class="mr-4"
          :loading="sendLoading"
          @click="closePanel"
        >
          Annuler
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import VueDropzone from 'vue2-dropzone'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CreateFiles',
  components: {
    VueDropzone,
  },
  props: {
    fileid: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: true,
      sendLoading: false,
      dropzoneOptions: {
        maxFiles: 1,
        autoProcessQueue: false,
        url: function() {},
        thumbnailWidth: 150,
        parallelUploads: 1,
        dictDefaultMessage:
          "<i class='fa fa-cloud-upload'></i>Cliquez ou déposez un fichier ici",
      },
      valid: true,
      title: '',
      titleRules: [(v) => !!v || 'Titre requis'],
      folderList: this.$enums.folderTypes,
      folder: '',
      folderRules: [(v) => !!v || 'Dossier requis'],
      description: '',
      descriptionRules: [
        (v) => v.length <= 255 || 'Description de 256 caractères maximum',
      ],
    }
  },
  computed: {
    ...mapGetters(['getFile']),
  },
  mounted() {
    if (this.fileid) {
      this.getEditedFile()
    } else {
      this.loading = false
    }
  },
  methods: {
    ...mapActions([
      'saveFile',
      'hidePanel',
      'toBase64',
      'getFilesForTreeView',
      'setReloadingNeed',
      'getFileById',
      'updateFile',
      'togglePanel',
    ]),
    removeFiles() {
      this.$refs.dropzoneFile.removeAllFiles()
    },
    getEditedFile() {
      this.getFileById(this.fileid)
        .then((response) => {
          this.title = response.data[0].title
          this.folder = response.data[0].folder
          if (response.data[0].description) {
            this.description = response.data[0].description
          } else {
            this.description = ''
          }
          this.loading = false
        })
        .catch((e) => {
          this.$awn.alert("ERREUR Le fichier n'a pas été trouvé")
          this.togglePanel()
        })
    },
    validate() {
      // Trigger loading
      this.sendLoading = true

      if (this.fileid) {
        const fileToUpdate = {
          id: this.fileid,
          title: this.title,
          description: this.description,
          folder: this.folder,
        }
        this.updateFile(fileToUpdate)
          .then((response) => {
            this.$awn.success('Fichier modifié avec succès')
            this.getFilesForTreeView()
            this.closePanel()
          })
          .catch((e) => {
            this.$awn.alert('ERREUR lors de la modification du fichier')
          })
      } else {
        const acceptedFiles = this.$refs.dropzoneFile.getAcceptedFiles()
        if (acceptedFiles.length >= 1) {
          this.$utils
            .toBase64(acceptedFiles[0])
            .then((response) => {
              const fileToSave = {
                name: acceptedFiles[0].name,
                title: this.title,
                description: this.description,
                folder: this.folder,
                data: response,
              }

              this.saveFile(fileToSave)
                .then((response) => {
                  this.$awn.success('Fichier enregistré avec succès')
                  this.getFilesForTreeView()
                  this.closePanel()
                })
                .catch((e) => {
                  this.$awn.alert("ERREUR lors de l'enregistrement du fichier")
                  // Disable loading
                  this.sendLoading = false
                })
            })
            .catch((e) => {
              this.$awn.alert('ERREUR lors de la lecture du fichier')
              // Disable loading
              this.sendLoading = false
            })
        } else {
          this.$awn.alert('ERREUR Aucun fichier trouvé')
          // Disable loading
          this.sendLoading = false
        }
      }
    },
    closePanel() {
      this.togglePanel()
      this.getFilesForTreeView()
      // Disable loading
      this.sendLoading = false
    },
    checkAddedFile(File) {
      // event addedFile watcher on dropzone to check file added
      if (File.size >= 5000000) {
        this.$refs.dropzoneFile.removeFile(File)
        this.$awn.warning('Le fichier doit faire moins de 5Mo')
      }
      if (this.$refs.dropzoneFile.getAcceptedFiles().length === 1) {
        this.$refs.dropzoneFile.removeFile(File)
        this.$awn.warning('Une seule image autorisée')
      }
    },
  },
}
</script>
