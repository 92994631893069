<template>
  <v-container id="b-o-places" fluid class="p-0">
    <v-row>
      <v-col cols="12" lg="12">
        <h3>Gestion des villes</h3>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="gotPlacesList"
      class="elevation-1"
      light
      :page.sync="page"
      :items-per-page="itemsPerPage"
      :loading="isLoading"
      loading-text="Chargement... Veuillez patienter."
      hide-default-footer
      dense
    >
      <template v-slot:[`item.show_in_vitrine`]="{ item }">
        <v-row justify-lg="center" no-gutters>
          <v-col cols="12" lg="auto">
            <v-switch
              v-model="currentDisplayList[item.id]"
              :loading="switchLoadingList[item.id]"
              dense
              @change="showInVitrineSwitch(item.id)"
            />
          </v-col>
          <v-col cols="12" lg="auto">
            <v-img :src="pinDisplay(item.id)" class="mt-3"> </v-img>
          </v-col>
        </v-row>
      </template>
    </v-data-table>

    <v-pagination
      v-model="page"
      :length="pageCount"
      class="mt-2"
    ></v-pagination>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'PlacesManagement',
  metaInfo() {
    return null
  },
  components: {},
  data() {
    return {
      headers: [
        {
          text: 'ID',
          value: 'id',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Nom',
          value: 'title',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Longitude',
          value: 'longitude',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Latitude',
          value: 'latitude',
          sortable: false,
          align: 'center',
        },
        {
          text: 'E-mail',
          value: 'email',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Agence Solerys / Explorys ',
          value: 'show_in_vitrine',
          sortable: false,
          align: 'center',
        },
      ],
      currentDisplayList: [],
      switchLoadingList: [],
      isLoading: true,
      itemsPerPage: 10,
      page: 1,
    }
  },
  computed: {
    ...mapGetters(['getLoggedUser', 'getPlacesList', 'isTableNeedsReloading']),
    loggedUser() {
      return this.getLoggedUser
    },
    gotPlacesList() {
      return this.getPlacesList
    },
    pageCount() {
      return Math.ceil(this.getPlacesList.length / this.itemsPerPage)
    },
    requestArgs() {
      const args = {
        limit: 0,
        offset: 0,
      }
      args.limit = this.itemsPerPage
      args.offset = (this.page - 1) * this.itemsPerPage
      return args
    },
    tableNeedsReloading() {
      return this.isTableNeedsReloading
    },
  },
  watch: {
    page: function() {
      this.initPlaces()
    },
  },
  mounted() {
    this.initPlaces()

    // Disable Loading
    this.setLoading(false)
  },
  methods: {
    ...mapActions([
      'fetchPlacesList',
      'setReloadingNeed',
      'updatePlaceDisplay',
      'setLoading'
    ]),
    initPlaces() {
      this.isLoading = true
      this.fetchPlacesList(this.requestArgs).then((response) => {
        response.forEach((place) => {
          this.$set(this.currentDisplayList, place.id, place.show_in_vitrine)
          this.$set(this.switchLoadingList, place.id, false)
        })
        this.isLoading = false
      })
    },
    showInVitrineSwitch(placeId) {
      this.$set(this.switchLoadingList, placeId, true)
      const displayState = {
        id: placeId,
        show_in_vitrine: this.currentDisplayList[placeId],
      }
      this.updatePlaceDisplay(displayState).then((response) => {
        this.$set(this.switchLoadingList, placeId, false)
      })
    },
    pinDisplay(id) {
      if (this.currentDisplayList[id] === !this.switchLoadingList[id]) {
        return require('@images/map_pin.png')
      } else {
        return require('@images/map_pin2.png')
      }
    },
  },
}
</script>
