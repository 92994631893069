<template>
  <v-container id="b-o-partners" fluid class="p-0">
    <v-row>
      <v-col cols="12" lg="4">
        <h3>Gestion des partenaires</h3>
      </v-col>
      <v-col cols="12" lg="4">
        <v-text-field
          v-model="searchString"
          label="Recherche"
          prepend-icon="mdi-magnify"
          clear-icon="mdi-close-circle-outline"
          clearable
          dense
          counter="3"
          @click:clear="clearSearch"
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="4" class="text-right">
        <v-btn
          v-if="loggedUser.role === 'SUPERADMIN'"
          dark
          color="primary"
          @click="openPanelCreatePartner"
        >
          <v-icon class="mr-2">fa fa-plus</v-icon>
          Ajouter un partenaire
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="gotPartnersList"
      class="elevation-1"
      light
      :page.sync="page"
      :items-per-page="itemsPerPage"
      :loading="isLoading"
      loading-text="Chargement... Veuillez patienter."
      hide-default-footer
    >
      <template v-slot:[`item.logo`]="{ item }">
        <v-avatar size="36">
          <v-img v-if="item.id_logo" :src="item.logo_data" />
          <v-img
            v-if="!item.id_logo"
            :src="require('@images/company_defaut.jpg')"
          />
        </v-avatar>
      </template>

      <template v-slot:[`item.creation_date`]="{ item }">
        {{ $utils.formatDate(item.creation_date) }}
      </template>

      <template v-slot:[`item.modification_date`]="{ item }">
        {{ $utils.formatDate(item.modification_date) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          v-tippy
          class="fs-16-btn mr-2"
          color="primary"
          icon
          small
          dark
          content="Modifier le partenaire"
          @click="openPanelEditPartner(item.id)"
        >
          <v-icon>fas fa-edit</v-icon>
        </v-btn>
        <v-btn
          v-tippy
          class="fs-16-btn"
          color="red"
          icon
          small
          dark
          content="Supprimer le partenaire"
          @click.native="confirmDeletePartner(item.id, item.name)"
        >
          <v-icon>fas fa-trash-alt</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-pagination
      v-model="page"
      :length="pageCount"
      class="mt-2"
    ></v-pagination>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'PartnersManagement',
  metaInfo() {
    return null
  },
  components: {},
  data() {
    return {
      headers: [
        {
          text: 'ID',
          value: 'id',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Date de création',
          value: 'creation_date',
          sortable: false,
          align: 'center',
          width: '200px',
        },
        {
          text: 'Date de modification',
          value: 'modification_date',
          sortable: false,
          align: 'center',
          width: '200px',
        },
        {
          text: 'Logo',
          value: 'logo',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Nom',
          value: 'name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Lien site',
          value: 'link_website',
          sortable: false,
          align: 'center',
        },

        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'center',
          width: '120px',
        },
      ],
      isLoading: true,
      searchString: '',
      itemsPerPage: 10,
      page: 1,
      rules: {
        info: (value) =>
          value.length === 0 || 'min 3 caractères - supprimer pour réinitialiser',
      },
    }
  },
  computed: {
    ...mapGetters([
      'getLoggedUser',
      'getPartnersList',
      'getPartnersCount',
      'isPanelDisplayed',
      'isTableNeedsReloading',
    ]),
    loggedUser() {
      return this.getLoggedUser
    },
    gotPartnersList() {
      return this.getPartnersList
    },
    pageCount() {
      return Math.ceil(this.getPartnersCount / this.itemsPerPage)
    },
    requestArgs() {
      const args = {
        search: '',
        limit: 0,
        offset: 0,
      }
      args.search = this.searchString
      args.limit = this.itemsPerPage
      args.offset = (this.page - 1) * this.itemsPerPage
      return args
    },
    panelDisplayed() {
      return this.isPanelDisplayed
    },
    tableNeedsReloading() {
      return this.isTableNeedsReloading
    },
  },
  watch: {
    searchString: function(value) {
      if (value && (value.length >= 3 || value.length === 0)) {
        this.isLoading = true
        this.page = 1
        this.fetchPartnersList(this.requestArgs).then((response) => {
          this.isLoading = false
        })
      }
    },
    page: function() {
      this.isLoading = true
      this.fetchPartnersList(this.requestArgs).then((response) => {
        this.isLoading = false
      })
    },
    panelDisplayed: function() {
      if (!this.panelDisplayed && this.isTableNeedsReloading) {
        this.isLoading = true
        this.fetchPartnersList(this.requestArgs).then((response) => {
          this.isLoading = false
          this.setReloadingNeed(false)
        })
      }
    },
  },
  mounted() {
    this.fetchPartnersList(this.requestArgs).then((response) => {
      this.isLoading = false
    })

    // Disable Loading
    this.setLoading(false)
  },
  methods: {
    ...mapActions([
      'togglePanel',
      'fetchPartnersList',
      'deletePartnerById',
      'setReloadingNeed',
      'setPanelForm',
      'setPanelItemId',
      'clearPanelItemId',
      'setLoading'
    ]),
    clearSearch() {
      this.searchString = ''
      this.isLoading = true
      this.fetchPartnersList(this.requestArgs).then((response) => {
        this.isLoading = false
      })
    },
    openPanelCreatePartner() {
      this.clearPanelItemId()
      this.setPanelForm('partners')
      this.togglePanel()
    },
    openPanelEditPartner(partnerId) {
      this.setPanelItemId(partnerId)
      this.setPanelForm('partners')
      this.togglePanel()
    },
    confirmDeletePartner(partnerId, partnerName) {
      // Set trigering functions
      const onOk = () => {
        this.deletePartner(partnerId, partnerName)
      }
      const onCancel = () => {
      }

      this.$awn.confirm(
        'Souhaitez-vous supprimer définitivement le partenaire <b>' +
          partnerName +
          '</b> ?',
        onOk,
        onCancel,
        {
          labels: {
            confirm: 'Suppression de partenaire',
          },
        }
      )
    },
    deletePartner(id, name) {
      this.isLoading = true
      this.deletePartnerById(id)
        .then((response) => {
          this.$awn.success('Le partenaire a bien été supprimé')
          this.fetchPartnersList(this.requestArgs)
        })
        .catch((e) => {
          this.$awn.alert(
            'Une erreur est survenue, veuillez contacter le service support'
          )
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
  },
}
</script>
