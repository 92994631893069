<template>
  <v-row justify="center">
    <v-col id="partners" cols="12" lg="10">
      <v-row class="row-head">
        <v-col id="partners-txt" cols="12" lg="12" class="pa-0">
          <h2>Ils nous soutiennent</h2>
          <p class="pt-0">
            Nos partenaires privilégiés soutiennent au quotidien les initiatives
            des entrepreneurs.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col id="partners-cards" cols="12" lg="12">
          <div v-if="!apiStatus" class="error-api-is-broken">
            <p>Un problème est survenu, veuillez re-essayer plus tard</p>
          </div>
          <v-row justify="space-around">
            <v-col
              v-for="partner in gotPartnersList"
              :key="partner.id"
              cols="8"
              sm="4"
              md="3"
              lg="2"
              class="card d-flex align-center"
              @click="goToWebsite(partner.link_website)"
            >
              <img
                v-if="partner.id_logo === 0 || !partner.id_logo"
                :src="require('@images/company_defaut.jpg')"
              />
              <img v-else :src="partner.logo_data" />
              <p class="name">
                {{ partner.name }}
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'OurPartners',
  props: {
    fpObj: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      apiStatus: true,
    }
  },
  computed: {
    ...mapGetters(['getPartnersList']),
    gotPartnersList() {
      return this.getPartnersList
    },
  },
  mounted() {
    this.fetchPartnersList({}).then((response) => {
      // Get props fp and rebuild after fetch
      if (this.fpObj) {
        this.fpObj.api.reBuild()
      }
    })
    this.verifApiStatus()
  },
  methods: {
    ...mapActions(['fetchPartnersList', 'checkApiStatus']),
    verifApiStatus() {
      this.checkApiStatus()
        .then((response) => {
          this.apiStatus = true
        })
        .catch((e) => {
          this.apiStatus = false
        })
    },
    goToWebsite(urlWebsite) {
      if (urlWebsite) {
        window.open(urlWebsite, '_blank')
      }
    },
  },
}
</script>
