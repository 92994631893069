import Vue from 'vue'

// State
const state = {
  logged: false,
  loggedUser: null,
}

// Getters
const getters = {
  getLogged(state) {
    return state.logged
  },
  getLoggedUser(state) {
    return state.loggedUser
  },
  getLoggedUserToken(state) {
    if (state.loggedUser) {
      return state.loggedUser.token
    }
  },
}

// Mutations
const mutations = {
  SET_LOGGED_USER(state, newUser) {
    state.loggedUser = newUser
  },
  SET_LOGGED(state, newState) {
    state.logged = newState
  },
}

// Actions
const actions = {
  loginUser(store, formUser) {
    return Vue.prototype.$API
      .withoutToken()
      .post('/auth/login', {
        login: formUser.login,
        password: formUser.password,
      })
      .then((response) => {
        // Set LoggedUser
        const newLoggedUser = {
          id: response.data.id,
          token: response.data.access_token,
          firstname: response.data.firstname,
          lastname: response.data.lastname,
          position: response.data.position,
          email: response.data.email,
          role: response.data.role,
          link_drive: response.data.link_drive,
          status: response.data.status,
          link_linkedin: response.data.link_linkedin,
          id_consultant: response.data.id_consultant,
          consultant_name: response.data.consultant_name,
          id_photo: response.data.id_photo,
          photo_data: response.data.photo_data,
          photo_type: response.data.photo_type,
          id_organisation: response.data.id_organisation,
          organisation_name: response.data.organisation_name,
        }
        // Set LoggedUser
        this.commit('SET_LOGGED_USER', newLoggedUser)
        this.commit('SET_LOGGED', true)
        return Promise.resolve(newLoggedUser)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  reFetchLoggedUser(store) {
    return Vue.prototype.$API
      .getToken()
      .get('/auth/token')
      .then((response) => {
        // Set LoggedUser
        var loggedUser = {
          id: response.data.id,
          token: response.data.access_token,
          firstname: response.data.firstname,
          lastname: response.data.lastname,
          position: response.data.position,
          email: response.data.email,
          role: response.data.role,
          link_drive: response.data.link_drive,
          status: response.data.status,
          link_linkedin: response.data.link_linkedin,
          id_consultant: response.data.id_consultant,
          consultant_name: response.data.consultant_name,
          id_photo: response.data.id_photo,
          photo_data: response.data.photo_data,
          photo_type: response.data.photo_type,
          id_organisation: response.data.id_organisation,
          organisation_name: response.data.organisation_name,
        }
        // Set LoggedUser
        this.commit('SET_LOGGED_USER', loggedUser)
        this.commit('SET_LOGGED', true)
        return Promise.resolve(loggedUser)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  checkLoggedToken(store) {
    return Vue.prototype.$API
      .getToken()
      .post('/auth/token')
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  checkLoggedTempToken(store, loginUser) {
    return Vue.prototype.$API
      .getToken()
      .post('/auth/temptoken', {
        'login': loginUser
      })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  logOutUser(store) {
    this.commit('SET_LOGGED_USER', null)
    this.commit('SET_LOGGED', false)
  },
  requestResetPwd(store, loginUser) {
    return Vue.prototype.$API
      .withoutToken()
      .post('/auth/reset', {
        login: loginUser,
      })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  launchResetPassword(store, passwordUser) {
    return Vue.prototype.$API
      .getToken()
      .patch('/auth/update', {
        password: passwordUser,
      })
      .then((response) => {
        this.commit('SET_LOGGED_USER', null)
        this.commit('SET_LOGGED', false)
        return Promise.resolve(response)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  updateLoggedUser(store, editUser) {
    return Vue.prototype.$API
      .getToken()
      .patch('/users/' + editUser.id, editUser)
      .then((response) => {
        return Promise.resolve(response.data.content)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  setLoggedUser(store, loggedUser) {
    this.commit('SET_LOGGED_USER', loggedUser)
  },
}

// Export
export default {
  state,
  getters,
  mutations,
  actions,
}
