<template>
  <div id="post-creation">
    <v-dialog
      v-model="successPost"
      max-width="500"
      overlay-color="#001a20"
      overlay-opacity="0.8"
    >
      <v-card id="contact-modal-card">
        <v-card-title class="headline success-text">
          <i class="fas fa-check-circle mr-2"></i> <span>Votre post à été publié !</span>
        </v-card-title>
        <v-card-text>
          <p v-if="modalPrivate" class="text-center">
            Vous pouvez retrouver votre post sur les petites annonces.
          </p>
          <p v-if="!modalPrivate" class="text-center">
            Vous pouvez retrouver votre post sur votre vitrine.
          </p>
        </v-card-text>
        <v-card-actions class="d-flex justify-end align-center">
          <v-btn
            color="blue darken-4 bold"
            text
            @click="redirectAndClose"
          >
            Me rendre sur la page
          </v-btn>
          <v-btn
            color="green lighten-1 bold"
            text
            @click="successPost = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-expansion-panels v-model="openedPanel">
      <v-expansion-panel>
        <v-expansion-panel-header v-slot="{ open }">
          <v-fade-transition>
            <v-row>
              <v-col v-if="open">
                <v-row>
                  <v-col v-if="!isMobile" cols="2" lg="1" sm="1">
                    <v-avatar>
                      <v-img
                        v-if="!loggedUser.id_photo"
                        :src="require('@images/avatar_defaut.png')"
                        max-height="40"
                        max-width="40"
                      />
                      <v-img
                        v-else
                        :src="loggedUser.photo_data"
                        max-height="40"
                        max-width="40"
                      />
                    </v-avatar>
                  </v-col>
                  <v-col cols="9" lg="9" sm="9" class="pt-2">
                    <b>{{
                      loggedUser.firstname + ' ' + loggedUser.lastname
                    }}</b>
                    <i class="post-position">
                      {{
                        (loggedUser.position ? loggedUser.position : '') +
                          ' chez ' +
                          loggedUser.organisation_name
                      }}
                    </i>
                  </v-col>
                </v-row>
              </v-col>
              <v-col v-else>
                <v-row>
                  <v-col
                    v-if="!isXSOnly"
                    cols="1"
                    lg="1"
                    class="postcrea-col-avatar"
                  >
                    <v-avatar>
                      <v-img
                        v-if="!loggedUser.id_photo"
                        id="photo"
                        :src="require('@images/avatar_defaut.png')"
                        contain
                        width="40"
                        height="40"
                      />
                      <v-img
                        v-else
                        :src="loggedUser.photo_data"
                        max-height="40"
                        max-width="40"
                      />
                    </v-avatar>
                  </v-col>
                  <v-col cols="10" lg="10" class="postcrea-col-btn">
                    <v-btn
                      rounded
                      outlined
                      block
                      class="postcrea-btn"
                      :class="isDashboard ? 'dashboard' : ''"
                    >
                      <v-icon>
                        mdi-square-edit-outline
                      </v-icon>
                      Que souhaitez vous partager ?
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-fade-transition>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- Content -->
          <v-row justify="space-around">
            <v-col cols="12" class="text-left">
              <v-row>
                <v-col cols="12" class="text-left">
                  <!-- HERE -->
                  <ckeditor v-model="currentPost.content" :editor="editor" :config="editorConfig"></ckeditor>
                  <!-- Limit -->
                  <div :class="isLengthValid ? 'hint' : 'hint err'">
                    <span v-if="!isLengthValid" class="float-left"
                      >Votre texte est trop long</span
                    >
                    {{ lengthContent + ' / ' + lengthMax }}
                  </div>
                </v-col>
              </v-row>

              <!-- Dropzone -->
              <v-row>
                <v-col>
                  <v-btn
                    v-if="!showDropzone"
                    color="primary"
                    class="my-1"
                    small
                    outlined
                    @click="showDropzone = true"
                  >
                    Ajouter des images
                  </v-btn>
                  <v-btn
                    v-if="showDropzone"
                    color="primary"
                    class="mt-1"
                    small
                    outlined
                    @click="showDropzone = false"
                  >
                    Masquer les images
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="showDropzone">
                <v-col cols="12">
                  <VueDropzone
                    id="dropzoneFile"
                    ref="dropzoneFile"
                    :options="dropzoneOptions"
                    @vdropzone-file-added="checkAddedFile"
                  />
                  <v-btn
                    v-if="openedPanel === 0"
                    color="error"
                    class="dropzone-btn dropzone-trash-btn"
                    dark
                    small
                    @click="removeFiles"
                  >
                    <v-icon small>fas fa-trash-alt</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <!-- Btn + label post -->
              <v-row justify="space-between">
                <!-- <v-col cols="auto" /> -->
                <v-col v-if="!isXSOnly" class="col-switch">
                  <v-switch
                    v-model="currentPost.private"
                    :label="currentPost.private ? 'Ce post sera visible dans les petites annonces' : 'Ce post sera visible sur ma vitrine d\'entreprise'"
                  ></v-switch>
                </v-col>
                <v-col class="col-btn">
                  <v-btn
                    :disabled="!valid"
                    color="success"
                    small
                    :loading="sendLoading"
                    @click="sendForm"
                  >
                    Enregistrer
                  </v-btn>
                  <v-btn color="secondary" small @click="endEdition">
                    Annuler
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import VueDropzone from 'vue2-dropzone'
import { mapGetters, mapActions } from 'vuex'

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  name: 'PostCreation',
  components: {
    VueDropzone,
  },
  data() {
    return {
      sendLoading: false,
      showDropzone: false,
      currentPost: {
        id: '',
        content: '',
        private: true,
        likes: '',
        id_user: '',
        files_list: [],
      },
      lengthMax: 1000,
      dropzoneOptions: {
        maxFiles: 4,
        maxFilesize: 4, // 5M
        autoProcessQueue: false,
        url: function() {},
        thumbnailWidth: 150,
        parallelUploads: 1,
        dictDefaultMessage: "Cliquez ou déposez ici jusqu'à 4 images",
      },
      openedPanel: null,
      valid: true,
      // ModalConfirm
      successPost: false,
      modalPrivate: null,
      // Ckeditor config
      editor: ClassicEditor,
      editorConfig: {
        // The configuration of the editor.
        language: 'fr',
        toolbar: [ 'undo', 'redo', '|', 'bold', 'italic', '|', 'link' ],
        placeholder: 'Saisissez ici le contenu de votre publication',
        link: {
          defaultProtocol: 'https://',
          addTargetToExternalLinks: true,
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'getLoggedUser',
      'getCurrentEditPost',
    ]),
    loggedUser() {
      return this.getLoggedUser
    },
    currentEditPost() {
      return this.getCurrentEditPost
    },
    isMaPage() {
      return this.$route.path.includes('votre-page')
    },
    lengthContent() {
      return this.currentPost.content.length
    },
    isLengthValid() {
      return this.lengthContent <= this.lengthMax
    },
    isDashboard() {
      return (
        this.$route.path === '/extranet' || this.$route.path === '/extranet/'
      )
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    isXSOnly() {
      return this.$vuetify.breakpoint.xsOnly
    },
  },
  mounted() {
    // Set post to public in MaPage
    this.currentPost.private = !this.isMaPage
  },
  methods: {
    ...mapActions([
      'setReloadingNeed',
      'createPost',
      'saveFile',
      'deleteFile',
      'fetchThenAddPost',
      'addImagesForPost',
    ]),
    removeFiles() {
      if (this.showDropzone) {
        this.$refs.dropzoneFile.removeAllFiles()
      }
    },
    checkAddedFile(File) {
      // event addedFile watcher on dropzone to check file added
      if (File.size >= 5000000) {
        this.$refs.dropzoneFile.removeFile(File)
        this.$awn.warning('Le fichier doit faire moins de 5Mo')
      }
      if (
        File.type !== 'image/png' &&
        File.type !== 'image/jpg' &&
        File.type !== 'image/jpeg'
      ) {
        this.$refs.dropzoneFile.removeFile(File)
        this.$awn.warning('Le fichier doit être une image (format PNG ou JPEG)')
      }
      const totalFiles = this.$refs.dropzoneFile.getAcceptedFiles().length
      if (totalFiles === 4) {
        this.$refs.dropzoneFile.removeFile(File)
        this.$awn.warning('Pas plus de 4 images par post !')
      }
    },
    sendForm() {
      // Check if empty content
      if (!this.currentPost.content) {
        this.$awn.warning(
          'Vous devrez remplir correctement le contenu de votre post'
        )
        return
      }

      // Check content limit
      if (!this.isLengthValid) {
        this.$awn.warning(
          'Le contenu de votre post est trop long'
        )
        return
      }
      
      this.currentPost.id_user = this.loggedUser.id

      this.sendLoading = true
      this.createPost(this.currentPost)
        .then((response) => {
          if (this.showDropzone) {
            // files to add
            const acceptedFiles = this.$refs.dropzoneFile.getAcceptedFiles()
            this.collectFilesPromises(acceptedFiles, response.data.id)
              .then(() => {
                // APRES TT LES PROMISES DU TABLEAU
                // Show success modal
                this.successPost = true
                this.modalPrivate = this.currentPost.private
                this.endEdition()
                this.fetchThenAddPost(response.data.id).then(() => {
                  this.sendLoading = false
                })
              })
          } else {
            // no files to add
            // Show success modal
            this.successPost = true
            this.modalPrivate = this.currentPost.private
            this.endEdition()
            this.fetchThenAddPost(response.data.id).then(() => {
              this.sendLoading = false
            })
          }
        })
        .catch((e) => {
          this.$awn.alert('ERREUR lors de la demande de création du post')
          this.sendLoading = false
        })
    },
    collectFilesPromises(acceptedFiles, postId=null) {
      var promisesList = []
      acceptedFiles.forEach((file) => {
        promisesList.push(this.sendFile(file, postId))
      })
      return Promise.all(promisesList)
    },
    async sendFile(file, postId=null) {
      // Convertir le file
      const fileB64 = await this.$utils.toBase64(file)
      // Get type
      var fileType = file.name.split('.')
      fileType = fileType[fileType.length - 1]
      // Créer l'objet file to send to API
      const buildedFileToSend = {
        name: file.name,
        title: 'Image Post',
        description: 'Image Post sets automatically from post form',
        folder: 'INTERNAL',
        type: fileType,
        data: fileB64,
      }

      return new Promise((resolve, reject) => {
        this.$API
          .getToken()
          .post('/files/create', buildedFileToSend)
          .then((response) => {
            const postImage = {
              idPost: postId,
              idFile: response.data.id
            }
            this.addImagesForPost(postImage)
              .then(() => {
                // this.$awn.success('Fichier créé avec succès')
                resolve(response)
              })
              .catch((e) => {
                reject(e)
              })
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    // FIXME: To remove ?
    formatLink() {
      // remove http(s)://
      const regexHTTP = /(https?:\/\/)?/gm
      this.currentPost.content = this.currentPost.content.replace(regexHTTP, '')
      // change URLs into links
      const regexURL = /[a-zA-Z0-9-@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gim
      this.currentPost.content = this.currentPost.content.replace(
        regexURL,
        '<a href="https://$&" target="_blank" style="text-decoration: underline;">$&</a>'
      )
    },
    endEdition() {
      this.openedPanel = null
      this.removeFiles()
      this.resetCurrentPost()
      // Set post to public in MaPage
      this.currentPost.private = !this.isMaPage

      this.setReloadingNeed(true)
    },
    resetCurrentPost() {
      this.currentPost = {
        id: '',
        content: '',
        private: true,
        likes: '',
        id_user: '',
        files_list: [],
      }
    },
    redirectAndClose() {
      this.successPost = false
      if(this.modalPrivate) {
        this.$router.push('/extranet/')
      } else {
        this.$router.push('/extranet/votre-page')
      }
    }
  },
}
</script>
