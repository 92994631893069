import router from '@router'
import store from '@store/store'
import Vue from 'vue'
import VueMeta from 'vue-meta'
import App from './App.vue'
import VueCookies from 'vue-cookies'

// Custom Plugin
import utils from './utils/utils'
import enums from './utils/enums'
// Global Settings
import globalSettings from '../public/etc/settings'
// Graphic framework
import vuetify from './utils/vuetify'

// Installed Libraries
import VueAWN from 'vue-awesome-notifications'
import 'fullpage.js/vendors/scrolloverflow.min.js'
import 'fullpage.js/vendors/easings.min.js'
import VueFullPage from 'vue-fullpage.js/dist/vue-fullpage.min.js'
import vue2Dropzone from 'vue2-dropzone'
import VueTippy, { TippyComponent } from 'vue-tippy'
import VueSplide from '@splidejs/vue-splide'
import InfiniteLoading from 'vue-infinite-loading'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
// Wysiwyg
import CKEditor from '@ckeditor/ckeditor5-vue2'
import '@ckeditor/ckeditor5-build-classic/build/translations/fr'
// Image Viewer
import VueSilentbox from 'vue-silentbox'

// Font awesome import
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons' // Include needed icons
// Font awesome register
Vue.component('FontAwesomeIcon', FontAwesomeIcon) // Register component globally
library.add(fas)

Vue.use(VueMeta)
Vue.use(store)
Vue.use(utils)
Vue.use(enums)
Vue.use(VueCookies)
Vue.use(VueFullPage)
Vue.use(VueTippy)
Vue.use(VueSplide)
Vue.use(InfiniteLoading, {
  /* options */
})
Vue.component('Tippy', TippyComponent)
Vue.use(PerfectScrollbar)
Vue.use(CKEditor)
Vue.use(VueSilentbox)

const dropzoneOptionsOverride = {
  url: '',
  thumbnailWidth: 100,
  addRemoveLinks: true,
  dictDefaultMessage: "<i class='fa fa-cloud-upload'></i>UPLOAD ME",
}
Vue.use(vue2Dropzone, dropzoneOptionsOverride)

const awnOptionsOverrides = {
  maxNotifications: 3,
  messages: {
    async: 'Veuillez patienter durant le chargement',
    'async-block': 'Chargement...',
  },
  labels: {
    tip: 'Conseils',
    info: 'Informations',
    success: 'Succès',
    warning: 'Attention',
    alert: 'Erreur',
    async: 'Chargement...',
    confirm: 'Confirmer',
    confirmOk: 'OK',
    confirmCancel: 'Annuler',
  },
}
Vue.use(VueAWN, awnOptionsOverrides)

// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === 'production'

// If running inside Cypress...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Ensure tests fail when Vue emits an error.
  Vue.config.errorHandler = window.Cypress.cy.onUncaughtException
}

// Map entries from public/etc/settings to create global variables
// accessible from everywhere
for (const [key, value] of Object.entries(globalSettings)) {
  Object.defineProperty(Vue.prototype, '$' + key, { value: value })
}

const app = new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')

// If running e2e tests...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Attach the app to the window, which can be useful
  // for manually setting state in Cypress commands
  // such as `cy.logIn()`.
  window.__app__ = app
}
