import Vue from 'vue'
import Vuetify from 'vuetify'

// Locale
import fr from 'vuetify/lib/locale/fr'

// Colors import to inject in Vuetify instanciation
import colors from '../design/_colors.scss'

// Font awesome import
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons' // Include needed icons
// Font awesome register
Vue.component('FontAwesomeIcon', FontAwesomeIcon) // Register component globally
library.add(fas)

// Vue use
Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      // import custom colors from _colors.scss to Vuetify
      light: {
        primary: colors.colorExpBlue,
        secondary: colors.colorExpGray,
        accent: colors.colorExpYellow,
        error: colors.colorError,
      },
      dark: {
        primary: colors.colorExpGray,
        secondary: colors.colorExpBlue,
        accent: colors.colorExpYellow,
        error: colors.colorError,
      },
    },
  },
  icons: {
    iconfont: 'fa',
  },
  breakpoint: {
    thresholds: {
      xs: 600,
      sm: 960,
      md: 1550,
      lg: 2000,
      xl: 2560,
    },
    mobileBreakpoint: 'sm',
    scrollBarWidth: 24,
  },
  lang: {
    locales: { fr },
    current: 'fr',
  },
})
