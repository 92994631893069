<template>
  <div class="pa-10">
    <h2 class="text-center mb-2">Conditions Générales d'Utilisation (CGU)</h2>

    <h3 class="text-center my-5">POLITIQUE DE CONFIDENTIALITÉ DU SITE : Explorys</h3>

    <h3 class="text-center my-5">ARTICLE 1 : PRÉAMBULE</h3>
    <p>
      Cette politique de confidentialité s'applique au site : Explorys.
      <br>
      La présente politique de confidentialité a pour but d'exposer aux utilisateurs du site :
      <br>
      Cette politique de confidentialité complète les mentions légales et les Conditions Générales d'Utilisation que les utilisateurs peuvent consulter à l'adresse ci-après : <b>www.explorys.fr</b>
    </p>

    <h3 class="text-center my-5">ARTICLE 2 : PRINCIPES GÉNÉRAUX EN MATIÈRE DE COLLECTE ET DE TRAITEMENT DE DONNÉES</h3>
    <p>
      Conformément aux dispositions de l'article 5 du Règlement européen 2016/679, la collecte et le traitement des données des utilisateurs du site respectent les principes suivants :
      <br><br>
      •	Licéité, loyauté et transparence : les données ne peuvent être collectées et traitées qu'avec le consentement de l'utilisateur propriétaire des données. A chaque fois que des données à caractère personnel seront collectées, il sera indiqué à l'utilisateur que ses données sont collectées, et pour quelles raisons ses données sont collectées ;
      <br>
      •	Finalités limitées : la collecte et le traitement des données sont exécutés pour répondre à un ou plusieurs objectifs déterminés dans les présentes conditions générales d'utilisation ;
      <br>
      •	Minimisation de la collecte et du traitement des données : seules les données nécessaires à la bonne exécution des objectifs poursuivis par le site sont collectées ;
      <br>
      •	Conservation des données réduites dans le temps : les données sont conservées pour une durée limitée, dont l'utilisateur est informé. Lorsque cette information ne peut pas être communiquée, l'utilisateur est informé des critères utilisés pour déterminer la durée de conservation ;
      <br>
      •	Intégrité et confidentialité des données collectées et traitées : le responsable du traitement des données s'engage à garantir l'intégrité et la confidentialité des données collectées.
    </p>

    <p>
      Afin d'être licites, et ce conformément aux exigences de l'article 6 du règlement européen 2016/679, la collecte et le traitement des données à caractère personnel ne pourront intervenir que s'ils respectent au moins l'une des conditions ci-après énumérées :
      <br><br>
      •	L'utilisateur a expressément consenti au traitement ;
      <br>
      •	Le traitement est nécessaire à la bonne exécution d'un contrat ;
      <br>
      •	Le traitement répond à une obligation légale ;
      <br>
      •	Le traitement s'explique par une nécessité liée à la sauvegarde des intérêts vitaux de la personne concernée ou d'une autre personne physique ;
      <br>
      •	Le traitement peut s'expliquer par une nécessité liée à l'exécution d'une mission d'intérêt public ou qui relève de l'exercice de l'autorité publique ;
      <br>
      •	Le traitement et la collecte des données à caractère personnel sont nécessaires aux fins des intérêts légitimes et privés poursuivis par le responsable du traitement ou par un tiers.
    </p>

    <h3 class="text-center my-5">ARTICLE 3 : DONNÉES À CARACTÈRE PERSONNEL COLLECTÉES ET TRAITÉES DANS LE CADRE DE LA NAVIGATION SUR LE SITE</h3>

    <h4 class="my-3">A. DONNÉES COLLECTÉES ET TRAITÉES ET MODE DE COLLECTE</h4>
    <p>
      Les données à caractère personnel collectées sur le site Explorys sont les suivantes :
      <br><br>
      Prénom-nom
      <br>
      Nom de l'organisation
      <br>
      Activité professionnelle
      <br>
      Lieu géographique de l'organisation
      <br>
      Email professionnel
      <br>
      Réseaux sociaux (optionnel)
      <br><br>
      Ces données sont collectées lorsque l'utilisateur effectue l'une des opérations suivantes sur le site :
      <br>
      Lorsque l'Utilisateur accède et enregistre ses informations depuis son espace personnel.
      <br>
      Le responsable du traitement conservera dans ses systèmes informatiques du site et dans des conditions raisonnables de sécurité l'ensemble des données collectées pour une durée de : 12 mois.
    </p>

    <p>
      La collecte et le traitement des données répondent aux finalités suivantes :
      <br>
      l'enregistrement des données de l'Utilisateur, concerne uniquement les Utilisateurs suivant un programme chez Explorys.
      <br><br>
      La collecte et le traitement des données permettent d'enregistrer les différents profils et de rendre leur profil visible par l'ensemble des internautes depuis l'onglet "Les Explorateurs".
      <br><br>
      Les traitements de données effectués sont fondés sur les bases légales suivantes :
      <br>
      consentement de l'utilisateur
    </p>

    <h4 class="my-3">B. TRANSMISSION DES DONNÉES A DES TIERS</h4>
    <p>
      Les données à caractère personnel collectées par le site ne sont transmises à aucun tiers, et ne sont traitées que par l'éditeur du site.
    </p>

    <h4 class="my-3">C. HÉBERGEMENT DES DONNÉES</h4>
    <p>
      Le site Explorys est hébergé par : OVH, dont le siège est situé à l'adresse ci-après :
      <br>
      2 rue Kellermann - 59100 Roubaix - France
      <br>
      L'hébergeur peut être contacté au numéro de téléphone suivant : 1007 (service client).
      <br>
      Les données collectées et traitées par le site sont exclusivement hébergées et traitées en France.
    </p>

    <h3 class="text-center my-5">ARTICLE 4 : RESPONSABLE DU TRAITEMENT DES DONNÉES</h3>
    <h4 class="my-3">A. LE RESPONSABLE DU TRAITEMENT DES DONNÉES</h4>
    <p>
      Le responsable du traitement des données à caractère personnel est Explorys. Il peut être contacté de la manière suivante : <b>help@explorys.fr</b>
      <br><br>
      Le responsable du traitement des données est chargé de déterminer les finalités et les moyens mis au service du traitement des données à caractère personnel.
    </p>

    <h4 class="my-3">B. OBLIGATIONS DU RESPONSABLE DU TRAITEMENT DES DONNÉES</h4>
    <p>
      Le responsable du traitement s'engage à protéger les données à caractère personnel collectées, à ne pas les transmettre à des tiers sans que l'utilisateur n'en ait été informé et à respecter les finalités pour lesquelles ces données ont été collectées.
      <br>
      Le site dispose d'un certificat SSL afin de garantir que les informations et le transfert des données transitant par le site sont sécurisés.
      <br>
      Un certificat SSL ("Secure Socket Layer" Certificate) a pour but de sécuriser les données échangées entre l'utilisateur et le site.
      <br>
      De plus, le responsable du traitement des données s'engage à notifier l'utilisateur en cas de rectification ou de suppression des données, à moins que cela n'entraîne pour lui des formalités, coûts et démarches disproportionnés.
      <br>
      Dans le cas où l'intégrité, la confidentialité ou la sécurité des données à caractère personnel de l'utilisateur est compromise, le responsable du traitement s'engage à informer l'utilisateur par tout moyen.
    </p>

    <h3 class="text-center my-5">ARTICLE 5 : DROITS DE L'UTILISATEUR</h3>
    <p>
      Conformément à la réglementation concernant le traitement des données à caractère personnel, l'utilisateur possède les droits ci-après énumérés.
      <br>
      Afin que le responsable du traitement des données fasse droit à sa demande, l'utilisateur est tenu de lui communiquer : son prénom et son nom ainsi que son adresse e-mail.
      <br>
      Le responsable du traitement des données est tenu de répondre à l'utilisateur dans un délai de 30 (trente) jours maximums.
    </p>

    <h4 class="my-3">A. PRÉSENTATION DES DROITS DE L'UTILISATEUR EN MATIÈRE DE COLLECTE ET TRAITEMENT DE DONNÉES</h4>
    <p>
      <i>a. Droit d'accès, de rectification et droit à l'effacement</i>
      <br><br>
      L'utilisateur peut prendre connaissance, mettre à jour, modifier ou demander la suppression des données le concernant, en respectant la procédure ci-après énoncée :
      <br><br>
      L’utilisateur doit envoyer un email au responsable des données personnelles en précisant l’objet de sa demande, à l'adresse email suivante : <b>help@explorys.fr</b>
      <br><br>
      S'il en possède un, l'utilisateur a le droit de demander la suppression de son espace personnel en suivant la procédure suivante :
      <br>
      L'utilisateur doit envoyer un email au responsable de données, <b>help@explorys.fr</b>. Il doit préciser le nom de son organisation ainsi que son prénom et son nom. Sa demande sera traitée dans les 10 jours ouvrés.
    </p>

    <p>
      <i>b. Droit à la portabilité des données</i>
      <br><br>
      L'utilisateur a le droit de demander la portabilité de ses données personnelles, détenues par le site, vers un autre site, en se conformant à la procédure ci-après :
      <br>
      L'utilisateur doit faire une demande de portabilité de ses données personnelles auprès du responsable de traitement des données, à l'adresse email indiquée ci-dessus.
    </p>

    <p>
      <i>c. Droit à la limitation et à l'opposition du traitement des données</i>
      <br><br>
      L'utilisateur a le droit de demander la limitation ou de s'opposer au traitement de ses données par le site, sans que le site ne puisse refuser, sauf à démontrer l'existence de motifs légitimes et impérieux, pouvant prévaloir sur les intérêts et les droits et libertés de l'utilisateur.
      <br><br>
      Afin de demander la limitation du traitement de ses données ou de formuler une opposition au traitement de ses données, l'utilisateur doit suivre la procédure suivante :
      <br>
      L'utilisateur doit faire une demande de limitation au traitement de ses données personnelles par e-mail à <b>help@explorys.fr</b>
    </p>

    <p>
      <i>d. Droit de ne pas faire l'objet d'une décision fondée exclusivement sur un procédé automatisé</i>
      <br><br>
      Conformément aux dispositions du règlement 2016/679, l'utilisateur a le droit de ne pas faire l'objet d'une décision fondée exclusivement sur un procédé automatisé si la décision produit des effets juridiques le concernant, ou l'affecte de manière significative de façon similaire.
    </p>

    <p>
      <i>e. Droit de déterminer le sort des données après la mort</i>
      <br><br>
      Il est rappelé à l'utilisateur qu'il peut organiser quel doit être le devenir de ses données collectées et traitées s'il décède, conformément à la loi n°2016-1321 du 7 octobre 2016.
    </p>

    <p>
      <i>f. Droit de saisir l'autorité de contrôle compétente</i>
      <br><br>
      Dans le cas où le responsable du traitement des données décide de ne pas répondre à la demande de l'utilisateur, et que l'utilisateur souhaite contester cette décision, ou, s'il pense qu'il est porté atteinte à l'un des droits énumérés ci-dessus, il est en droit de saisir la CNIL (Commission Nationale de l'Informatique et des Libertés, https://www.cnil.fr) ou tout juge compétent.
    </p>

    <h3 class="text-center my-5">ARTICLE 6 : UTILISATION DES FICHIERS "COOKIES"</h3>
    <p>
      Le site a éventuellement recours aux techniques de "cookies".
      <br><br>
      Pour l'utilisation de fichiers "cookies" impliquant la sauvegarde et l'analyse de données à caractère personnel, le consentement de l'utilisateur est nécessairement demandé.
      <br>
      Pour des besoins de statistiques et d'affichage, le présent site utilise des cookies. Il s'agit de petits fichiers textes stockés sur votre disque dur afin d'enregistrer des données techniques sur votre navigation. Certaines parties de ce site ne peuvent être fonctionnelle sans l’acceptation de cookies.
      <br><br>
      Ce consentement de l'utilisateur est considéré comme valide pour une durée de 6 (six) mois maximum. A l'issue de cette période, le site demandera à nouveau l'autorisation de l'utilisateur pour enregistrer des fichiers "cookies" sur son disque dur.
    </p>

    <p>
      <i>a. Opposition de l'utilisateur à l'utilisation de fichiers "cookies" par le site</i>
      <br><br>
      Les cookies non essentiels au fonctionnement du site ne sont déposés sur le terminal de l'utilisateur qu'après avoir obtenu son consentement. L'utilisateur peut retirer son consentement à tout moment, de la manière suivante :
      <br>
      En cliquant sur le bouton « uniquement nécessaire »
      <br><br>
      De manière plus générale, il est porté à la connaissance de l'utilisateur qu'il peut s'opposer à l'enregistrement de ces fichiers "cookies" en configurant son logiciel de navigation.
    </p>

    <p>
      Dans le cas où l'utilisateur décide de désactiver les fichiers "cookies", il pourra poursuivre sa navigation sur le site. Toutefois, tout dysfonctionnement du site provoqué par cette manipulation ne pourrait être considéré comme étant du fait de l'éditeur du site.
    </p>

    <p>
      <i>b. Description des fichiers "cookies" utilisés par le site</i>
      <br><br>
      L'éditeur du site attire l'attention de l'utilisateur sur le fait que les cookies suivants sont utilisés lors de sa navigation :
      <br><br>
      Safari, Firefox, Chrome ou Microsoft Edge
      <br><br>
      En naviguant sur le site, il est porté à connaissance de l'utilisateur que des fichiers "cookies" de tiers peuvent être enregistrés.
    </p>

    <p>
      De plus, le site intègre des boutons de réseaux sociaux, permettant à l'utilisateur de partager son activité sur le site. Des fichiers "cookies" de ces réseaux sociaux sont par conséquent susceptibles d'être stockés sur l'ordinateur de l'utilisateur lorsqu'il utilise ces fonctionnalités.
      <br><br>
      L'attention de l'utilisateur est portée sur le fait que ces sites disposent de politiques de confidentialité propres et de conditions générales d'utilisation possiblement différentes du site. L'éditeur du site invite les utilisateurs à consulter les politiques de confidentialité et les conditions générales d'utilisation de ces sites.
    </p>

    <h3 class="text-center my-5">ARTICLE 7 : CONDITIONS DE MODIFICATION DE LA POLITIQUE DE CONFIDENTIALITÉ</h3>
    <p>
      La présente politique de confidentialité peut être consultée à tout moment à l'adresse ci-après indiquée : <b>www.explorys.fr</b>
      <br>
      L'éditeur du site se réserve le droit de la modifier afin de garantir sa conformité avec le droit en vigueur.
      <br><br>
      Par conséquent, l'utilisateur est invité à venir consulter régulièrement cette politique de confidentialité afin de se tenir informé des derniers changements qui lui seront apportés.
      <br>
      Il est porté à la connaissance de l'utilisateur que la dernière mise à jour de la présente politique de confidentialité est intervenue le : 29/01/2021.
    </p>

    <h3 class="text-center my-5">ARTICLE 8 : ACCEPTATION PAR L'UTILISATEUR DE LA POLITIQUE DE CONFIDENTIALITÉ</h3>
    <p>
      En naviguant sur le site, l'utilisateur atteste avoir lu et compris la présente politique de confidentialité et en accepte les conditions, en ce qui concerne plus particulièrement la collecte et le traitement de ses données à caractère personnel, ainsi que l'utilisation de fichiers "cookies".
    </p>

  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'PageCGU',
  metaInfo() {
    return {
      title: 'Conditions Générales d\'Utilisation',
      meta: [{ name: 'robots', content: 'noindex' }],
    }
  },
  mounted() {
    // Disable Loading
    this.setLoading(false)
  },
  methods: {
    ...mapActions(['setLoading',]),
  }
}
</script>
