<template>
  <div>
    <v-dialog
      v-model="showEditModal"
      persistent
      max-width="800"
      width="100%"
      overlay-color="#001a20"
      overlay-opacity="0.8"
    >
      <v-card v-if="showEditModal" id="edit-post-modal">
        <v-card-title class="d-flex justify-space-between">
          <h4>Modifier son post</h4>
          <v-btn
              icon
              @click="closeEditModal"
            >
              <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="currentEditPost">
          <v-row>
            <v-col cols="12" class="text-left edit-post-modal--content">
              <!-- HERE -->
              <ckeditor v-model="contentEdited" :editor="editor" :config="editorConfig"></ckeditor>
              <!-- Limit -->
              <div 
                :class="isLengthValid ? 'd-flex justify-end' : 'd-flex justify-space-between err'">
                <span v-if="!isLengthValid">Votre texte est trop long</span>
                {{ lengthContent + ' / ' + lengthMax }}
              </div>
            </v-col>
          </v-row>
          <h5>Galerie d'images</h5>
          <v-row v-if="imagesGallery && imagesGallery.length > 0" class="edit-post-modal--images align-center justify-space-around">
            <v-col 
              v-for="image in imagesGallery"
              :key="image.id"
              cols="3"
              class="text-center pa-1"
            >
              <v-img :src="image.src" :alt="image.alt" width="100w" />
              <div>
                <div v-if="getImageDeleteStatus(image.id)">
                  <strong>Confirmer ? </strong>
                  <v-btn
                    v-tippy
                    :loading="deleteImageLoading"
                    content="Confirmer la suppression de l'image"
                    color="success"
                    icon
                    small
                    @click="confirmDeleteImage(image.id)"
                  >
                    <v-icon>
                      mdi-check
                    </v-icon>
                  </v-btn>
                  <v-btn
                    v-tippy
                    :loading="deleteImageLoading"
                    content="Annuler"
                    color="error"
                    icon
                    small
                    @click="toggleImgDeleteStatus(image.id)"
                  >
                    <v-icon>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </div>
                <v-btn
                  v-else
                  v-tippy
                  content="Supprimer cette image du post ?"
                  color="error"
                  icon
                  small
                  @click="toggleImgDeleteStatus(image.id)"
                >
                  <v-icon>
                    mdi-trash-can-outline
                  </v-icon>
                </v-btn>
              </div>
              <div class="mb-0">
              </div>
            </v-col>
          </v-row>
          <v-row v-else >
            <v-col 
              cols="12"
              class="text-center"
            >
              Aucune image
            </v-col>
          </v-row>
          <v-row v-if="imagesGallery && imagesGallery.length < 4" class="mt-2">
            <v-expand-transition>
              <v-col v-if="addNewImages" cols="12" class="text-center">
                <v-btn
                  v-tippy
                  content="Annuler"
                  color="primary"
                  dark
                  icon
                  @click="addNewImages = false"
                >
                  <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
                <VueDropzone
                  id="dropzoneAddingFile"
                  ref="dropzoneAddingFile"
                  :options="dropzoneOptions"
                  @vdropzone-file-added="checkAddedFile"
                />
                <div class="dropzone-btn d-flex flex-column mt-2">
                  <v-btn
                    v-tippy
                    :loading="loadingUpload"
                    content="Enregistrer l'image"
                    color="success"
                    dark
                    small
                    @click="saveAndAddFile"
                  >
                    <v-icon small>fas fa-plus-circle</v-icon>
                  </v-btn>
                  <v-btn
                    v-tippy
                    :loading="loadingUpload"
                    content="Effacer l'image"
                    color="error"
                    dark
                    small
                    class="mt-1"
                    @click="removeFiles"
                  >
                    <v-icon small>fas fa-trash-alt</v-icon>
                  </v-btn>
                </div>
              </v-col>
              <v-col v-else cols="12" class="text-center">
                <v-btn
                  v-tippy
                  content="Ajouter une image"
                  color="primary"
                  dark
                  icon
                  @click="addNewImages = true"
                >
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>
                <div class="empty-space-dropzone"></div>
              </v-col>
            </v-expand-transition>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end align-center">
          <v-btn
            color="blue darken-4 bold"
            text
            :loading="loadingEdit"
            @click="closeEditModal"
          >
            Fermer
          </v-btn>
          <v-btn
            color="green lighten-1 bold"
            text
            :loading="loadingEdit"
            :disabled="!canUpdate"
            @click="updateEditPost"
          >
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card class="post-card" elevation="5" no-body :disabled="waitForDelete">
      <v-card-title>
        <v-row justify="space-between" no-gutters>
          <v-col cols="2" lg="1" sm="1" class="pt-1">
            <v-avatar>
              <v-img
                v-if="!currentPost.user_photo_data"
                id="photo"
                :src="require('@images/avatar_defaut.png')"
                contain
                width="40"
                height="40"
              />
              <v-img
                v-else
                :src="currentPost.user_photo_data"
                max-height="40"
                max-width="40"
              />
            </v-avatar>
          </v-col>
          <v-col cols="7" lg="8" sm="8" class="pt-1">
            <router-link
              v-if="loggedUser"
              v-tippy
              class="text-deco-none"
              content="Voir le profil"
              :to="'/extranet/explorateur/' + currentPost.user_id_organisation"
            >
              <b class="post-fullname link-lighted">{{ currentPost.user_fullname }}</b>
            </router-link>
            <b v-else class="post-fullname">{{ currentPost.user_fullname }}</b>
            <i class="post-position">
              {{ currentPost.user_position }} chez
              <span>{{ currentPost.user_organisation_name }}</span>
            </i>
          </v-col>
          <v-col cols="3" lg="3" sm="3" class="post-date">
            <span
              v-tippy
              :content="$utils.formatDateWithHours(currentPost.creation_date)"
            >
              {{ $utils.calcTimeFromNow(currentPost.creation_date) }}
            </span>
            <span v-if="currentPost.private" class="private-hint">
              Privé
            </span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="card-content">
        <v-row v-if="imagesGallery.length >= 1">
          <v-col cols="12" lg="12">
            <!-- Images gallery -->
            <silent-box :gallery="imagesGallery"></silent-box>
          </v-col>
        </v-row>
        <v-row>
          <!-- eslint-disable-next-line -->
          <v-col cols="12" lg="12" class="dark" v-html="currentPost.content">
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="py-1" :class="isDashboard ? 'pl-5 px-1' : 'px-1'">
        <v-row justify="space-between">
          <v-col lg="auto" class="d-flex">
            <div v-if="loggedUser">
              <v-btn
                v-tippy="{ placement: 'top' }"
                :disabled="!loggedUser"
                color="error"
                :content="currentPost.logged_user_likes ? 'Je n\'aime pas' : 'J\'aime'"
                tile
                text
                small
                :loading="likesLoading"
                @click="toggleLike"
              >
                <v-icon v-if="isLoggedUserLike" class="">
                  fas fa-heart
                </v-icon>
                <v-icon v-else class="">
                  far fa-heart
                </v-icon>
              </v-btn>
              <v-btn
                v-tippy="{ placement: 'top' }"
                :content="currentPost.likes ? 'Voir les mentions J\'aime' : 'Aucune mention J\'aime'"
                :disabled="!loggedUser"
                :color="currentPost.likes ? 'primary' : 'secondary'"
                tile
                text
                small
                :loading="likesLoading"
                @click="showLikes(currentPost.id)"
              >
                <v-icon v-if="currentPost.likes" class="pr-2">
                  fas fa-user
                </v-icon>
                <v-icon v-else class="pr-2">
                  far fa-user
                </v-icon>
                {{ currentPost.likes }}
              </v-btn>
              <v-btn
                v-tippy="{ placement: 'top' }"
                :color="currentPost.comments_count === 0 ? 'secondary' : 'primary'"
                content="Commentez"
                tile
                text
                small
                :loading="commentsLoading"
                :disabled="!loggedUser && currentPost.comments_count === 0"
                @click="toggleCommentPanel"
              >
                <v-icon v-if="currentPost.comments_count === 0" class="pr-2">
                  far fa-comment
                </v-icon>
                <v-icon v-else class="pr-2">
                  fas fa-comment
                </v-icon>
                {{ currentPost.comments_count }}
              </v-btn>
            </div>
            <v-btn
              v-if="!isDashboard"
              class="facebook-btn mx-3"
              small
              :onclick="
                'window.open(\'https://www.facebook.com/sharer/sharer.php?u=' +
                  urlToShare + '&t=' + currentPost.user_organisation_name +
                  '\',\'_blank\', \'width=600, height=600\'); return false;'
              "
            >
              <v-icon :class="isXSOnly ? '' : 'mr-2'">
                fab fa-facebook
              </v-icon>
              <span v-if="!isXSOnly">Partager</span>
            </v-btn>

            <!-- Linkedin -->
            <v-btn
              v-if="!isDashboard"
              class="linkedin-btn"
              small
              :onclick="
                'window.open(\'https://www.linkedin.com/shareArticle?mini=true&url=' +
                  urlToShare + '&title=' + currentPost.user_organisation_name +
                  '\',\'_blank\', \'width=600, height=600\'); return false;'
              "
            >
              <v-icon :class="isXSOnly ? '' : 'mr-2'">
                fab fa-linkedin
              </v-icon>
              <span v-if="!isXSOnly">Partager</span>
            </v-btn>
          </v-col>
          <v-col lg="2" class="text-right pr-3">
            <v-btn
              v-if="loggedUser && (loggedUser.role === 'SUPERADMIN' || loggedUser.id === currentPost.id_user)"
              v-tippy
              color="primary"
              icon
              small
              content="Modifier ce post"
              @click="editMyPost(currentPost.id)"
            >
              <v-icon>
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-btn
              v-if="
                loggedUser &&
                  (loggedUser.role === 'SUPERADMIN' ||
                    loggedUser.id === currentPost.id_user)
              "
              v-tippy
              color="error"
              icon
              small
              content="Supprimer ce post"
              @click="confirmDeletePost"
            >
              <v-icon>
                mdi-trash-can-outline
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <!-- Comments -->
      <v-card-text>
        <v-expand-transition>
          <v-container
            v-if="displayCommentPanel && !commentsLoading"
            fluid
            class="comments-container"
          >
            <v-row v-if="loggedUser">
              <v-col cols="1" lg="1" sm="2" class="pa-0 mt-2 text-center">
                <v-avatar class="new-comment-avatar" size="40">
                  <v-img
                    v-if="loggedUser && !loggedUser.photo_data"
                    id="photo"
                    :src="require('@images/avatar_defaut.png')"
                    contain
                  />
                  <v-img
                    v-else
                    :src="loggedUser.photo_data"
                  />
                </v-avatar>
              </v-col>
              <v-col cols="10" lg="10" sm="9" class="pb-0">
                <v-textarea
                  v-model="currentComment.content"
                  auto-grow
                  rows="1"
                  placeholder="Saisissez ici votre commentaire"
                />
              </v-col>
              <v-col cols="1" lg="1" sm="1" class="text-left pa-0 pt-3">
                <v-btn
                  v-tippy
                  content="Commenter"
                  :loading="sendLoading"
                  :disabled="!currentComment.content || !(currentComment.content.length > 0)"
                  :color="currentComment.content && currentComment.content.length > 0 ? 'success' : 'secondary'"
                  icon
                  light
                  small
                  @click="postComment(null)"
                >
                  <v-icon>
                    mdi-send
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <div v-if="gotCommentsList.length">
              <div v-for="comment in gotCommentsList" :key="comment.id">
                <v-row>
                  <v-col cols="1" class="comment-avatar text-center">
                    <v-avatar size="40" class="mt-2">
                      <v-img
                        v-if="!comment.user_photo_data"
                        :src="require('@images/avatar_defaut.png')"
                        contain
                      />
                      <v-img
                        v-else
                        :src="comment.user_photo_data"
                      />
                    </v-avatar>
                  </v-col>
                  <v-col class="comment-bubble">
                    <v-row class="comment-header">
                      <v-col cols="8" lg="8" class="title-user-col">
                        <router-link
                          v-if="loggedUser"
                          v-tippy
                          class="text-deco-none"
                          content="Voir le profil"
                          :to="'/extranet/explorateur/' + comment.id_organisation"
                        ><b class="link-lighted">{{ comment.user_fullname }}</b></router-link>
                        <b v-else>{{ comment.user_fullname }}</b>
                        <i class="post-position">
                          {{ comment.user_position }} chez {{ comment.user_organisation_name }}
                        </i>
                      </v-col>
                      <v-col cols="4" lg="4" class="post-date pr-0">
                        <span
                          v-tippy
                          :content="
                            $utils.formatDateWithHours(comment.creation_date)
                          "
                        >
                          {{ $utils.calcTimeFromNow(comment.creation_date) }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" lg="12" class="comment-content">
                        {{ comment.content }}
                        <div class="comment-content--btn">
                          <v-btn
                            v-if="loggedUser && comment.id_comment_parent === null"
                            v-tippy
                            color="primary"
                            icon
                            small
                            :content="idCommentToComment === comment.id ? 'Annuler' : 'Répondre à ce commentaire'"
                            @click="addCommentToParent(comment.id)"
                          >
                            <v-icon v-if="idCommentToComment === comment.id">
                              mdi-close
                            </v-icon>
                            <v-icon v-else>
                              mdi-reply
                            </v-icon>
                          </v-btn>
                          <v-btn
                            v-if="
                              loggedUser &&
                                (loggedUser.role === 'SUPERADMIN' ||
                                  loggedUser.id === currentComment.id_user)
                            "
                            v-tippy
                            color="error"
                            icon
                            small
                            content="Supprimer ce commentaire"
                            :loading="deleteCommentLoading"
                            @click="confirmDeleteComment(comment.id)"
                          >
                            <v-icon>
                              mdi-trash-can-outline
                            </v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row v-if="comment.comments && comment.comments.length > 0">
                  <!-- @HERE START SUB-COMMENTS -->
                  <v-col cols="1">&nbsp;</v-col>
                  <v-col cols="11" class="comment-childs-content">
                    <v-row v-for="subComment in comment.comments" :key="subComment.id">
                      <v-col cols="1" class="comment-avatar mt-2 text-center">
                        <v-avatar size="40">
                          <v-img
                            v-if="!subComment.user_photo_data"
                            :src="require('@images/avatar_defaut.png')"
                            contain
                          />
                          <v-img
                            v-else
                            :src="subComment.user_photo_data"
                          />
                        </v-avatar>
                      </v-col>
                      <v-col class="comment-bubble">
                        <v-row class="comment-header">
                          <v-col cols="8" lg="8" class="title-user-col">
                            <router-link
                              v-if="loggedUser"
                              v-tippy
                              class="text-deco-none"
                              content="Voir le profil"
                              :to="'/extranet/explorateur/' + subComment.id_organisation"
                            ><b class="link-lighted">{{ subComment.user_fullname }}</b></router-link>
                            <b v-else>{{ subComment.user_fullname }}</b>
                            <i class="post-position">
                              {{ subComment.user_position }} chez
                              {{ subComment.user_organisation_name }}
                            </i>
                          </v-col>
                          <v-col cols="4" lg="4" class="post-date pr-0">
                            <span
                              v-tippy
                              :content="
                                $utils.formatDateWithHours(subComment.creation_date)
                              "
                            >
                              {{ $utils.calcTimeFromNow(subComment.creation_date) }}
                            </span>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" lg="12" class="comment-content">
                            {{ subComment.content }}
                            <div class="comment-content--btn">
                              <v-btn
                                v-if="
                                  loggedUser &&
                                    (loggedUser.role === 'SUPERADMIN' ||
                                      loggedUser.id === currentComment.id_user)
                                "
                                v-tippy
                                color="error"
                                icon
                                small
                                content="Supprimer ce commentaire"
                                :loading="deleteCommentLoading"
                                @click="confirmDeleteComment(subComment.id)"
                              >
                                <v-icon>
                                  mdi-trash-can-outline
                                </v-icon>
                              </v-btn>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <!-- @HERE END SUB-COMMENTS -->
                <v-row v-if="idCommentToComment && idCommentToComment === comment.id" class="pt-2">
                  <v-col cols="1">&nbsp;</v-col>
                  <v-col cols="1" lg="1" sm="2" class="pa-0 text-center">
                    <v-avatar class="new-comment-avatar" size="40">
                      <v-img
                        v-if="loggedUser && !loggedUser.photo_data"
                        id="photo"
                        contain
                        :src="require('@images/avatar_defaut.png')"
                      />
                      <v-img
                        v-else
                        :src="loggedUser.photo_data"
                      />
                    </v-avatar>
                  </v-col>
                  <v-col cols="9" lg="9" sm="8" class="pb-0">
                    <v-textarea
                      v-model="currentSubComment.content"
                      auto-grow
                      rows="1"
                      placeholder="Saisissez ici votre commentaire"
                    />
                  </v-col>
                  <v-col cols="1" lg="1" sm="1" class="text-left pa-0 pt-3">
                    <v-btn
                      v-tippy
                      content="Commenter"
                      :loading="sendLoading"
                      :disabled="!currentSubComment.content || !(currentSubComment.content.length > 0)"
                      :color="currentSubComment.content && currentSubComment.content.length > 0 ? 'success' : 'secondary'"
                      icon
                      light
                      small
                      @click="postComment(comment.id)"
                    >
                      <v-icon>
                        mdi-send
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

                <!-- @TODO: ADD SUB-COMMENT -->
                <br v-if="comment.comments && comment.comments.length > 0">
              </div>
            </div>
          </v-container>
        </v-expand-transition>
      </v-card-text>
    </v-card>
    <v-dialog v-model="showModalLikes" max-width="400">
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <h4>Mentions J'aime</h4>
          <v-btn
              icon
              @click="showModalLikes = false"
            >
              <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <ul style="list-style-type: none;">
            <li v-for="like in likesList" :key="like.id">
              <router-link
                v-if="loggedUser"
                class="link-lighted"
                :to="'/extranet/explorateur/' + like.id_organisation"
              >{{ like.firstname }} {{ like.lastname }}</router-link>
              <span v-else>{{ like.firstname }} {{ like.lastname }}</span>
              ({{ like.name }})
            </li>
          </ul>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import VueDropzone from 'vue2-dropzone'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'PostDisplay',
  components: { 
    VueDropzone,
  },
  props: {
    post: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      addNewImages: false,
      isConfirmDeleteImage: false,
      deleteImageLoading: false,
      deleteCommentLoading: false,
      imagesIdsPostDelete: [],
      loadingUpload: false,
      loadingEdit: false,
      contentEdited: null,
      imagesEditedGallery: [],
      showEditModal: false,
      currentEditPost: null,
      idCommentToComment: null,
      showModalLikes: false,
      likesList: [],
      sendLoading: false,
      lengthMax: 1000,
      dropzoneOptions: {
        maxFiles: 1,
        maxFilesize: 4, // 5M
        autoProcessQueue: false,
        url: function() {},
        thumbnailWidth: 100,
        thumbnailHeight: 150,
        parallelUploads: 1,
        dictDefaultMessage: "Cliquez ou déposez 1 image",
      },
      imagesGallery: [],
      gotCommentsList: [],
      spliderOptions: {
        rewind: true,
        width: '100%',
        perPage: 1,
        height: 300,
        pagination: false,
      },
      displayCommentPanel: false,
      commentsLoading: false,
      currentComment: null,
      currentSubComment: null,
      likesLoading: false,
      loading: true,
      // Share var
      descriptionToShare: null,
      waitForDelete: false,
      // Ckeditor config
      editor: ClassicEditor,
      editorConfig: {
        // The configuration of the editor.
        language: 'fr',
        toolbar: { 
          items: ['undo', 'redo', '|', 'bold', 'italic', '|', 'link' ],
          shouldNotGroupWhenFull: true,
        },
        placeholder: 'Saisissez ici le contenu de votre publication',
        link: {
          defaultProtocol: 'https://',
          addTargetToExternalLinks: true,
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'getLoggedUser',
      'getPostsArgs',
    ]),
    getAcceptedFiles() {
      if (this.$refs.dropzoneAddingFile) {
        return this.$refs.dropzoneAddingFile.getAcceptedFiles()
      } else {
        return null
      }
    },
    postsArgs() {
      return this.getPostsArgs
    },
    loggedUser() {
      return this.getLoggedUser
    },
    lengthContent() {
      if (this.contentEdited) {
        return this.contentEdited.length
      }
      else {
        return null
      }
    },
    isLengthValid() {
      if (this.lengthContent) {
        return this.lengthContent <= this.lengthMax
      } else {
        return false
      }
    },
    canUpdate() {
      if (this.isLengthValid) {
        if (this.contentEdited === this.currentEditPost.content) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    },
    publisherName() {
      if (this.loggedUser && this.loggedUser.id === this.currentPost.id_user) {
        return 'Vous'
      } else {
        return this.currentPost.user_fullname
      }
    },
    isLoggedUserLike() {
      // Check if loggedUser like this post
      if (this.loggedUser) {
        return this.currentPost.id_users_likes.includes(this.loggedUser.id)
      } else {
        return false
      }
    },
    currentDomain() {
      return window.location.origin
    },
    urlToShare() {
      const url = this.currentDomain +
        '/explorateur/' +
        this.$route.params.id_organisation
      return url
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    isDashboard() {
      return (
        this.$route.path === '/extranet' || this.$route.path === '/extranet/'
      )
    },
    isXSOnly() {
      return this.$vuetify.breakpoint.xsOnly
    },
  },
  beforeMount() {
    this.currentPost = this.post
  },
  mounted() {
    if (this.currentPost.files_list.length > 0) {
      this.currentPost.files_list.forEach((fileId) => {
        this.fetchFile(fileId).then((image) => {
          // construct new image for gallery
          const galleryImg = {
            id: image.id,
            src: image.data,
            description: image.name,
            alt: image.name,
            thumbnailWidth: '220px',
          }

          // Push into array
          this.addImageToGallery(galleryImg)
        })
      })
    }
  },
  methods: {
    ...mapActions([
      'fetchFile',
      'deleteFile',
      'checkUserLikesComment',
      'addLikeToPostById',
      'removeLikeFromPostById',
      'fetchCommentsByPostId',
      'addComment',
      'deletePostById',
      'deleteCommentById',
      'setReloadingNeed',
      'fetchLikesForAPost',
      'updatePost',
      'reloadUpdatedPost',
      'clearPostsListDB',
      'fetchPostsListDB',
      'clearPostsListSN',
      'fetchPostsListSN',
      'setPostsArgs',
      'saveFile',
      'addImagesForPost',
      'reloadUpdatedPost',
    ]),
    generateFacebookLink(post) {
      const link = 'https://www.facebook.com/plugins/share_button.php?href=' +
                  this.urlToShare + '&layout=button&size=large&width=104&height=28&appId'
                  // this.urlToShare + '&title=' + post.user_organisation_name +
                  // '&layout=button&size=large&width=104&height=28&appId'
      return link          
    },
    addImageToGallery(imageObj) {
        this.imagesGallery.push(imageObj)
        // const findImg = this.imagesIdsPostDelete.filter(img => img.id === imageObj.id)
        const findImg = this.getImageDeleteId(imageObj.id)
        if (findImg.length === 0) {
          this.imagesIdsPostDelete.push({id: imageObj.id, status: false})
        }
    },
    getImageDeleteStatus(imgId) {
      const img = this.imagesIdsPostDelete.filter(img => img.id === imgId)
      if (img.length===0) {
        return false
      } else {
        return img[0].status
      }
    },
    getImageDeleteId(imgId) {
      return this.imagesIdsPostDelete.filter(img => img.id === imgId)
    },
    showImgDelete(imgId) {
      const findImgId = this.imagesIdsPostDelete.findIndex({id: imgId, status: false})
      if (findImgId) {
        this.imagesIdsPostDelete[findImgId].status = true
      }
    },
    maskImgDelete(imgId) {
      const findImgId = this.imagesIdsPostDelete.findIndex({id: imgId, status: true})
      if (findImgId) {
        this.imagesIdsPostDelete[findImgId].sttus = false
      }
    },
    toggleImgDeleteStatus(imgId) {
      const findImgIndex = this.imagesIdsPostDelete.findIndex((obj => obj.id === imgId))
      if (this.imagesIdsPostDelete[findImgIndex]) {
        if (this.imagesIdsPostDelete[findImgIndex].status === false) {
          const showedImagesDelete = this.imagesIdsPostDelete.filter(img => img.status === true)
          showedImagesDelete.forEach((imgDeleteStatus) => {
            imgDeleteStatus.status = false
          })
        }
        this.imagesIdsPostDelete[findImgIndex].status = !this.imagesIdsPostDelete[findImgIndex].status
      }
    },
    removeFiles() {
      this.$refs.dropzoneAddingFile.removeAllFiles()
    },
    saveAndAddFile() {
      this.loadingUpload = true
      if (this.$refs.dropzoneAddingFile.getAcceptedFiles().length > 0) {
        const fileToUpload = this.$refs.dropzoneAddingFile.getAcceptedFiles()[0]
        
        // Get type
        var fileType = fileToUpload.name.split('.')
        fileType = fileType[fileType.length - 1]
        // Créer l'objet file to send to API
        const buildedFileToSend = {
          name: fileToUpload.name,
          title: 'Image Post',
          description: 'Image Post sets automatically from post form',
          folder: 'INTERNAL',
          type: fileType,
          data: fileToUpload.dataURL,
        }

        this.saveFile(buildedFileToSend)
          .then((response) => {
            const postImage = {
              idPost: this.currentPost.id,
              idFile: response.data.id
            }

            const imgAdddGallery = {
              alt: fileToUpload.name,
              description: fileToUpload.name,
              id: response.data.id,
              src: fileToUpload.dataURL,
              thumbnailWidth: "220px",
            }
            this.addImagesForPost(postImage)
              .then(() => {
                this.$awn.success('Image ajoutée avec succès')
                this.addImageToGallery(imgAdddGallery)
                this.addNewImages = false
                this.$refs.dropzoneAddingFile.removeAllFiles()
                this.loadingUpload = false
              })
              .catch((e) => {
                console.log(e)
                this.loadingUpload = false
                this.$awn.alert('Erreur lors de l\'enregistrement de l\'image')
              })
          })
          .catch((e) => {
            console.log(e)
            this.loadingUpload = false
            this.$awn.alert('Erreur lors de l\'enregistrement de l\'image')
          })
      } else {
        this.loadingUpload = false
      }
    },
    checkAddedFile(File) {
      if (File.size >= 5000000) {
        this.$refs.dropzoneAddingFile.removeFile(File)
        this.$awn.warning('Le fichier doit faire moins de 5Mo')
      }
      if (
        File.type !== 'image/png' &&
        File.type !== 'image/jpg' &&
        File.type !== 'image/jpeg'
      ) {
        this.$refs.dropzoneAddingFile.removeFile(File)
        this.$awn.warning('Le fichier doit être une image (format PNG ou JPEG)')
      }
      const totalFiles = this.$refs.dropzoneAddingFile.getAcceptedFiles().length
      if (totalFiles === 1) {
        this.$refs.dropzoneAddingFile.removeFile(File)
        this.$awn.warning('Pas plus de 1 image à la fois !')
      }
    },
    toggleCommentPanel() {
      this.currentComment = {
        content: null,
        id_user: this.loggedUser.id,
      }
      if (!this.displayCommentPanel) {
        this.commentsLoading = true
        this.fetchCommentsByPostId(this.currentPost.id).then((list) => {
          this.gotCommentsList = _.orderBy(list, 'creation_date', 'desc')
          this.commentsLoading = false
        })
      }
      this.displayCommentPanel = !this.displayCommentPanel
    },
    toggleLike() {
      const like = {
        id_user: this.loggedUser.id,
        id_post: this.currentPost.id,
      }
      this.likesLoading = true
      if (this.isLoggedUserLike) {
        this.removeLikeFromPostById(like).then((response) => {
          this.currentPost.likes -= 1
          var indexToRemove = this.currentPost.id_users_likes.findIndex(
            (u) => u.id === this.loggedUser.id
          )
          this.currentPost.id_users_likes.splice(indexToRemove, 1)
          this.likesLoading = false
        })
      } else {
        this.addLikeToPostById(like).then((response) => {
          this.currentPost.likes += 1
          this.currentPost.id_users_likes.push(this.loggedUser.id)
          this.likesLoading = false
        })
      }
    },
    showLikes(idPost) {
      this.likesLoading = true
      this.fetchLikesForAPost(idPost)
        .then((response) => {
          if (response.data.length > 0) {
            this.likesLoading = false
            this.likesList = response.data
            this.showModalLikes = true
          } else {
            this.likesLoading = false
          }
        })
        .catch((e) => {
          this.likesLoading = false
        })
    },
    confirmDeletePost(idPost) {
      const onOk = () => {
        this.waitForDelete = true
        this.deletePostById(this.currentPost.id)
          .then(() => {
            this.$awn.success(
              'Le post ' + this.currentPost.id + ' a été suppprimé avec succès'
            )
          })
          .catch((e) => {
            this.$awn.alert('ERREUR lors de la suppression du post')
          })
      }
      const onCancel = () => {}
      this.$awn.confirm(
        'Souhaitez-vous vraiment supprimer le post <b>' +
          this.currentPost.id +
          '</b> ?',
        onOk,
        onCancel,
        {
          labels: {
            confirm: 'Suppression de post',
          },
        }
      )
    },
    confirmDeleteComment(idCommment) {
      const onOk = () => {
        this.deleteCommentById(idCommment)
          .then(() => {
            this.deleteCommentLoading = true
            this.fetchCommentsByPostId(this.currentPost.id)
              .then((list) => {
                this.gotCommentsList = list
                this.deleteCommentLoading = false
                this.currentPost.comments_count -= 1
                this.$awn.success('Le commentaire a été suppprimé avec succès')
              })
              .catch((e) => {
                this.deleteCommentLoading = false
                this.$awn.alert(
                  'ERREUR lors de la récupération des commentaires'
                )
              })
          })
          .catch((e) => {
            this.deleteCommentLoading = false
            this.$awn.alert('ERREUR lors de la suppression du commentaire')
          })
      }
      const onCancel = () => {}
      this.$awn.confirm(
        'Souhaitez-vous vraiment supprimer ce commentaire ?',
        onOk,
        onCancel,
        {
          labels: {
            confirm: 'Suppression de commentaire',
          },
        }
      )
    },
    postComment(idParent) {
      if (idParent) {
        if (this.currentSubComment && this.currentSubComment.content !== '') {
          this.sendLoading = true
          const commentToSend = {
            id_user: this.loggedUser.id,
            id_post: this.currentPost.id,
            content: this.currentSubComment.content,
            id_comment_parent: idParent,
          }
          this.addComment(commentToSend).then((response) => {
            this.fetchCommentsByPostId(this.currentPost.id)
              .then((list) => {
                this.gotCommentsList = list
                this.currentSubComment.content = ''
                this.idCommentToComment = null
                this.sendLoading = false
                // Increment comments_count
                this.currentPost.comments_count += 1
              })
              .catch((e) => {
                this.sendLoading = false
              })
          })
          .catch((e) => {
            this.sendLoading = false
          })
        } else {
          this.$awn.warning('Vous devrez remplir le contenu de votre commentaire')
        }
      } else {
        if (this.currentComment && this.currentComment.content !== '') {
          this.sendLoading = true
          const commentToSend = {
            id_user: this.loggedUser.id,
            id_post: this.currentPost.id,
            content: this.currentComment.content,
            id_comment_parent: null,
          }
          this.addComment(commentToSend).then((response) => {
            this.fetchCommentsByPostId(this.currentPost.id)
              .then((list) => {
                this.gotCommentsList = list
                this.currentComment.content = ''
                this.sendLoading = false
                // Increment comments_count
                this.currentPost.comments_count += 1
              })
              .catch((e) => {
                this.sendLoading = false
              })
          })
          .catch((e) => {
            this.sendLoading = false
          })
        } else {
          this.$awn.warning('Vous devrez remplir le contenu de votre commentaire')
        }
      }
    },
    addCommentToParent(idComment) {
      if (this.idCommentToComment === idComment) {
        this.idCommentToComment = null
      } else {
        this.idCommentToComment = idComment
      }
      this.currentSubComment = {
        content: null,
        id_user: this.loggedUser.id,
      }
    },
    editMyPost() {
      this.currentEditPost = this.currentPost
      this.contentEdited = _.clone(this.currentEditPost.content)
      this.showEditModal = true
    },
    updateEditPost() {
      this.loadingEdit = true
      const postToEdit = {
        id: this.currentEditPost.id,
        content: this.contentEdited
      }
      this.updatePost(postToEdit)
        .then(() => {
          this.clearPostsListDB()
          this.clearPostsListSN()
          const args = _.clone(this.postsArgs)
          args.offset = 0
          this.setPostsArgs(args)
          this.loadingEdit = false
          this.closeEditModal()
          if (this.postsArgs.private) {
            this.fetchPostsListDB(this.postsArgs)
              .then(() => {})
          } else {
            this.fetchPostsListSN(this.postsArgs)
              .then(() => {})
          }
        })
        .catch((e) => {
          console.log(e)
          this.loadingEdit = false
        })
    },
    closeEditModal() {
      this.showEditModal = false
      this.currentEditPost = null
      this.contentEdited = null
    },
    confirmDeleteImage(idImage) {
      this.deleteImageLoading = true
      this.deleteFile(idImage)
      .then(() => {
        let imgToRemove = null
        this.imagesGallery.forEach((img, index) => {
          if (idImage === img.id) {
            imgToRemove = index
          }
        })
        this.imagesIdsPostDelete.splice(idImage.id, 1)
        this.imagesGallery.splice(imgToRemove, 1)
        this.reloadUpdatedPost(this.post.id)
        this.deleteImageLoading = false
      })
      .catch((e) => {
        console.log(e)
        this.$awn.error('Une erreur est survenue lors de la suppression de l\'image')
        this.deleteImageLoading = false
      })
    },
  },
}
</script>
