// State
const state = {
  scrollBox: Number,
  scrollPage: String,
}

// Getters
const getters = {
  getScrollBox(state) {
    return state.scrollBox
  },
  getScrollPage(state) {
    return state.scrollPage
  },
}

// Mutations
const mutations = {
  SET_SCROLLBOX(state, posScrollBox) {
    state.scrollBox = posScrollBox
  },
  SET_SCROLLPAGE(state, actualPage) {
    state.scrollPage = actualPage
  },
}

// Actions
const actions = {
  setNewScrollPos(store, newPosScroll) {
    this.commit('SET_SCROLLBOX', newPosScroll)
  },
  setActualPage(store, actualPage) {
    this.commit('SET_SCROLLPAGE', actualPage)
  },
}

// Export
export default {
  state,
  getters,
  mutations,
  actions,
}
