import Vue from 'vue'

// State
const state = {
  file: {},
  listFiles: [],
  postFiles: [],
}

// Getters
const getters = {
  getFile(state) {
    return state.file
  },
  getFiles(state) {
    return state.listFiles
  },
  getPostFiles(state) {
    return state.postFiles
  },
}

// Mutations
const mutations = {
  SET_FILE(state, aFile) {
    state.file = aFile
  },
  SET_FILES(state, files) {
    state.listFiles = files
  },
  ADD_FILE_TO_POST_FILES(state, file) {
    state.postFiles.push(file)
  },
  REMOVE_FILE_FROM_POST_FILES(state, idFile) {
    state.postFiles = state.postFiles.filter((post) => post.id !== idFile)
  },
  CLEAR_POST_FILES(state) {
    state.postFiles = []
  },
}

// Actions
const actions = {
  getFilesForTreeView(store) {
    return Vue.prototype.$API
      .getToken()
      .get('/files/list')
      .then((response) => {
        const fileList = []
        response.data.forEach((element) => {
          // TODO: need to find better...
          if (element.folder !== 'INTERNAL') {
            fileList.push(element)
          }
        })
        this.commit('SET_FILES', fileList)
        return Promise.resolve(fileList)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  getDataForDownload(store, idFile) {
    return Vue.prototype.$API
      .getToken()
      .get('/files/get/' + idFile)
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  fetchFile(store, idFile) {
    return Vue.prototype.$API
      .withoutToken()
      .get('/files/get/' + idFile)
      .then((response) => {
        this.commit('SET_FILE', response.data[0])
        return Promise.resolve(response.data[0])
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  getFileById(store, idFile) {
    return Vue.prototype.$API
      .withoutToken()
      .get('/files/get/' + idFile)
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  addFileToPostFilesByIdFile(store, idFile) {
    return Vue.prototype.$API
      .getToken()
      .get('/files/get/' + idFile)
      .then((response) => {
        this.commit('ADD_FILE_TO_POST_FILES', response.data[0])
        return Promise.resolve(response)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  removeFileFromPostFiles(store, idFile) {
    this.commit('REMOVE_FILE_FROM_POST_FILES', idFile)
  },
  clearPostFiles(store) {
    this.commit('CLEAR_POST_FILES')
  },
  saveFile(store, aFile) {
    return Vue.prototype.$API
      .getToken()
      .post('/files/create', aFile)
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  deleteFile(store, idFile) {
    return Vue.prototype.$API
      .getToken()
      .delete('/files/delete/' + idFile)
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  updateFile(store, aFile) {
    return Vue.prototype.$API
      .getToken()
      .patch('/files/update/' + aFile.id, aFile)
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
}

// Export
export default {
  state,
  getters,
  mutations,
  actions,
}
