import Vue from 'vue'

// State
const state = {
  globalSettings: {
    maintenanceMode: Boolean,
    enableRegister: Boolean,
  },
  cookieTimeToDie: 60*60*12,
  cookieResetTimeToDie: 60*60*2,
  loading: true,
}

// Getters
const getters = {
  getGlobalSettings(state) {
    return state.globalSettings
  },
  getLoading(state) {
    return state.loading
  },
  getCookieLoginTime(state) {
    return state.cookieTimeToDie
  },
  getCookieResetTime(state) {
    return state.cookieResetTimeToDie
  },
}

// Mutations
const mutations = {
  SET_GLOBAL_SETTINGS(state, newSettings) {
    state.globalSettings = newSettings
  },
  SET_LOADING(state, newLoading) {
    state.loading = newLoading
  },
}

// Actions
const actions = {
  // Global Settings
  setGlobalSettings(store, newSettings) {
    this.commit('SET_GLOBAL_SETTINGS', newSettings)
  },
  setLoading(store, newLoading) {
    this.commit('SET_LOADING', newLoading)
  },
  checkApiStatus(store) {
    return Vue.prototype.$API
      .withoutToken()
      .get('/core/status')
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  }
}

// Export
export default {
  state,
  getters,
  mutations,
  actions,
}
