<template>
  <v-container id="home" fluid>
    <ScrollBtn v-if="fp && !isMobile" :is-last="isLast" :fp="fp" />

    <!-- fullpage -->
    <full-page id="fullpage" ref="fullpage" :options="fpOpt">
      <div
        id="container-slider-home"
        class="section"
        data-tooltip="Diaporama d'accueil"
      >
        <SliderHome />
      </div>
      <div
        id="container-powerfull-message"
        class="section"
        data-tooltip="Message fort"
      >
        <MessageFort />
      </div>
      <div
        id="container-testimonials"
        class="section"
        data-tooltip="Témoignages"
      >
        <Temoignages />
      </div>
      <FseHome />
      <!-- Footer fullpage -->
      <Footer />
    </full-page>
  </v-container>
</template>

<script>
import SliderHome from '@components/HomeSlider.vue'
import MessageFort from '@components/HomePowerfullMessage.vue'
import Temoignages from '@components/HomeTestimonials.vue'
import FseHome from '@components/FseHome.vue'
import Footer from '@components/Footer.vue'
import ScrollBtn from '@components/ScrollBtn.vue'
import { mapActions } from 'vuex'

export default {
  name: 'Home',
  metaInfo() {
    return {
      title: 'Accueil',
      meta: [{ name: 'description', content: 'L\'entrepreneur de demain se construit aujourd\'hui.' }],
    }
  },
  components: {
    ScrollBtn,
    SliderHome,
    MessageFort,
    Temoignages,
    FseHome,
    Footer,
  },
  data() {
    return {
      fp: null,
      isLast: false,
      fpOpt: {
        licenseKey: '0AF69EB3-BBC24272-B09D04A0-C7C9A161',
        // Navigation fullpage
        navigation: true,
        controlArrows: false,
        // Design fullpage
        responsiveWidth: this.$vuetify.breakpoint.thresholds.sm,
        // Scrolling fullpage
        css3: true,
        easingcss3: 'cubic-bezier(0.75, 0, 0.25, 1)',
        scrollingSpeed: 700,
        loopHorizontal: true,
        // Accessibility fullpage
        keyboardScrolling: true,
        recordHistory: false,
        scrollOverflow: !this.$vuetify.breakpoint.mobile,
        // Events fullpage
        onLeave: function(origin, destination, direction) {
          this.isLast = destination.isLast
          this.setNewScrollPos(destination.index)
        }.bind(this),
      },
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
  mounted() {
    // Store fullpage into data return
    if (this.$refs && this.$refs.fullpage) {
      // Get FP
      this.fp = this.$refs.fullpage
      this.setActualPage('home')
      this.setNewScrollPos(0)
    }

    // Disable Loading
    this.setLoading(false)
  },
  methods: {
    ...mapActions(['setNewScrollPos', 'setActualPage', 'setLoading']),
  },
}
</script>
