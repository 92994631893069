<template>
  <b-container fluid>
    <h1 class="hidden">Sitemap</h1>

    <!-- Title -->
    <div class="sitemap-title">
      Sitemap du site Explorys
    </div>

    <!-- Embed Raw XML -->
    <div class="sitemap">
      <pre>{{ rawXml }}</pre>
    </div>
  </b-container>
</template>

<script>
import { mapActions } from 'vuex'
import sitemapXml from '@public/sitemap.xml'

export default {
  name: 'Sitemap',
  metaInfo() {
    return {
      title: 'SiteMap',
      // meta: [{ name: 'description', content: '' }],
    }
  },
  data() {
    return {
      rawXml: sitemapXml,
    }
  },
  mounted() {
    // Disable Loading
    this.setLoading(false)
  },
  methods: {
    ...mapActions(['setLoading'])
  }
}
</script>
