<template>
  <v-container id="bloc-powerfull-message" fluid>
    <v-row>
      <v-col cols="12" md="6" class="pm-image">
        <v-img
          class="shadow-yellow"
          :src="require('@images/home_powerfull_message.jpg')"
        />
      </v-col>
      <v-col cols="12" md="6" class="pm-content">
        <h2 class="my-5">
          EXPLORYS,<br />
          L’INCUBATEUR DES PME ET DES INDÉPENDANTS !
        </h2>
        <div class="pad-right">
          <p class="pm-description">
            Que vous soyez comptable, prof de yoga, informaticien, consultant ou
            artisan, il y a forcément un programme pour vous chez Explorys !
          </p>
          <p class="pm-description">
            Car au-delà de votre projet, c’est l’humain qui est au coeur de
            notre incubateur.
          </p>
          <h3>
            Faisons de grandes choses ensemble !
          </h3>
          <v-btn tile elevation="0" class="pm-btn" @click="goToWhyExplorys">
            <v-icon class="pr-2">fas fa-angle-double-right</v-icon>
            Pourquoi Explorys ?
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'MessageFort',
  data() {
    return {}
  },
  methods: {
    goToWhyExplorys() {
      this.$router.push({ name: 'WhoWeAre' })
    },
  },
}
</script>
