<template>
  <v-container id="content-crash-test" class="p-0">
    <v-row v-if="!isMobile" id="top-container">
      <v-col cols="12" text-align="center">
        <h2>LE CRASH-TEST</h2>
        <br />
        <h3>Vous avez envie de créer une entreprise ? </h3>
        <br />
        <p id="description">
          Un accompagnement pour les (futurs) entrepreneurs qui souhaitent
          tester la viabilité de leur projet de création d’entreprise.
        </p>
      </v-col>
    </v-row>
    <v-row v-if="isMobile" id="top-container-mobile" class="justify-center">
      <v-col cols="12">
        <!-- <img :src="require('@images/illustration_crash_test_mobile.jpg')" /> -->
        <h2>LE CRASH-TEST</h2>
        <span id="description">
          <h3>Vous avez envie de créer une entreprise ? </h3>
          Un accompagnement pour les (futurs) entrepreneurs qui souhaitent
          tester la viabilité de leur projet de création d’entreprise.
          <br />
          <br />
          L'opération "Tester pour mieux créer" est cofinancée par l’Union
          européenne dans le cadre du Fonds Social Européen (FSE).
        </span>
      </v-col>
    </v-row>
    <v-row id="list-container">
      <v-col id="list" cols="12" md="9" lg="6">
        <v-row justify-lg="end">
          <v-col cols="12" lg="auto" class="item"
            ><span class="number">01.</span> Un <b>statut</b> optimisé pour
            facturer : Le contrat CAPE</v-col
          >
        </v-row>
        <v-row justify-lg="end">
          <v-col cols="12" lg="auto" class="item"
            ><span class="number">02.</span> Un <b>consultant</b> pour
            m’accompagner</v-col
          >
        </v-row>
        <v-row justify-lg="end">
          <v-col cols="12" lg="auto" class="item"
            ><span class="number">03.</span> Des
            <b>formations</b> spécialisées</v-col
          >
        </v-row>
        <v-row justify-lg="end">
          <v-col cols="12" lg="auto" class="item"
            ><span class="number">04.</span> Une <b>méthode</b> éprouvée et
            validée</v-col
          >
        </v-row>
        <v-row justify-lg="end">
          <v-col cols="12" lg="auto" class="item"
            ><span class="number">05.</span> Une <b>équipe</b>
            pour la comptabilité, le juridique et le financier</v-col
          >
        </v-row>
        <v-row justify-lg="end">
          <v-col cols="12" lg="auto" class="item"
            ><span class="number">06.</span> Des interventions d’<b
              >experts</b
            ></v-col
          >
        </v-row>
        <v-row justify-lg="end">
          <v-col cols="12" lg="auto" class="item">
            <span class="number">07.</span> Un <b>réseau</b> d’explorateurs
          </v-col>
        </v-row>
        <v-row justify-lg="end">
          <v-col cols="12" lg="auto" class="item"
            ><span class="number">08.</span> Un <b>lieu</b> pour
            échanger</v-col
          >
        </v-row>
        <v-row justify-lg="end">
          <v-col cols="12" lg="auto" class="item"
            ><span class="number">09.</span> Une <b>plateforme</b> interactive dédiée</v-col
          >
        </v-row>
      </v-col>
      <v-col v-if="!isMobile" id="picture" cols="12" md="3" lg="6">
        <div class="opacity-black">
          <span id="picture-text">POURQUOI<br />LE CRASH-TEST ?</span>
        </div>
      </v-col>
    </v-row>

    <v-row id="timeline-title" class="px-10 px-sm-4 justify-center">
      <v-col cols="12" lg="12" class="chevron-left">
        &laquo;
      </v-col>
      <v-col cols="12" lg="12">
        <span id="slogan">
          Le Crash-test vous assure <b>sérénité et sécurité </b>
          dans votre parcours entrepreneurial.
        </span>
      </v-col>
      <v-col cols="12" lg="12" class="chevron-right">
        &raquo;
      </v-col>
    </v-row>
    <v-row justify="center" class="my-8">
      <v-col id="method-title" cols="12" lg="auto" sm="auto">
        <h3>La méthode d'Explorys</h3>
      </v-col>
    </v-row>
    <v-row v-if="!isMobile" id="timeline-blocs-top">
      <v-col cols="12" lg="2" offset-lg="1" class="timeline-bloc">
        <span class="tl-number">01.</span
        ><span class="tl-title">Formaliser</span>
        <span class="tl-list">Produit/Service<br />Clientèle cible</span>
      </v-col>
      <v-col cols="12" lg="2" offset-lg="2" class="timeline-bloc">
        <span class="tl-number">03.</span><span class="tl-title">Valider</span>
        <span class="tl-list">Business model<br />Identité de marque</span>
      </v-col>
      <v-col cols="12" lg="2" offset-lg="2" class="timeline-bloc">
        <span class="tl-number">05.</span>
        <span class="tl-title">Concrétiser</span>
        <span class="tl-list">
          Prévisions financières<br />
          Statut juridique<br />
          Immatriculation
        </span>
      </v-col>
    </v-row>
    <v-row v-if="!isMobile">
      <v-col id="timeline-line" cols="12" lg="12"> </v-col>
    </v-row>
    <v-row v-if="!isMobile" id="timeline-blocs-bottom">
      <v-col cols="12" lg="2" offset-lg="3" class="timeline-bloc">
        <span class="tl-list">Retour clients<br />Analyse des ventes</span>
        <span class="tl-number">02.</span><span class="tl-title">Tester</span>
      </v-col>
      <v-col cols="12" lg="2" offset-lg="2" class="timeline-bloc">
        <span class="tl-list">Développement commercial<br />Communication</span>
        <span class="tl-number">04.</span
        ><span class="tl-title">Dupliquer</span>
      </v-col>
    </v-row>
    <v-row v-if="isMobile">
      <v-col id="timeline-blocs-left" cols="6">
        <v-row class="timeline-bloc">
          <v-col cols="auto" class="tl-title">
            <span class="tl-number">01.</span>Formaliser
          </v-col>
          <v-col class="tl-hline"></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="tl-list">
            <span> Produit/Service<br />Clientèle cible </span>
          </v-col>
        </v-row>
        <v-row class="timeline-bloc">
          <v-col cols="auto" class="tl-title">
            <span class="tl-number">03.</span>Valider
          </v-col>
          <v-col class="tl-hline"></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="tl-list">
            <span> Business model<br />Identité de marque </span>
          </v-col>
        </v-row>
        <v-row class="timeline-bloc">
          <v-col cols="auto" class="tl-title">
            <span class="tl-number">05.</span>Concrétiser
          </v-col>
          <v-col class="tl-hline"></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="tl-list">
            <span>
              Prévisions financières<br />
              Statut juridique<br />
              Immatriculation
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col id="timeline-blocs-right" cols="6">
        <v-row class="timeline-bloc">
          <v-col class="tl-hline"></v-col>
          <v-col cols="auto" class="tl-title">
            <span class="tl-number">02.</span>Tester
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="tl-list">
            <span>Retour clients<br />Analyse des ventes</span>
          </v-col>
        </v-row>
        <v-row class="timeline-bloc">
          <v-col class="tl-hline"></v-col>
          <v-col cols="auto" class="tl-title">
            <span class="tl-number">04.</span>Dupliquer
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="tl-list">
            <span>Développement commercial<br />Communication</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <router-link to="/contact">
      <v-btn id="contact-us-btn" tile small elevation="0">
        >> Contactez-nous
      </v-btn>
    </router-link>
  </v-container>
</template>

<script>
export default {
  name: 'CrashTest',
  data() {
    return {}
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
}
</script>
