<template>
  <div>
    <v-card id="form-experts">
      <v-card-title v-if="!expertid" dark class="panel-title">
        Ajouter un(e) expert(e)
      </v-card-title>
      <v-card-title v-if="expertid" dark class="panel-title">
        Modifier l'expert(e) :
        {{
          currentExpert
            ? currentExpert.firstname + ' ' + currentExpert.lastname
            : ''
        }}
      </v-card-title>

      <v-spacer></v-spacer>

      <v-skeleton-loader
        v-if="loading"
        id="loader-form"
        :loading="loading"
        type="image"
      ></v-skeleton-loader>
      <v-card-text v-show="!loading">
        <v-form ref="formFile" v-model="valid">
          <v-row justify="space-around">
            <v-col cols="5">
              <v-text-field
                v-model="currentExpert.firstname"
                :rules="expertRules.firstname"
                label="Prénom"
                required
              >
              </v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="currentExpert.lastname"
                :rules="expertRules.lastname"
                label="Nom"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row justify="space-around">
            <v-col cols="5">
              <v-text-field
                v-model="currentExpert.position"
                :rules="expertRules.position"
                label="Poste occupé"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="currentExpert.link_linkedin"
                label="Liens réseaux sociaux"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row justify="space-around">
            <v-col cols="5">
              <v-text-field
                v-model="currentExpert.email"
                :rules="expertRules.email"
                label="E-mail"
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="currentExpert.phone"
                label="Téléphone"
                counter="10"
                @keydown="$utils.formatPhoneInput($event)"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row justify="space-around">
            <v-col cols="11">
              <v-text-field
                v-model="currentExpert.description"
                :rules="expertRules.description"
                label="Description"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row justify="space-around">
            <v-col cols="5">
              <v-img
                v-if="currentExpert.id_photo === 0 || !currentExpert.id_photo"
                id="photo"
                :src="require('@images/avatar_defaut.png')"
                contain
                max-width="500"
                max-height="150"
              />
              <v-img
                v-else
                id="photo"
                :src="currentExpert.photo_data"
                contain
                max-width="500"
                max-height="150"
              />
            </v-col>
            <v-col cols="5">
              <VueDropzone
                id="dropzoneFile"
                ref="dropzoneFile"
                :options="dropzoneOptions"
                @vdropzone-file-added="checkAddedFile"
              />
              <v-btn
                color="red"
                dark
                small
                class="dropzone-trash-btn"
                @click="removeFiles"
              >
                <v-icon small>fas fa-trash-alt</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-spacer></v-spacer>

      <v-card-actions class="justify-end">
        <v-btn
          :disabled="!valid && !loading"
          color="success"
          class="mr-4"
          :loading="sendLoading"
          @click="validate"
        >
          Enregistrer
        </v-btn>
        <!-- <v-btn
          :disabled="!valid && !loading"
          color="success"
          class="mr-4"
          @click="validate"
        >
          Enreg. et + utilisateurs
        </v-btn> -->

        <v-btn
          color="secondary"
          class="mr-4"
          :loading="sendLoading"
          @click="closePanel"
        >
          Annuler
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
/* global _ */
import VueDropzone from 'vue2-dropzone'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CreateEditExperts',
  components: {
    VueDropzone,
  },
  props: {
    // if expertid is set, that's an update else it's a creation
    expertid: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      sendLoading: false,
      currentExpert: {
        firstname: '',
        lastname: '',
        position: '',
        email: '',
        phone: '',
        link_linkedin: '',
        description: '',
        id_photo: 0,
      },
      expertRules: {
        firstname: [(v) => !!v || 'Prénom requis'],
        lastname: [(v) => !!v || 'Nom requis'],
        position: [(v) => !!v || 'Poste requis'],
        description: [(v) => !!v || 'Description requise'],
        email: [
          (v) =>
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
              v
            ) || 'Email non valide',
        ],
      },
      dropzoneOptions: {
        maxFiles: 1,
        autoProcessQueue: false,
        url: function() {},
        thumbnailWidth: 150,
        parallelUploads: 1,
        dictDefaultMessage:
          "<i class='fa fa-cloud-upload'></i>Cliquez ou déposez un fichier ici",
      },
      valid: true,
      loading: true,
      fileIdToDelete: 0,
    }
  },
  computed: {
    ...mapGetters([]),
  },
  mounted() {
    // Change default value of fields if it's an update
    if (this.expertid) {
      this.fetchExpertById(this.expertid).then((expert) => {
        this.currentExpert = _.clone(expert, true)
        this.loading = false
      })
    } else {
      this.loading = false
    }
  },
  methods: {
    ...mapActions([
      'togglePanel',
      'createExpert',
      'fetchExpertById',
      'updateExpert',
      'setReloadingNeed',
      'saveFile',
      'deleteFile',
    ]),
    removeFiles() {
      this.$refs.dropzoneFile.removeAllFiles()
    },
    validate() {
      const acceptedFiles = this.$refs.dropzoneFile.getAcceptedFiles()
      // Expert request with new file
      if (acceptedFiles.length >= 1) {
        this.$utils
          .toBase64(acceptedFiles[0])
          .then((response) => {
            // set file type from file name
            var fileType = acceptedFiles[0].name.split('.')
            fileType = fileType[fileType.length - 1]

            const fileToSave = {
              name: acceptedFiles[0].name,
              title:
                'Photo Expert ' +
                this.currentExpert.firstname +
                ' ' +
                this.currentExpert.lastname,
              description:
                'Photo Expert ' +
                this.currentExpert.firstname +
                ' ' +
                this.currentExpert.lastname +
                ' sets automatically from expert form',
              folder: 'INTERNAL',
              type: fileType,
              data: response,
            }
            this.saveFile(fileToSave)
              .then((response) => {
                this.$awn.success('Fichier enregistré avec succès')

                // Set old file to delete if needed
                if (this.currentExpert.id_photo !== 0) {
                  this.fileIdToDelete = this.currentExpert.id_photo
                }

                this.currentExpert.id_photo = response.data.id
                this.sendExpertRequest()
              })
              .catch((e) => {
                this.$awn.alert("ERREUR lors de l'enregistrement du fichier")
              })
          })
          .catch((e) => {
            this.$awn.alert('ERREUR lors de la lecture du fichier')
          })
      }
      // Expert request without new file
      else {
        this.sendExpertRequest()
      }
    },
    sendExpertRequest() {
      // Trigger loading
      this.sendLoading = true

      // UPADATE case
      if (this.expertid) {
        this.currentExpert.id = this.expertid
        this.updateExpert(this.currentExpert)
          .then((response) => {
            this.$awn.success(
              "L'expert <b>" +
                this.currentExpert.firstname +
                ' ' +
                this.currentExpert.lastname +
                '</b> a été modifiée'
            )
            // Delete old File if needed
            if (this.fileIdToDelete !== 0) {
              this.deleteFile(this.fileIdToDelete)
            }
            this.closePanel()
          })
          .catch((e) => {
            this.$awn.alert(
              "ERREUR lors de la demande de modification de l'expert <b>" +
                this.currentExpert.firstname +
                ' ' +
                this.currentExpert.lastname +
                '</b>'
            )
            // Disable loading
            this.sendLoading = false
          })
      }
      // POST case
      else {
        this.createExpert(this.currentExpert)
          .then((response) => {
            this.$awn.success(
              "L'expert <b>" +
                this.currentExpert.firstname +
                ' ' +
                this.currentExpert.lastname +
                '</b> a été créé avec succès'
            )
            this.closePanel()
          })
          .catch((e) => {
            this.$awn.alert("ERREUR lors de la demande de création d'expert")
            // Disable loading
            this.sendLoading = false
          })
      }
    },
    closePanel() {
      this.fileIdToDelete = 0
      this.togglePanel()
      this.setReloadingNeed(true)
      // Disable loading
      this.sendLoading = false
    },
    checkAddedFile(File) {
      // event addedFile watcher on dropzone to check file added
      if (File.size >= 5000000) {
        this.$refs.dropzoneFile.removeFile(File)
        this.$awn.warning('Le fichier doit faire moins de 5Mo')
      }
      if (
        File.type !== 'image/png' &&
        File.type !== 'image/jpg' &&
        File.type !== 'image/jpeg'
      ) {
        this.$refs.dropzoneFile.removeFile(File)
        this.$awn.warning('Le fichier doit être une image (format PNG ou JPEG)')
      }
      if (this.$refs.dropzoneFile.getAcceptedFiles().length === 1) {
        this.$refs.dropzoneFile.removeFile(File)
        this.$awn.warning('Une seule image autorisée')
      }
    },
  },
}
</script>
