<template>
  <v-row justify="center">
    <v-col id="why" cols="12" lg="10">
      <v-row>
        <v-col cols="12" lg="6" :class="isMobile ? 'pa-0' : 'pt-5'">
          <!-- <video id="movie-why" autoplay muted loop data-autoplay playsinline>
            <source
              :src="require('@movies/photos_teams_explorys.mp4')"
              type="video/mp4"
            />
          </video> -->
          <v-img :src="require('@images/explorys_team_2023.gif')" alt="GIF de l'équipe Explorys 2023" />
        </v-col>
        <v-col id="txt-why" cols="12" lg="6">
          <h2>POURQUOI EXPLORYS ?</h2>
          <p>
            L’incubateur EXPLORYS est issu du Groupe ALERYS, acteur majeur du
            conseil en ressources humaines depuis plus de 25 ans.
          </p>
          <p v-if="!isMobile">
            Pour nous, les entrepreneurs indépendants et les Petites et Moyennes Entreprises qui représentent 90 % des entreprises françaises sont « le poumon » de notre économie.
          </p>
          <p id="our-mission">
            <b>Notre mission :</b> Permettre à chacun de créer une entreprise viable et rentable.
          </p>
          <p id="our-mission">
            <b>Comment ?</b> 
            En mettant au service des indépendants et des <abbr title="Petites et Moyennes Entreprises">PME</abbr> 
            les méthodes d’accompagnement des startups.
          </p>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="12" md="6" lg="8">
          <v-row id="e-boxes-container" class="justify-sm-end">
            <WhoWeAreEBox
              v-for="box in eBoxes"
              :key="box.id"
              :box="box"
              :name="box.title"
            />
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="4">
          <v-row id="lines-container">
            <v-col id="line-1" cols="12" sm="4" md="12" lg="12">
              <span class="number-stat">300</span> ENTREPRENEURS
            </v-col>
            <v-col id="line-2" cols="12" sm="4" md="12" lg="12">
              <span class="number-stat">95%</span> DE VIABILITÉ
            </v-col>
            <v-col id="line-3" cols="12" sm="4" md="12" lg="12">
              <span class="number-stat">99%</span> DE SATISFACTION
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import WhoWeAreEBox from '@components/WhoWeAreEBox.vue'

export default {
  name: 'WhyExplorys',
  components: {
    WhoWeAreEBox,
  },
  data() {
    return {
      eBoxes: [
        {
          id: 1,
          title: 'ENGAGEMENT',
          baseline: 'The win win theory',
          list: ['Gagnant/Gagnant', 'Partenariat', 'Transparence'],
        },
        {
          id: 2,
          title: 'ÉNERGIE',
          baseline: "Apprendre par l'expérience",
          list: ['Test', 'Agilité', 'Motivation'],
        },
        {
          id: 3,
          title: 'EFFICACITÉ',
          baseline: 'Seuls les résultats comptent',
          list: ['Méthode', 'Pragmatisme', 'Résultats'],
        },
        {
          id: 4,
          title: 'ÉCOSYSTEME',
          baseline: 'Ensemble on va plus loin',
          list: ['Explorateurs', 'Experts', 'Réseau'],
        },
      ],
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
}
</script>
