import Vue from 'vue'
import Vuex from 'vuex'

/* Store Modules */
import moduleScroll from './modules/scroll'
import moduleAuth from './modules/auth'
import moduleGeneral from './modules/general'
import moduleFiles from './modules/files'
import moduleOrganisations from './modules/organisations'
import modulePartners from './modules/partners'
import modulePanels from './modules/panels'
import moduleUsers from './modules/users'
import moduleEvents from './modules/events'
import modulePrograms from './modules/programs'
import moduleActivities from './modules/activities'
import modulePlaces from './modules/places'
import moduleExperts from './modules/experts'
import modulePosts from './modules/posts'
import moduleFirstConnexion from './modules/first-connexion'
import moduleCookiesPref from './modules/cookies-pref'

Vue.use(Vuex)

/* new Store */
const store = new Vuex.Store({
  modules: {
    moduleAuth,
    moduleScroll,
    moduleGeneral,
    moduleFiles,
    moduleOrganisations,
    modulePartners,
    modulePanels,
    moduleUsers,
    moduleEvents,
    modulePrograms,
    moduleActivities,
    modulePlaces,
    moduleExperts,
    modulePosts,
    moduleFirstConnexion,
    moduleCookiesPref,
  },
  strict: true,
})

export default {
  store,
  install(Vue, options) {
    Vue.prototype.$store = store
  },
}
