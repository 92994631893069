// State
const state = {
  listPanels: [],
  // jb...
  panelDisplayed: false,
  tableNeedsReloading: false,
  panelForm: '',
  panelItemId: 0,
}

// Getters
const getters = {
  getListPanels(state) {
    return state.listPanels
  },
  getFilesPanel(state) {
    // Check si il y a un moins un element dans le tableau
    if (state.listPanels.length) {
      // Check si filesPanel est bien présent dans les panels à afficher
      if (state.listPanels.find((i) => i === 'filesPanel')) {
        return true
      }
    } else {
      return false
    }
  },
  getEventsPanel(state) {
    // Check si il y a un moins un element dans le tableau
    if (state.listPanels.length) {
      // Check si filesPanel est bien présent dans les panels à afficher
      if (state.listPanels.find((i) => i === 'eventsPanel')) {
        return true
      }
    } else {
      return false
    }
  },
  // jb...
  isPanelDisplayed(state) {
    return state.panelDisplayed
  },
  isTableNeedsReloading(state) {
    return state.tableNeedsReloading
  },
  getPanelForm(state) {
    return state.panelForm
  },
  getPanelItemId(state) {
    return state.panelItemId
  },
}

// Mutations
const mutations = {
  SET_LIST_PANELS(state, panels) {
    state.listPanels = panels
  },
  ADD_PANEL(state, panelToAdd) {
    state.listPanels.push(panelToAdd)
  },
  REMOVE_PANEL(state, panelToRemove) {
    const indexToRemove = state.listPanels.indexOf(panelToRemove)
    state.listPanels.splice(indexToRemove, 1)
  },
  // jb ...
  TOGGLE_PANEL(state) {
    if (state.panelDisplayed) {
      state.panelDisplayed = false
    } else {
      state.panelDisplayed = true
    }
  },
  HIDE_PANEL(state) {
    state.panelDisplayed = false
  },
  SET_RELOADING_NEED(state, needState) {
    state.tableNeedsReloading = needState
  },
  SET_PANEL_FORM(state, newForm) {
    state.panelForm = newForm
  },
  SET_PANEL_ITEM_ID(state, newId) {
    state.panelItemId = newId
  },
}

// Actions
const actions = {
  setPanels(store, panels) {
    this.commit('SET_LIST_PANELS', panels)
  },
  showPanel(store, panelToAdd) {
    this.commit('ADD_PANEL', panelToAdd)
  },
  // hidePanel(store, panelToRemove) {
  //   this.commit('REMOVE_PANEL', panelToRemove)
  // },
  // jb...
  togglePanel(store) {
    this.commit('TOGGLE_PANEL')
  },
  hidePanel(store) {
    this.commit('HIDE_PANEL')
  },
  setReloadingNeed(store, newNeed) {
    this.commit('SET_RELOADING_NEED', newNeed)
  },
  setPanelForm(store, newForm) {
    this.commit('SET_PANEL_FORM', newForm)
  },
  clearPanelForm(store) {
    this.commit('SET_PANEL_FORM', '')
  },
  setPanelItemId(store, newId) {
    this.commit('SET_PANEL_ITEM_ID', newId)
  },
  clearPanelItemId(store) {
    this.commit('SET_PANEL_ITEM_ID', 0)
  },
}

// Export
export default {
  state,
  getters,
  mutations,
  actions,
}
