import Vue from 'vue'
import Router from 'vue-router'

// Themes
import ThemeVitrine from '@containers/ThemeVitrine.vue'
import ThemeExtranet from '@containers/ThemeExtranet.vue'
// Global
import Page404 from '@containers/404.vue'
import Page500 from '@containers/500.vue'
// Vitrine
import Home from '@containers/Home.vue'
import WhoWeAre from '@containers/WhoWeAre.vue'
import Expertise from '@containers/Expertise.vue'
import ExplorersList from '@containers/ExplorersList.vue'
import Contact from '@containers/Contact.vue'
import MentionsLegales from '@containers/MentionsLegales.vue'
import Sitemap from '@containers/Sitemap.vue'
// @TODO: move to /extranet
import ResetPassword from '@static/Reset.vue'
import FirstConnexion from '@static/FirstConnexion.vue'
import ExplorateurLogo from '@static/ExplorateurLogo.vue'
// Extranet
import Dashboard from '@containers/Dashboard.vue'
import Agenda from '@containers/Agenda.vue'
import SocialNetwork from '@containers/SocialNetwork.vue'
import FilesManagement from '@containers/FilesManagement.vue'
import PartnersManagement from '@containers/PartnersManagement.vue'
import ExpertsManagement from '@containers/ExpertsManagement.vue'
import PlacesManagement from '@containers/PlacesManagement.vue'
import EventsConfirmation from '@containers/EventsConfirmation.vue'
import UsersManagement from '@containers/UsersManagement.vue'
import OrganisationsManagement from '@containers/OrganisationsManagement.vue'
import PageCGU from '@containers/PageCGU.vue'


Vue.use(Router)

var isMobile = true

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: ThemeVitrine,
      children: [
        {
          path: '',
          name: 'Home',
          component: Home,
        },
        {
          path: 'qui-sommes-nous',
          name: 'WhoWeAre',
          component: WhoWeAre,
        },
        {
          path: 'nos-programmes',
          name: 'Expertise',
          component: Expertise,
        },
        {
          path: 'nos-explorateurs',
          name: 'ExplorersList',
          component: ExplorersList,
        },
        {
          path: 'explorateur/:id_organisation',
          name: 'Explorer',
          component: SocialNetwork,
          props: true,
        },
        {
          path: 'contact',
          name: 'Contact',
          component: Contact,
        },
        {
          path: 'reset',
          name: 'Reset',
          component: ResetPassword,
          props: true,
        },
        {
          path: 'premiers-pas',
          name: 'PremiersPas',
          component: FirstConnexion,
          props: true,
        },
        {
          path: 'mentions-legales',
          name: 'MentionLegales',
          component: MentionsLegales,
        },
        {
          path: '404',
          name: '404',
          component: Page404,
          // Allows props to be passed to the 404 page through route
          // params, such as `resource` to define what wasn't found.
          props: true,
        },
        {
          path: '500',
          name: '500',
          component: Page500,
        },
      ],
    },
    {
      path: '/extranet/',
      component: ThemeExtranet,
      meta: {
        roles: ['USER', 'ADMIN', 'SUPERADMIN'],
      },
      children: [
        {
          path: '',
          name: 'Dashboard',
          component: Dashboard,
          meta: {
            roles: ['USER', 'ADMIN', 'SUPERADMIN'],
          },
        },
        {
          path: 'nos-explorateurs',
          name: 'ExplorersListExtranet',
          component: ExplorersList,
          meta: {
            roles: ['USER', 'ADMIN', 'SUPERADMIN'],
          },
        },
        {
          path: 'gestion-partenaires',
          name: 'PartnersManagement',
          component: PartnersManagement,
          meta: {
            roles: ['ADMIN', 'SUPERADMIN'],
          },
        },
        {
          path: 'gestion-equipe',
          name: 'ExpertsManagement',
          component: ExpertsManagement,
          meta: {
            roles: ['ADMIN', 'SUPERADMIN'],
          },
        },
        {
          path: 'gestion-villes',
          name: 'PlacesManagement',
          component: PlacesManagement,
          meta: {
            roles: ['ADMIN', 'SUPERADMIN'],
          },
        },
        {
          path: 'validation-evenements',
          name: 'EventsConfirmation',
          component: EventsConfirmation,
          meta: {
            roles: ['ADMIN', 'SUPERADMIN'],
          },
        },
        {
          path: 'gestion-utilisateurs',
          name: 'UsersManagement',
          component: UsersManagement,
          meta: {
            roles: ['ADMIN', 'SUPERADMIN'],
          },
        },
        {
          path: 'agenda',
          name: 'Agenda',
          component: Agenda,
          meta: {
            roles: ['USER', 'ADMIN', 'SUPERADMIN'],
          },
        },
        {
          path: 'votre-page',
          name: 'SocialNetwork',
          component: isMobile ? SocialNetwork : SocialNetwork,
          meta: {
            roles: ['USER', 'ADMIN', 'SUPERADMIN'],
          },
        },
        {
          path: 'explorateur/:id_organisation',
          name: 'ExplorerExtranet',
          component: SocialNetwork,
          props: true,
          meta: {
            roles: ['USER', 'ADMIN', 'SUPERADMIN'],
          },
        },
        {
          path: 'gestion-fichiers',
          name: 'FilesManagement',
          component: FilesManagement,
          meta: {
            roles: ['USER', 'ADMIN', 'SUPERADMIN'],
          },
        },
        {
          path: 'gestion-organisations',
          name: 'OrganisationsManagement',
          component: OrganisationsManagement,
          meta: {
            roles: ['USER', 'ADMIN', 'SUPERADMIN'],
          },
        },
        {
          path: 'cgu',
          name: 'PageCGU',
          component: PageCGU,
        },
      ],
    },
    {
      path: '/logo/:id_logo',
      name: 'ExplorateurLogo',
      component: ExplorateurLogo,
      props: true,
    },
    {
      path: '/sitemap',
      name: 'Sitemap',
      component: Sitemap,
    },
    // Redirect any unmatched routes to the 404 page. This may
    // require some server configuration to work in production:
    // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
    {
      path: '*',
      redirect: '404',
    },
  ],
  scrollBehavior: function(to) {
    if (to.hash) {
      return {
        selector: to.hash,
      }
    }
  },
})

export default router
