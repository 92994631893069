<template>
  <v-container id="events-confirmation" fluid class="p-0">
    <v-row>
      <v-col cols="12" lg="8">
        <h3>Modération des évènements</h3>
      </v-col>
      <v-col cols="12" lg="4" class="text-right">
        <v-btn
          dark
          color="primary"
          @click="$router.push({ name: 'Agenda' })"
        >
          <v-icon class="mr-2">fas fa-calendar-alt</v-icon>
          Aller à l'agenda
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="gotEvents"
      class="elevation-1"
      light
      no-data-text="Aucun évènement disponible"
      :disable-sort="true"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      :loading="isLoading"
      loading-text="Chargement... Veuillez patienter."
      hide-default-footer
    >
      <template v-slot:[`item.start`]="{ item }">
        {{ $utils.formatDateWithHours(item.start) }}
      </template>

      <template v-slot:[`item.end`]="{ item }">
        {{ $utils.formatDateWithHours(item.end) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          v-tippy
          class="fs-16-btn mr-2"
          color="green"
          icon
          small
          dark
          content="Valider"
          @click="openPanelValidateEvent(item.id)"
        >
          <v-icon>fas fa-check</v-icon>
        </v-btn>
        <v-btn
          v-tippy
          class="fs-16-btn"
          color="red"
          icon
          small
          dark
          content='Refuser'
          @click.native="confirmDeleteEvent(item.id, item.name)"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-pagination
      v-model="page"
      :length="pageCount"
      class="mt-2"
    ></v-pagination>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'EventsConfirmation',
  components: {},
  data() {
    return {
      itemId: 0,
      headers: [
        {
          text: 'ID',
          value: 'id',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Date de début',
          value: 'start',
          sortable: false,
          align: 'center',
          width: '200px',
        },
        {
          text: 'Date de fin',
          value: 'end',
          sortable: false,
          align: 'center',
          width: '200px',
        },
        {
          text: 'Titre',
          value: 'name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Nom créateur',
          value: 'lastname',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Prénom du créateur',
          value: 'firstname',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'center',
          width: '120px',
        },
      ],
      isLoading: true,
      searchString: '',
      itemsPerPage: 10,
      page: 1,
    }
  },
  computed: {
    ...mapGetters(['isPanelDisplayed', 'getEvents', 'getEventsCount']),
    gotEvents() {
      return this.getEvents
    },
    pageCount() {
      return Math.ceil(this.getEventsCount / this.itemsPerPage)
    },
  },
  mounted() {
    this.loadList()

    // Disable Loading
    this.setLoading(false)
  },
  methods: {
    ...mapActions([
      'togglePanel',
      'loadListEvents',
      'setPanelForm',
      'setPanelItemId',
      'clearPanelItemId',
      'deleteEvent',
      'setLoading'
    ]),
    loadList() {
      this.loadListEvents('PENDING')
        .then((response) => {
          this.isLoading = false
        })
        .catch((e) => {
          this.isLoading = false
          this.$awn.alert('ERREUR lors du chargement des évènements')
        })
    },
    openPanelValidateEvent(eventId) {
      this.setPanelItemId(eventId)
      this.setPanelForm('events')
      this.togglePanel()
    },
    confirmDeleteEvent(eventId, eventName) {
      const onOk = () => {
        this.deleteEvent(eventId)
          .then((response) => {
            this.loadList()
            this.$awn.success("L'évènement a bien été suppprimé")
          })
          .catch((e) => {
            this.$awn.alert("ERREUR lors de la suppression de l'évènement")
          })
      }
      const onCancel = () => {
      }
      this.$awn.confirm(
        "Souhaitez-vous vraiment supprimer l'évènement <b>" +
          eventName +
          '</b> ?',
        onOk,
        onCancel,
        {
          labels: {
            confirm: "Suppression d'évènement",
          },
        }
      )
    },
  },
}
</script>
