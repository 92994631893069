import Vue from 'vue'

// State
const state = {
  program: {
    id: String,
  },
  programsList: [],
}

// Getters
const getters = {
  getProgram(state) {
    return state.program
  },
  getProgramsList(state) {
    return state.programsList
  },
}

// Mutations
const mutations = {
  SET_PROGRAM(state, program) {
    state.program = program
  },
  SET_PROGRAMS(state, programsList) {
    state.programsList = programsList
  },
  DELETE_PROGRAM_BY_ID(state, idProgram) {
    state.program = state.programsList.filter((n) => n.id !== idProgram)
  },
}

// Actions
const actions = {
  fetchProgramById(store, programId) {
    return Vue.prototype.$API
      .withoutToken()
      .get('/programs/' + programId)
      .then((response) => {
        // Set Program
        this.commit('SET_PROGRAM', response)
        return Promise.resolve(response)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  fetchProgramsList(store, args) {
    return Vue.prototype.$API
      .withoutToken()
      .get('/programs')
      .then((response) => {
        // Set Programs
        var programsList = []
        response.data.forEach((program) => {
          const newProgram = {
            value: program.id,
            text: program.title,
          }
          programsList.push(newProgram)
        })
        // Set Programs
        this.commit('SET_PROGRAMS', programsList)
        return Promise.resolve(programsList)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
}

// Export
export default {
  state,
  getters,
  mutations,
  actions,
}
