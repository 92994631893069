var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"p-0",attrs:{"id":"b-o-users","fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('h3',[_vm._v("Gestion des utilisateurs")])]),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-text-field',{attrs:{"label":"Recherche","prepend-icon":"mdi-magnify","clear-icon":"mdi-close-circle-outline","clearable":"","dense":"","counter":"3"},on:{"click:clear":_vm.clearSearch},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","lg":"4"}},[(_vm.loggedUser && _vm.loggedUser.role === 'SUPERADMIN')?_c('v-btn',{attrs:{"dark":"","color":"primary"},on:{"click":_vm.openPanelCreateUser}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fa fa-plus")]),_vm._v(" Ajouter un(e) utilisateur(trice) ")],1):_vm._e()],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.gotUsersList,"light":"","page":_vm.page,"items-per-page":_vm.itemsPerPage,"loading":_vm.isLoading,"loading-text":"Chargement... Veuillez patienter.","hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.photo",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"36"}},[(item.id_photo)?_c('v-img',{attrs:{"src":item.photo_data}}):_vm._e(),(!item.id_photo)?_c('v-img',{attrs:{"src":require('@images/avatar_defaut.png')}}):_vm._e()],1)]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.$enums.getRoleChipsColor(item.role),"text-color":"white","small":""}},[_vm._v(" "+_vm._s(_vm.$enums.getRoleChipsText(item.role))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.$enums.getStatusChipsColor(item.status),"text-color":"white","small":""}},[_vm._v(" "+_vm._s(_vm.$enums.getStatusChipsText(item.status))+" ")])]}},{key:"item.creation_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.formatDate(item.creation_date))+" ")]}},{key:"item.modification_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.formatDate(item.modification_date))+" ")]}},{key:"item.fullname",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstname + ' ' + item.lastname)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.isSuperAdmin && item.status === 'PENDING')?_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"fs-16-btn mr-2",attrs:{"color":"success","icon":"","small":"","dark":"","loading":_vm.sendLoading,"content":"Renvoyer le mail de bienvenue"},on:{"click":function($event){return _vm.launchWelcome(item.id)}}},[_c('v-icon',[_vm._v(" fas fa-paper-plane ")])],1):_vm._e(),(!_vm.$utils.isExplorys(item.organisation_name))?_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"fs-16-btn mr-2",attrs:{"color":"primary","icon":"","small":"","dark":"","content":"Voir sa vitrine"},on:{"click":function($event){return _vm.redirectOrganisation(item.id_organisation)}}},[_c('v-icon',[_vm._v("fas fa-building")])],1):_vm._e(),(_vm.isSuperAdmin)?_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"fs-16-btn mr-2",attrs:{"color":"primary","icon":"","small":"","dark":"","content":"Modifier l'utilisateur"},on:{"click":function($event){return _vm.openPanelEditUser(item.id)}}},[_c('v-icon',[_vm._v(" fas fa-edit ")])],1):_vm._e(),(_vm.isSuperAdmin)?_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"fs-16-btn",attrs:{"color":"red","icon":"","small":"","light":"","content":"Supprimer l'utilisateur","dark":_vm.loggedUser && !_vm.loggedUser.id === item.id,"disabled":_vm.loggedUser && _vm.loggedUser.id === item.id},nativeOn:{"click":function($event){return _vm.confirmDeleteUser(item.id, item.firstname + ' ' + item.lastname)}}},[_c('v-icon',[_vm._v(" fas fa-trash-alt ")])],1):_vm._e()]}}],null,true)}),_c('v-pagination',{staticClass:"mt-2",attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }