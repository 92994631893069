<template>
  <v-container id="content-explorys-academy" class="p-0" fluid>
    <v-row class="px-10 px-sm-4 justify-center">
      <v-col cols="12" md="10" lg="6" class="content-explorys-academy-header py-0">
        <img
          v-if="isMobile"
          :src="require('@images/illustration_crash_test_mobile.jpg')"
          alt="entête bloc explorys academy"
        />
        <h2>academy</h2>
        <div class="mobile-header">
          <p class="sentence-explorys-academy mb-0">
            Former les entrepreneurs de demain<br>en prenant en compte la spécificité de chacun.
          </p>
        </div>
      </v-col>
    </v-row>

    <v-row class="px-0 pb-4 my-0 row-cards justify-center">
      <v-col cols="8" sm="8" md="6" lg="5" xl="4" class="col-card">
        <div class="card d-flex flex-column justify-space-between">
          <p class="panel">formation 1</p>

          <h4>statuts juridiques</h4>

          <p class="subtitle">3 modules</p>

          <v-row class="steps px-0">
            <!-- step 1 -->
            <v-col cols="12" sm="4" md="5" lg="4" class="step">
              <span class="number">1.</span> Choisir le statut
            </v-col>
            <v-col cols="12" sm="2" md="1" lg="2" class="line-horizontal">
              <div class="line"></div>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" class="empty"> </v-col>
            <!-- step 2 -->
            <v-col cols="12" sm="6" md="6" lg="6" class="empty"> </v-col>
            <v-col cols="12" sm="2" md="2" lg="2" class="line-horizontal">
              <div class="line"></div>
            </v-col>
            <v-col cols="12" sm="4" md="4" lg="4" class="step">
              <span class="number">2.</span> Immatriculer son entreprise
            </v-col>
            <!-- step 3 -->
            <v-col cols="12" sm="4" md="5" lg="4" class="step">
              <span class="number">3.</span> Gérer les formalités quotidiennes
            </v-col>
            <v-col cols="12" sm="2" md="1" lg="2" class="line-horizontal">
              <div class="line"></div>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" class="empty"> </v-col>
          </v-row>
        </div>
      </v-col>

      <v-col cols="8" sm="8" md="6" lg="5" xl="4" class="col-card">
        <div class="card d-flex flex-column justify-space-between">
          <p class="panel">formation 2</p>

          <h4>création d'entreprise</h4>

          <p class="subtitle">6 modules</p>

          <v-row class="steps px-0">
            <!-- step 1 -->
            <v-col cols="12" sm="4" md="5" lg="4" class="step">
              <span class="number">1.</span> Profil entrepreneurial
            </v-col>
            <v-col cols="12" sm="2" md="1" lg="2" class="line-horizontal">
              <div class="line"></div>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" class="empty"> </v-col>
            <!-- step 2 -->
            <v-col cols="12" sm="6" md="6" lg="6" class="empty"> </v-col>
            <v-col cols="12" sm="2" md="2" lg="2" class="line-horizontal">
              <div class="line"></div>
            </v-col>
            <v-col cols="12" sm="4" md="5" lg="4" class="step">
              <span class="number">2.</span> Cohérence entrepreneur / projet
            </v-col>
            <!-- step 3 -->
            <v-col cols="12" sm="4" md="5" lg="4" class="step">
              <span class="number">3.</span> Discours commercial
            </v-col>
            <v-col cols="12" sm="2" md="1" lg="2" class="line-horizontal">
              <div class="line"></div>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" class="empty"> </v-col>
            <!-- step 4 -->
            <v-col cols="12" sm="6" md="6" lg="6" class="empty"> </v-col>
            <v-col cols="12" sm="2" md="1" lg="2" class="line-horizontal">
              <div class="line"></div>
            </v-col>
            <v-col cols="12" sm="4" md="5" lg="4" class="step">
              <span class="number">4.</span> Étude de marché
            </v-col>
            <!-- step 5 -->
            <v-col cols="12" sm="4" md="5" lg="4" class="step">
              <span class="number">5.</span> Prévisionnel financier
            </v-col>
            <v-col cols="12" sm="2" md="1" lg="2" class="line-horizontal">
              <div class="line"></div>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" class="empty"> </v-col>
            <!-- step 6 -->
            <v-col cols="12" sm="6" md="6" lg="6" class="empty"> </v-col>
            <v-col cols="12" sm="2" md="1" lg="2" class="line-horizontal">
              <div class="line"></div>
            </v-col>
            <v-col cols="12" sm="4" md="5" lg="4" class="step">
              <span class="number">6.</span> Statut juridique
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <v-row class="px-0 mb-8 row-cards justify-center align-center">
      <v-col cols="10" sm="12" md="4" lg="3" xl="3" class="logo_qualiopi d-flex justify-center">
        <img :src="require('@images/logo_qualiopi.png')" alt="Logo Qualiopi" />
      </v-col>
      <v-col cols="10" sm="10" md="5" lg="5" xl="5" class="certification_qualiopi">
        <p class="text-left">La certification qualité a été délivrée au titre de la catégorie d'actions suivantes :<br/>
          - Actions de formation aux créateurs et repreneurs d'entreprise
        </p>
      </v-col>
      <v-col cols="10" sm="12" md="3" lg="2" xl="2" class="cpf justify-center">
        <h4 class="mb-4">Éligible au CPF</h4>
        <v-btn color="primary" @click="goToMoncompteFormation">
          Inscrivez-vous
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="px-0 my-4 row-cards justify-center">
      <v-col cols="8" sm="8" md="6" lg="5" xl="4" class="col-card">
        <div class="card d-flex flex-column justify-space-between">
          <p class="panel">formation 3</p>

          <h4>reprise d'entreprise</h4>

          <p class="subtitle">6 modules</p>

          <v-row class="steps px-0">
            <!-- step 1 -->
            <v-col cols="12" sm="4" md="5" lg="4" class="step">
              <span class="number">1.</span> Profil entrepreneurial
            </v-col>
            <v-col cols="12" sm="2" md="1" lg="2" class="line-horizontal">
              <div class="line"></div>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" class="empty"> </v-col>
            <!-- step 2 -->
            <v-col cols="12" sm="6" md="6" lg="6" class="empty"> </v-col>
            <v-col cols="12" sm="2" md="1" lg="2" class="line-horizontal">
              <div class="line"></div>
            </v-col>
            <v-col cols="12" sm="4" md="5" lg="4" class="step">
              <span class="number">2.</span> Cadrage
            </v-col>
            <!-- step 3 -->
            <v-col cols="12" sm="4" md="5" lg="4" class="step">
              <span class="number">3.</span> Recherche de cibles
            </v-col>
            <v-col cols="12" sm="2" md="1" lg="2" class="line-horizontal">
              <div class="line"></div>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" class="empty"> </v-col>
            <!-- step 4 -->
            <v-col cols="12" sm="6" md="6" lg="6" class="empty"> </v-col>
            <v-col cols="12" sm="2" md="1" lg="2" class="line-horizontal">
              <div class="line"></div>
            </v-col>
            <v-col cols="12" sm="4" md="5" lg="4" class="step">
              <span class="number">4.</span> Evalutation et diagnostic
            </v-col>
            <!-- step 5 -->
            <v-col cols="12" sm="4" md="5" lg="4" class="step">
              <span class="number">5.</span> Plan de reprise
            </v-col>
            <v-col cols="12" sm="2" md="1" lg="2" class="line-horizontal">
              <div class="line"></div>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" class="empty"> </v-col>
            <!-- step 6 -->
            <v-col cols="12" sm="6" md="6" lg="6" class="empty"> </v-col>
            <v-col cols="12" sm="2" md="1" lg="2" class="line-horizontal">
              <div class="line"></div>
            </v-col>
            <v-col cols="12" sm="4" md="5" lg="4" class="step">
              <span class="number">6.</span> Négociation et conclusion
            </v-col>
          </v-row>
        </div>
      </v-col>

      <v-col cols="8" sm="8" md="6" lg="5" xl="4" class="col-card">
        <div class="card d-flex flex-column justify-space-between">
          <p class="panel">formation 4</p>

          <h4>dirigeant d'entreprise</h4>

          <p class="subtitle">4 modules</p>

          <v-row class="steps px-0">
            <!-- step 1 -->
            <v-col cols="12" sm="4" md="5" lg="4" class="step">
              <span class="number">1.</span> Stratégie
            </v-col>
            <v-col cols="12" sm="2" md="1" lg="2" class="line-horizontal">
              <div class="line"></div>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" class="empty"> </v-col>
            <!-- step 2 -->
            <v-col cols="12" sm="6" md="6" lg="6" class="empty"> </v-col>
            <v-col cols="12" sm="2" md="1" lg="2" class="line-horizontal">
              <div class="line"></div>
            </v-col>
            <v-col cols="12" sm="4" md="5" lg="4" class="step">
              <span class="number">2.</span> Finance
            </v-col>
            <!-- step 3 -->
            <v-col cols="12" sm="4" md="5" lg="4" class="step">
              <span class="number">3.</span> Commercial
            </v-col>
            <v-col cols="12" sm="2" md="1" lg="2" class="line-horizontal">
              <div class="line"></div>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" class="empty"> </v-col>
            <!-- step 4 -->
            <v-col cols="12" sm="6" md="6" lg="6" class="empty"> </v-col>
            <v-col cols="12" sm="2" md="1" lg="2" class="line-horizontal">
              <div class="line"></div>
            </v-col>
            <v-col cols="12" sm="4" md="5" lg="4" class="step">
              <span class="number">4.</span> Communication
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ExplorysAcademy',
  data() {
    return {}
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    isLgOnly() {
      return this.$vuetify.breakpoint.lgOnly
    }
  },
  methods: {
    goToMoncompteFormation() {
      window.open('https://www.moncompteformation.gouv.fr/espace-prive/', '_blank')
    },
  },
}
</script>
