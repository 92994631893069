import Vue from 'vue'

// State
const state = {
  step: 1,
  iduser: null,
  infosUser: {},
  infosOrganisation : {}
}

// Getters
const getters = {
  getStep(state) {
    return state.step
  },
  getIdUser(state) {
    return state.idUser
  },
  getInfosUser(state) {
    return state.infosUser
  },
  getInfosOrganisation(state) {
    return state.infosOrganisation
  },
}

// Mutations
const mutations = {
  SET_STEP(state, newStep) {
    state.step = newStep
  },
  SET_INFOS_USER(state, newInfosUser) {
    state.infosUser = newInfosUser
  },
  SET_ID_USER(state, newIdUser) {
    state.idUser = newIdUser
  },
  SET_INFOS_ORGANISATION(state, newInfosOrganisation) {
    state.infosOrganisation = newInfosOrganisation
  },
}

// Actions
const actions = {
  setIdUser(store, idUser) {
    this.commit('SET_ID_USER', idUser)
  },
  fetchDatasFromDB(store, idUser) {
    return Vue.prototype.$API
      .getToken()
      .get('/users/' + idUser)
      .then((response) => {
        // Set User
        this.commit('SET_INFOS_USER', response.data[0])
        var user = response.data[0]

        if (user.role === 'USER') {
          return Vue.prototype.$API
            .getToken()
            .get('/organisations/' + response.data[0].id_organisation)
            .then((responseOrga) => {
              // Set Organisation
              this.commit('SET_INFOS_ORGANISATION', responseOrga.data[0])
              user.organisation = responseOrga.data[0]
              return Promise.resolve(user)
            })
            .catch((e) => {
              return Promise.reject(e)
            })
        } else {
          return Promise.resolve(user)
        }
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
}


// Export
export default {
  state,
  getters,
  mutations,
  actions,
}
