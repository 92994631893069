<template>
  <v-container id="content-impulse" class="p-0">

    <v-row class="px-0 justify-center">
      <v-col
        cols="12"
        lg="6"
        class="content-impulse-header"
      >
        <img v-if="isMobile" :src="require('@images/illustration_crash_test_mobile.jpg')" alt="entête bloc explorys academy">
        <h2>Impulse&reg;</h2>
        <div class="mobile-header">
          <p class="sentence-impulse">Accompagner les dirigeants de PME à chaque étape de la vie de leur entreprise.</p>
        </div>
      </v-col>
    </v-row>

    <v-row class="row-cards px-0 py-2 justify-center">
      <v-col
        cols="12"
        sm="12"
        md="4"
        lg="4"
        class="col-card px-lg-8"
      >
        <div class="card card-yellow">
          <!-- <p class="tip">X heures <br> individuelles</p> -->

          <h4>croissance</h4>

          <p class="subtitle-question">Votre société grandit rapidement ?</p>

          <p class="subtitle">Notre engagement :</p>

          <p class="subtitle-sentence">Un accompagnement sur mesure pour bien vivre la croissance de votre entreprise et éviter les pièges.</p>

          <ul class="list-engagements">
            <li>
              <span class="number">1.</span>
              Stratégique
            </li>
            <li>
              <span class="number">2.</span>
              Financier
            </li>
            <li>
              <span class="number">3.</span>
              Ressources humaines
            </li>
            <li>
              <span class="number">4.</span>
              Organisationnel
            </li>
            <li>
              <span class="number">5.</span>
              Achat
            </li>
            <li>
              <span class="number">6.</span>
              Marketing & Communication
            </li>
          </ul>
        </div>
      </v-col>

      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="4"
        lg="4"
        class="col-card px-lg-8"
      >
        <div class="card card-blue">

          <h4>rebond</h4>

          <p class="subtitle-question">Votre entreprise est en difficulté ?</p>

          <p class="subtitle">Notre engagement :</p>

          <p class="subtitle-sentence">Un accompagnement à la restructuration de votre entreprise.</p>

          <ul class="list-engagements">
            <li>
              <span class="number">1.</span>
              Financier
            </li>
            <li>
              <span class="number">2.</span>
              Stratégique
            </li>
            <li>
              <span class="number">3.</span>
              Organisationnel
            </li>
            <li>
              <span class="number">4.</span>
              Marketing et Commercial
            </li>
          </ul>
        </div>
      </v-col>

      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="4"
        lg="4"
        class="col-card px-lg-8"
      >
        <div class="card card-yellow">

          <h4>transition</h4>

          <p class="subtitle-question">Vous souhaitez céder votre entreprise ?</p>

          <p class="subtitle">Notre engagement :</p>

          <p class="subtitle-sentence">Un accompagnement pour faciliter la cession de votre entreprise.</p>

          <ul class="list-engagements">
            <li>
              <span class="number">1.</span>
              Evaluation financière
            </li>
            <li>
              <span class="number">2.</span>
              Préparation à la cession
            </li>
            <li>
              <span class="number">3.</span>
              Recherche d'acquéreur potentiel
            </li>
            <li>
              <span class="number">4.</span>
              Réseau EXPLORYS et partenaires
            </li>
            <li>
              <span class="number">5.</span>
              Mise en concurrence et négociation
            </li>
            <li>
              <span class="number">6.</span>
              Accompagnement jusqu'à la finalisation de la vente
            </li>
          </ul>
        </div>
      </v-col>

    </v-row>
  </v-container>
</template>


<script>
export default {
  name: 'Impulse',
  data() {
    return {}
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
}
</script>
