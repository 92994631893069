import Vue from 'vue'

// State
const state = {
  user: {
    id: String,
  },
  usersList: [],
  usersCount: 0,
  consultantsList: [],
}

// Getters
const getters = {
  getUser(state) {
    return state.user
  },
  getUsersList(state) {
    return state.usersList
  },
  getUsersCount(state) {
    return state.usersCount
  },
  getConsultantsList(state) {
    return state.consultantsList
  },
}

// Mutations
const mutations = {
  SET_USER(state, user) {
    state.user = user
  },
  SET_USERS(state, usersList) {
    state.usersList = usersList
  },
  SET_USERS_COUNT(state, count) {
    state.usersCount = count
  },
  DELETE_USER_BY_ID(state, idUser) {
    state.user = state.usersList.filter((n) => n.id !== idUser)
  },
  SET_CONSULTANTS(state, consultantsList) {
    state.consultantsList = consultantsList
  },
}

// Actions
const actions = {
  fetchUserById(store, userId) {
    return Vue.prototype.$API
      .getToken()
      .get('/users/' + userId)
      .then((response) => {
        // Set User
        this.commit('SET_USER', response.data[0])
        return Promise.resolve(response.data[0])
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  fetchUsersList(store, args) {
    let requestURL = '/users'
    // Optionnal arguments for search and pagination
    requestURL += Vue.prototype.$utils.toURLRequest(args)

    return Vue.prototype.$API
      .getToken()
      .get(requestURL)
      .then((response) => {
        // Set Users
        var usersList = []
        response.data.data.forEach((user) => {
          usersList.push(user)
        })
        // Set Users
        this.commit('SET_USERS_COUNT', response.data.count)
        this.commit('SET_USERS', usersList)
        return Promise.resolve(usersList)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  fetchConsultantsList(store) {
    return Vue.prototype.$API
      .getToken()
      .get('/users/consultants')
      .then((response) => {
        // Set Users
        var consultantsList = []
        response.data.data.forEach((user) => {
          const newConsultant = {
            value: user.id,
            text: user.firstname + ' ' + user.lastname,
          }
          consultantsList.push(newConsultant)
        })
        // Set Users
        this.commit('SET_CONSULTANTS', consultantsList)
        return Promise.resolve(consultantsList)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  createUser(store, newUser) {
    return Vue.prototype.$API
      .getToken()
      .post('/users', newUser)
      .then((response) => {
        return Promise.resolve(response.data.content)
      })
      .catch((e) => {
        return Vue.prototype.$API.displayErrors(e)
      })
  },
  patchUser(store, editUser) {
    return Vue.prototype.$API
      .getToken()
      .patch('/users/' + editUser.id, editUser)
      .then((response) => {
        this.commit('SET_USER', response.data.content)
        return Promise.resolve(response.data.content)
      })
      .catch((e) => {
        return Vue.prototype.$API.displayErrors(e)
      })
  },
  deleteUserById(store, idUser) {
    return Vue.prototype.$API
      .getToken()
      .delete('/users/' + idUser)
      .then((response) => {
        this.commit('DELETE_USER_BY_ID', idUser)
        return Promise.resolve(response.data.content)
      })
      .catch((e) => {
        return Vue.prototype.$API.displayErrors(e)
      })
  },
  resendWelcomeMail(store, idUser) {
    return Vue.prototype.$API
      .getToken()
      .get('/users/resend-welcome/' + idUser)
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((e) => {
        return Vue.prototype.$API.displayErrors(e)
      })
  },
}

// Export
export default {
  state,
  getters,
  mutations,
  actions,
}
