<template>
  <v-container id="experts" fluid :class="isSafari && !isMobile ? 'pb-80' : ''">
    <v-row justify="center">
      <v-col id="experts-txt" cols="12" lg="12">
        <h2>Nos experts à votre écoute</h2>
        <p>
          Qui mieux que des entrepreneurs pour accompagner les créateurs
          d’entreprise ? <br />
          Notre équipe est composée d’experts dans l’entrepreneuriat.
        </p>
      </v-col>
      <v-col id="experts-cards" cols="12" lg="12">
        <div v-if="!apiStatus" class="error-api-is-broken">
          <p>Un problème est survenu, veuillez re-essayer plus tard</p>
        </div>
        <v-row v-if="isLoading" justify-sm="center">
          <v-col
            v-for="i in 40"
            :key="i"
            cols="10"
            sm="4"
            md="3"
            lg="2"
            class="card"
          >
            <v-skeleton-loader type="card" />
          </v-col>
        </v-row>
        <v-row
          justify="center"
          align="stretch"
          class="experts-row"
          no-gutters
        >
          <v-col
            v-for="expert in expertsList"
            :key="expert.id"
            cols="10"
            sm="4"
            md="4"
            lg="2"
            class="card"
          >
            <v-row>
              <v-col cols="12">
                <v-img
                  v-if="expert.id_photo === 0 || !expert.id_photo"
                  :src="require('@images/avatar_defaut.png')"
                />
                <v-img v-else :src="expert.photo_data">
                  <v-btn
                    v-if="expert.link_linkedin"
                    class="btn-linkedin"
                    icon
                    :href="expert.link_linkedin"
                    target="_blank"
                  >
                    <v-icon class="icon-linkedin">fab fa-linkedin</v-icon>
                  </v-btn>
                </v-img>
              </v-col>
            </v-row>
            <v-container class="bottom-card">
              <div class="row-title">
                <p class="fullname">
                  {{ expert.firstname }}
                  <br />
                  {{ expert.lastname }}
                </p>
                <p class="position">
                  {{ expert.position }}
                </p>
              </div>
              <div class="row-content text-center d-flex align-center">
                <p class="description">
                  {{ '"' + expert.description + '"' }}
                </p>
              </div>
            </v-container>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'OurExperts',
  props: {
    fpObj: {
      type: Object,
      default: null,
    },
    isPosOne: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      apiStatus: true,
      onceReload: false,
      expertsList: [],
    }
  },
  computed: {
    ...mapGetters(['getExpertsList', 'getLoading']),
    gotExpertsList() {
      return this.getExpertsList
    },
    gotExpertsListx2() {
      return this.gotExpertsList.concat(this.voidExpertsList)
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    isLoading() {
      return this.getLoading
    },
    isSafari() {
      /* eslint-disable */
      var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
      return isSafari
      /* eslint-enable */
    }
  },
  watch: {
    // FIXME: FIX DEGUEU
    isPosOne() {
      this.reloadTrigger()
    }
  },
  mounted() {
    this.verifApiStatus()
    this.fetchExpertsList({
      order: 'order_card-asc',
    })
      .then((response) => {
        // Disable Loading
        this.setLoading(false)

        // Set variable here after fetch is done
        this.expertsList = this.gotExpertsList

        // Get props fp and rebuild after fetch
        if (this.fpObj) {
          this.fpObj.api.reBuild()
        }
      })
      .catch((e) => {
        // Disable Loading
        this.setLoading(false)
      })
  },
  methods: {
    ...mapActions(['fetchExpertsList', 'checkApiStatus', 'setLoading']),
    verifApiStatus() {
      this.checkApiStatus()
        .then((response) => {
          this.apiStatus = true
        })
        .catch((e) => {
          this.apiStatus = false
        })
    },
    reloadTrigger() {
      // Get props fp and rebuild after fetch
      if (this.fpObj) {
        this.fpObj.api.reBuild()
      }

      // :class="!isPosOne ? 'pb-1000' : ''"

    }
  },
}
</script>
