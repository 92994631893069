<template>
  <div>
    <Header v-if="!isMobile" class="vitrine" section="vitrine" />
    <HeaderMobile v-if="isMobile" section="vitrine" />

    <div id="container-vitrine">
      <router-view
        class="router-content"
        :class="$vuetify.breakpoint.mobile ? 'mobile' : ''"
      />
    </div>

    <!-- Cookies Banners -->
    <CookiesBanner v-if="!isMobile" />
    <CookiesBannerMobile v-if="isMobile" />

    <!-- RS Btn -->
    <div v-if="!isMobile && !isExplorateur && !isExplorersList && !isMentionsLegales" id="btns-rs">
      <v-btn
        tile
        fab
        small
        href="https://www.linkedin.com/company/explorys-incubateur"
        target="_blank"
      >
        <v-icon>
          fab fa-linkedin-in
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import Header from '@components/Header.vue'
import HeaderMobile from '@components/HeaderMobile.vue'
import CookiesBanner from '@components/CookiesBanner.vue'
import CookiesBannerMobile from '@components/CookiesBannerMobile.vue'

export default {
  components: {
    Header,
    HeaderMobile,
    CookiesBanner,
    CookiesBannerMobile,
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    isExplorateur() {
      return this.$route.path.includes('/explorateur/')
    },
    isExplorersList() {
      return this.$route.path.includes('/nos-explorateurs')
    },
    isMentionsLegales() {
      return this.$route.path.includes('/mentions-legales')
    }
  },
}
</script>
