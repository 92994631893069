var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"p-0",attrs:{"id":"file-management","fluid":""}},[_c('v-row',[(_vm.loggedUser.role !== 'SUPERADMIN')?_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('h3',[_vm._v("Documents Explorys")])]):_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('h3',[_vm._v("Gestion des fichiers Explorys")])]),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-text-field',{attrs:{"label":"Recherche","prepend-icon":"mdi-magnify","clear-icon":"mdi-close-circle-outline","clearable":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"files-btn",attrs:{"cols":"12","lg":"4"}},[(_vm.loggedUser.role === 'SUPERADMIN')?_c('v-btn',{attrs:{"dark":"","color":"primary"},on:{"click":_vm.openPanelCreateFile}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fa fa-plus")]),_vm._v(" Ajouter un fichier ")],1):_vm._e()],1)],1),_c('v-card',{attrs:{"id":"files-content"}},[_c('v-progress-linear',{attrs:{"indeterminate":_vm.loading}}),_c('v-treeview',{attrs:{"search":_vm.search,"filter":_vm.filter,"items":_vm.allItems,"selectable":_vm.isSuperAdmin,"item-key":"name","open-on-click":""},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(!item.file)?_c('v-icon',[_vm._v(" "+_vm._s(open ? 'mdi-folder-open' : 'mdi-folder')+" ")]):_c('v-icon',[_vm._v(" "+_vm._s(_vm.files[item.file])+" ")])]}},{key:"label",fn:function(ref){
var item = ref.item;
return [(item.description !== undefined)?_c('span',[_c('b',[_vm._v(_vm._s(item.title))]),_vm._v(" ("+_vm._s(item.name)+")"),_c('br'),_vm._v(" "+_vm._s(item.description)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"append",fn:function(ref){
var item = ref.item;
return [(item.file)?_c('span',[_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"fs-16-btn mr-2",attrs:{"dark":"","color":"green","icon":"","small":"","content":"Télécharger le fichier","loading":_vm.sendLoading},on:{"click":function($event){return _vm.downloadFile(item.id)}}},[_c('v-icon',[_vm._v(" fa-download ")])],1),_vm._v(" "),(_vm.getLoggedUser.role === 'SUPERADMIN')?_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"fs-16-btn mr-2",attrs:{"color":"primary","dark":"","icon":"","small":"","content":"Editer les informations","loading":_vm.sendLoading},on:{"click":function($event){return _vm.openPanelEditFile(item.id)}}},[_c('v-icon',[_vm._v(" fa-edit ")])],1):_vm._e(),_vm._v(" "),(_vm.loggedUser.role === 'SUPERADMIN')?_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"fs-16-btn mr-2",attrs:{"color":"red","dark":"","icon":"","small":"","content":"Supprimer le fichier","loading":_vm.sendLoading},on:{"click":function($event){return _vm.confirmDeleteFile(item.id, item.name)}}},[_c('v-icon',[_vm._v(" far fa-trash-alt ")])],1):_vm._e()],1):_vm._e()]}}]),model:{value:(_vm.tree),callback:function ($$v) {_vm.tree=$$v},expression:"tree"}}),(_vm.loggedUser.role === 'SUPERADMIN' && _vm.tree.length)?_c('v-card-actions',[_c('v-btn',{attrs:{"color":"red","dark":"","small":"","loading":_vm.sendLoading},on:{"click":_vm.confirmDeleteAllFiles}},[_c('v-icon',{staticClass:"fs-11 mr-2"},[_vm._v(" far fa-trash-alt ")]),_vm._v(" Supprimer la séléction ")],1),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","dark":"","small":"","loading":_vm.sendLoading},on:{"click":_vm.resetSelectedTree}},[_c('v-icon',{staticClass:"fs-11 mr-2"},[_vm._v(" fas fa-times ")]),_vm._v(" Effacer la séléction ")],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }