import Vue from 'vue'

// State
const state = {
  event: {},
  nbEvents: 0,
  listEvents: [],
  listParticipants: [],
  showDialogEvent: false,
}

// Getters
const getters = {
  getEvent(state) {
    return state.event
  },
  getEvents(state) {
    return state.listEvents
  },
  getEventsCount(state) {
    return state.nbEvents
  },
  getShowDialogEvent(state) {
    return state.showDialogEvent
  },
  getListParticipants(state) {
    return state.listParticipants
  },
}

// Mutations
const mutations = {
  SET_EVENT(state, anEvent) {
    state.event = anEvent
  },
  SET_EVENTS(state, events) {
    state.listEvents = events
  },
  SET_NB_EVENTS(state, nb) {
    state.nbEvents = nb
  },
  SET_SHOW_DIALOG_EVENT(state, newState) {
    state.showDialogEvent = newState
  },
  SET_LIST_PARTICIPANTS(state, newList) {
    state.listParticipants = newList
  },
}

// Actions
const actions = {
  loadListEvents(store, status = 'ACTIVE') {
    return Vue.prototype.$API
      .getToken()
      .get('/events/list?status=' + status)
      .then((response) => {
        const eventList = []
        response.data.data.forEach((element) => {
          // const startDate = this.$utils.formatGenericDate(element.time_start)
          // const timeStart = this.$utils.formatTime(element.time_start)
          // const endDate = this.$utils.formatGenericDate(element.time_end)
          // const timeEnd = this.$utils.formatTime(element.time_end)

          // const finalStartDate = startDate + ' ' + timeStart + ':00'
          // const finalEndDate = endDate + ' ' + timeEnd + ':00'

          if (element.status === status) {
            eventList.push({
              id: element.id,
              color: 'amber',
              desc: element.description,
              place: element.place,
              name: element.title,
              timed: true,
              start: element.time_start,
              end: element.time_end,
              planner: element.id_planner,
              firstname: element.firstname[0],
              lastname: element.lastname[0],
              participants: element.participants,
              status: element.status,
              safaridate_start: element.safaridate_start,
              safaridate_end: element.safaridate_end,
            })
          }
        })

        this.commit('SET_NB_EVENTS', response.data.count)
        this.commit('SET_EVENTS', eventList)
        return Promise.resolve(response)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  getEventById(store, idEvent) {
    return Vue.prototype.$API
      .getToken()
      .get('/events/get/' + idEvent)
      .then((response) => {
        this.commit('SET_EVENT', response.data)
        return Promise.resolve(response)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  saveEvent(store, anEvent) {
    return Vue.prototype.$API
      .getToken()
      .post('/events/create', anEvent)
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  updateEvent(store, anEvent) {
    return Vue.prototype.$API
      .getToken()
      .patch('/events/update/' + anEvent.id, anEvent)
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  deleteEvent(store, idEvent) {
    return Vue.prototype.$API
      .getToken()
      .delete('/events/delete/' + idEvent)
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  addParticipant(store, participation) {
    return Vue.prototype.$API
      .getToken()
      .post('/events/participate/add', participation)
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  removeParticipant(store, participation) {
    return Vue.prototype.$API
      .getToken()
      .delete(
        '/events/participate/' +
          participation.idEvent +
          '/remove/' +
          participation.idUser
      )
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  cleanEventActif(store) {
    this.commit('SET_EVENT', {})
  },
  setShowDialogEvent(store, newState) {
    this.commit('SET_SHOW_DIALOG_EVENT', newState)
  },
  loadListParticipants(store, listIds) {
    return Vue.prototype.$API
      .getToken()
      .post('/events/participants', listIds)
      .then((response) => {
        var participants = []
        response.data.forEach((element) => {
          participants.push({
            id: element[0],
            name: element[1] + ' ' + element[2],
            email: element[3],
            organisation: element[4],
          })
        })
        this.commit('SET_LIST_PARTICIPANTS', participants)
        return Promise.resolve(participants)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
}

// Export
export default {
  state,
  getters,
  mutations,
  actions,
}
